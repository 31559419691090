/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Service Add/Edit Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 07/July/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getProductVariant, getLoggedInUser, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { basicValidationSchema } from "../validationSchema/index";
import { fetchSingleService, addService, updateService } from "../../../../../middleware/services/servicesApi";
import { TextField, Dropdown, MultiSelect, DatePicker } from "../formFields";
import { fetchCustomForms } from "../../../../../middleware/services/cmsApi";
import { fetchClientUser, fetchExternalUser } from "../../../../../middleware/services/userApi";
import { SERVICE_TYPE_OPTIONS, SERVICE_STATUS_OPTIONS } from "../formFieldOptions";
import _ from "underscore";
import AccessDenied from "../../../../components/accessDenied";
import { ROLES } from "../../../../../constants";

const initialState = {
  pVariant: getProductVariant(),
  serviceUniqueCode: "",
  serviceSubject: "",
  serviceType: "",
  serviceForm: "",
  serviceConductedDate: "",
  serviceCompletedDate: "",
  serviceStatus: "Active",
  serviceUsers: [],
  serviceExpert: [],
};
const Basic = (props) => {
  const dispatch = useDispatch();
  const serviceId = props.serviceId;
  const history = useHistory();
  const productVariant = getProductVariant();
  const loggedInUser = getLoggedInUser();
  const [editData, setEditData] = useState(null);
  const [users, setClientUsers] = useState(null);
  const [forms, setForms] = useState([]);
  const [experts, setExternalExperts] = useState(null);

  /*
       @Description : Formik Initialization
   */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(basicValidationSchema),
  });

  /*
       @Description : BreadCumb Defination
   */
  const breadCrumbLinks = [
    { linkUrl: "/services", linkName: props?.moduleName || "", linkActive: false },
    {
      linkUrl: serviceId ? `/services/${serviceId}/edit` : "/services/add",
      linkName: (editData && editData?.serviceUniqueCode) || "Add new",
      linkActive: false,
    },
    {
      linkUrl: `/services/${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  /*
       @Description : Fetch Single Service Details
   */
  const fetchServiceDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "serviceUniqueCode",
      "serviceSubject",
      "serviceType",
      "serviceStatus",
      "serviceForm",
      "serviceUsers",
      "serviceExpert",
      "actualStartDate",
      "actualEndDate",
    ];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleService(serviceId, params).then((res) => {
      let data = (res.data && res.data.data) || {};
      setEditData(data);

      if (data) {
        setTimeout(() => {
          formik.setValues({
            serviceUniqueCode: data.serviceUniqueCode,
            serviceSubject: data.serviceSubject,
            serviceType: data.serviceType,
            serviceStatus: data.serviceStatus,
            actualStartDate: data.actualStartDate || "",
            actualEndDate: data.actualEndDate || "",
            serviceForm: data.serviceForm && data.serviceForm.id,
            serviceUsers: data.serviceUsers && _.pluck(data.serviceUsers, "userId"),
            serviceExpert: data.serviceExpert && _.pluck(data.serviceExpert, "userId"),
          });
        }, 300);
      }
      dispatch(hideLoader());
    });
  };

  /*
      @Description : Load  Basic Details on Edit and 
    */

  useEffect(() => {
    if (serviceId) {
      !editData && fetchServiceDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/services"));
    getClientUsers();
    getExternalExperts();
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, props.serviceId, props.selectedIndex, editData]);

  /**
   * @description get client users
   */
  const getClientUsers = async function () {
    let params = {};
    params.filters = [["userActive", "eq", 1]];
    let fields = ["id", "userFname", "userLname"];
    params.fields = fields;
    params.limit = 2000;
    params.sort = "userFname:asc";
    const response = await fetchClientUser(params);
    if (response.data && response.data.data) {
      const userData =
        response &&
        response.data &&
        response.data.data.map((typesData) => {
          return {
            userid: typesData["id"],
            fullname: `${typesData["userFname"]} ${typesData["userLname"]}`,
            userFname: typesData["userFname"],
            userLname: typesData["userLname"],
          };
        });
      setClientUsers(userData);
    }
  };

  /**
   * @description get external experts
   */
  const getExternalExperts = async function () {
    let params = {};
    params.filters = [
      ["userActive", "eq", 1],
      ["userRole", "eq", ROLES.EXPERT],
    ];
    let fields = ["userFname", "userLname", "userEmail"];
    params.fields = fields;
    params.limit = 10;
    params.sort = "userFname:asc";
    const response = await fetchExternalUser(params);
    if (response.data && response.data.data) {
      const userData =
        response &&
        response.data &&
        response.data.data.map((typesData) => {
          return {
            userid: typesData["id"],
            fullname: `${typesData["userFname"]} ${typesData["userLname"]}`,
            userFname: typesData["userFname"],
            userLname: typesData["userLname"],
          };
        });
      setExternalExperts(userData);
    }
  };

  /**
   * @description get Service Forms
   */
  useEffect(() => {
    if (formik.values.serviceType) {
      let params = {};
      params.filters = [
        ["productVariant.isLive", "eq", "YES"],
        ["productVariant.productName", "eq", productVariant],
        ["formIsDeleted", "eq", "NO"],
        ["formIsActive", "eq", "YES"],
        ["formType", "eq", formik.values.serviceType],
      ];
      fetchCustomForms(params)
        .then((res) => {
          let { data = [] } = res.data;
          setForms(
            data.map((formData) => {
              return { value: formData.id, label: formData.formTitle };
            })
          );
        })
        .catch((err) => {
          console.log("err fetchForms :: ", err);
        });
    }
  }, [formik.values.serviceType]);

  /*
       @Description : Save Service Basic Details
   */
  useEffect(() => {
    if (props.saveBasic) {
      formik.validateForm().then((res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          let payload = {};
          const {
            serviceSubject,
            serviceType,
            serviceStatus,
            serviceForm,
            serviceUsers = [],
            serviceExpert = [],
            actualStartDate,
            actualEndDate,
          } = formik.values;
          payload.serviceSubject = serviceSubject;
          payload.serviceType = serviceType;
          payload.serviceStatus = serviceStatus;

          if (actualStartDate) {
            let ConductedDate = new Date(actualStartDate);
            ConductedDate.setHours(ConductedDate.getHours() + 1);
            payload.actualStartDate = ConductedDate;
          }

          if (actualEndDate) {
            let CompletedDate = new Date(actualEndDate);
            CompletedDate.setHours(CompletedDate.getHours() + 1);
            payload.actualEndDate = CompletedDate;

            if (!actualStartDate) {
              payload.actualStartDate = CompletedDate;
            }
          } else {
            payload.actualEndDate = null;
          }

          payload.serviceCreatedBy = { userId: loggedInUser._id, userFname: loggedInUser.userFname, userLname: loggedInUser.userLname };
          const selectedUsers =
            users &&
            users
              .map((u) => {
                return serviceUsers.includes(u.userid) && { userId: u.userid, userFname: u.userFname, userLname: u.userLname };
              })
              .filter((v) => {
                return typeof v === "object";
              });
          payload.serviceUsers = selectedUsers;

          const selectedExpert =
            experts &&
            experts
              .map((u) => {
                return serviceExpert.includes(u.userid) && { userId: u.userid, userFname: u.userFname, userLname: u.userLname };
              })
              .filter((v) => {
                return typeof v === "object";
              });
          payload.serviceExpert = selectedExpert;

          if (serviceId) {
            delete payload.serviceType;
            delete payload.serviceCreatedBy;
            updateService(serviceId, payload)
              .then((res) => {
                dispatch(actionSuccess("Customer Service details updated successfully"));
              })
              .catch((err) => {
                dispatch(actionError(err?.data?.message || "Something went wrong"));
              });
          } else {
            payload.serviceForm = { formId: serviceForm };
            payload.productVariant = [{ productName: productVariant, isLive: "NO" }];
            addService(payload)
              .then((res) => {
                if (res) {
                  dispatch(actionSuccess("Customer Service details  saved successfully"));
                  history.replace({
                    pathname: `/services/${res.data.data.id}/edit`,
                    hash: "",
                    state: { serviceId: res.data.data.id },
                  });
                  props.tabRef.current.enableTab(1, true);
                  props.tabRef.current.enableTab(2, true);
                  props.tabRef.current.select(0);
                } else {
                  dispatch(actionError("Something went wrong"));
                }
              })
              .catch((err) => {
                dispatch(actionError(err?.data?.message || "something went wrong"));
              });
          }
        }
      });
      props.handleSave(false);
    }
  }, [props.saveBasic]);
  return (
    <>
      {props?.interfaceAccess ? (
        <div className="e-content pt-4 mx-2" style={{ padding: "1%", marginTop: "1%", backgroundColor: "#ffffff", width: "98.7%" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  {serviceId && formik.values.serviceUniqueCode && (
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          formik={formik}
                          readOnly
                          type="text"
                          id="serviceUniqueCode"
                          placeholder="Unique Code"
                          name="serviceUniqueCode"
                          showClearButton={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    formik={formik}
                    type="text"
                    id="serviceSubject"
                    name="serviceSubject"
                    placeholder="Service Subject *"
                    required="required"
                    maxLength="150"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  {SERVICE_TYPE_OPTIONS && (
                    <Dropdown
                      formik={formik}
                      sortOrder="Acending"
                      placeholder="Service Type *"
                      id="serviceType"
                      name="serviceType"
                      enabled={serviceId ? false : true}
                      fields={{ text: "label", value: "value" }}
                      dataSource={SERVICE_TYPE_OPTIONS}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  {forms && forms.length > 0 && (
                    <Dropdown
                      formik={formik}
                      sortOrder="Acending"
                      placeholder="Service Form *"
                      id="serviceForm"
                      name="serviceForm"
                      value={editData && editData.serviceForm.id}
                      enabled={serviceId ? false : true}
                      fields={{ text: "label", value: "value" }}
                      dataSource={forms}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {users && (
                    <MultiSelect
                      formik={formik}
                      mode="Box"
                      sortOrder="Acending"
                      showSelectAll={false}
                      placeholder="Select Client Users"
                      id="serviceUsers"
                      name="serviceUsers"
                      allowCustomValue={true}
                      value={formik.values.serviceUsers}
                      fields={{ text: "fullname", value: "userid" }}
                      dataSource={users}
                      noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  {SERVICE_STATUS_OPTIONS && (
                    <Dropdown
                      formik={formik}
                      sortOrder="Acending"
                      placeholder="Service Status *"
                      id="serviceStatus"
                      name="serviceStatus"
                      fields={{ text: "label", value: "value" }}
                      dataSource={SERVICE_STATUS_OPTIONS}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  {experts && (
                    <MultiSelect
                      formik={formik}
                      mode="CheckBox"
                      sortOrder="Acending"
                      showSelectAll={false}
                      placeholder="Service Expert (Multiple Select)"
                      id="serviceExpert"
                      name="serviceExpert"
                      allowCustomValue={true}
                      value={formik.values.serviceExpert}
                      fields={{ text: "fullname", value: "userid" }}
                      dataSource={experts}
                      noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <DatePicker
                    formik={formik}
                    properties={{
                      placeholder: "Actual Start Date",
                      id: "actualStartDate",
                      name: "actualStartDate",
                      format: "dd MMM yyyy",
                      // showTodayButton: false,
                      allowEdit: false,
                      floatLabelType: "Auto",
                      openOnFocus: true,
                      max: getDateFromMongoDate(new Date()),
                    }}
                  />
                </div>
                {
                  <div className="col-md-3">
                    <DatePicker
                      formik={formik}
                      // required="required"
                      properties={{
                        placeholder: "Actual End Date",
                        id: "actualEndDate",
                        name: "actualEndDate",
                        format: "dd MMM yyyy",
                        // showTodayButton: false,
                        floatLabelType: "Auto",
                        allowEdit: false,
                        openOnFocus: true,
                        max: getDateFromMongoDate(new Date()),
                      }}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Basic;
