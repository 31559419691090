/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase Folder Items
   Summary : My Briefcase Folder Items
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				    : Satya Prakash Mall
================================================================ 
*/
import React, { Fragment, useEffect, useRef } from "react";
import classes from "./myBriefcase.module.css";
import { getLoggedInUser } from "../../../utilities";
import moment from "moment";
import { Tooltip } from "@mui/material";

function Items({
  folderList,
  categoryId,
  updateCategoryId,
  setSelectedDoc,
  setBriefcaseFilterValue,
  setBriefcaseSortValue,
  setBriefcaseCardsValue,
  renameDeleteId,
  showMenu,
  menuClickHandler,
  handleRenameFolderShow,
  handleDeleteShow,
  setShowMenu,
  handleShareShow,
  handleViewAccessShow,
}) {
  const wrapperRef = useRef(null);
  const user = getLoggedInUser();
  // function to close the menu modal on clicking outside
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const handleCount = (count) => {
    if (count === 0) return 0;
    if (count < 10) return `0${count}`;
    else return count;
  };

  return (
    <Fragment>
      {folderList &&
        folderList?.length !== 0 &&
        folderList?.map((item) => (
          <div id={`list${item.id}`} className={classes.FilterList} key={item.id}>
            <div className={`${classes.bookMarkListBox} ${item.id === categoryId ? classes.newListActive : ""}`}>
              <div className={classes.titleIconDiv}>
                <p>
                  {item?.categoryShared?.length > 0 ? (
                    <div>
                      {user?._id !== item?.categoryCreatedByUserId ? (
                        <img src="/assets/Shared_me_folder_icon.svg" alt="Shared_Me_Folder_icon"></img>
                      ) : (
                        <img src="/assets/Shared_folder_icon.svg" alt="Shared_Folder_icon"></img>
                      )}
                    </div>
                  ) : (
                    <img src="/assets/folder.svg" alt="folder"></img>
                  )}
                </p>
                <div className={classes.bookMarkListContent}>
                  <p
                    onClick={() => {
                      updateCategoryId(item.id);
                      setSelectedDoc([]);
                      setBriefcaseFilterValue("All");
                      setBriefcaseSortValue("SortBySequence");
                      setBriefcaseCardsValue([]);
                    }}
                    className={`${classes.bookmarkTitle} ${item.id === categoryId ? classes.clickActive : ""}`}
                  >
                    {item.categoryName}
                  </p>
                  <div className={classes.briefcaseFolderDetail}>
                    <div>
                      <p className={classes.bookMarkView}>Files</p>
                      <p className={classes.briefcaseFolderValue}>{handleCount(item.presentationCount)}</p>
                    </div>
                    <div>
                      <p className={classes.bookMarkView}>Created Date</p>
                      <p className={classes.briefcaseFolderValue} style={{ width: "74px" }}>
                        {moment(item.categoryCreatedDate).format("DD MMM YYYY")}
                      </p>
                    </div>
                    <div>
                      <p className={classes.bookMarkView}>Created By</p>
                      <Tooltip
                        title={`${item.categoryCreatedByUserFname} ${item.categoryCreatedByUserLname}`
                          .toLowerCase()
                          .split(" ")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ")}
                        arrow
                      >
                        <p className={classes.briefcaseFolderValue} style={{ width: "80px", cursor: "default" }}>
                          {`${item.categoryCreatedByUserFname} ${item.categoryCreatedByUserLname}`
                            .toLowerCase()
                            .split(" ")
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <span className={classes.deleteRename}>
                <img
                  src={`${item.id === renameDeleteId && showMenu ? "/assets/active-dots-menu.svg" : "/assets/dots-menu.svg"}`}
                  alt="dots"
                  onClick={() => menuClickHandler(item.id, item.categoryName)}
                ></img>
                {showMenu && renameDeleteId === item.id && (
                  <div className={classes.select_list_content} ref={wrapperRef}>
                    {user?._id === item?.categoryCreatedByUserId && (
                      <div className={classes.select_btn}>
                        <p onClick={handleRenameFolderShow}>Rename</p>
                      </div>
                    )}
                    {/* <div className={classes.divider}></div> */}
                    {user?._id === item?.categoryCreatedByUserId && (
                      <div className={classes.select_btn}>
                        <p onClick={() => handleDeleteShow(item?.categoryShared)}>Delete </p>
                      </div>
                    )}
                    {user?._id === item?.categoryCreatedByUserId && (
                      <div className={classes.select_btn}>
                        <p onClick={() => handleShareShow(item?.categoryShared)}>Share</p>
                      </div>
                    )}
                    {user?._id !== item?.categoryCreatedByUserId && (
                      <div className={classes.select_btn}>
                        <p onClick={() => handleViewAccessShow(item?.categoryShared)}>View Access</p>
                      </div>
                    )}
                  </div>
                )}
              </span>
            </div>
          </div>
        ))}
    </Fragment>
  );
}

export default Items;
