const CUSTOM_WIDGET_STATE = {
  combinedWidgetData: [],
  selectedWidgetData: {},
  rightWidgetList: [],
  previewContent: [],
  isLoading: true,
  relatedContentList: [],
};

export default (state = CUSTOM_WIDGET_STATE, action) => {
  switch (action.type) {
    case "CUSTOM_WIDGET_COMBINED_DATA": {
      return { ...state, combinedWidgetData: action.payload };
    }

    case "RESET_CUTOM_WIDGET_REDUX": {
      return {
        ...state,
        combinedWidgetData: [],
      };
    }
    case "SELECTED_CUTOM_WIDGET_DATA": {
      return { ...state, selectedWidgetData: action.payload };
    }
    case "RIGHT_SIDE_WIDGET_LIST": {
      return {
        ...state,
        rightWidgetList: action.payload,
      };
    }
    case "PREVIEW_CONTENT": {
      return {
        ...state,
        previewContent: action.payload,
      };
    }
    case "WIDGET_HIDE_LOADER": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "SIGNAL_RELATED_WIDGET_LIST": {
      return {
        ...state,
        relatedContentList: action.payload,
      };
    }

    default:
      return state;
  }
};
