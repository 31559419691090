/**
 * @fileoverview This file contains common datagrid component.
 * @date 03/Mar/2023
 * @author Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */

import { DataUtil } from "@syncfusion/ej2-data";
import {
  ColumnChooser,
  ColumnMenu,
  ContextMenu,
  Filter,
  Freeze,
  GridComponent,
  Inject,
  Page,
  Resize,
  RowDD,
  Selection,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import "./styles.css";
import { Stack } from "@mui/material";
import { IoMdFunnel } from "react-icons/io";
import * as CONFIG from "../../../config";

Datagrid.propTypes = {
  name: PropTypes.string.isRequired,
  age: PropTypes.number,
  isStudent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  rowData: PropTypes.array,
  setPage: PropTypes.func,
  children: PropTypes.element,
  height: PropTypes.string,
  filters: PropTypes.array,
  sort: PropTypes.string,
  setFilters: PropTypes.func,
  setSort: PropTypes.func,
  showBottomBorder: PropTypes.bool,
  page: PropTypes.string,
  total: PropTypes.string,
  resetDataGridFilter: PropTypes.bool,
  allowSorting: PropTypes.bool,
  setInputValue: PropTypes.func,
  allowPaging: PropTypes.bool,
  selectedRows: PropTypes.array,
};
const FilterSettings = {
  type: "Menu",
  operators: {
    stringOperator: [
      { value: "equal", text: "Equal" },
      { value: "notequal", text: "Not Equal" },
      { value: "contains", text: "Contains" },
      {
        value: "startswith",
        text: "Starts With",
      },
      {
        value: "endswith",
        text: "Ends With",
      },
    ],
    numberOperator: [
      { value: "equal", text: "Equal" },
      { value: "notequal", text: "Not Equal" },
    ],
    dateOperator: [
      {
        value: "equal",
        text: "Equal",
      },
      { value: "notequal", text: "Not Equal" },
    ],
  },
};

function generatePaginationText(info) {
  const startIndex = (info.currentPage - 1) * info.pageSize + 1;
  const endIndex = Math.min(startIndex + info.pageSize - 1, info.totalRecordsCount);
  if (info.totalRecordsCount === 0) {
    return `No record found`;
  }
  return `${startIndex} - ${endIndex} of ${info.totalRecordsCount} `;
}

function GridToolbar(props) {
  const { children } = props;
  return (
    <div
      style={{
        backgroundColor: "#373D41",
        height: "48px",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        {children}
      </div>
    </div>
  );
}

function Datagrid(props) {
  const {
    rowData,
    children,
    height,
    setFilters,
    setSort,
    showBottomBorder = true,
    resetDataGridFilter,
    allowSorting = true,
    allowPaging = true,
    showGridToolBar = false,
    toolbar,
    showHelpdeskEmptyGridMessage,
    customEmptyGridMessage,
    showCustomEmptyGridMessage,
    customStyle = { padding: "0px 16px 0px 16px" },
    allowFiltering = true,
    setSelectedRows,
    allowSelection = false,
    selectedRows,
    disableCheckBoxSelection = false,
    rowDataBound,
    id = "",
  } = props;
  const dataGridRef = useRef(null);
  const [pageCount, setPageCount] = useState(3);

  /**
   * @description Function used as Reset Filters button handler.
   */
  const handleResetFilters = () => {
    dataGridRef?.current?.clearFiltering();
    dataGridRef?.current?.clearRowSelection();
    dataGridRef?.current?.clearSorting();
    setFilters?.([]);
    setSort?.("");
    if (showCustomEmptyGridMessage) {
      const rows = dataGridRef?.current?.element.querySelector(".rows-per-page");
      if (rows) rows.style.display = "block";
    }
  };

  const handleDataBound = () => {
    if (!dataGridRef?.current) return;
    dataGridRef.current.hideScroll();
    selectedRows?.length && dataGridRef?.current?.selectRows?.(selectedRows);
    const pager = dataGridRef.current.pageSettings?.properties;
    if (pager?.totalRecordsCount > 0) {
      handlePageCount();
      handleTextChange();
      handleHandlepageCountChange();

      const gridContent = dataGridRef?.current?.element.querySelector(".e-frozencontent");
      if (gridContent) {
        gridContent.style.display = "block";
        gridContent.style.borderLeft = "1px solid #DBDBDD !important";
        gridContent.style.height = "max-content";
      }
      const pageContainer = dataGridRef?.current?.element.querySelector(".e-pagercontainer");
      if (pageContainer) pageContainer.style.visibility = "visible";
    } else handleNoRecords();
  };

  function handleTextChange() {
    let textBox = dataGridRef?.current?.element.querySelector(".e-pagesizes");
    const rows = dataGridRef?.current?.element.querySelector(".rows-per-page");
    const pagesizes = dataGridRef?.current?.element.querySelector(".e-pagerdropdown");
    if (pagesizes) pagesizes.style.display = "inline-block";
    if (rows) rows.style.display = "inline";
    const prev = dataGridRef?.current?.element.querySelector(".e-prev.e-icons.e-icon-prev");
    if (prev) prev.innerHTML = "Previous";
    const next = dataGridRef?.current?.element.querySelector(".e-next.e-icons.e-icon-next");
    if (next) next.innerHTML = "Next";
    let existingText = textBox.textContent.trim();
    const searchText = "Rows per page";
    if (!existingText.includes(searchText)) {
      const newMsg = document.createElement("div");
      newMsg.className = "rows-per-page";
      newMsg.innerHTML = searchText;
      textBox.insertBefore(newMsg, textBox.firstChild);
    }
  }

  function handleHandlepageCountChange() {
    let pageBox = dataGridRef?.current?.element.querySelector(".e-pagesizes");
    const element = dataGridRef?.current?.element.querySelector(".e-pagesizes-message");
    const paginationInfo = dataGridRef.current.pageSettings?.properties;
    if (!element) {
      const newText = document.createElement("div");
      newText.className = "e-pagesizes-message";
      newText.innerHTML = generatePaginationText(paginationInfo);
      newText.style.fontWeight = "normal !important";
      pageBox.insertBefore(newText, pageBox.lastChild);
    } else {
      element.innerHTML = generatePaginationText(paginationInfo);
      element.style.fontWeight = "normal !important";
    }
  }

  function handlePageCount() {
    const width = window.innerWidth;
    if (width < 600) {
      setPageCount(1);
    }
  }

  function handleColumnChooser(e) {
    if (dataGridRef?.current?.columnChooserSettings && dataGridRef?.current?.columnChooserModule) {
      dataGridRef.current.columnChooserSettings = { operator: "contains" };
      dataGridRef?.current?.columnChooserModule?.openColumnChooser(e.clientX - 250, 0);
    }
  }

  function handleNoRecords() {
    if (!dataGridRef?.current) return;
    const pageContainer = dataGridRef?.current?.element.querySelector(".e-pagercontainer");
    const pagesizes = dataGridRef?.current?.element.querySelector(".e-pagerdropdown");
    const gridContent = dataGridRef?.current?.element.querySelector(".e-frozencontent");
    const pageMessage = dataGridRef?.current?.element.querySelector(".e-pagesizes-message");
    const rows = dataGridRef?.current?.element.querySelector(".rows-per-page");
    const movableContent = dataGridRef?.current?.element.querySelector(".e-movablecontent");
    if (pageContainer) pageContainer.style.visibility = "hidden";
    if (pagesizes) pagesizes.style.display = "none";
    if (gridContent && showHelpdeskEmptyGridMessage) gridContent.style.display = "none";
    if (pageMessage) {
      pageMessage.innerHTML = "";
    }
    if (rows) rows.style.display = "none";
    if (movableContent) movableContent.style.border = "none";
  }

  /**
   * @description Function that is triggered when row is selected or deselected.
   */
  function handleRowSelection() {
    if (!dataGridRef?.current) return;
    const selectedRows = dataGridRef?.current?.getSelectedRecords();
    setSelectedRows?.(selectedRows);
  }

  const handleRowDataBound = (args) => {
    if (!disableCheckBoxSelection) return;
    rowDataBound?.(args);
  };

  useEffect(() => {
    handleResetFilters();
  }, [resetDataGridFilter]);

  return (
    <div className="gridContainer" style={window.innerWidth > 820 ? customStyle : { padding: "16px 0px" }}>
      {rowData?.length > 0 && showGridToolBar && (
        <GridToolbar>
          <Stack spacing={2} direction="row" pl={2} sx={{ width: "50%", alignItems: "center" }}>
            {toolbar?.gridTitle && <div style={{ fontSize: "18px", fontWeight: "700", color: "white" }}>{toolbar?.gridTitle}</div>}
          </Stack>
          <Stack spacing={2} direction="row" pr={2} sx={{ alignItems: "center" }}>
            {toolbar?.showClearFilterButton && (
              <button className="btn btn-outline-white active mr-sm-2 btn-sm  btn-dark " id="clearFilter" onClick={handleResetFilters}>
                <i className="pr-1">
                  <IoMdFunnel />
                </i>
                Reset Filters
              </button>
            )}
            {toolbar?.showColumnChooserButton && (
              <button className="btn btn-outline-white active  btn-sm btn btn-dark" id="openColumnChooser" onClick={(e) => handleColumnChooser(e)}>
                <i className="fa fa-columns pr-1"></i>Column Chooser
              </button>
            )}
          </Stack>
        </GridToolbar>
      )}
      {rowData && rowData?.length > 0 && (
        <div
          style={{
            height: "100%",
            maxWidth: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <GridComponent
            ref={dataGridRef}
            dataSource={DataUtil.parse.parseJson(rowData)}
            gridLines="Horizontal"
            height={height || "100%"}
            allowSorting={allowSorting}
            allowFiltering={allowFiltering}
            allowSelection={allowSelection}
            enableHeaderFocus
            enableStickyHeader
            loadingIndicator={{
              indicatorType: "Spinner",
            }}
            filterSettings={FilterSettings}
            showColumnChooser
            dataBound={handleDataBound}
            style={{
              height: "100%",
              maxHeight: "100%",
              maxWidth: "100%",
              overflowY: "auto",
              overflowX: "auto",
              borderBottom: showBottomBorder ? "1px solid rgba(209,213,219,0.5)" : "none",
            }}
            statelessTemplates={["directiveTemplates"]}
            enableHover={false}
            pageSettings={{
              pageSize: 10,
              pageSizes: CONFIG.PAGE_SIZE_RANGE || [10, 20, 50, 100],
              pageCount: pageCount,
            }}
            allowPaging={allowPaging}
            Toolbar
            rowSelected={handleRowSelection}
            rowDeselected={handleRowSelection}
            selectionSettings={{ checkboxOnly: true }}
            rowDataBound={handleRowDataBound}
            id={id}
          >
            {children}
            <Inject services={[Freeze, Page, Sort, Filter, Resize, Selection, ColumnChooser, ColumnMenu, ContextMenu, RowDD, Toolbar]} />
          </GridComponent>
        </div>
      )}
      {!rowData?.length && showCustomEmptyGridMessage && <div className="EmptyCenteredText">{customEmptyGridMessage}</div>}
    </div>
  );
}

export default memo(Datagrid);
