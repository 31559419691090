/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA Add/Edit Page 
----------------------------------------------------------------
    Creation Details
    Date Created				: 04/June/2021
    Author						: Sandeep K. Sharma
================================================================
*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getProductVariant, getLoggedInUser, getDateFromMongoDate } from "../../../../../utilities";
import { actionSuccess, actionError, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { overviewValidationSchema } from "../validationSchema/index";
import { fetchSingleKia, addKia, updateKia } from "../../../../../middleware/services/kiaApi";
import { TextField, Dropdown, CkEditor, MultiSelect, ToggleSwitch, DatePicker } from "../formFields";
import { fetchTaxonomyTrendIntern } from "../../../../../middleware/services/cmsApi";
import { fetchClientUser, fetchExternalUser } from "../../../../../middleware/services/userApi";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import ActionButtons from "../actionButtons";
import { KIA_STATUS } from "../formFieldOptions";
import _ from "underscore";
import AccessDenied from "../../../../components/accessDenied";
import { ROLES } from "../../../../../constants";

ckEditorMinimalConfig.toolbarGroups.push({ name: "insert", groups: ["insert"] });
ckEditorMinimalConfig.toolbarGroups.push({ name: "tools", groups: ["tools"] });

const initialState = {
  pVariant: getProductVariant(),
  kiaName: "",
  kiaDescription: "",
  kiaCurrentResearchStartups: "",
  customKia: "No",
  kiaStatus: "Draft",
  kiaClientUsers: [],
  kiaIndustryTag: "",
  kiaScore: 0,
  kiaNextUpdateDate: "",
  kiaExpert: [],
  kiaExpertNetworkStrength: "",
  kiaImpactAreaSurveyContributors: "",
  kiaClientExpertEngagements: "",
};
const Overview = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const kiaId = props.kiaId;
  const tabRef = useState(0);
  const history = useHistory();
  const productVariant = getProductVariant();
  const [disableToggle, setDisableToggle] = useState("No");
  const [editData, setEditData] = useState(null);
  const [users, setClientUsers] = useState(null);
  const [experts, setExternalExperts] = useState(null);
  const [industries, setIndustries] = useState([]);
  const loggedInUser = getLoggedInUser();
  const [selectedIndex, setSelectedIndex] = useState(0);
  /*
       @Description : Formik Initialization
   */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(overviewValidationSchema),
  });

  /*
       @Description : BreadCumb Defination
   */
  const breadCrumbLinks = [
    { linkUrl: "/kia", linkName: aR.moduleName, linkActive: false },
    { linkUrl: kiaId ? `/kia/${kiaId}/edit` : "/kia/add", linkName: (editData && editData?.kiaName) || "Add New", linkActive: false },
    {
      linkUrl: `/kia/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  /*
       @Description : Fetch Single KIA Details
   */
  const fetchKiaDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "kiaName",
      "kiaDescription",
      "kiaCurrentResearchStartups",
      "customKia",
      "kiaStatus",
      "kiaNextUpdateDate",
      "kiaClientUsers",
      "kiaIndustryTag",
      "kiaScore",
      "kiaExpert",
      "kiaExpertNetworkStrength",
      "kiaImpactAreaSurveyContributors",
      "kiaClientExpertEngagements",
    ];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];

    fetchSingleKia(kiaId, params).then((res) => {
      let data = res.data.data || {};
      if (data) {
        setTimeout(() => {
          setEditData(data);
          formik.setValues({
            kiaName: data.kiaName,
            kiaDescription: data.kiaDescription,
            kiaCurrentResearchStartups: data.kiaCurrentResearchStartups,
            customKia: data.customKia,
            kiaStatus: data.kiaStatus,
            kiaNextUpdateDate: data.kiaNextUpdateDate || "",
            kiaIndustryTag: data.kiaIndustryTag && _.pluck(data.kiaIndustryTag, "industryId")[0],
            kiaClientUsers: data.kiaClientUsers && _.pluck(data.kiaClientUsers, "userId"),
            kiaScore: data.kiaScore || 0,
            kiaExpertNetworkStrength: data.kiaExpertNetworkStrength || "",
            kiaImpactAreaSurveyContributors: data.kiaImpactAreaSurveyContributors || "",
            kiaClientExpertEngagements: data.kiaClientExpertEngagements || "",
            kiaExpert: data.kiaExpert && _.pluck(data.kiaExpert, "userId"),
          });
          dispatch(hideLoader());
        }, 200);
      }
    });
  };

  /**
   * @description get external experts
   */
  const getExternalExperts = async function () {
    let params = {};
    params.filters = [
      ["userActive", "eq", 1],
      ["userRole", "eq", ROLES.EXPERT],
    ];
    let fields = ["userFname", "userLname", "userEmail"];
    params.fields = fields;
    params.limit = 2000;
    params.sort = "userFname:asc";
    const response = await fetchExternalUser(params);
    if (response.data && response.data.data) {
      const userData =
        response &&
        response.data &&
        response.data.data.map((typesData) => {
          return {
            userid: typesData["id"],
            fullname: `${typesData["userFname"]} ${typesData["userLname"]}`,
            userFname: typesData["userFname"],
            userLname: typesData["userLname"],
          };
        });
      setExternalExperts(userData);
    }
  };

  useEffect(() => {
    if (formik.values.customKia === "No") {
      formik.values.kiaClientUsers = [];
    }
  }, [formik.values.customKia]);

  /*
      @Description : Load KIA Overview Details on Edit and 
    */

  useEffect(() => {
    if (kiaId) {
      dispatch(hideLoader());
      !editData && fetchKiaDetails();
    }
    getClientUsers();
    getExternalExperts();
    fetchIndustries();
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/kia"));
  }, [dispatch, props.kiaId, props.selectedIndex, editData, props.location.hash]);

  /**
   * @description get client users
   */
  const getClientUsers = async function () {
    let params = {};
    params.filters = [["userActive", "eq", 1]];
    let fields = ["id", "userFname", "userLname"];
    params.fields = fields;
    params.limit = 2000;
    params.sort = "userFname:asc";
    const response = await fetchClientUser(params);
    if (response?.data && response?.data?.data) {
      const userData =
        response &&
        response.data &&
        response.data.data.map((typesData) => {
          return {
            userid: typesData["id"],
            fullname: `${typesData["userFname"]} ${typesData["userLname"]}`,
            userFname: typesData["userFname"],
            userLname: typesData["userLname"],
          };
        });
      setClientUsers(userData);
    }
  };
  /**
   * @description fetch Industries for KIA Tagging
   */
  const fetchIndustries = () => {
    fetchTaxonomyTrendIntern({ filters: [["isTrendNode", "eq", 1]], sort: "trendParentName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { industryId: sTT.trendParentId, industryName: sTT.trendParentName };
        });
        setIndustries([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Industry Failed";
        dispatch(actionError(errMsg));
      });
  };

  /*
       @Description : Save KIA Overview Details
   */
  useEffect(() => {
    if (props.saveKiaOverView) {
      formik.validateForm().then((res) => {
        if (Object.keys(res).length) {
          const touched = {};
          Object.keys(res).map((field) => {
            touched[field] = true;
            return touched;
          });
          formik.setFormikState({ ...formik, touched: touched, errors: res });
        } else {
          let payload = {};
          const {
            kiaName,
            kiaDescription,
            kiaCurrentResearchStartups,
            customKia,
            kiaStatus,
            kiaClientUsers = [],
            kiaExpert = [],
            kiaIndustryTag,
            kiaScore,
            kiaNextUpdateDate,
            kiaExpertNetworkStrength,
            kiaImpactAreaSurveyContributors,
            kiaClientExpertEngagements,
          } = formik.values;

          if (customKia === "Yes") {
            setDisableToggle("Yes");
          }

          if (kiaNextUpdateDate) {
            let nextUpdateDate = new Date(kiaNextUpdateDate);
            nextUpdateDate.setHours(nextUpdateDate.getHours() + 1);
            payload.kiaNextUpdateDate = nextUpdateDate;
          } else {
            payload.kiaNextUpdateDate = null;
          }

          payload.kiaName = kiaName;
          payload.kiaDescription = kiaDescription;
          payload.kiaCurrentResearchStartups = kiaCurrentResearchStartups;
          payload.customKia = customKia;
          payload.kiaStatus = kiaStatus;
          payload.kiaScore = kiaScore;
          payload.kiaExpertNetworkStrength = kiaExpertNetworkStrength;
          payload.kiaImpactAreaSurveyContributors = kiaImpactAreaSurveyContributors;
          payload.kiaClientExpertEngagements = kiaClientExpertEngagements;

          const selectedExpert =
            experts &&
            experts
              .map((u) => {
                return kiaExpert.includes(u.userid) && { userId: u.userid, userFname: u.userFname, userLname: u.userLname };
              })
              .filter((v) => {
                return typeof v === "object";
              });
          payload.kiaExpert = selectedExpert;

          const selectedUsers =
            users &&
            users
              .map((u) => {
                return kiaClientUsers.includes(u.userid) && { userId: u.userid, userFname: u.userFname, userLname: u.userLname };
              })
              .filter((v) => {
                return typeof v === "object";
              });
          const selectedIndustries =
            industries &&
            industries
              .map((f) => {
                return kiaIndustryTag.includes(f.industryId) && { industryId: f.industryId, industryName: f.industryName };
              })
              .filter((v) => {
                return typeof v === "object";
              });
          payload.kiaClientUsers = selectedUsers;
          payload.kiaIndustryTag = selectedIndustries;
          if (kiaId) {
            updateKia(kiaId, payload)
              .then((res) => {
                dispatch(actionSuccess("Impact Area details updated successfully"));
              })
              .catch((err) => {
                dispatch(actionError(err?.data?.message || "Something went wrong"));
              });
          } else {
            payload.kiaCreatedBy = { userId: loggedInUser._id, userFname: loggedInUser.userFname, userLname: loggedInUser.userLname };
            payload.productVariant = [{ productName: productVariant }];
            addKia(payload)
              .then((res) => {
                if (res) {
                  dispatch(actionSuccess("Impact Area details saved successfully"));
                  history.replace({
                    pathname: `/kia/${res.data.data.id}/edit`,
                    hash: "",
                    state: { kiaId: res.data.data.id },
                  });
                  props.tabRef.current.enableTab(1, true);
                  props.tabRef.current.enableTab(2, true);
                  props.tabRef.current.enableTab(3, true);
                  props.tabRef.current.enableTab(4, true);
                  props.tabRef.current.select(0);
                } else {
                  dispatch(actionError("Something went wrong"));
                }
              })
              .catch((err) => {
                dispatch(actionError("something went wrong"));
              });
          }
        }
      });
      props.handleSave(false);
    }
  }, [props.saveKiaOverView]);

  return (
    <>
      {props?.interfaceAccess ? (
        <div
          className="pt-3 mx-2 gennx-content-wrapper padding-bottom-150i"
          style={{ padding: "1%", marginTop: "1%", backgroundColor: "#ffffff", width: "98.7%" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  {industries && industries.length > 0 && (
                    <Dropdown
                      formik={formik}
                      sortOrder="Acending"
                      required="required"
                      placeholder="Industry *"
                      id="kiaIndustryTag"
                      name="kiaIndustryTag"
                      allowCustomValue={true}
                      value={formik.values.kiaIndustryTag}
                      fields={{ text: "industryName", value: "industryId" }}
                      dataSource={industries}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    formik={formik}
                    type="text"
                    id="kiaName"
                    name="kiaName"
                    placeholder="Impact Area Name *"
                    required="required"
                    maxLength="150"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group required">
                    <label htmlFor="kiaDescription">Impact Area Description</label>
                    <CkEditor
                      formik={formik}
                      id="kiaDescription"
                      name="kiaDescription"
                      // charCountLimit={5000}
                      config={{
                        ...ckEditorMinimalConfig,
                        extraPlugins: "editorplaceholder,uploadimage",
                        editorplaceholder: "Impact Area Description *",
                        height: "235px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="kiaCurrentResearchStartups">Impact Area Current Research & Start-ups</label>
                    <CkEditor
                      formik={formik}
                      id="kiaCurrentResearchStartups"
                      name="kiaCurrentResearchStartups"
                      // charCountLimit={5000}
                      config={{
                        ...ckEditorMinimalConfig,
                        extraPlugins: "editorplaceholder,uploadimage",
                        editorplaceholder: "Impact Area Current Research & Start-ups",
                        height: "235px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <ToggleSwitch
                    formik={formik}
                    properties={{
                      id: "customKia",
                      name: "customKia",
                      label: "Custom Impact Area",
                      disabled: disableToggle && editData && editData.customKia === "Yes",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {users && formik.values.customKia === "Yes" && (
                    <MultiSelect
                      required="required"
                      formik={formik}
                      mode="Box"
                      // sortOrder="Acending"
                      showSelectAll={false}
                      placeholder="Select Client Users *"
                      id="kiaClientUsers"
                      name="kiaClientUsers"
                      enabled={kiaId && editData && editData.customKia === "Yes" ? false : true}
                      allowCustomValue={true}
                      value={formik.values.kiaClientUsers}
                      fields={{ text: "fullname", value: "userid" }}
                      dataSource={users}
                      sortOrder="Ascending"
                      noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <Dropdown
                    formik={formik}
                    required="required"
                    placeholder="Status"
                    id="kiaStatus"
                    name="kiaStatus"
                    fields={{ text: "label", value: "value" }}
                    dataSource={KIA_STATUS}
                  />
                </div>
                <div className="col-md-3">
                  <TextField formik={formik} required="required" placeholder="Assessment Score *" id="kiaScore" name="kiaScore" />
                </div>
                <div className="col-md-3">
                  <TextField
                    formik={formik}
                    required="required"
                    placeholder="Expert Network Strength *"
                    id="kiaExpertNetworkStrength"
                    name="kiaExpertNetworkStrength"
                  />
                </div>
                <div className="col-md-3">
                  <TextField
                    formik={formik}
                    required="required"
                    placeholder="Impact Area Survey Contributions *"
                    id="kiaImpactAreaSurveyContributors"
                    name="kiaImpactAreaSurveyContributors"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  {experts && (
                    <MultiSelect
                      formik={formik}
                      mode="CheckBox"
                      sortOrder="Acending"
                      showSelectAll={false}
                      placeholder="Kia Expert (Multiple Select)"
                      id="kiaExpert"
                      name="kiaExpert"
                      allowCustomValue={true}
                      value={formik.values.kiaExpert}
                      fields={{ text: "fullname", value: "userid" }}
                      dataSource={experts}
                      noRecordsTemplate={() => <div>Please type to search</div>}
                    />
                  )}
                </div>

                <div className="col-md-3">
                  <DatePicker
                    formik={formik}
                    // required="required"
                    properties={{
                      placeholder: "Please select next update date",
                      id: "kiaNextUpdateDate",
                      name: "kiaNextUpdateDate",
                      // label: "KIA Next Update Date",
                      format: "dd MMM yyyy",
                      // showTodayButton: false,
                      allowEdit: false,
                      openOnFocus: true,
                      min: getDateFromMongoDate(new Date()),
                      // max: getDateFromMongoDate(new Date()),
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <TextField
                    formik={formik}
                    required="required"
                    placeholder="Client Expert Engagements *"
                    id="kiaClientExpertEngagements"
                    name="kiaClientExpertEngagements"
                  />
                </div>
                <ActionButtons
                  selectedIndex={selectedIndex}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  accessRights={props.accessRights}
                  interfaceActionAccess={props?.interfaceActionAccess}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Overview;
