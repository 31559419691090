/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { Autocomplete, Button, Checkbox, Chip, TextField, Tooltip } from "@mui/material";
import { CheckBox } from "@material-ui/icons";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useQuery } from "@tanstack/react-query";
import {
  getBriefcaseCategoryList,
  getSpecificCategoryBriefcase,
  deleteBriefcase,
  deleteCategory,
  getAllBriefcaseCategoryList,
  updateCategoryShared,
} from "../../../middleware/services/myBriefcase";
import ModalNewFolder from "../../components/ModalNewFolder/ModalNewFolder";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import ModalRenameFolder from "../../components/ModalRenameFolder/ModalRenameFolder";
import SortDropdown from "../../components/sortingDropdown/sortingDropdown";
import EmptyData from "../../components/emptyData/EmptyData";
import PinkButton from "../../components/pinkButton/PinkButton";
import SidebarModal from "../../components/sidebar-modal";
import classes from "./myBriefcase.module.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import withScrolling from "react-dnd-scrolling";
import MoveToBriefcase from "../../components/moveBriefcase/moveBriefcase";
import ContainerSidebar from "./ContainerSidebarCard.jsx";
import { accessRightActionCheck, getLoggedInUser } from "utilities";
import {
  updateBriefcaseCategoryId,
  updateBriefcaseCount,
  updateCategorySkipCount,
  applyCategoryFilter,
  updateNewFolderId,
  resetBriefcaseListing,
  getCategoryListing,
  getAllBriefcaseCategory,
  updateCategoryBriefcaseListing,
  deleteBriefcaseCategory,
  resetBriefcaseState,
} from "../../../middleware/actions/myBriefcaseAction";
import { actionError, actionSuccess, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { downloadMethod } from "../../../middleware/services/downloadPdfService";
import { CMS_API_URL } from "../../../config";
import FilterContentTypeDropdown from "../../components/filterContentTypeDropdown/filterContentTypeDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/fontawesome-free-solid";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import Items from "./Items";
import { COMMON_ACCESS_ACTION } from "../../../constants";
import { fetchAccessUser, fetchUser } from "../../../middleware/services/userApi";
import { ItemFilter, StringFilter } from "../../components/SideBarDataGrid/DatagridHelpers";
import Datagrid from "../../components/SideBarDataGrid/DataGrid.jsx";
import { ColumnDirective, ColumnsDirective } from "@syncfusion/ej2-react-grids";
import BriefPopUp from "../../components/briefPopup";
import ContainerCard from "./ContainerCard";

const ScrollingComponent = withScrolling("div");

const MyBriefcase = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [folderList, setFolderList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [renameDeleteId, setRenameDeleteId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameFolderModal, setShowRenameFolderModal] = useState(false);
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showDownloadOption, setShowDownloadOption] = useState(false);
  const [showSiderBar, setShowSiderBar] = useState(false);
  const [activePPage, setActivePPage] = useState(1);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [open, setOpen] = useState(false);
  const [permission, setPermission] = useState("View");
  const [shareUsers, setShareUsers] = useState([]);
  const [existShareUsers, setExistShareUsers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [revokeConfirmModal, setRevokeConfirmModal] = useState(false);
  const [singleRevokeConfirmModal, setSingleRevokeConfirmModal] = useState(false);
  const [submitConfirmModal, setSubmitConfirmModal] = useState(false);
  const [viewAccess, setViewAccess] = useState(false);
  const [sharedFolder, setSharedFolder] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // ** Delete moldel state
  const [showBriefcaseDeleteModal, setShowBriefcaseDeleteModal] = useState(false);
  const [showBriefcaseMoveModal, setShowBriefcaseMoveModal] = useState(false);
  const [categorySortValue, setCategorySortValue] = useState("Newest");
  const [briefcaseFilterValue, setBriefcaseFilterValue] = useState("All");
  const [briefcaseSortValue, setBriefcaseSortValue] = useState("SortBySequence");
  const [briefcaseCardsValue, setBriefcaseCardsValue] = useState([]);
  const [lodingBriefcase, setLodingBriefcase] = useState(true);
  const user = getLoggedInUser();
  const productVariant = localStorage.getItem("productVariant");
  const categoryId = useSelector((state) => state["myBriefcaseState"]["categoryId"]);
  const categoryList = useSelector((state) => state["myBriefcaseState"]["categoryListing"]);
  const categoryBriefcase = useSelector((state) => state["myBriefcaseState"]["categoryBriefcaseListing"]);
  const newFolderId = useSelector((state) => state["myBriefcaseState"]["newFolderId"]);
  let userCompetencyIndustry = JSON.parse(localStorage.getItem("userCompetencyIndustry")) || [];

  /* Common access */
  const accessActionMove = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.MOVE);
  const accessActionDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionDownload = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DOWNLOAD);

  const handleRenameFolderShow = () => {
    setShowRenameFolderModal(true);
    setShowMenu(false);
  };
  const handleRenameFolderClose = () => setShowRenameFolderModal(false);

  const handleDeleteShow = (sharedUsers = []) => {
    setShowDeleteModal(true);
    setShowMenu(false);
    if (sharedUsers?.length > 0) setSharedFolder(true);
    else setSharedFolder(false);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
    setSharedFolder(false);
  };

  const handleShareShow = (sharedUsers = []) => {
    setShareUsers([...shareUsers, ...sharedUsers]);
    setExistShareUsers([...sharedUsers]);
    setViewAccess(false);
    setShowShareModal(true);
    setShowMenu(false);
  };

  const handleViewAccessShow = (sharedUsers = []) => {
    setShareUsers([...shareUsers, ...sharedUsers]);
    setViewAccess(true);
    setShowShareModal(true);
    setShowMenu(false);
  };

  const handleShareClose = () => {
    setShowShareModal(false);
    setSelectedOptions([]);
    setShareUsers([]);
    setExistShareUsers([]);
    setViewAccess(false);
    setPermission("View");
  };

  // ** Delete Model functions
  const handleBriefcaseDeleteShow = () => setShowBriefcaseDeleteModal(true);
  const handleBriefcaseDeleteClose = () => setShowBriefcaseDeleteModal(false);
  const handleBriefcaseMoveShow = () => setShowBriefcaseMoveModal(true);
  const handleBriefcaseMoveClose = () => {
    setShowBriefcaseMoveModal(false);
  };

  const handleNewFolderClose = () => setShowNewFolderModal(false);
  const handleNewFolderShow = () => setShowNewFolderModal(true);

  // show download option
  const downloadOptionHandler = () => {
    setShowDownloadOption(!showDownloadOption);
  };

  const updateCategoryId = (id) => {
    dispatch(updateBriefcaseCategoryId(id));
    setSelectedDoc([]);
  };

  const menuClickHandler = (id, folderName) => {
    setShowMenu(true);
    setRenameDeleteId(id);
    setCategoryName(folderName);
  };

  const changeFilter = (event) => {
    let filterValue = event.target.value;
    setCategorySortValue(filterValue);
    if (filterValue === "Newest") {
      dispatch(applyCategoryFilter("categoryCreatedDate:desc"));
      getBriefcaseSortFolderList("categoryCreatedDate:desc");
    }
    if (filterValue === "Oldest") {
      dispatch(applyCategoryFilter("categoryCreatedDate:asc"));
      getBriefcaseSortFolderList("categoryCreatedDate:asc");
    }
    if (filterValue === "Ascending") {
      dispatch(applyCategoryFilter("categoryName:asc"));
      getBriefcaseSortFolderList("categoryName:asc");
    }
    if (filterValue === "Descending") {
      dispatch(applyCategoryFilter("categoryName:desc"));
      getBriefcaseSortFolderList("categoryName:desc");
    }
  };

  const setActivePage = (event) => {
    dispatch(updateCategorySkipCount(event));
  };

  const updateCategoryListPage = (num) => {
    dispatch(updateCategorySkipCount(num));
    dispatch(updateBriefcaseCategoryId(""));
  };

  const handlePageClick = (event) => {
    setSelectedDoc([]);
    dispatch(updateNewFolderId(""));
    setActivePPage(event);
    setActivePage(event);
    updateCategoryListPage(event);
    getBriefcaseFolderListAfterAction();
  };

  const changeBriefcaseFilter = (event) => {
    let filterValue = event.target.value;
    setBriefcaseFilterValue(filterValue);
    let result = sortAndFilterData(briefcaseSortValue, filterValue, categoryBriefcase?.list);
    setBriefcaseCardsValue(result || []);
  };

  function sortAndFilterData(sortValue, filterValue, data) {
    if (filterValue === "All") {
      return data;
    } else {
      const filteredData = data.filter((item) => {
        return item.presentationContentType === filterValue;
      });
      return filteredData;
    }
  }

  const getCategoryBriefcase = async (briefcaseCategoryId, sort) => {
    dispatch(showLoader());
    setLodingBriefcase(true);
    await getSpecificCategoryBriefcase(user?._id, briefcaseCategoryId, sort)
      .then((res) => {
        setBriefcaseCardsValue(res.data || []);
        dispatch(
          updateCategoryBriefcaseListing({
            list: res.data,
            totalCount: res.totalCount,
          })
        );
        setBriefcaseFilterValue("All");
        setBriefcaseSortValue("SortBySequence");
        dispatch(hideLoader());
        setLodingBriefcase(false);
      })
      .catch((error) => {
        dispatch(hideLoader());
        setLodingBriefcase(false);
        console.log("error  category Briefcase listing >>>", error);
      });
  };

  const getAllCategory = () => {
    getAllBriefcaseCategoryList(user?._id, productVariant)
      .then((res) => {
        dispatch(
          getAllBriefcaseCategory({
            res,
            totalCount: res.totalCount,
          })
        );
      })
      .catch((error) => {
        console.log("error  category listing >>>", error);
      });
  };

  const getBriefcaseFolderList = async () => {
    await getBriefcaseCategoryList(user?._id, categoryList.params, productVariant)
      .then((res) => {
        setFolderList(res.data || []);
        dispatch(
          getCategoryListing({
            res,
            totalCount: res.totalCount,
          })
        );
        if (!res?.data?.length) setLodingBriefcase(false);
      })
      .catch((error) => {
        setLodingBriefcase(false);
        console.log("error  category listing >>>", error);
      });
  };

  const getBriefcaseFolderListAfterAction = async () => {
    await getBriefcaseCategoryList(user?._id, categoryList.params, productVariant)
      .then((res) => {
        setFolderList(res.data || []);
        dispatch(updateBriefcaseCategoryId(res?.data[0]?.id));
        if (res?.data[0]?.id) getCategoryBriefcase(res?.data[0]?.id, `presentationSeqNo:asc`);
        dispatch(
          getCategoryListing({
            res,
            totalCount: res.totalCount,
          })
        );
        if (!res?.data?.length) setLodingBriefcase(false);
      })
      .catch((error) => {
        setLodingBriefcase(false);
        console.log("error  category listing >>>", error);
      });
  };

  const getBriefcaseSortFolderList = async (sort) => {
    await getBriefcaseCategoryList(user?._id, { skip: 0, limit: 10, sort: sort }, productVariant)
      .then((res) => {
        setSelectedDoc([]);
        setBriefcaseFilterValue("All");
        setBriefcaseSortValue("SortBySequence");
        setBriefcaseCardsValue([]);
        setFolderList(res.data || []);
        dispatch(updateBriefcaseCategoryId(res?.data[0]?.id));
        dispatch(
          getCategoryListing({
            res,
            totalCount: res.totalCount,
          })
        );
        if (!res?.data?.length) setLodingBriefcase(false);
      })
      .catch((error) => {
        setLodingBriefcase(false);
        console.log("error  category listing >>>", error);
      });
  };

  // function to close the menu modal on clicking outside
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false);
          setShowDownloadOption(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  // Download Briefcase in Pdf file
  const downloadSelectedDocsPdf = async (fileContents, categoryName) => {
    try {
      dispatch(showLoader());
      const response = await downloadMethod(`${CMS_API_URL}/export-to-pdf`, {
        zipFileName: categoryName,
        fileContents: fileContents,
        pdfContents: [],
      });
      let fileName = categoryName ? `${categoryName}.pdf` : `briefcase.pdf`;

      let blob = new Blob([response], { type: "application/pdf" });

      if (!blob.size) {
        dispatch(actionError("Unable to export the selected briefcase(s). Please try again."));
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    } catch (error) {
      let msg = "Unable to export the selected briefcase(s). Please try again by selecting fewer briefcase(s) at a time.";
      console.error("Error during download:", error);
      dispatch(actionError(msg));
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickDownload = (isMultipleDownload) => {
    let categoryName = categoryList?.list?.find((el) => el.id === categoryId)?.categoryName;
    if (isMultipleDownload) {
      let list = [];
      selectedDoc.forEach((res) => {
        if (res.presentationEntityPageId) {
          list.push({
            entityId: res.presentationEntityId,
            pageId: res.presentationEntityPageId,
            pageHtmlContent: res.presentationEntityPageHtmlContent,
            pageTitle: res.presentationTitle,
            pageContentType: res.presentationContentType,
            pageCreatedDate: res.presentationCreatedDate,
          });
        }
      });

      let fileContents = list?.map((res) => ({
        fileName: res.pageTitle,
        htmlContent: res.pageHtmlContent,
        contentType: res.pageContentType,
        createdDate: res.pageCreatedDate,
      }));

      if (fileContents?.length > 0) {
        downloadSelectedDocsPdf(fileContents, categoryName);
        setSelectedDoc([]);
        setShowSiderBar(false);
      }
    }
  };

  // Download Briefcase in PPT
  const downloadSelectedPpt = async (fileContents, categoryName) => {
    try {
      dispatch(showLoader());
      const response = await downloadMethod(`${CMS_API_URL}/export-to-ppt`, {
        zipFileName: categoryName,
        fileContents: fileContents,
        pdfContents: [],
      });
      let fileName = categoryName ? `${categoryName}.pptx` : `briefcase.pptx`;

      let blob = new Blob([response], { type: "application/pptx" });
      if (!blob.size) {
        dispatch(actionError("Unable to export the selected briefcase(s). Please try again."));
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    } catch (error) {
      let msg = "Unable to export the selected briefcase(s). Please try again by selecting fewer briefcase(s) at a time.";
      console.error("Error during download:", error);
      dispatch(actionError(msg));
    } finally {
      dispatch(hideLoader());
    }
  };

  const onClickDownloadPpt = (isMultipleDownload) => {
    let categoryName = categoryList?.list?.find((el) => el.id === categoryId)?.categoryName;
    if (isMultipleDownload) {
      let list = [];
      selectedDoc.forEach((res) => {
        if (res.presentationEntityPageId) {
          list.push({
            entityId: res.presentationEntityId,
            pageId: res.presentationEntityPageId,
            pageHtmlContent: res.presentationEntityPageHtmlContent,
            pageTitle: res.presentationTitle,
            pageContentType: res.presentationContentType,
            pageCreatedDate: res.presentationCreatedDate,
          });
        }
      });

      let fileContents = list?.map((res) => ({
        fileName: res.pageTitle,
        htmlContent: res.pageHtmlContent,
        contentType: res.pageContentType,
        createdDate: res.pageCreatedDate,
      }));

      if (fileContents?.length > 0) {
        downloadSelectedPpt(fileContents, categoryName);
        setSelectedDoc([]);
        setShowSiderBar(false);
      }
    }
  };

  const onClickDelete = (isMultipleDelete) => {
    setSelectedDoc([]);
    if (isMultipleDelete) {
      dispatch(showLoader());
      let list = selectedDoc.map((res) => res.id);
      deleteBriefcase(user?._id, list)
        .then(() => {
          dispatch(actionSuccess("Briefcase deleted successfully."));
          getBriefcaseFolderList();
          getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
          sortAndFilterData(briefcaseSortValue, briefcaseFilterValue, briefcaseCardsValue);
          dispatch(hideLoader());
        })
        .catch((error) => {
          dispatch(actionError(error.response.data.message));
          dispatch(hideLoader());
        });
    }
    handleBriefcaseDeleteClose();
  };

  const handleIdValue = (id) => {
    return selectedDoc.some((el) => el.id === id);
  };

  const onCheckHandler = (item) => {
    const found = selectedDoc.some((el) => el.id === item.id);
    if (found) {
      const newArray = selectedDoc.filter((el) => el.id !== item.id);
      setSelectedDoc(newArray);
    } else {
      setSelectedDoc([...selectedDoc, item]);
    }
  };

  const selectAllBriefcase = () => {
    setShowSiderBar(false);
    setSelectedDoc([...briefcaseCardsValue]);
  };

  const DeSelectAllBriefcase = () => {
    setShowSiderBar(false);
    setSelectedDoc([]);
  };

  const resetBriefcase = () => {
    getBriefcaseFolderList();
    getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
  };

  useEffect(() => {
    if (!user?._id) return;
    getBriefcaseFolderList();
  }, [categoryList.params]);

  useEffect(() => {
    dispatch(resetBriefcaseState());
    dispatch(setBreadCrumb());
    if (user?._id) {
      getAllCategory();
    }
  }, []);

  useEffect(() => {
    if (categoryId && categoryId !== "") {
      getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
    }
  }, [categoryId, user?._id]);

  // auto scroll function to visible selected news section
  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(`list${categoryId}`);
      if (element !== null) {
        element.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [categoryId]);

  useEffect(() => {
    if (categoryId === "") {
      dispatch(resetBriefcaseListing());
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    if (activePPage > 1 && !categoryList.list.length) {
      dispatch(updateCategorySkipCount(activePPage - 1));
      dispatch(updateBriefcaseCategoryId(""));
      setActivePPage(activePPage - 1);
    }
  }, [activePPage, categoryList.list, dispatch]);

  useEffect(() => {
    if (renameDeleteId && renameDeleteId !== categoryId) return;
    if (newFolderId === "" && categoryList.list && categoryList.list.length && categoryList.list.filter((e) => e.id === categoryId).length === 0) {
      dispatch(updateBriefcaseCategoryId(categoryList.list[0].id));
    }
  }, [categoryId, categoryList.list, dispatch, newFolderId]);

  let categoryPage = categoryList.params.page === undefined ? 1 : categoryList.params.page;

  useEffect(() => {
    if (categoryBriefcase.list.length && categoryBriefcase.list[0].presentationCategoryId === categoryId) {
      const selectedFolder = categoryList.list.filter((i) => i.id === categoryId);

      if (selectedFolder.length && selectedFolder[0].presentationCount !== categoryBriefcase.list.length) {
        dispatch(updateBriefcaseCount(categoryBriefcase.list.length));
      }
    }
  }, [categoryId, categoryList.list, categoryBriefcase.list, dispatch]);

  useEffect(() => {
    if (categoryBriefcase.list.length === 0) {
      dispatch(updateBriefcaseCount(categoryBriefcase.list.length));
    }
  }, [categoryBriefcase.list.length, dispatch]);

  /* Interface actions */
  useEffect(() => {
    let actionAccess = accessRightActionCheck(aR.moduleName, aR.interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  /**
   * @description : Get industry id from user compentency
   */
  const compentencyIndusries = useMemo(() => {
    return userCompetencyIndustry?.map((industry) => industry?.id) || [];
  }, [userCompetencyIndustry]);

  /**
   * @description : Get user data with user compentency
   */
  const { data: userData } = useQuery({
    queryKey: ["user-data", compentencyIndusries],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["_id", "neq", user?._id],
        ["userActive", "eq", 1],
        ["userCompentencyIndustry.industryId", "in", compentencyIndusries],
      ];
      params.limit = 2000;
      params.sort = "userFname:asc";
      return fetchUser(params);
    },
    enabled: compentencyIndusries?.length > 0,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  /**
   * @description : Get user email from user data to get user that have GeNnx access
   */
  const userEmails = useMemo(() => {
    return userData?.data?.data?.map((email) => email.userEmail) || [];
  }, [userData?.data?.data]);

  /**
   * @description : Get user data for Multiple Select user to share briefcase category
   */
  const { data: userAccessData, isFetching: accessUserFetching } = useQuery({
    queryKey: ["user-Access-data", userEmails],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["userIsEnable", "eq", "YES"],
        ["userAccess.moduleName", "eq", "Deliverables"],
        ["userAccess.appId", "eq", import.meta.env.VITE_ENTERPRISE_APP_ID],
        ["userAccess.interfaceName", "eq", "Listing"],
      ];
      params.limit = 2000;
      params.sort = "userFname:asc";
      return fetchAccessUser(params);
    },
    enabled: userEmails?.length > 0,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });
  /**
   * @description : Create user data as group function for Multiple Select user to share briefcase category
   */
  const groupedOptions = useMemo(() => {
    // Filter the data based on the email list
    const filteredData = userAccessData?.data?.data?.filter((item) => userEmails.includes(item.userEmail));
    return (
      filteredData
        ?.filter((users) => !shareUsers.some((id) => id?.userId === users?.id))
        ?.map((user) => {
          return {
            userId: user?.id,
            userName: `${user?.userFname || ""} ${user?.userLname || ""}`
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            userEmail: user?.userEmail,
          };
        }) || []
    );
  }, [userAccessData?.data?.data, shareUsers]);

  /**
   * @description : User Add for share briefcase category function
   */
  const handleUserAdd = () => {
    if (!selectedOptions?.length) return;
    const userDataWithPermission = selectedOptions?.map((option) => {
      return {
        ...option,
        canView: permission === "View" ? "Yes" : "No",
        canEdit: permission === "Modify" ? "Yes" : "No",
        canDownload: "No",
        sharedDate: new Date(),
      };
    });
    setShareUsers([...shareUsers, ...userDataWithPermission]);
    setSelectedOptions([]);
  };

  /**
   * @description : Template Component for action column
   */
  const actionTemplate = (value) => {
    return (
      <div style={{ cursor: "pointer" }} onClick={() => handleSingleAccessRevoke(value)}>
        <Tooltip title="Revoke Access" arrow>
          <img src="/assets/user-xmark-regular-large.svg" alt="user-xmark"></img>
        </Tooltip>
      </div>
    );
  };

  /**
   * @description : Template Component for action column
   */
  const userNameTemplate = (value) => {
    return <div style={{ paddingLeft: "12px" }}>{value?.userName}</div>;
  };

  // formatRowData function required to format rowData for datGrid
  const formatRowData = useMemo(() => {
    if (!shareUsers?.length) return [];
    const paginatedRowData = shareUsers
      .sort((a, b) => a.userName.localeCompare(b.userName))
      .map((userData) => ({
        id: userData?.userId,
        userName: userData?.userName
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        sharedDate: userData?.sharedDate || "",
        permission: userData?.canView === "No" ? "Can Modify" : "Can View",
      }));
    return paginatedRowData;
  }, [shareUsers]);

  /**
   * @description Single user Revoke to share category function
   */
  const handleSingleAccessRevoke = (rows) => {
    let obj = [
      {
        id: rows.id,
        userName: rows.userName,
        permission: rows.permission,
      },
    ];
    setSelectedRows(obj);
    setRevokeConfirmModal(true);
    setSingleRevokeConfirmModal(true);
  };

  /**
   * @description Revoke user to share category function
   */
  const handleRevoke = () => {
    if (!selectedRows?.length) return;
    const idsToRemoveSet = new Set(selectedRows.map((obj) => obj.id));
    const filteredShareUsers = shareUsers.filter((user) => !idsToRemoveSet.has(user.userId)) || [];
    setShareUsers(filteredShareUsers);
    setRevokeConfirmModal(false);
    setSingleRevokeConfirmModal(false);
    setSelectedRows([]);
  };

  /**
   * @description Share category to user submit function
   */
  const handleShareSubmit = () => {
    setSubmitConfirmModal(true);
  };

  /**
   * @description Save to Database share category of user
   */
  const handleShareSave = () => {
    dispatch(showLoader());
    let payload = {
      categoryShared: shareUsers,
    };
    updateCategoryShared(user?._id, renameDeleteId, payload)
      .then(() => {
        dispatch(actionSuccess("Briefcase shared successfully."));
        getBriefcaseFolderList();
        getAllCategory();
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(actionError(error.response.data.message));
        dispatch(hideLoader());
      });
    setSubmitConfirmModal(false);
    setSelectedRows([]);
    handleShareClose();
  };

  /**
   * @description Share Briefcase category to user sidebar grid
   */
  const memoGrid = useMemo(() => {
    return (
      <Datagrid
        rowData={formatRowData}
        allowPaging={true}
        allowSelection={true}
        isMyRequestsGrid={true}
        showGridToolBar={viewAccess ? false : true}
        toolbar={{ gridTitle: "Manage Access", showClearFilterButton: true }}
        setSelectedRows={setSelectedRows}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="userName"
            headerText="Team Member"
            filterTemplate={StringFilter}
            type="String"
            textAlign="left"
            headerTextAlign="Left"
            width={"150"}
            maxWidth={"150"}
            clipMode="Ellipsis"
            filter={{ operator: "contains" }}
            template={userNameTemplate}
          />
          <ColumnDirective
            field="sharedDate"
            headerText="Shared On"
            textAlign="Center"
            headerTextAlign="Left"
            width={"100"}
            maxWidth={"100"}
            type="date"
            format={{
              type: "date",
              format: "dd-MMM-yyyy",
            }}
          />
          <ColumnDirective
            field="permission"
            headerText="Access Control"
            filterTemplate={ItemFilter}
            type="Boolean"
            textAlign="left"
            headerTextAlign="left"
            clipMode="Ellipsis"
            width={"120"}
            maxWidth={"120"}
          />
          {!viewAccess && (
            <ColumnDirective
              field="Actions"
              headerText="Actions"
              type="String"
              template={actionTemplate}
              textAlign="Center"
              headerTextAlign="Center"
              width={"60"}
              maxWidth={"60"}
              allowFiltering={false}
              allowSorting={false}
              showInColumnChooser={false}
              // freeze={"Right"}
            />
          )}
        </ColumnsDirective>
      </Datagrid>
    );
  }, [formatRowData]);

  return (
    <div style={{ background: "#F2F2F2", overflow: "hidden", padding: "16px", position: "relative" }} className="gennx-envelope">
      <div className={classes.bookMarkMain}>
        <div className={classes.bookMarkRecent}>
          <div className={classes.sort_content}>
            <p onClick={handleNewFolderShow}>
              <img src="/assets/add-folder.svg" alt="folder_icon"></img> <span style={{ paddingTop: "3px" }}>New Folder</span>
            </p>
            <SortDropdown changeFilter={changeFilter} showText={false} value={categorySortValue} />
          </div>
          <div className={classes.listManDiv} id="bookmarkMobileList">
            {categoryList?.list?.length > 0 && (
              <Items
                folderList={folderList}
                categoryId={categoryId}
                updateCategoryId={updateCategoryId}
                setSelectedDoc={setSelectedDoc}
                setBriefcaseFilterValue={setBriefcaseFilterValue}
                setBriefcaseSortValue={setBriefcaseSortValue}
                setBriefcaseCardsValue={setBriefcaseCardsValue}
                renameDeleteId={renameDeleteId}
                showMenu={showMenu}
                menuClickHandler={menuClickHandler}
                handleRenameFolderShow={handleRenameFolderShow}
                handleDeleteShow={handleDeleteShow}
                setShowMenu={setShowMenu}
                handleShareShow={handleShareShow}
                handleViewAccessShow={handleViewAccessShow}
              />
            )}

            {categoryList?.totalCount > 0 && (
              <div className={classes.paginationMain}>
                <p className={classes.paginationCountP}>
                  {categoryPage * 10 - 9} - {categoryPage * 10 > categoryList?.totalCount ? categoryList?.totalCount : categoryPage * 10} of{" "}
                  {categoryList?.totalCount}
                </p>

                <Pagination
                  innerClass={`${classes.paginationButtonDiv}`}
                  activeClass={classes.activePageClass}
                  activePage={categoryPage}
                  itemsCountPerPage={10}
                  totalItemsCount={categoryList?.totalCount}
                  pageRangeDisplayed={3}
                  onChange={handlePageClick}
                  hideFirstLastPages={true}
                  prevPageText={<img src="/assets/pagination-arrow.svg" alt="prevIcon" />}
                  nextPageText={<img src="/assets/pagination-arrow.svg" alt="nextIcon" />}
                />
              </div>
            )}
          </div>
        </div>

        <div className={classes.bookMarkFilters}>
          <div className={classes.bookMarkFiltersInside}>
            {!selectedDoc.length && categoryList?.list?.length > 0 && categoryBriefcase?.list?.length > 0 && (
              <div className={classes.downloadBoxRight} style={{ marginBottom: window.innerWidth <= 1024 ? "16px" : "" }}>
                <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginRight: "24px" }}>
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "24px" }}>
                    <p style={{ fontSize: "14px", marginRight: "16px", color: "#B7B7BB" }}>Filter By Content Type</p>
                    <FilterContentTypeDropdown changeFilter={changeBriefcaseFilter} value={briefcaseFilterValue} />
                  </div>
                </div>
                {briefcaseCardsValue && briefcaseCardsValue?.length > 0 && (
                  <div className={classes.downloadDelete}>
                    <PinkButton onClick={() => selectAllBriefcase()} className={classes.pinkButton}>
                      Select All
                    </PinkButton>
                  </div>
                )}
                <div className={classes.downloadDelete} style={{ marginLeft: "24px" }}>
                  <PinkButton onClick={() => resetBriefcase()} className={classes.pinkButton}>
                    Reset Filters
                  </PinkButton>
                </div>
              </div>
            )}
            {selectedDoc.length > 0 && (
              <div className={classes.downloadDelete}>
                {window.innerWidth > 1024 && (
                  <p className={classes.selectedText} style={{ marginBottom: window.innerWidth <= 1024 ? "16px" : "" }}>
                    {selectedDoc.length} selected
                  </p>
                )}

                <div className={classes.downloadBoxRight} style={{ marginBottom: window.innerWidth <= 1024 ? "0px" : "" }}>
                  <div className={classes.selectAllBox}>
                    {selectedDoc.length === briefcaseCardsValue?.length ? (
                      <PinkButton onClick={() => DeSelectAllBriefcase()} className={classes.pinkButton}>
                        Deselect All
                      </PinkButton>
                    ) : (
                      <PinkButton onClick={() => selectAllBriefcase()} className={classes.pinkButton}>
                        Select All
                      </PinkButton>
                    )}
                  </div>
                  {accessActionMove &&
                    (categoryList?.list?.find((cat) => cat.id === categoryId)?.categoryCreatedByUserId === user?._id ||
                      categoryList?.list?.find((cat) => cat.id === categoryId)?.categoryShared?.find((s) => s?.userId === user?._id)?.canEdit ===
                        "Yes") && (
                      <div className={classes.moveText} onClick={handleBriefcaseMoveShow}>
                        <p>
                          <img src="/assets/folder-move.svg" alt="move"></img>
                          Move
                        </p>
                      </div>
                    )}
                  {accessActionDelete &&
                    (categoryList?.list?.find((cat) => cat.id === categoryId)?.categoryCreatedByUserId === user?._id ||
                      categoryList?.list?.find((cat) => cat.id === categoryId)?.categoryShared?.find((s) => s?.userId === user?._id)?.canEdit ===
                        "Yes") && (
                      <div className={classes.deleteText} onClick={handleBriefcaseDeleteShow}>
                        <p>
                          <img src="/assets/delete.svg" alt="delete"></img>
                          Delete
                        </p>
                      </div>
                    )}
                  {accessActionDownload && (
                    <div className={classes.downloadBox} onClick={() => setShowSiderBar(true)}>
                      <p className={classes.downloadBtnP}>
                        <img src="/assets/download.svg" alt="download"></img>
                        Download
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {window.innerWidth <= 1024 && selectedDoc.length > 0 && (
              <p className={classes.selectedText} style={{ marginBottom: window.innerWidth <= 1024 ? "16px" : "" }}>
                {selectedDoc.length} selected
              </p>
            )}
            {categoryList?.list?.length > 0 && categoryBriefcase && categoryBriefcase?.list?.length > 0 && (
              <DndProvider backend={window.innerWidth > 1024 ? HTML5Backend : TouchBackend} options={{ enableMouseEvents: true }}>
                <ScrollingComponent className={classes.drag_scroll_bar}>
                  <ContainerCard
                    categoryBriefcase={briefcaseCardsValue || []}
                    onCheckHandler={onCheckHandler}
                    handleIdValue={handleIdValue}
                    selectedDoc={selectedDoc}
                    categoryId={categoryId}
                    userId={user?._id}
                    getCategoryBriefcase={getCategoryBriefcase}
                    briefcaseSortValue={briefcaseSortValue}
                    briefcaseFilterValue={briefcaseFilterValue}
                    lodingBriefcase={lodingBriefcase}
                    showDragAndDrop={categoryList?.list?.find((cat) => cat.id === categoryId)?.categoryCreatedByUserId === user?._id}
                  />
                </ScrollingComponent>
              </DndProvider>
            )}
            {!lodingBriefcase && (!categoryBriefcase?.list?.length || !categoryList?.list?.length) && (
              <EmptyData msg="You don't have any briefcase." />
            )}
          </div>
        </div>
      </div>

      <ModalDelete
        show={showDeleteModal}
        onHide={handleDeleteClose}
        onDeleteClick={() => {
          deleteCategory(user._id, renameDeleteId)
            .then(() => {
              dispatch(actionSuccess("Briefcase deleted successfully."));
              dispatch(updateCategorySkipCount(activePPage));
              dispatch(deleteBriefcaseCategory(categoryId));
              getAllCategory();
              if (renameDeleteId === categoryId) getBriefcaseFolderListAfterAction();
              else getBriefcaseFolderList();
            })
            .catch((error) => {
              dispatch(actionError(error.response.data.message));
            });
          handleDeleteClose();
        }}
        text={
          sharedFolder ? (
            <div>
              All the content blocks added in this folder will be removed. Are you sure, you want to delete folder? <br /> Note : If folder is shared
              with team members then shared access will be revoked.
            </div>
          ) : (
            "All the briefcase you have added in this folder will be removed. Are you sure, you want to delete folder?"
          )
        }
        saveButtonText="Delete"
      />
      <ModalNewFolder
        folderType="briefcase"
        modalTitle="New Folder"
        show={showNewFolderModal}
        showNewFolderModal={showNewFolderModal}
        onHide={handleNewFolderClose}
        setActivePage={setActivePage}
        setActivePPage={setActivePPage}
        setCategorySortValue={setCategorySortValue}
        getBriefcaseSortFolderList={getBriefcaseSortFolderList}
      />
      <ModalRenameFolder
        renameType="briefcase"
        modalTitle="Rename Folder"
        show={showRenameFolderModal}
        onHide={handleRenameFolderClose}
        renameDeleteId={renameDeleteId}
        getBriefcaseFolderList={getBriefcaseFolderList}
      />
      <ModalDelete
        show={showBriefcaseDeleteModal}
        onHide={handleBriefcaseDeleteClose}
        onDeleteClick={() => onClickDelete(true)}
        text="All the briefcase you have added will be removed. Are you sure, you want to delete briefcase?"
        saveButtonText="Delete"
      />
      <MoveToBriefcase
        showBriefcaseModal={showBriefcaseMoveModal}
        hideBriefcaseModal={handleBriefcaseMoveClose}
        setShow={setShowBriefcaseMoveModal}
        selectedBriefcase={selectedDoc}
        briefcaseCategoryId={categoryId}
        setSelectedDoc={setSelectedDoc}
        getCategoryAllBriefcase={getCategoryBriefcase}
        getBriefcaseFolderList={getBriefcaseFolderList}
      />
      {/* Download briefcase sidebar */}
      {showSiderBar && selectedDoc?.length > 0 && (
        <SidebarModal
          style={{ width: "400px" }}
          childrenClassName="SideBarChildrenBriefcaseDownload"
          header={`Download (${selectedDoc?.length})`}
          backDrop
          footer={
            <div className="pt-2 pb-2">
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{
                  width: "82px",
                  fontSize: "14px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  color: "#F94F5E",
                }}
                onClick={() => {
                  setShowSiderBar(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ml-3 mr-3"
                style={{
                  width: "130px",
                  fontSize: "14px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                }}
                onClick={downloadOptionHandler}
              >
                <div className={classes.downloadOptionsBtn}>
                  Download <FontAwesomeIcon icon={faAngleDown} width={20} height={20} style={{ marginLeft: "4px" }} />
                </div>
                {showDownloadOption && (
                  <div className={classes.download_options} ref={wrapperRef}>
                    <div className={classes.select_btn} onClick={() => onClickDownload(true)}>
                      <p>PDF File</p>
                    </div>
                    <div className={classes.select_btn} onClick={() => onClickDownloadPpt(true)}>
                      <p>PPT File </p>
                    </div>
                  </div>
                )}
              </button>
            </div>
          }
        >
          <DndProvider backend={window.innerWidth > 1024 ? HTML5Backend : TouchBackend} options={{ enableMouseEvents: true }}>
            <ScrollingComponent
              className={classes.drag_side_scroll_bar}
              style={{ overflow: "auto", maxHeight: "100vh", width: "100%", marginTop: "-22px" }}
            >
              <ContainerSidebar selectedDoc={selectedDoc} setSelectedDoc={setSelectedDoc} />
            </ScrollingComponent>
          </DndProvider>
        </SidebarModal>
      )}
      {/* Share Briefcase Category Sidebar */}
      {showShareModal && (
        <SidebarModal
          style={{ width: "628px" }}
          header={
            categoryName?.length > 55 ? (
              <Tooltip title={categoryName} arrow>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "545px",
                    color: "black",
                    fontSize: "18px",
                    cursor: "default",
                  }}
                >
                  {viewAccess ? "" : "Share - "}
                  {categoryName}
                </div>
              </Tooltip>
            ) : (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "545px",
                  color: "black",
                  fontSize: "18px",
                }}
              >
                {viewAccess ? "" : "Share - "}
                {categoryName}
              </div>
            )
          }
          closeIcon={
            <div onClick={handleShareClose}>
              <img src="/assets/Sidebar_Cross.svg" alt="Cross_icon"></img>
            </div>
          }
          backDrop
          footer={
            !viewAccess ? (
              <div className="pt-2 pb-2">
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  style={{
                    width: "82px",
                    fontSize: "14px",
                    paddingLeft: "18px",
                    paddingRight: "18px",
                    color: "#F94F5E",
                  }}
                  onClick={handleShareClose}
                >
                  Cancel
                </button>
                <Button
                  variant="contained"
                  sx={{
                    width: "82px",
                    height: "35px",
                    fontSize: "14px",
                    paddingTop: "10px",
                    backgroundColor: "#f94f5e",
                    "&:disabled": {
                      opacity: "0.5 !important",
                      backgroundColor: "#DBDBDD",
                      color: "#FFFFFF",
                    },
                    textTransform: "inherit !important",
                    "&:hover": {
                      backgroundColor: "#f94f5e",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                  className="px-4 mx-3"
                  onClick={handleShareSubmit}
                  disabled={!existShareUsers?.length && !shareUsers?.length}
                >
                  Submit
                </Button>
              </div>
            ) : null
          }
        >
          {!viewAccess && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="ml-3">
                <Autocomplete
                  multiple
                  options={
                    accessUserFetching || !groupedOptions?.length
                      ? [] // Show no options if loading or groupedOptions is empty
                      : [...(selectedOptions?.length === groupedOptions?.length ? ["Unselect All"] : ["Select All"]), ...(groupedOptions || [])]
                  } // Toggle text dynamically
                  open={open} // Control the open state
                  onOpen={() => setOpen(true)} // Set to true when opened
                  onClose={() => setOpen(false)} // Set to false when closed
                  getOptionLabel={(option) => (option === "Select All" || option === "Unselect All" ? option : option?.userId)} // Handle dynamic label for "Select All"
                  filterOptions={(options, state) => {
                    if (!state.inputValue.trim()) {
                      return options;
                    }
                    return options.filter(
                      (option) =>
                        option === "Select All" ||
                        option === "Unselect All" ||
                        option?.userName?.toLowerCase().includes(state?.inputValue?.toLowerCase())
                    );
                  }}
                  disableCloseOnSelect
                  freeSolo={false} // Disable manual typing
                  value={selectedOptions}
                  onChange={(_, newValue) => {
                    if (newValue.some((option) => option === "Select All" || option === "Unselect All")) {
                      // Toggle Select All / Unselect All
                      if (selectedOptions.length === groupedOptions.length) {
                        setSelectedOptions([]); // Unselect all
                      } else {
                        setSelectedOptions(groupedOptions); // Select all
                      }
                    } else {
                      setSelectedOptions(newValue.filter((option) => option !== "Select All" && option !== "Unselect All"));
                    }
                  }}
                  loading={accessUserFetching} // Show loading spinner while fetching
                  renderTags={(value, getTagProps) => {
                    const maxDisplayedItems = 1;
                    const remainingCount = value.length - maxDisplayedItems;

                    return [
                      ...value.slice(0, maxDisplayedItems).map((option, index) => (
                        <Chip
                          key={index}
                          label={
                            option?.userName?.length > 18 // Trim titles longer than 15 characters
                              ? `${option?.userName.slice(0, 15)}...`
                              : option?.userName
                          }
                          {...getTagProps({ index })}
                          sx={{
                            maxWidth: "150px",
                            height: "26px",
                            fontSize: "14px",
                            color: "black",
                            backgroundColor: "#F2F2F2",
                            border: "1px solid #DBDBDD",
                            borderRadius: "4px",
                          }}
                        />
                      )),
                      remainingCount > 0 && (
                        <span
                          key="remainingCount"
                          style={{
                            fontSize: "14px",
                            color: "#000000",
                            marginLeft: "8px",
                          }}
                        >
                          +{remainingCount} more
                        </span>
                      ),
                    ];
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Team Member"
                      variant="outlined"
                      required
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "44px", // Ensure the input's height remains fixed
                          overflow: "hidden", // Prevent overflow
                          display: "flex", // Ensure chips are aligned correctly
                          alignItems: "center", // Center items vertically
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "50px", // Ensure the root's height is consistent
                          overflow: "hidden", // Prevent overflow
                          flexWrap: "nowrap",
                          paddingRight: "0px",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option) => {
                    const isSelectAll = option === "Select All" || option === "Unselect All"; // Detect "Select All" dynamically
                    const isAllSelected = selectedOptions.length === groupedOptions.length;

                    return (
                      <li
                        {...props}
                        style={{
                          fontSize: "12px",
                          padding: "0px 0px 0px 26px",
                          margin: "0px",
                          display: "flex",
                          gap: "8px",
                          height: "36px",
                          alignItems: "center",
                          color: "black",
                          fontWeight: "400",
                          borderBottom: "1px solid #DBDBDD",
                        }}
                      >
                        <Checkbox
                          icon={<CheckBoxOutlineBlank />}
                          checkedIcon={<CheckBox />}
                          checked={isSelectAll ? isAllSelected : selectedOptions.some((selected) => selected?.userId === option?.userId)}
                          sx={{
                            width: "16px",
                            height: "16px",
                            color: "#DBDBDD",
                            "&.Mui-checked": {
                              color: isSelectAll ? "#f94f5e" : undefined, // Highlight "Select All" when active
                            },
                          }}
                        />
                        {isSelectAll ? option : option?.userName}
                      </li>
                    );
                  }}
                  sx={{
                    width: "349px",
                    height: "44px", // Fixed height for the container
                    "& .MuiOutlinedInput-root": {
                      height: "100%", // Ensure the height is consistent
                      padding: "4px",
                      "&.Mui-focused": {
                        "& fieldset": {
                          borderColor: "#f94f5e",
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#f94f5e",
                      },
                    },
                    "& .MuiChip-deleteIcon": {
                      color: "black !important",
                      fontSize: "16px !important",
                      margin: "-1px 4px 0px -6px !important",
                    },
                  }}
                />
              </div>
              <div className="ml-3 mt-1" style={{ height: "48px" }}>
                <DropDownListComponent
                  value={permission}
                  placeholder="Access Control"
                  change={(value) => {
                    setPermission(value?.itemData.value);
                  }}
                  cssClass={"customCss e-outline"}
                  floatLabelType="Auto"
                  dataSource={[
                    { text: "Can View", value: "View" },
                    { text: "Can Modify", value: "Modify" },
                  ]}
                  fields={{ text: "text", value: "value" }}
                  style={{ height: "48px", width: "100px" }}
                />
              </div>
              <div className="ml-3 mt-2">
                <Button
                  variant="contained"
                  sx={{
                    height: "38px",
                    fontSize: "14px",
                    paddingTop: "10px",
                    backgroundColor: "#f94f5e",
                    "&:disabled": {
                      opacity: "0.5 !important ",
                      backgroundColor: "#DBDBDD",
                      color: "#FFFFFF",
                    },
                    textTransform: "inherit !important",
                    "&:hover": {
                      backgroundColor: "#f94f5e",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                  className="px-4 pb-2"
                  onClick={handleUserAdd}
                  disabled={!selectedOptions?.length}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
          <div className={viewAccess ? classes.sidebarGridBoxReceiver : classes.sidebarGridBoxCreator}>
            {shareUsers?.length > 0 && (
              <div className={viewAccess ? classes.sidebarGridContainerReceiver : classes.sidebarGridContainerCreator}>{memoGrid}</div>
            )}
          </div>
        </SidebarModal>
      )}
      {/* Revoke user after selection confirmation modal */}
      {revokeConfirmModal && (
        <BriefPopUp
          open={revokeConfirmModal}
          containerStyle={{
            height: "150px",
            width: "450px",
            paddingTop: "0",
            paddingBottom: "0",
            marginBottom: "16px",
            marginTop: "4rem",
          }}
          content={
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                paddingRight: "16px",
                marginTop: singleRevokeConfirmModal ? "47px" : "28px",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{
                  width: "82px",
                  fontSize: "14px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  color: "#F94F5E",
                }}
                onClick={() => {
                  setRevokeConfirmModal(false);
                  setSingleRevokeConfirmModal(false);
                }}
              >
                Cancel
              </button>
              <Button
                variant="contained"
                sx={{
                  width: "82px",
                  height: "35px",
                  fontSize: "14px",
                  backgroundColor: "#f94f5e",
                  "&:disabled": {
                    opacity: "0.5 !important ",
                    backgroundColor: "#DBDBDD",
                    color: "#FFFFFF",
                  },
                  textTransform: "inherit !important",
                  "&:hover": {
                    backgroundColor: "#f94f5e",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                }}
                className="pl-4 pr-4 ml-3"
                onClick={handleRevoke}
              >
                Revoke
              </Button>
            </div>
          }
          customHeader={
            <div style={{ width: "100%", borderTop: "4px solid #F94F5E" }}>
              <div style={{ fontSize: "14px", marginLeft: "16px", marginRight: "16px", marginTop: "16px", marginBottom: "10px" }}>
                {singleRevokeConfirmModal
                  ? "Are you sure you want to revoke access?"
                  : "Are you sure you want to revoke access for selected team members?"}
              </div>
            </div>
          }
        />
      )}
      {/* Submit share briefcase category to user confirmation modal */}
      {submitConfirmModal && (
        <BriefPopUp
          open={submitConfirmModal}
          containerStyle={{
            height: "150px",
            width: "450px",
            paddingTop: "0",
            paddingBottom: "0",
            marginBottom: "16px",
            marginTop: "4rem",
          }}
          content={
            <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingRight: "16px", marginTop: "48px" }}>
              <button
                type="button"
                className="btn btn-outline-dark"
                style={{
                  width: "82px",
                  fontSize: "14px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  color: "#F94F5E",
                }}
                onClick={() => {
                  setSubmitConfirmModal(false);
                }}
              >
                Cancel
              </button>
              <Button
                variant="contained"
                sx={{
                  width: "82px",
                  height: "35px",
                  fontSize: "14px",
                  backgroundColor: "#f94f5e",
                  "&:disabled": {
                    opacity: "0.5 !important ",
                    backgroundColor: "#DBDBDD",
                    color: "#FFFFFF",
                  },
                  textTransform: "inherit !important",
                  "&:hover": {
                    backgroundColor: "#f94f5e",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                }}
                className="pl-4 pr-4 ml-3"
                onClick={handleShareSave}
              >
                Save
              </Button>
            </div>
          }
          customHeader={
            <div style={{ width: "100%", borderTop: "4px solid #F94F5E" }}>
              <div style={{ fontSize: "14px", marginLeft: "16px", marginTop: "16px" }}>Are you sure you want to save?</div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default MyBriefcase;
