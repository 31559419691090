/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News overview - Add | Edit Overview
     1. Import all news overview element from customFormFields
     2 Use Formik for validation
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/

import React, { useState, useEffect, useRef } from "react";
import { TextField, Dropdown, MultiSelectNew, DatePicker, AsyncMultiSelect } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { fetchExternalUser } from "../../../../../middleware/services/userApi";
import { fetchCompany } from "../../../../../middleware/services/companyApi";
import { fetchNewsletterTags, fetchNewsDocument, deleteNewsDocument } from "../../../../../middleware/services/newsApi";
import { fetchProductBasic } from "../../../../../middleware/services/productApi";
import { FileSize, supportedFiles, maxFiles } from "./validationSchema";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { getProductVariant, getDateFromMongoDate } from "../../../../../utilities";
import newsImage from "../../../../../assets/images/news.jpg";
import Categorization from "./categorization";
import { useDispatch } from "react-redux";
import { PRODUCT_VARIANT, MAX_FILE_SIZE, SUPPORTED_FILES, MAX_FILE_NUMBER, ROLES } from "../../../../../constants";
import { NEWS_THUMB_IMG_SIZE } from "../../../../../config";
import { NEWS_OPTIONS, NEWS_OPTIONS_INSIDER } from "./formFieldOptions";
import { useDropzone } from "react-dropzone";
import ReactTooltip from "react-tooltip";
import { BsDot } from "react-icons/bs";
import { setBasicTab } from "../../../../../middleware/actions/newsAction";

const OverviewForm = (props) => {
  const newsImageRef = useRef(null);
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [newsExpertDataSource, setNewsExpertDataSource] = useState([]);
  const [showTrash, setShowTrash] = useState(false);
  const productVariant = getProductVariant();
  const [newsCustomTags, setNewsletterTag] = useState([]);
  const dispatch = useDispatch();
  const { formik, newsDefaultImageRef, newsImageSrc, imageError, setImageError, setNewsExpertData, setNonDt, setImageErrorFlag } = props;
  const style = {
    label: {
      fontWeight: 600,
    },
  };

  useEffect(() => {
    if (props.newsImage) {
      setIsDefaultImage(false);
      setShowTrash(true);
    }
    isDefaultImage && props.newsImage === undefined && productVariant !== PRODUCT_VARIANT.COGNITION
      ? setImageErrorFlag(false)
      : setImageErrorFlag(true);
  }, [props.newsImage, isDefaultImage]);

  useEffect(() => {
    setImageError("");
  }, []);
  /*
        @Description : Set news data to fields 
    */
  useEffect(() => {
    formik.setValues(props.newsInformation);
  }, [props.newsInformation]);

  useEffect(() => {
    try {
      const response = fetchNewsletterTags();
      response
        .then((response) => {
          if (response.status == 200) {
            let dataSource = response.data.data.map((v) => {
              return {
                label: v.tagName,
                value: v.id,
                ...v,
              };
            });
            setNewsletterTag(dataSource);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  // UseEffect for redux
  useEffect(() => {
    dispatch(setBasicTab(formik.values));
  }, [formik.values]);

  const handleSaveCategorization = (selectedTech = "", checkedNonDt) => {
    setNonDt(checkedNonDt);
  };
  /*
        @Description : Read news image
    */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      newsImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const searchNewsCompany = async (val) => {
    let resData = [];
    if (val.length > 2) {
      let filters = [[`productVariant~productName:${productVariant},isLive`, "eq", "YES"]];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["companyName", "stw", val]);
      let fields = { fields: ["companyId", "companyName"] };
      const response = await fetchCompany({
        filters: filters,
        ...fields,
        sort: "companyName:asc",
        // limit: 10,
      });
      if (response.data && response.data.data) {
        resData =
          response.data.data.map((v) => {
            return { label: v.companyName, value: v.id };
          }) || [];
      }
    }
    return resData;
  };

  const searchNewsProduct = async (val) => {
    let resData = [];
    if (val.length > 2) {
      let filters = [[`productVariant~productName:${productVariant},isLive`, "eq", "YES"]];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["productName", "stw", val]);
      let fields = { fields: ["productId", "productName"] };
      const response = await fetchProductBasic({ filters: filters, ...fields });
      if (response && response.data && response.data.data) {
        resData =
          response.data.data.map((productData) => {
            return { label: productData.productName, value: productData.id };
          }) || [];
      }
    }
    return resData;
  };

  const deleteSingleDocument = async (newsId, documentId) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteNewsDocument(newsId, documentId)
            .then((res) => {
              if (res.status === 200) {
                dispatch(actionSuccess("Document deleted Successfully"));
              }
              props.fetchNews(newsId);
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
            });
        },
      })
    );
  };
  let existingDocument = props?.newsInformation?.newsDocuments ? props.newsInformation.newsDocuments : [];
  const UploadComponent = (props) => {
    let newsDocument = [];
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",

      accept: SUPPORTED_FILES,
      // maxSize: MAX_FILE_SIZE,
      maxFiles: MAX_FILE_NUMBER, //comment this line if upload multiple File
      onDrop: (acceptedFiles, fileRejections) => {
        newsDocument = existingDocument.concat(acceptedFiles);

        if (newsDocument.length > 10 || fileRejections.length > 10) {
          dispatch(actionError(maxFiles));
          newsDocument = existingDocument;
        } else if (fileRejections.length > 0 && fileRejections[0].errors[0].code === "file-invalid-type") {
          dispatch(actionError(supportedFiles));
        } else if (fileRejections.length > 0 && fileRejections[0].errors[0].code === "file-too-large") {
          dispatch(actionError(FileSize));
        }
        let fileSize = 0;
        let fileSizeErrorCount = 0;
        acceptedFiles.map((resp) => {
          fileSize = fileSize + resp.size;
          if (fileSize > MAX_FILE_SIZE) {
            fileSizeErrorCount++;
            newsDocument = existingDocument;
          }
        });
        if (fileSizeErrorCount > 0) {
          dispatch(actionError(FileSize));
        }
        if (existingDocument.length > 0) {
          let fileNameErrorCount = 0;
          existingDocument.map((result) => {
            let fileName = result.docName;
            acceptedFiles.map((resp) => {
              if (fileName === resp.name) {
                fileNameErrorCount++;
                newsDocument = existingDocument;
              }
            });
          });
          if (fileNameErrorCount > 0) {
            dispatch(actionError("File with same name is already exists"));
          }
        }

        props.formik.setValues({
          ...props.formik.values,
          newsDocuments: newsDocument,
        });
      },
    });

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group bg-light p-3 border-dotted mb-1 mt-1">
            <div {...getRootProps()} className="d-inline-block">
              <input {...getInputProps()} />
              <button type="button" className="btn btn-dark">
                Upload Documents
              </button>
            </div>
          </div>
          <div className="d-inline-block ">
            {props.formik.values.newsDocuments && props.formik.values.newsDocuments.length
              ? props.formik.values.newsDocuments.map((file, i) => {
                  let documentId = file.docFileName && file.docFileName.split(".")[0];
                  return (
                    <div className="row">
                      <div className="float-LEFT text-left col-md-8">
                        <div className="document-list" style={{ display: "inline-flex" }}>
                          <BsDot color="black" size={18} className="mt-1" />
                          <li key={i} className="documemt-truncate mr-3 mb-1" style={{ cursor: "default" }} title={file.name || file.docName || ""}>
                            {`${file.name || file.docName || ""}`}
                          </li>
                        </div>
                      </div>
                      {file && file.docFileName ? (
                        <div className="float-right text-right col-md-4" style={{ paddingRight: "18px", paddingLeft: "0px" }}>
                          <a href={fetchNewsDocument(file.docFileName, file.docName)} download={file.docName}>
                            <i className="fas fa-download mr-2" data-tip="Download" download={file.docName} />
                          </a>
                          <span onClick={() => deleteSingleDocument(props.newsId, documentId)}>
                            <i className="fa fa-trash ml-2 cursor-pointer" data-tip="Delete" />
                          </span>
                          <ReactTooltip />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div className="col-md-6">
          <small>
            <span className="font-weight-bold">Note : </span>
            <li> Only pdf, doc/docx, xls/xlsx, ppt/pptx files are allowed to upload.</li>
            <li> You can upload multiple documents.</li>
            <li> You can upload maximum upto 90 MB files in single upload.</li>
            <li> You can upload maximum 10 files only.</li>
          </small>
        </div>
      </div>
    );
  };

  const searchText = async (val) => {
    if (val.length > 2) {
      const response = await fetchExternalUser({
        fields: ["userFname", "userLname", "userEmail"],
        filters: [
          ["userFname", "cn", val],
          ["userRole", "eq", ROLES.EXPERT],
          ["userActive", "eq", 1],
        ],
        sort: "userFname:asc",
        limit: 10,
      });
      if (response?.data && response?.data?.data) {
        setNewsExpertData((prevValues) => [...prevValues, response?.data?.data]);
        const resData = response.data.data.map((v) => {
          return {
            label: `${v.userFname} ${v.userLname} (${v.userEmail})`,
            value: v.id,
          };
        });
        if (resData) {
          setNewsExpertDataSource(resData);
        }
      }

      // const response = await fetchExternalUser({
      //     fields: ["userFname", "userLname", "userEmail"],
      //     filters: [["userFname", "cn", val], ["userRole", "eq", "expert"], ["userActive", "eq", 1]], sort: "userFname:asc", limit: 10
      // });
      // if (response?.data && response?.data?.data) {
      //     setNewsExpertData(response?.data?.data)
      //     const resData = response.data.data.map(v => {
      //         return { label: `${v.userFname} ${v.userLname} (${v.userEmail})`, value: v.id }
      //     });
      //     if (resData) {
      //         setNewsExpertDataSource(resData)
      //     }
      // }
    }
  };

  const searchUserByIds = async (ids) => {
    // const searchUserByIds = async (ele2) => {

    // let ids = []

    // ele2.forEach(e => ids.push(e.value))

    const response = await fetchExternalUser({
      fields: ["userFname", "userLname", "userEmail"],
      filters: [["_id", "in", ids]],
      sort: "userFname:asc",
      limit: 10,
    });
    if (response?.data && response?.data?.data) {
      setNewsExpertData(response?.data?.data);
      // setNewsExpertData(prevValues => [...prevValues, response?.data?.data])
      const resData = response.data.data.map((v) => {
        return {
          label: `${v.userFname} ${v.userLname} (${v.userEmail})`,
          value: v.id,
        };
      });
      if (resData) {
        setNewsExpertDataSource(resData);
        formik.setFieldValue(
          "newsExpert",
          resData.map((v) => {
            return v.value;
          })
        );
      }
    }
  };

  return (
    <div className="mt-2" style={{}}>
      <form>
        <div className="row">
          <div className="col-md-12">
            <TextField
              formik={formik}
              type="text"
              id="newsTitle"
              placeholder="News Title *"
              name="newsTitle"
              required="required"
              hideInitial={true}
              numberCount={true}
              maxLength={125}
              characterLimitText={true}
            />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <TextField formik={formik} type="text" id="newsSourceURL" placeholder="News Source URL" name="newsSourceURL" />
              </div>
              {(productVariant && productVariant == PRODUCT_VARIANT.WHATNEXT) || productVariant == PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 d-flex m-0 mb-4">
                      <div className="flex-fill mr-3">
                        <AsyncMultiSelect
                          formik={formik}
                          id="newsCompany"
                          name="newsCompany"
                          placeholder="Company"
                          filtering={(e) => searchNewsCompany(e)}
                          noRecordsTemplate={(record, inputValue) => {
                            return record?.inputValue.trim().length > 2 || inputValue.trim().length > 2
                              ? "No match found"
                              : "Please type at least 3 characters";
                          }}
                        />
                      </div>
                      <div className="px-0">
                        <a className="btn btn-outline-dark btn-sm add-new-company-btn" target="_blank" href="/company/add">
                          Add company
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="col-md-4">
                {productVariant == PRODUCT_VARIANT.INSIDER ? (
                  <>
                    {NEWS_OPTIONS_INSIDER && (
                      <Dropdown
                        formik={formik}
                        placeholder="News Type"
                        id="newsType"
                        name="newsType"
                        value={formik.values.newsType}
                        fields={{ text: "label", value: "value" }}
                        dataSource={NEWS_OPTIONS_INSIDER}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {NEWS_OPTIONS && (
                      <Dropdown
                        formik={formik}
                        placeholder="News Type"
                        id="newsType"
                        name="newsType"
                        value={formik.values.newsType}
                        fields={{ text: "label", value: "value" }}
                        dataSource={NEWS_OPTIONS}
                      />
                    )}
                  </>
                )}
              </div>

              {(productVariant && productVariant == PRODUCT_VARIANT.WHATNEXT) ||
              productVariant == PRODUCT_VARIANT.CHEERSIN ||
              productVariant == PRODUCT_VARIANT.COGNITION ||
              productVariant == PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-4">
                  {
                    <DatePicker
                      formik={formik}
                      properties={{
                        placeholder: "Publish Date *",
                        id: "newsPublishedDate",
                        name: "newsPublishedDate",
                        format: "dd MMM yyyy",
                        allowEdit: false,
                        floatLabelType: "Auto",
                        openOnFocus: true,
                        max: getDateFromMongoDate(new Date()),
                      }}
                    />
                  }
                </div>
              ) : null}

              {productVariant && productVariant == PRODUCT_VARIANT.WHATNEXT ? (
                <div className="col-md-8">
                  <MultiSelectNew
                    formik={formik}
                    mode="Box"
                    allowCustomValue={true}
                    allowFiltering={true}
                    required=""
                    placeholder="Custom Tag"
                    id="newsCustomTags"
                    name="newsCustomTags"
                    value={props.newsInformation.newsCustomTags.map((v) => {
                      return v.label;
                    })}
                    fields={{ text: "label", value: "value" }}
                    noRecordsTemplate="Please type to search or create new"
                    dataSource={newsCustomTags}
                  />
                </div>
              ) : null}

              {(productVariant && productVariant == PRODUCT_VARIANT.WHATNEXT) || productVariant == PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-6">
                  {
                    <MultiSelectNew
                      formik={formik}
                      mode="Box"
                      allowCustomValue={true}
                      allowFiltering={true}
                      sortOrder="Acending"
                      showSelectAll={true}
                      required=""
                      placeholder="News Expert (Multiple Select)"
                      id="newsExpert"
                      name="newsExpert"
                      value={props.newsInformation?.newsExpert?.map((v) => {
                        return v.label;
                      })}
                      fields={{ text: "label", value: "value" }}
                      filtering={(e) => {
                        searchText(e.text);
                      }}
                      noRecordsTemplate={() => <div className="norecord">Please type at least 3 characters to search company</div>}
                      dataSource={newsExpertDataSource}
                    />
                  }
                  {/* {<MultiSelect
                                        formik={formik}
                                        mode="CheckBox"
                                        allowCustomValue={false}
                                        allowFiltering={true}
                                        enableRtl={true}
                                        showClearButton={false}
                                        required=""
                                        placeholder="News Expert (Multiple Select)"
                                        id="newsExpert"
                                        name="newsExpert"
                                        // value={[...formik?.values?.newsExpert]}
                                        value={props.newsInformation.newsExpert.map(v => { return v.label })}
                                        fields={{ text: "label", value: "value" }}
                                        filtering={(e) => { searchText(e.text) }}
                                        noRecordsTemplate={() => <div>Please type to search</div>}
                                        dataSource={newsExpertDataSource}
                                    />} */}
                </div>
              ) : null}

              {/* <div className="col-md-12">
                <ToggleSwitchNew
                  formik={formik}
                  required="required"
                  properties={{
                    id: "newsPaywallSetting",
                    name: "newsPaywallSetting",
                    label: "Enable Paywall",
                  }}
                />
              </div> */}

              {(productVariant && productVariant == PRODUCT_VARIANT.WHATNEXT) || productVariant == PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-12 newsCategories">
                  <div className="form-group">
                    <label style={style.label}>News Categories</label>
                    <Categorization {...props} handleSaveCategorization={handleSaveCategorization} />
                  </div>
                </div>
              ) : null}
              {productVariant && productVariant == PRODUCT_VARIANT.COGNITION && (
                <div className="col-md-12">
                  <label style={style.label} htmlFor="objective">
                    Upload News Files
                  </label>
                  <UploadComponent editDocumentData={formik.values.newsDocuments} formik={formik} {...props} />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <TextField
                  formik={formik}
                  type="text"
                  id="newsSource"
                  value={formik.values.newsSource}
                  placeholder="News Source Name"
                  name="newsSource"
                />
              </div>
              {(productVariant && productVariant == PRODUCT_VARIANT.WHATNEXT) || productVariant == PRODUCT_VARIANT.INSIDER ? (
                <div className="col-md-12 mb-4">
                  <AsyncMultiSelect
                    formik={formik}
                    id="newsProduct"
                    name="newsProduct"
                    placeholder="Product"
                    filtering={(e) => searchNewsProduct(e)}
                    noRecordsTemplate={(record, inputValue) => {
                      return record?.inputValue.trim().length > 2 || inputValue.trim().length > 2
                        ? "No match found"
                        : "Please type at least 3 characters";
                    }}
                  />
                </div>
              ) : null}
              <div className="col-md-4">
                <div className="">
                  {productVariant == PRODUCT_VARIANT.COGNITION ? (
                    <label style={style.label} htmlFor="newsCompany">
                      News Thumbnail{" "}
                    </label>
                  ) : (
                    <label style={style.label} htmlFor="newsCompany">
                      News Thumbnail *
                    </label>
                  )}
                  <div className="image-holder" style={{ padding: "unset" }}>
                    {/* <div className="d-flex align-item-right justify-content-right"> */}
                    <img width="200" height="200" src={newsImageSrc || newsImage} alt="News" ref={newsImageRef} />
                    <div className="image-action newsThumbnail_fa_icons" style={{ top: "5px", marginRight: "5em" }}>
                      <span
                        onClick={() => {
                          dispatch(
                            showAlertBox({
                              content: "Are you sure you want to delete?",
                              okText: "Delete",
                              cancelText: "Cancel",
                              title: "dialogAlertCssWarning",
                              okCallback: async () => {
                                dispatch(actionSuccess("Image deleted successfully"));
                                setShowTrash(false);
                                if (productVariant !== PRODUCT_VARIANT.COGNITION) {
                                  setImageError("Please upload image");
                                }
                                newsImageRef.current.setAttribute("src", newsImage);
                                newsDefaultImageRef.current.value = "";
                              },
                            })
                          );
                        }}
                      >
                        <i className={`fa fa-trash mr-1 ${!showTrash ? "d-none" : undefined}`} aria-hidden="true" />
                      </span>
                      <span onClick={() => newsDefaultImageRef.current.click()}>
                        <i className="fa fa-pencil-alt" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                  <input
                    type="file"
                    className="d-none"
                    accept=".jpg, .jpeg, .png"
                    id="newsProfileImage"
                    name="newsProfileImage"
                    ref={newsDefaultImageRef}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        if (
                          (e.target.files[0].type == "image/png" ||
                            e.target.files[0].type == "image/jpg" ||
                            e.target.files[0].type == "image/jpeg") &&
                          e.target.files[0].size <= NEWS_THUMB_IMG_SIZE
                        ) {
                          //1000kb == 1000000 byte
                          readProfileImage(e.target.files[0]);

                          newsImageRef.current.setAttribute("src", e.target.files[0]);
                          setImageError("");
                          setShowTrash(true);
                          setIsDefaultImage(false);
                          formik.setErrors({
                            ...formik.errors,
                            newsProfileImage: "",
                          });
                        } else {
                          if (productVariant !== PRODUCT_VARIANT.COGNITION) {
                            setImageError("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                          }
                        }
                      }
                    }}
                  />
                  <div className="text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                    <small>{imageError}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OverviewForm;
