/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase Draggable Card
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React from "react";
import classes from "./myBriefcase.module.css";
import ImageFile from "../../components/imageFileCard/ImageFile";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";

const BriefcaseCard = ({ itemCard, onCheckHandler, handleIdValue, selectedDoc, handleModalShow, setContent }) => {
  const handlePreview = (itemData) => {
    if (itemData?.presentationEntityPageId && itemData?.presentationEntityPageHtmlContent) {
      handleModalShow();
      setContent(itemData);
    }
  };

  return (
    <div className={classes.bookMarkFiltersImg}>
      <div
        className={classes.presentBorder}
        onClick={() => onCheckHandler(itemCard)}
        style={handleIdValue(itemCard.id) ? { border: "1px solid #F94F5E", cursor: "default" } : { border: "", cursor: "default" }}
      >
        <ImageFile
          className={classes.presentationThumbnailCursor}
          fileName={itemCard.presentationThumbnail}
          entityType="presentation"
          draggable="false"
        />
      </div>
      <div className={classes.presentation_title_container}>
        <div className={classes.presentation_title}>{itemCard?.presentationTitle}</div>
        <div className={classes.presentation_info_content}>
          <p
            style={{ marginBottom: "0px" }}
            dangerouslySetInnerHTML={{
              __html: itemCard?.presentationTitle,
            }}
          />
        </div>
      </div>
      <div className={classes.singleDownDeleteDiv}>
        <div className={classes.card_cal}>
          <span style={{ fontSize: "12px", color: "#939399" }}>Added on</span>
          <span style={{ paddingLeft: "4px", fontSize: "12px", color: "#4B4B55" }}>
            {moment(itemCard.presentationCreatedDate).format("DD MMM YYYY")}
          </span>
          {itemCard?.presentationContentType && <div style={{ paddingLeft: "8px" }}>|</div>}
          <p style={{ paddingLeft: "8px", fontSize: "12px", color: "#4B4B55", marginBottom: "0px" }}>{itemCard?.presentationContentType}</p>
        </div>
        <div
          style={{
            width: "24px",
            height: "24px",
            border: "1px solid #DBDBDD",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="/assets/eye.svg" alt="eye" width={18} style={{ color: "#707070", cursor: "pointer" }} onClick={() => handlePreview(itemCard)} />
        </div>
      </div>

      <div className={selectedDoc.length ? classes.showCheckBox : classes.forgot_check}>
        <Checkbox
          id="check"
          checked={handleIdValue(itemCard.id)}
          onChange={() => {
            onCheckHandler(itemCard);
          }}
          sx={{
            borderRadius: "2px",
            borderWidth: "2px",
            height: "20px",
            width: "20px",
            marginTop: "-4px",
            marginRight: "0px",
            color: "#B7B7BB",
            "&.Mui-checked": { color: "#f94f5e" },
          }}
        />
      </div>
    </div>
  );
};

export default BriefcaseCard;
