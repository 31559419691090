/**
 * @fileDescription Product Listing
 * @author Pankaj Kulshreshtha | Cheers Interactive
 * @date 03/Aug/2020 *
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { swap, getProductVariant, fetchTechTrendData } from "../../../utilities";
import CommonDataGrid from "../../components/dataGrid";
import _ from "underscore";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import {
  fetchProductBasic,
  deleteManyProduct,
  deleteProduct,
  saveProductTaxonomyTags,
  saveProductKiaTags,
  saveValueChainTags,
  saveProductBusinessObjectiveTaxonomy,
  saveProductTrendTags,
} from "../../../middleware/services/productApi";
import ThreeDotMenu from "../../components/threeDotMenu";
import { internalGridVisited, setInternalGridState, setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../constants";
import { resetRedux, setFilteredTaxonomy } from "../../../middleware/actions/taxonomyFilterAction";
import {
  setInitialCheckedKiaNodes,
  setInitialCheckedObjectiveNodes,
  setInitialCheckedSgfNodes,
  setInitialCheckedTechnologyNodes,
  setInitialCheckedTrendNodes,
  setInitialCheckedValueChainNodes,
  setKiaCheckedNodes,
  setKiaUnCheckedNodes,
  setObjectiveTaxonomyCheckedNodes,
  setObjectiveTaxonomyUnCheckedNodes,
  setResetRedux,
  setTaxonomyCheckedNodes,
  setTaxonomyUnCheckedNodes,
  setTransformingCheckedNodes,
  setTransformingUnCheckedNodes,
  setTrendCheckedNodes,
  setTrendUnCheckedNodes,
  setValueChainCheckedNodes,
  setValueChainUnCheckedNodes,
} from "../../../middleware/actions/taxonomyTaggingAction";
import { resetProductRedux } from "../../../middleware/actions/productAction";
import PropTypes from 'prop-types';


// product component
const ProductListing = (props) => {
  const { accessRights: aR } = props;
  const productVariant = getProductVariant();
  const [deleteId, setDeleteId] = useState(undefined);
  const [rowData1, setRowData] = useState([]);
  const [techTrendData, setTechTrendData] = useState(undefined);
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);
  const history = useHistory();
  const dispatch = useDispatch();
  const split_url = props.location.pathname.split("/");
  split_url.pop();
  const showLoaderGrid = () => dispatch(showLoader());
  const gridState = useSelector((state) => state.gridState);
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [initalState, setInitialState] = useState([]);
  const [bulkCheck, setBulkCheck] = useState([]);
  const [valueChainData, setValueChainData] = useState([]);
  const [showTaxonomyBulk, setShowTaxonomyBulk] = useState(false);
  const productTaxonomy = useSelector((state) => state.taxonomyTaggingState);
  let moduleName = props.accessRights?.moduleName;
  const [cssClass, setCssClass] = useState("treeClass");
  const [listClass, setListClass] = useState("select-lists");
  const [taxonomyState, setTaxonomyState] = useState([]);
  const [taxonomyTransformationState, setTaxonomyTransformationState] = useState([]);
  const [taxonomyTaggingTabName, setTaxonomyTaggingTabName] = useState(false);
  const breadCrumbLinks = [{ linkUrl: "/product", linkName: aR.moduleName, linkActive: true }];

  /* Common access */
  const accessActionCompanyListingAddNew = props?.interfaceActionAccessProduct?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionCompanyEdit = props?.interfaceActionAccessProduct?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionCompanyDelete = props?.interfaceActionAccessProduct?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionCompanyFilterByTaxonomy = props?.interfaceActionAccessProduct?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);
  const accessActionCompanyTaxonomyBulkTagging = props?.interfaceActionAccessProduct?.includes(COMMON_ACCESS_ACTION.TAXONOMY_BULK_TAGGING);
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionFilterByTaxonomy = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);
  const accessActionTaxonomyBulkTagging = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.TAXONOMY_BULK_TAGGING);

  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };

  useEffect(() => {
    if (!techTrendData) fetchTechData();
    localStorage.removeItem("selectedProducts");
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => {
      localStorage.removeItem("selectedProducts");
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);
  const pathname = window.location.pathname;
  const myArray = pathname.split("/");

  // Reset Redux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];

    if (locationPath != `/product`) {
      dispatch(setFilteredTaxonomy([]));
      setFilterData([]);
      dispatch(resetRedux());
      localStorage.setItem("locationPath", window.location.pathname);
    }
  }, []);
  const deleteSingleProduct = (data) => {
    deleteProduct(data.Id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(actionSuccess("Product deleted successfully"));
          if (deleteId) setDeleteId(undefined);
          setDeleteId(true);
        } else {
          dispatch(actionError("Somthing wrong. Please check API Request"));
        }
      })
      .catch((err) => {
        let errMsg = err.data.message ? err.data.message : "Delete Product Failed";
        dispatch(actionError(errMsg));
      });
  };

  const columnNames = {
    id: "id",
    "Product Name": "productName",
    Company: "productCompany.companyName",
    // Technology: "productTechnologyTags~dtParentId:0,dtName",
    "Launch Year": "productLaunchYear",
    // Taxonomy: "productCompletionStatus.technology.status",
    Overview: "productCompletionStatus.overview",
  };
  if (productVariant) columnNames["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  else columnNames["Is Live"] = "productVariant.isLive";
  if (productVariant && productVariant === "Insider") columnNames["Trend"] = "productIndustryTrendsTags.trendName";
  else columnNames["Technology"] = "productTechnologyTags.dtName";

  const editLinkTemplate = (value) => {
    const linkUrl = `${split_url.join("/")}/product/${value.id}/edit#overview`;
    return (
      <span
        className="span-link"
        onClick={() => {
          history.push(linkUrl, {
            productId: value.id,
            companyId: props.companyId,
            editData: true,
          });
          if (props.companyId) {
            dispatch(visitRecord());
            dispatch(internalGridVisited());
          } else {
            dispatch(visitRecord());
          }
        }}
      >
        {value["Product Name"]}
      </span>
    );
  };

  const actionTemplate = (value) => {
    const linkUrl = `${split_url.join("/")}/product/${value.id}/edit#overview`;
    return (
      <ThreeDotMenu
        rowID={value.id}
        {...(accessActionEdit || accessActionCompanyEdit
          ? {
              Edit: () => {
                history.push(linkUrl, {
                  productId: value.id,
                  companyId: props.companyId,
                  editData: true,
                });
                dispatch(visitRecord());
                if (props.companyId) {
                  dispatch(internalGridVisited());
                }
              },
            }
          : {})}
        {...(accessActionDelete || accessActionCompanyDelete
          ? {
              Delete: () => {
                dispatch(
                  showAlertBox({
                    okCallback: deleteSingleProduct,
                    data: value,
                    okText: "Delete",
                    cancelText: "Cancel",
                    content: "Are you sure you want to delete ?",
                    title: "dialogAlertCssWarning",
                  })
                );
              },
            }
          : {})}
      />
    );
  };

  // Dropdown Filter template
  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Overview":
        sourceData = [
          { id: "COMPLETE", text: "Completed" },
          { id: "-", text: "Blank" },
        ];
        break;
      case "Technology":
        sourceData = techTrendData;
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  // Overview Cell template
  const overViewTemplate = (value) => {
    if (value.column.field === "Overview") {
      return (
        <span className="ml-n2" style={{ paddingLeft: "5px" }}>
          {value["Overview"] === "COMPLETE" ? <span>Completed</span> : value["Overview"] === "In Progress" ? <span>In Progress</span> : ""}
        </span>
      );
    }
  };
  // specify column fields for datGrid here
  // 4 types of field - String, Numeric, Date, Boolean
  const columnFields = [
    {
      field: "id",
      type: "String",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Product Name",
      type: "String",
      template: editLinkTemplate,
      filterTemplate: StringFilter,
      showInColumnChooser: false,
      textAlign: "Left",
      width: 200,
    },
    {
      field: "Company",
      type: "String",
      filterTemplate: StringFilter,
      width: 150,
      textAlign: "Left",
    },
    {
      field: `${productVariant && productVariant === "Insider" ? "Trend" : "Technology"}`,
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "contains" },
      textAlign: "Left",
      headerTextAlign: "Left",
      width: 200,
    },
    {
      field: "Launch Year",
      type: "String",
      filterTemplate: StringFilter,
      textAlign: "Center",
      width: 150,
    },
    // { field: "Taxonomy", type: "String", template: (value) => productCompletionTemplate(value, "Taxonomy"), textAlign: "Center", headerTextAlign: "Center", width: 120 },
    {
      field: "Is Live",
      type: "String",
      filterTemplate: ItemFilter,
      textAlign: "Center",
      width: 140,
    },
    {
      field: "Overview",
      type: "String",
      template: (value) => overViewTemplate(value),
      filterTemplate: ItemFilter,

      filter: {
        operators: {
          stringOperator: [{ value: "equal", text: "Equal" }],
        },
        // operators:[{ value: 'equal', text: 'Equal..' }],

        operator: "equal",
        width: 150,
      },
      textAlign: "Left",
      predicate: false,
      headerTextAlign: "Left",
      width: 120,
      filterType: "StartsWith",
    },
  ];

  if (accessActionEdit || accessActionCompanyEdit || accessActionDelete || accessActionCompanyDelete) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Left",
    });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      allowFiltering: false,
      allowSorting: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
      });
      if (productVariant && productVariant === "Insider") {
        if (rD.productIndustryTrendsTags && rD.productIndustryTrendsTags.length) {
          newRD.Trend = rD.productIndustryTrendsTags
            .map((trend) => {
              if (trend.trendParentId !== 0 && trend.trendRoot !== 0 && trend.trendSubRoot == 0 && !trend.trendIsSgf) {
                return trend.trendName;
              }
              return "";
            })
            .filter((trend) => trend !== "")
            .join(", ");
        } else newRD.Trend = "";
      } else {
        if (rD.productTechnologyTags && rD.productTechnologyTags.length) {
          newRD.Technology = rD.productTechnologyTags
            .map((tech) => {
              if (tech.dtParentId === 0 && tech.dtRoot === 0) {
                return tech.dtName;
              }
              return "";
            })
            .filter((tech) => tech !== "")
            .join(", ");
        } else newRD.Technology = "";
      }
      if (rD.productCompany) {
        newRD.Company = rD.productCompany.companyName;
      } else {
        newRD.Company = "";
      }
      // newRD["Taxonomy"] = rD.productCompletionStatus?.technology?.length
      // 	? rD.productCompletionStatus.technology.filter(t => t.status === "INPROGRESS").length ? "INPROGRESS" : "COMPLETE"
      // 	: "";

      newRD["Overview"] = rD.productCompletionStatus?.overview ? rD.productCompletionStatus.overview : "";

      if (rD.productVariant?.length) {
        if (productVariant) {
          const rDPV = rD.productVariant.find((pV) => pV.productName === productVariant);
          newRD["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
        } else newRD["Is Live"] = rD.productVariant[0].isLive ? rD.productVariant[0].isLive : "NO";
      } else newRD["Is Live"] = "NO";
      return newRD;
    });
    return formatedRowData;
  };

  const fetchProductBasicWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    localStorage.removeItem("selectedProducts");
    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchProductBasic({ ...params });
    }

    if (Object.keys(filterData).length > 0) {
      let technologyFilter = [];
      let kiaFilter = [];
      let valueChainFilter = [];

      technologyFilter = filterData?.technology?.length > 0 ? ["productTechnologyTags.dtId", "in", filterData.technology] : [];
      kiaFilter = filterData?.kia?.length > 0 ? ["productKiaTags.kiaId", "in", filterData.kia] : [];
      valueChainFilter = filterData?.valueChain?.length > 0 ? ["productIndustryValuechainTags.valueChainId", "in", filterData.valueChain] : [];
      let boTaxonomy = filterData?.boTaxonomy?.length > 0 ? ["productObjectiveTaxonomy.taxonomyId", "in", filterData.boTaxonomy] : [];
      let businessObjective =
        filterData?.businessObjective?.length > 0 ? ["productBusinessObjective.objectiveId", "in", filterData.businessObjective] : [];
      let trendfilter = filterData?.trend?.length > 0 ? ["productIndustryTrendsTags.trendId", "in", filterData.trend] : [];

      params.filters = [...filters, ...defaultFilter];

      technologyFilter.length > 0 && params.filters.push(technologyFilter);
      kiaFilter.length > 0 && params.filters.push(kiaFilter);
      valueChainFilter.length > 0 && params.filters.push(valueChainFilter);
      businessObjective.length > 0 && params.filters.push(businessObjective);
      boTaxonomy.length > 0 && params.filters.push(boTaxonomy);
      trendfilter.length > 0 && params.filters.push(trendfilter);
    }

    return fetchProductBasic({ ...params });
  };

  const fetchProductBasicWithCompany = (params = {}) => {
    let defaultFilter = [];
    if (props.companyId) {
      if (props.backPath === "/processing/company/:companyId/edit") {
        defaultFilter = [["productCompany.companyId", "eq", props.companyId], "and", ["product_in_queue.queue_status", "eq", "PROCESSING_FRESH"]];
      } else if (props.backPath === "/data-qc/company/:companyId/edit") {
        defaultFilter = [["productCompany.companyId", "eq", props.companyId], "and", ["product_in_queue.queue_status", "eq", "QC_FRESH"]];
      } else {
        defaultFilter = [["productCompany.companyId", "eq", props.companyId]];
      }
    }

    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    localStorage.removeItem("selectedProducts");

    if (Object.keys(filterData).length > 0) {
      let technologyFilter = [];
      let kiaFilter = [];
      let valueChainFilter = [];

      technologyFilter = filterData?.technology?.length > 0 ? ["productTechnologyTags.dtId", "in", filterData.technology] : [];
      kiaFilter = filterData?.kia?.length > 0 ? ["productKiaTags.kiaId", "in", filterData.kia] : [];
      valueChainFilter = filterData?.valueChain?.length > 0 ? ["productIndustryValuechainTags.valueChainId", "in", filterData.valueChain] : [];
      let boTaxonomy = filterData?.boTaxonomy?.length > 0 ? ["productObjectiveTaxonomy.taxonomyId", "in", filterData.boTaxonomy] : [];
      let businessObjective =
        filterData?.businessObjective?.length > 0 ? ["productBusinessObjective.objectiveId", "in", filterData.businessObjective] : [];
      let trendfilter = filterData?.trend?.length > 0 ? ["productIndustryTrendsTags.trendId", "in", filterData.trend] : [];

      params.filters = [...filters, ...defaultFilter];

      technologyFilter.length > 0 && params.filters.push(technologyFilter);
      kiaFilter.length > 0 && params.filters.push(kiaFilter);
      valueChainFilter.length > 0 && params.filters.push(valueChainFilter);
      businessObjective.length > 0 && params.filters.push(businessObjective);
      boTaxonomy.length > 0 && params.filters.push(boTaxonomy);
      trendfilter.length > 0 && params.filters.push(trendfilter);
    }
    if (!gridState.internalGridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setInternalGridState(params.skip, params.sort, params.filters));
    }

    if (gridState.internalGridState.recordVisited) {
      params = {
        filters: gridState.internalGridState.filtersParams,
        sort: gridState.internalGridState.sortRecords,
        skip: gridState.internalGridState.skipRecords,
        // limit:gridState.recordsPerPage,
      };
      dispatch(setInternalGridState(params.skip, params.sort, params.filters));
    }

    return fetchProductBasic({ ...params });
  };

  const onQueryCellInfo = (args) => {
    if (args.column.field === "Overview") {
      if (args.cell.textContent === "In Progress") {
        args.cell.querySelector(".temp-overview").classList.add("inactive");
      }
      if (args.cell.textContent === "Complete") {
        args.cell.querySelector(".temp-overview").classList.add("active");
      }
      if (args.cell.textContent === "") {
      }
    }
  };
  const deleteManyFunc = (flag) => {
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (flag) {
      if (selectedProducts.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteManyProduct({ productIds: selectedProducts })
                .then((response) => {
                  if (response.data.data) {
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    const params = {};

                    if (productVariant) params.filters = [["productVariant.productName", "eq", productVariant]];
                    fetchProductBasic(params).then((product) => {
                      formatRowData(product.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete product"));
                });
            },
            content: "Are you sure you want to delete records?",
            okText: "Delete",
            cancelText: "Cancel",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  /* 
	@Description : Bulk tagging technology tab
	*/
  const handleSubmitTechnology = async (fetchGridData) => {
    let payload = {
      productIds: initalState,
      taxonomyTagging: productTaxonomy.taxonomyCheckedNodes,
      taxonomyUnTagging: productTaxonomy.taxonomyUnCheckedNodes,
    };

    if (productTaxonomy?.initialTechnologyCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveProductTaxonomyTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select product"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchTechnologyTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (selectedProducts.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      params.fields = ["productTechnologyTags"];
      const contentTaxonomy = fetchProductBasic(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.productTechnologyTags.length > 0 &&
              value.productTechnologyTags.map((v) => {
                checkNodes.push(v.dtId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedProducts.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...productTaxonomy.taxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTaxonomyCheckedNodes([...productTaxonomy.taxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const fetchValuchainData = (data, selectedProducts) => {
    if (data.length) {
      let valueChainIds = [];
      let commanIds = [];
      let taxonomyTee = [];
      data.map((value) => {
        value.productIndustryTag.length > 0 &&
          value.productIndustryTag.map((item) => {
            if (!valueChainIds.includes(item.industryId)) {
              let taxonomyTeeData = {};
              taxonomyTeeData["id"] = item.industryId;
              taxonomyTeeData["name"] = item.industryName;
              taxonomyTee.push(taxonomyTeeData);
            }
            valueChainIds.push(item.industryId);
          });
      });
      let count = _.countBy(valueChainIds);
      let countarray = Object.entries(count);
      countarray.map((d) => {
        if (selectedProducts.length == d[1]) {
          commanIds.push(d[0]);
        }
      });
      let valueChainData = _.filter(taxonomyTee, function (element) {
        return _.find(commanIds, function (name) {
          return element.id === name;
        });
      });
      setValueChainData(valueChainData);
    }
  };

  const taxonomyBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (selectedProducts.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      params.fields = ["productIndustryTag"];
      const companyIsLive = await fetchProductBasic(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedProducts);
        setShowTaxonomyBulk(true);
        companyIsLive?.data?.data && fetchValuchainData(companyIsLive.data.data, selectedProducts);
      }
    } else {
      dispatch(actionError("Please select product."));
    }
  };

  const addTaxonomyCheckedNodes = (id) => {
    let taxonomyCheck = productTaxonomy.taxonomyCheckedNodes;
    let taxonomyUnCheck = productTaxonomy.taxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = productTaxonomy.initialTechnologyCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTechnologyNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTechnologyNodes([...productTaxonomy.initialTechnologyCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTaxonomyUnCheckedNodes([...productTaxonomy.taxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTaxonomyCheckedNodes([...productTaxonomy.taxonomyCheckedNodes, id]));
  };

  const objectiveTaxonomyTag = async () => {
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    dispatch(visitRecord());
    if (selectedProducts.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      params.fields = ["productVariant"];
      dispatch(showLoader());
      const productIsLive = await fetchProductBasic(params);
      dispatch(hideLoader());
      if (productIsLive.data) {
        let nonLiveComp = productIsLive.data.data.filter((comp) => {
          let isLIveComp = true;
          comp.productVariant.forEach((prod) => {
            if (prod.productName == productVariant && prod.isLive == "YES") {
              isLIveComp = false;
            }
          });
          return isLIveComp;
        });
        if (nonLiveComp.length) {
          dispatch(actionError("Please select only live product."));
        } else {
          history.push(`/business-objective/taxonomy/product`, {
            productIds: selectedProducts,
            entityName: "product",
          });
        }
      }
    } else {
      dispatch(actionError("Please select product."));
    }
  };

  /* 
		@Description : Bulk tagging for kia
	*/
  const handleSubmitKia = async (fetchGridData) => {
    let payload = {
      productIds: initalState,
      taxonomyTagging: productTaxonomy.kiaCheckedNodes,
      taxonomyUnTagging: productTaxonomy.kiaUnCheckedNodes,
    };

    if (productTaxonomy?.initialKiaCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveProductKiaTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select product"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchKiaTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (selectedProducts.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      params.fields = ["productKiaTags"];
      const contentTaxonomy = fetchProductBasic(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.productKiaTags.length > 0 &&
              value.productKiaTags.map((v) => {
                checkNodes.push(v.kiaId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedProducts.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            commonIds.map((element) => {
              value.productKiaTags.length > 0 &&
                value.productKiaTags.map((v) => {
                  if (v.kiaId === element) {
                    commonIds.push(v.kiaIndustryId);
                  }
                });
            });
            commonIds = [...new Set(commonIds)];
            setBulkCheck([...productTaxonomy.kiaCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setKiaCheckedNodes([...productTaxonomy.kiaCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addKiaCheckedNodes = (id) => {
    let taxonomyCheck = productTaxonomy.kiaCheckedNodes;
    let taxonomyUnCheck = productTaxonomy.kiaUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = productTaxonomy.initialKiaCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedKiaNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedKiaNodes([...productTaxonomy.initialKiaCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setKiaCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setKiaUnCheckedNodes([...productTaxonomy.kiaUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setKiaUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setKiaCheckedNodes([...productTaxonomy.kiaCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging Value chain
	*/
  const handleSubmitValueChain = async (fetchGridData) => {
    let payload = {
      productIds: initalState,
      taxonomyTagging: productTaxonomy.valueChainCheckedNodes,
      taxonomyUnTagging: productTaxonomy.valueChainUnCheckedNodes,
      transformingTechnologiesIds: productTaxonomy.transformingCheckedNodes,
      transformingUnCheckedTechnologiesIds: productTaxonomy.transformingUnCheckedNodes,
    };

    if (productTaxonomy?.initialValueChainCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveValueChainTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select product"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchValueChainTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (selectedProducts.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      params.fields = ["productIndustryValuechainTags"];
      const contentTaxonomy = fetchProductBasic(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let checkNodes2 = [];
          let count;
          let count2;
          res.data.data.map((value) => {
            value.productIndustryValuechainTags.length > 0 &&
              value.productIndustryValuechainTags.map((v) => {
                checkNodes.push(v.valueChainId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }

                // transformingTechnologies id
                v.transformingTechnologies &&
                  v.transformingTechnologies.map((ele) => {
                    checkNodes2.push(ele.techId);
                    count2 = {};
                    for (const element of checkNodes2) {
                      if (count2[element]) {
                        count2[element] += 1;
                      } else {
                        count2[element] = 1;
                      }
                    }
                  });
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedProducts.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            let transformingtechnology = count2 && Object.entries(count2);
            let transformingtechnologyCommanId = [];
            transformingtechnology &&
              transformingtechnology.map((d) => {
                if (selectedProducts.length == d[1]) {
                  transformingtechnologyCommanId.push(d[0]);
                }
              });

            setBulkCheck([...productTaxonomy.valueChainCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            setTaxonomyTransformationState([...transformingtechnologyCommanId]);
            dispatch(
              setValueChainCheckedNodes([
                ...productTaxonomy.valueChainCheckedNodes,
                ...commonIds,
                ...productTaxonomy.transformingCheckedNodes,
                ...transformingtechnologyCommanId,
              ])
            );
            dispatch(setTransformingCheckedNodes([...productTaxonomy.transformingCheckedNodes, ...transformingtechnologyCommanId]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addValueChainCheckedNodes = (id) => {
    let taxonomyCheck = productTaxonomy.valueChainCheckedNodes;
    let taxonomyUnCheck = productTaxonomy.valueChainUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = productTaxonomy.initialValueChainCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedValueChainNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedValueChainNodes([...productTaxonomy.initialValueChainCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setValueChainCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setValueChainUnCheckedNodes([...productTaxonomy.valueChainUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setValueChainUnCheckedNodes(taxonomyUnCheck));
    }

    dispatch(setValueChainCheckedNodes([...productTaxonomy.valueChainCheckedNodes, id]));
  };

  const addValueChainTransformingCheckedNodes = (id) => {
    let taxonomyCheck = productTaxonomy.transformingCheckedNodes;
    let taxonomyUnCheck = productTaxonomy.transformingUnCheckedNodes;
    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTransformingCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTransformingUnCheckedNodes([...productTaxonomy.transformingUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTransformingUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTransformingCheckedNodes([...productTaxonomy.transformingCheckedNodes, id]));
  };

  /* 
		@Description : Bulk tagging for business objective
	*/
  const handleSubmitObjective = async (fetchGridData) => {
    let taxonomy = {
      productIds: initalState,
      productObjectiveTaxonomy: productTaxonomy.objectiveTaxonomyCheckedNodes,
      productUnCheckObjectiveTaxonomy: productTaxonomy.objectiveTaxonomyUnCheckedNodes,
    };

    if (productTaxonomy?.initialObjectiveCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveProductBusinessObjectiveTaxonomy(taxonomy)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select product"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchObjectiveTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (selectedProducts.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      params.fields = ["productObjectiveTaxonomy"];
      const contentTaxonomy = fetchProductBasic(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.productObjectiveTaxonomy.length > 0 &&
              value.productObjectiveTaxonomy.map((v) => {
                checkNodes.push(v.taxonomyId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedProducts.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            setBulkCheck([...productTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setObjectiveTaxonomyCheckedNodes([...productTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addObjectiveCheckedNodes = (id) => {
    let taxonomyCheck = productTaxonomy.objectiveTaxonomyCheckedNodes;
    let taxonomyUnCheck = productTaxonomy.objectiveTaxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = productTaxonomy.initialObjectiveCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedObjectiveNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedObjectiveNodes([...productTaxonomy.initialObjectiveCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setObjectiveTaxonomyUnCheckedNodes([...productTaxonomy.objectiveTaxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setObjectiveTaxonomyCheckedNodes([...productTaxonomy.objectiveTaxonomyCheckedNodes, id]));
  };

  /* 
	@Description : Bulk tagging trend sgf - Insider
	*/
  const handleSubmitTrend = async (fetchGridData) => {
    let payload = {
      productIds: initalState,
      taxonomyTagging: productTaxonomy.trendCheckedNodes,
      taxonomyUnTagging: productTaxonomy.trendUnCheckedNodes,
      sgfIds: productTaxonomy.initialSgfCheckedNodes,
    };

    if (productTaxonomy?.initialTrendCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveProductTrendTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select product"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchTrendTagging = () => {
    setCssClass("treeClass trend");
    setListClass("select-lists bulktagging");
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (selectedProducts.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      params.fields = ["productIndustryTrendsTags"];
      const contentTaxonomy = fetchProductBasic(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.productIndustryTrendsTags.length > 0 &&
              value.productIndustryTrendsTags.map((v) => {
                checkNodes.push(v.trendId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedProducts.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...productTaxonomy.trendCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTrendCheckedNodes([...productTaxonomy.trendCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const trendBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedProducts = localStorage.getItem("selectedProducts") ? JSON.parse(localStorage.getItem("selectedProducts")) : [];
    if (selectedProducts.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedProducts],
      ];
      const companyIsLive = await fetchProductBasic(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedProducts);
        setShowTaxonomyBulk(true);
      }
    } else {
      dispatch(actionError("Please select product."));
    }
  };

  const addTrendCheckedNodes = (taxonomyId) => {
    let id = taxonomyId.split("-")[1];
    let sgfId = taxonomyId.split("-")[0];
    let taxonomyCheck = productTaxonomy.trendCheckedNodes;
    let taxonomyUnCheck = productTaxonomy.trendUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = productTaxonomy.initialTrendCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTrendNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTrendNodes([...productTaxonomy.initialTrendCheckedNodes, id]));

    // set checked sgf node
    dispatch(setInitialCheckedSgfNodes([...productTaxonomy.initialSgfCheckedNodes, sgfId]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTrendCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTrendUnCheckedNodes([...productTaxonomy.trendUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTrendUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTrendCheckedNodes([...productTaxonomy.trendCheckedNodes, id]));
  };

  //
  let tabNames;
  if (productVariant == PRODUCT_VARIANT.INSIDER) {
    tabNames = taxonomyTaggingTabName ? ["Tag Industry"] : ["By Industry"];
  } else {
    tabNames = taxonomyTaggingTabName
      ? ["Tag Technology", "Tag Key Impact Area", "Tag Value Chain", "Tag Business Objective"]
      : ["By Technology", "By Key Impact Area", "By Value Chain", "By Business Objective"];
  }

  // handle save for taxonomy bulk tagging
  const handleSave = (selectedIndex, fetchGridData) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        handleSubmitTechnology(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitKia(fetchGridData);
      }
      if (selectedIndex == 2) {
        handleSubmitValueChain(fetchGridData);
      }
      if (selectedIndex == 3) {
        handleSubmitObjective(fetchGridData);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        handleSubmitTrend(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitObjective(fetchGridData);
      }
    }
  };

  const handleTaxonomyTagging = (args, selectedIndex) => {
    let valueId = args?.data[0].id;
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        addTaxonomyCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addKiaCheckedNodes(valueId);
      }
      if (selectedIndex == 2) {
        handleValueChainValidation(args);
      }
      if (selectedIndex == 3) {
        addObjectiveCheckedNodes(valueId);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        addTrendCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addObjectiveCheckedNodes(valueId);
      }
    }
  };

  const handleValueChainValidation = (args) => {
    if (args?.data[0]?.parentID == null) {
      addValueChainCheckedNodes(args?.data[0]?.id);
    }
    if (args?.data[0]?.parentID !== null) {
      addValueChainTransformingCheckedNodes(args?.data[0]?.id);
    }
  };

  // fetch TaxonomyTagging data for taxonomy bluk tagging
  const fetchTaxonomyTagging = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        fetchTechnologyTagging();
      }
      if (selectedIndex == 1) {
        fetchKiaTagging();
      }
      if (selectedIndex == 2) {
        fetchValueChainTagging();
      }
      if (selectedIndex == 3) {
        fetchObjectiveTagging();
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        fetchTrendTagging();
      }
      if (selectedIndex == 1) {
        fetchObjectiveTagging();
      }
    }
  };

  const setBulkCheckedNode = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      selectedIndex == 0 && setBulkCheck([...productTaxonomy.taxonomyCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...productTaxonomy.kiaCheckedNodes]);
      selectedIndex == 2 && setBulkCheck([...productTaxonomy.valueChainCheckedNodes, ...productTaxonomy.transformingCheckedNodes]);
      selectedIndex == 3 && setBulkCheck([...productTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      selectedIndex == 0 && setBulkCheck([...productTaxonomy.trendCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...productTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
  };

  const resetAllTaxonnomy = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.COGNITION) {
      if (selectedIndex == 0) {
        dispatch(setTaxonomyCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setKiaCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 2) {
        dispatch(setValueChainCheckedNodes([...taxonomyState]));
        dispatch(setTransformingCheckedNodes([...taxonomyTransformationState]));
      }
      if (selectedIndex == 3) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        dispatch(setTrendCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    setBulkCheck([...taxonomyState]);
  };
  let internalGrid = myArray[1] == "company" || myArray[1] == "processing" || myArray[1] == "data-qc" ? true : false;

  /*Reset redux of common save*/
  useEffect(() => {
    dispatch(resetProductRedux());
  });
  return (
    <div
      className={` content-wrapper ${props.companyId ? "gennx-grid-container-custom mt-3  ml-2 mr-3" : "gennx-grid-container  px-3 productCheckBox"}`}
    >
      <div className={`gennx-grid-container`}>
        {techTrendData && (
          <CommonDataGrid
            gridTitle="Product Records"
            fetch={props.companyId && props.companyId.length === 24 ? fetchProductBasicWithCompany : fetchProductBasicWithProductVariant}
            deleteOne={deleteProduct}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            columnNames={columnNames}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            queryCellInfo={onQueryCellInfo}
            uniqueVal={(data) => {
              if (!rowData1.length) setRowData(data);
            }}
            getMultiSelectedRows={(data) => {
              localStorage.setItem("selectedProducts", JSON.stringify([...data]));
            }}
            getMultiDeSelectedRows={(data) => {
              localStorage.setItem("selectedProducts", JSON.stringify([...data]));
            }}
            deleteRight={true}
            deleteMany={props?.companyId ? (accessActionCompanyDelete ? deleteManyFunc : null) : accessActionDelete ? deleteManyFunc : null}
            objectiveTaxonomyRight={props?.companyId ? accessActionCompanyTaxonomyBulkTagging : accessActionTaxonomyBulkTagging}
            objectiveTaxonomyTag={objectiveTaxonomyTag}
            addNewRight={props?.companyId && props?.backPath !== "/product" ? true : false && accessActionCompanyListingAddNew}
            addNew={() => {
              history.push(`${split_url.join("/")}/product/add#overview`, {
                companyId: props?.companyId,
              });
            }}
            autoFitColumnName={["Product Name", "Company"]}
            isChildGrid={props?.companyId && props?.companyId.length === 24 ? true : false}
            setFilterData={setFilterData}
            filterData={filterData}
            showFilterByTaxonomy={
              props?.companyId
                ? productVariant !== PRODUCT_VARIANT.CHEERSIN && accessActionCompanyFilterByTaxonomy
                : productVariant !== PRODUCT_VARIANT.CHEERSIN && accessActionFilterByTaxonomy
            }
            boTagging={true}
            moduleName={moduleName}
            //bulk tagging
            checked={bulkCheck}
            setBulkCheck={setBulkCheck}
            showTaxonomyBulk={showTaxonomyBulk}
            setShowTaxonomyBulk={setShowTaxonomyBulk}
            taxonomyBulkTagging={taxonomyBulkTagging}
            trendBulkTagging={trendBulkTagging}
            cssClass={cssClass}
            setCssClass={setCssClass}
            listClass={listClass}
            setListClass={setListClass}
            tabName={tabNames}
            handleTaxonomyTabName={setTaxonomyTaggingTabName}
            customDialogBoxClass={internalGrid ? "productDialoagBoxClass" : "dialoagBoxClass"}
            customArrowClass={internalGrid ? "product-arrow-top" : "arrow-top"}
            bulkTaggingArrowTop={internalGrid ? "productbulkTaggingArrowTop" : "bulkTaggingArrowTop"}
            handleSubmit={handleSave}
            handleTaxonomyTagging={handleTaxonomyTagging}
            fetchTaxonomyTagging={fetchTaxonomyTagging}
            setBulkCheckedNode={setBulkCheckedNode}
            resetAllTaxonnomy={resetAllTaxonnomy}
            valueChainData={valueChainData}
            accessPermissionBulk={props?.companyId ? accessActionCompanyTaxonomyBulkTagging : accessActionTaxonomyBulkTagging}
            customSearch={internalGrid && "productCustomSearch"}
          />
        )}
      </div>

      <div className="mx-n4">
        {props.companyId && (
          <div className="form-submit-box" style={{ marginLeft: "1em" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="float-left col-md-6 text-left">
                  {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                    <span className="pl-4" style={{ marginLeft: "-3em" }}>
                      <span className="span-link">
                        <button
                          type="button"
                          className="btn btn-outline-dark pl-4 pr-4 cy-btn "
                          onClick={() => {
                            (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                              (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                          }}
                        >
                          Previous
                        </button>
                      </span>
                    </span>
                  ) : null}
                  {props.selectedIndex > -1 ? (
                    <span className="pl-4">
                      <span className="span-link">
                        <button
                          type="button"
                          className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                          onClick={() => {
                            props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex + 1);
                          }}
                        >
                          Next
                        </button>
                      </span>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
ProductListing.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.string), 
  newsId: PropTypes.string, 
  location: PropTypes.string,
  computedMatch:PropTypes.string,
  interfaceActionAccess: PropTypes.bool, 
  companyId: PropTypes.number,
  selectedIndex: PropTypes.number,
  setCurrentTab: PropTypes.func,
  backPath: PropTypes.string,
  interfaceActionAccessProduct: PropTypes.arrayOf(PropTypes.string)
};
export default ProductListing;
