/**
 * @fileDescription My Briefcase API
 * @author Satya Prakash Mall
 * @date 06/Dec/2023
 */

import http from "./http-common";
import { USER_URL } from "../../config";

// Briefcase folder get api
export const getBriefcaseCategoryList = async (userId, params, productVariant) => {
  try {
    const response = await http.get(`${USER_URL}/user/${userId}/presentation/category`, {
      params: {
        skip: params.skip,
        limit: params.limit,
        sort: params.sort,
        filters: JSON.stringify([
          [
            "or",
            [
              ["categoryShared.userId", "eq", userId],
              ["categoryCreatedByUserId", "eq", userId],
            ],
          ],
          ["categoryProductPlatform", "eq", productVariant + " V2"],
          ["categoryActionType", "eq", "BRIEFCASE"],
        ]),
      },
    });
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: " + err);
  }
};

export const getAllBriefcaseCategoryList = async (userId, productVariant) => {
  try {
    const response = await http.get(`${USER_URL}/user/${userId}/presentation/category`, {
      params: {
        limit: 10000,
        sort: "categoryCreatedDate:desc",
        filters: JSON.stringify([
          [
            "or",
            [
              ["categoryShared.userId", "eq", userId],
              ["categoryCreatedByUserId", "eq", userId],
            ],
          ],
          ["categoryProductPlatform", "eq", productVariant + " V2"],
          ["categoryActionType", "eq", "BRIEFCASE"],
        ]),
      },
    });
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: " + err);
  }
};

export const getSpecificCategoryBriefcase = async (userId, categoryId, sort) => {
  try {
    const response = await http.get(`${USER_URL}/user/${userId}/presentation`, {
      params: {
        filters: `[["presentationCategoryId","eq","${categoryId}"]]`,
        skip: 0,
        limit: 1000,
        sort: sort,
      },
    });
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const deleteBriefcase = async (userId, ids) => {
  try {
    const response = await http.delete(`${USER_URL}/user/${userId}/presentation`, { data: { presentationIds: ids } });
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const deleteCategory = async (userId, categoryId) => {
  try {
    const response = await http.delete(`${USER_URL}/user/${userId}/presentation/category/${categoryId}`);
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const addNewBriefcaseFolder = async (userId, folderName, productVariant) => {
  try {
    const response = await http.post(`${USER_URL}/user/${userId}/presentation/category`, {
      categoryName: folderName,
      categoryType: "folder",
      categoryProductPlatform: productVariant + " V2",
      categoryActionType: "BRIEFCASE",
    });
    if (response?.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const renameBriefcaseFolderName = async (userId, categoryId, categoryName) => {
  try {
    const response = await http.put(
      `${USER_URL}/user/${userId}/presentation/category/${categoryId}`,
      JSON.stringify({
        categoryName: categoryName,
        categoryType: "folder",
      })
    );
    if (response.status !== 200) {
      throw new Error("Something went wrong");
    }

    return response.data.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const updateBriefcaseSequence = async (userId, payload) => {
  try {
    const response = await http.put(`${USER_URL}/user/${userId}/presentation/indexes`, payload);
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const updateBriefcaseMove = async (userId, categoryId, ids) => {
  try {
    const response = await http.put(`${USER_URL}/user/${userId}/presentation/move/${categoryId}`, { presentationIds: ids });
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getCategoryBriefcase = (userId, categoryId) => {
  return http
    .get(`${USER_URL}/user/${userId}/presentation`, {
      params: {
        filters: `[["presentationCategoryId","eq","${categoryId}"]]`,
        skip: 0,
        limit: 1000,
        sort: `presentationSeqNo:asc`,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.log("error  category Briefcase listing >>>", error);
    });
};

export const updateCategoryShared = async (userId, categoryId, payload) => {
  try {
    const response = await http.put(`${USER_URL}/user/${userId}/presentation/category/${categoryId}/share`, payload);
    if (response.status !== 200) {
      throw new Error("Something went wrong!");
    }
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
