import "./index.scss";
import { accessRightActionCheck, getLoggedInUser, getProductVariant } from "../../../../../../utilities";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader, actionError, actionSuccess } from "../../../../../../middleware/actions/utilityAction";
import {
  fetchDeliverableSetting,
  fetchSingleDeliverable,
  updateDeliverableSetting,
  updateOverView,
} from "../../../../../../middleware/services/deliverablesApi";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { setDeliverableSettingDetails } from "../../../../../../middleware/actions/deliverableAction";
import { TextField, Dropdown, NumericTextField } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import "../../../../../components/accordion/index.scss";
import { fetchConfig, fetchFontFamily } from "../../../../../../middleware/services/cmsApi";
import { useQuery } from "@tanstack/react-query";
import { deleteWidgetContent, fetchWidget, updateWidgetContent } from "middleware/services/customizableWidgetApi";
import { PINNED_CONTENT } from "constants/widget-constants";
import { combineIndustriesWithWidgets, filterIndustryFromTrends, filterIndustryIds } from "utils";
import { COMMON_ACCESS_ACTION, CONTENT_TYPE, PRODUCT_VARIANT } from "../../../../../../constants";
import { Autocomplete, Button, Checkbox, TextField as MuiTextField } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { deliverableSettingValidationSchema } from "./validationSchema";
import PropTypes from 'prop-types';

const Settings = (props) => {
  const deliverableId = props.deliverableId;
  const dispatch = useDispatch();
  let user = getLoggedInUser();
  const forcedActiveState = false;
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null); // Create a ref for the container
  const [fontstate, setFontState] = useState(false);
  const [colorState, setcolorState] = useState(false);
  const [formattingState, setFormattingState] = useState(false);
  const [exportToPDFState, setExportToPDFState] = useState(false);
  const [exportToExcelState, setExportToExcelState] = useState(false);
  const [exportToSectionState, setExportToSectionState] = useState(false);
  const [exportToPowerPointState, setExportToPowerPointState] = useState(false);
  const [exportToChartState, setExportToChartState] = useState(false);
  const [applyPdfSettingState, setApplyPdfSettingState] = useState(false);
  const [applyExcelSettingState, setApplyExcelSettingState] = useState(false);
  const [applyPowerPointSettingState, setApplyPowerPointSettingState] = useState(false);
  const [applySectionSettingState, setApplySectionSettingState] = useState(false);
  const [applyChartsSettingState, setApplyChartsSettingState] = useState(false);
  const [fontFamilyList, setFontFamilyList] = useState("");
  const [colorList, setColorList] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [addToWidget, setAddToWidget] = useState(false);
  const disabledSaveOnContentType = [CONTENT_TYPE.CLIENT_BUSINESS_OBJECTIVE, CONTENT_TYPE.DASHBOARD, CONTENT_TYPE.TRAINING];
  const [mostReadResearch, setMostReadResearch] = useState("");
  const [popularReport, setPopularReport] = useState("");
  const [deliverableDetails, setDeliverableDetails] = useState({});

  let interfaceName = "Settings";
  let actionAccess = accessRightActionCheck(props?.moduleName, interfaceName) || [];
  const accessActionWidgetSave = actionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionDesignAndExportSave = actionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionNewsletterSave = actionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);

  const settingState = useSelector((state) => state.deliverableState.settingsDetails);

  /*
     @Description : Assign default value and bind validation schema
   */
  const formik = useFormik({
    initialValues: {
      pdfText: "",
      pdfFontFamily: "",
      pdfFontSize: "",
      pdfColor: "",
      pdfOpacity: "",
      pdfTopPosition: "",
      pdfLeftPosition: "",
      pdfRotate: "",
      excelText: "",
      excelFontFamily: "",
      excelFontSize: "",
      excelColor: "",
      excelOpacity: "",
      excelTopPosition: "",
      excelLeftPosition: "",
      excelRotate: "",
      powerPointText: "",
      powerPointFontFamily: "",
      powerPointFontSize: "",
      powerPointColor: "",
      powerPointOpacity: "",
      powerPointTopPosition: "",
      powerPointLeftPosition: "",
      powerPointRotate: "",
      chartsText: "",
      chartsFontFamily: "",
      chartsFontSize: "",
      chartsColor: "",
      chartsOpacity: "",
      chartsTopPosition: "",
      chartsLeftPosition: "",
      chartsRotate: "",
      sectionText: "",
      sectionFontFamily: "",
      sectionFontSize: "",
      sectionColor: "",
      sectionOpacity: "",
      sectionTopPosition: "",
      sectionLeftPosition: "",
      sectionRotate: "",
      exportAsPdf: "No",
      exportAsExcel: "No",
      exportAsPpt: "No",
      exportASection: "No",
      exportAsChart: "No",
    },
    validationSchema: Yup.object().shape(deliverableSettingValidationSchema),
  });

  const watermarkRotate = [
    {
      label: "0 Degree",
      value: 0,
    },
    {
      label: "30 Degrees",
      value: 30,
    },
    {
      label: "45 Degrees",
      value: 45,
    },
    {
      label: "60 Degrees",
      value: 60,
    },
    {
      label: "90 Degrees",
      value: 90,
    },
  ];

  const exportTypes = ["pdf", "excel", "powerPoint", "section", "charts"];

  const { data: deliverableData, isFetching: deliverableFetching } = useQuery({
    queryKey: ["deliverable-data"],
    queryFn: () => fetchSingleDeliverable(deliverableId),
    enabled: Boolean(deliverableId),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    setDeliverableDetails(deliverableData?.data?.data);
  }, [deliverableData?.data?.data]);

  const getFontFamily = async () => {
    dispatch(showLoader());
    fetchFontFamily({ sort: "name:asc" })
      .then((res) => {
        setFontFamilyList(res.data.data);
        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
        dispatch(hideLoader());
      });
  };

  const getWatermarkColors = async () => {
    dispatch(showLoader());
    const productVariant = getProductVariant();
    const params = { filters: [["config_name", "eq", "watermark-colors"]] };
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        let variantColors = productVariant === "Insider" ? data.insider : productVariant === "WhatNext" ? data.whatnext : data.cognition;
        setColorList(variantColors);
        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
        dispatch(hideLoader());
      });
  };
 
  const setSettingsData = async () => {
    dispatch(showLoader());
    
    try {
        const res = await fetchDeliverableSetting(deliverableId);
        
        const settings = res.data.data.deliverableSetting;

        const allowChangeFont = settingState?.allowChangeFont === "Yes" || (settings?.allowChangeFont === "Yes");
        const allowChangeColor = settingState?.allowChangeColor === "Yes" || (settings?.allowChangeColor === "Yes");
        const allowChangeFormatting = settingState?.allowChangeFormatting === "Yes" || (settings?.allowChangeFormatting === "Yes");
        const exportAsPdf = settingState?.exportAsPdf === "Yes" || (settings?.exportAsPdf === "Yes");
        const applyPdfSetting = settingState?.applyPdfSetting === "Yes" || (settings?.applyPdfSetting === "Yes");
        const exportAsPpt = settingState?.exportAsPpt === "Yes" || (settings?.exportAsPpt === "Yes");
        const exportAsExcel = settingState?.exportAsExcel === "Yes" || (settings?.exportAsExcel === "Yes");
        const exportASection = settingState?.exportASection === "Yes" || (settings?.exportASection === "Yes");
        const exportAsChart = settingState?.exportAsChart === "Yes" || (settings?.exportAsChart === "Yes");

        setFontState(allowChangeFont);
        setcolorState(allowChangeColor);
        setFormattingState(allowChangeFormatting);
        setExportToPDFState(exportAsPdf);
        setApplyPdfSettingState(applyPdfSetting);
        setExportToPowerPointState(exportAsPpt);
        setExportToExcelState(exportAsExcel);
        setExportToSectionState(exportASection);
        setExportToChartState(exportAsChart);

        formik.setFieldValue("exportAsPdf", exportAsPdf);
        formik.setFieldValue("exportAsPpt", exportAsPpt);
        formik.setFieldValue("exportAsExcel", exportAsExcel);
        formik.setFieldValue("exportASection", exportASection);
        formik.setFieldValue("exportAsChart", exportAsChart);

        if (
            !settings &&
            !(
                settingState.pdfWatermarkSettings ||
                settingState.excelWatermarkSettings ||
                settingState.pptWatermarkSettings ||
                settingState.chartWatermarkSettings ||
                settingState.sectionWatermarkSettings
            )
        ) {
            setWatermarkDefaultData(res);
        } else {
            setWatermarkAccordianData(res);
        }

    } catch (e) {
        dispatch(actionError("Something went wrong"));
    } finally {
        dispatch(hideLoader());
    }
};

  const setWatermarkAccordianData = (res) => {

    formik.setFieldValue(
      "pdfText",
      settingState?.pdfWatermarkSettings?.text
        ? settingState.pdfWatermarkSettings.text
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.text
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "pdfFontFamily",
      settingState?.pdfWatermarkSettings?.fontFamily
        ? settingState.pdfWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "pdfFontSize",
      settingState?.pdfWatermarkSettings?.fontSize
        ? settingState.pdfWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "pdfColor",
      settingState?.pdfWatermarkSettings?.color
        ? settingState.pdfWatermarkSettings.color
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.color
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "pdfOpacity",
      settingState?.pdfWatermarkSettings?.opacity
        ? settingState.pdfWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.opacity === 0
          ? 0
          : res.data.data.deliverableSetting.pdfWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "pdfTopPosition",
      settingState?.pdfWatermarkSettings?.topPosition
        ? settingState.pdfWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "pdfLeftPosition",
      settingState?.pdfWatermarkSettings?.leftPosition
        ? settingState.pdfWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "pdfRotate",
      settingState?.pdfWatermarkSettings?.rotate
        ? settingState.pdfWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.pdfWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.pdfWatermarkSettings.rotate
        : 0
    );

    formik.setFieldValue(
      "excelText",
      settingState?.excelWatermarkSettings?.text
        ? settingState.excelWatermarkSettings.text
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.text
        ? res.data.data.deliverableSetting.excelWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "excelFontFamily",
      settingState?.excelWatermarkSettings?.fontFamily
        ? settingState.excelWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.excelWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "excelFontSize",
      settingState?.excelWatermarkSettings?.fontSize
        ? settingState.excelWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.excelWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "excelColor",
      settingState?.excelWatermarkSettings?.color
        ? settingState.excelWatermarkSettings.color
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.color
        ? res.data.data.deliverableSetting.excelWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "excelOpacity",
      settingState?.excelWatermarkSettings?.opacity
        ? settingState.excelWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.excelWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "excelTopPosition",
      settingState?.excelWatermarkSettings?.topPosition
        ? settingState.excelWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.excelWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "excelLeftPosition",
      settingState?.excelWatermarkSettings?.leftPosition
        ? settingState.excelWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.excelWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "excelRotate",
      settingState?.excelWatermarkSettings?.rotate
        ? settingState.excelWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.excelWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.excelWatermarkSettings.rotate
        : 0
    );
    formik.setFieldValue(
      "powerPointText",
      settingState?.pptWatermarkSettings?.text
        ? settingState.pptWatermarkSettings.text
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.text
        ? res.data.data.deliverableSetting.pptWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "powerPointFontFamily",
      settingState?.pptWatermarkSettings?.fontFamily
        ? settingState.pptWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.pptWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "powerPointFontSize",
      settingState?.pptWatermarkSettings?.fontSize
        ? settingState.pptWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.pptWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "powerPointColor",
      settingState?.pptWatermarkSettings?.color
        ? settingState.pptWatermarkSettings.color
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.color
        ? res.data.data.deliverableSetting.pptWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "powerPointOpacity",
      settingState?.pptWatermarkSettings?.opacity
        ? settingState.pptWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.pptWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "powerPointTopPosition",
      settingState?.pptWatermarkSettings?.topPosition
        ? settingState.pptWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.pptWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "powerPointLeftPosition",
      settingState?.pptWatermarkSettings?.leftPosition
        ? settingState.pptWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.pptWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "powerPointRotate",
      settingState?.pptWatermarkSettings?.rotate
        ? settingState.pptWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.pptWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.pptWatermarkSettings.rotate
        : 0
    );
    formik.setFieldValue(
      "chartsText",
      settingState?.chartWatermarkSettings?.text
        ? settingState.chartWatermarkSettings.text
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.text
        ? res.data.data.deliverableSetting.chartWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "chartsFontFamily",
      settingState?.chartWatermarkSettings?.fontFamily
        ? settingState.chartWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.chartWatermarkSettings.fontFamily
        : ""
    );

    formik.setFieldValue(
      "chartsFontSize",
      settingState?.chartWatermarkSettings?.fontSize
        ? settingState.chartWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.chartWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "chartsColor",
      settingState?.chartWatermarkSettings?.color
        ? settingState.chartWatermarkSettings.color
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.color
        ? res.data.data.deliverableSetting.chartWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "chartsOpacity",
      settingState?.chartWatermarkSettings?.opacity
        ? settingState.chartWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.chartWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "chartsTopPosition",
      settingState?.chartWatermarkSettings?.topPosition
        ? settingState.chartWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.chartWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "chartsLeftPosition",
      settingState?.chartWatermarkSettings?.leftPosition
        ? settingState.chartWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.chartWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "chartsRotate",
      settingState?.chartWatermarkSettings?.rotate
        ? settingState.chartWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.chartWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.chartWatermarkSettings.rotate
        : 0
    );

    formik.setFieldValue(
      "sectionText",
      settingState?.sectionWatermarkSettings?.text
        ? settingState.sectionWatermarkSettings.text
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.text
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.text
        : ""
    );
    formik.setFieldValue(
      "sectionFontFamily",
      settingState?.sectionWatermarkSettings?.fontFamily
        ? settingState.sectionWatermarkSettings.fontFamily
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontFamily
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontFamily
        : ""
    );
    formik.setFieldValue(
      "sectionFontSize",
      settingState?.sectionWatermarkSettings?.fontSize
        ? settingState.sectionWatermarkSettings.fontSize
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontSize
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontSize
        : ""
    );
    formik.setFieldValue(
      "sectionColor",
      settingState?.sectionWatermarkSettings?.color
        ? settingState.sectionWatermarkSettings.color
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.color
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.color
        : ""
    );
    formik.setFieldValue(
      "sectionOpacity",
      settingState?.sectionWatermarkSettings?.opacity
        ? settingState.sectionWatermarkSettings.opacity
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.opacity
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.opacity
        : ""
    );
    formik.setFieldValue(
      "sectionTopPosition",
      settingState?.sectionWatermarkSettings?.topPosition
        ? settingState.sectionWatermarkSettings.topPosition
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.topPosition
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.topPosition
        : 0
    );
    formik.setFieldValue(
      "sectionLeftPosition",
      settingState?.sectionWatermarkSettings?.leftPosition
        ? settingState.sectionWatermarkSettings.leftPosition
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.leftPosition
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.leftPosition
        : 0
    );
    formik.setFieldValue(
      "sectionRotate",
      settingState?.sectionWatermarkSettings?.rotate
        ? settingState.sectionWatermarkSettings.rotate
        : res.data.data.deliverableSetting?.sectionWatermarkSettings?.rotate
        ? res.data.data.deliverableSetting.sectionWatermarkSettings.rotate
        : 0
    );
    dispatch(
      setDeliverableSettingDetails({
        allowChangeFont: settingState?.allowChangeFont
          ? settingState?.allowChangeFont
          : res.data.data.deliverableSetting?.allowChangeFont === "Yes"
          ? "Yes"
          : "No",
        allowChangeColor: settingState?.allowChangeColor
          ? settingState?.allowChangeColor
          : res.data.data.deliverableSetting?.allowChangeColor === "Yes"
          ? "Yes"
          : "No",
        allowChangeFormatting: settingState?.allowChangeFormatting
          ? settingState?.allowChangeFormatting
          : res.data.data.deliverableSetting?.allowChangeFormatting === "Yes"
          ? "Yes"
          : "No",
        exportAsPdf: settingState?.exportAsPdf ? settingState?.exportAsPdf : res.data.data.deliverableSetting?.exportAsPdf === "Yes" ? "Yes" : "No",
        exportAsExcel: settingState?.exportAsExcel
          ? settingState?.exportAsExcel
          : res.data.data.deliverableSetting?.exportAsExcel === "Yes"
          ? "Yes"
          : "No",
        exportASection: settingState?.exportASection
          ? settingState?.exportASection
          : res.data.data.deliverableSetting?.exportASection === "Yes"
          ? "Yes"
          : "No",
        exportAsChart: settingState?.exportAsChart
          ? settingState?.exportAsChart
          : res.data.data.deliverableSetting?.exportAsChart === "Yes"
          ? "Yes"
          : "No",
        exportAsPpt: settingState?.exportAsPpt ? settingState?.exportAsPpt : res.data.data.deliverableSetting?.exportAsPpt === "Yes" ? "Yes" : "No",
        pdfWatermarkSettings: {
          text: settingState?.pdfWatermarkSettings?.text
            ? settingState.pdfWatermarkSettings.text
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.text
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.text
            : "",
          fontFamily: settingState?.pdfWatermarkSettings?.fontFamily
            ? settingState.pdfWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.pdfWatermarkSettings?.fontSize
            ? settingState.pdfWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.fontSize
            : "",
          color: settingState?.pdfWatermarkSettings?.color
            ? settingState.pdfWatermarkSettings.color
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.color
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.color
            : "",
          opacity: settingState?.pdfWatermarkSettings?.opacity
            ? settingState.pdfWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.opacity === 0
              ? 0
              : res.data.data.deliverableSetting.pdfWatermarkSettings.opacity
            : "",
          topPosition: settingState?.pdfWatermarkSettings?.topPosition
            ? settingState.pdfWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.pdfWatermarkSettings?.leftPosition
            ? settingState.pdfWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.pdfWatermarkSettings?.rotate
            ? settingState.pdfWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.pdfWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.pdfWatermarkSettings.rotate
            : 0,
        },
        excelWatermarkSettings: {
          text: settingState?.excelWatermarkSettings?.text
            ? settingState.excelWatermarkSettings.text
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.text
            ? res.data.data.deliverableSetting.excelWatermarkSettings.text
            : "",
          fontFamily: settingState?.excelWatermarkSettings?.fontFamily
            ? settingState.excelWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.excelWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.excelWatermarkSettings?.fontSize
            ? settingState.excelWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.excelWatermarkSettings.fontSize
            : "",
          color: settingState?.excelWatermarkSettings?.color
            ? settingState.excelWatermarkSettings.color
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.color
            ? res.data.data.deliverableSetting.excelWatermarkSettings.color
            : "",
          opacity: settingState?.excelWatermarkSettings?.opacity
            ? settingState.excelWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.excelWatermarkSettings.opacity
            : "",
          topPosition: settingState?.excelWatermarkSettings?.topPosition
            ? settingState.excelWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.excelWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.excelWatermarkSettings?.leftPosition
            ? settingState.excelWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.excelWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.excelWatermarkSettings?.rotate
            ? settingState.excelWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.excelWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.excelWatermarkSettings.rotate
            : 0,
        },
        pptWatermarkSettings: {
          text: settingState?.pptWatermarkSettings?.text
            ? settingState.pptWatermarkSettings.text
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.text
            ? res.data.data.deliverableSetting.pptWatermarkSettings.text
            : "",
          fontFamily: settingState?.pptWatermarkSettings?.fontFamily
            ? settingState.pptWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.pptWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.pptWatermarkSettings?.fontSize
            ? settingState.pptWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.pptWatermarkSettings.fontSize
            : "",
          color: settingState?.pptWatermarkSettings?.color
            ? settingState.pptWatermarkSettings.color
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.color
            ? res.data.data.deliverableSetting.pptWatermarkSettings.color
            : "",
          opacity: settingState?.pptWatermarkSettings?.opacity
            ? settingState.pptWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.pptWatermarkSettings.opacity
            : "",
          topPosition: settingState?.pptWatermarkSettings?.topPosition
            ? settingState.pptWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.pptWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.pptWatermarkSettings?.leftPosition
            ? settingState.pptWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.pptWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.pptWatermarkSettings?.rotate
            ? settingState.pptWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.pptWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.pptWatermarkSettings.rotate
            : 0,
        },
        sectionWatermarkSettings: {
          text: settingState?.sectionWatermarkSettings?.text
            ? settingState.sectionWatermarkSettings.text
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.text
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.text
            : "",
          fontFamily: settingState?.sectionWatermarkSettings?.fontFamily
            ? settingState.sectionWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.sectionWatermarkSettings?.fontSize
            ? settingState.sectionWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.fontSize
            : "",
          color: settingState?.sectionWatermarkSettings?.color
            ? settingState.sectionWatermarkSettings.color
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.color
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.color
            : "",
          opacity: settingState?.sectionWatermarkSettings?.opacity
            ? settingState.sectionWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.opacity
            : "",
          topPosition: settingState?.sectionWatermarkSettings?.topPosition
            ? settingState.sectionWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.sectionWatermarkSettings?.leftPosition
            ? settingState.sectionWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.sectionWatermarkSettings?.rotate
            ? settingState.sectionWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.sectionWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.sectionWatermarkSettings.rotate
            : 0,
        },
        chartWatermarkSettings: {
          text: settingState?.chartWatermarkSettings?.text
            ? settingState.chartWatermarkSettings.text
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.text
            ? res.data.data.deliverableSetting.chartWatermarkSettings.text
            : "",
          fontFamily: settingState?.chartWatermarkSettings?.fontFamily
            ? settingState.chartWatermarkSettings.fontFamily
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontFamily
            ? res.data.data.deliverableSetting.chartWatermarkSettings.fontFamily
            : "",
          fontSize: settingState?.chartWatermarkSettings?.fontSize
            ? settingState.chartWatermarkSettings.fontSize
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.fontSize
            ? res.data.data.deliverableSetting.chartWatermarkSettings.fontSize
            : "",
          color: settingState?.chartWatermarkSettings?.color
            ? settingState.chartWatermarkSettings.color
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.color
            ? res.data.data.deliverableSetting.chartWatermarkSettings.color
            : "",
          opacity: settingState?.chartWatermarkSettings?.opacity
            ? settingState.chartWatermarkSettings.opacity
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.opacity
            ? res.data.data.deliverableSetting.chartWatermarkSettings.opacity
            : "",
          topPosition: settingState?.chartWatermarkSettings?.topPosition
            ? settingState.chartWatermarkSettings.topPosition
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.topPosition
            ? res.data.data.deliverableSetting.chartWatermarkSettings.topPosition
            : 0,
          leftPosition: settingState?.chartWatermarkSettings?.leftPosition
            ? settingState.chartWatermarkSettings.leftPosition
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.leftPosition
            ? res.data.data.deliverableSetting.chartWatermarkSettings.leftPosition
            : 0,
          rotate: settingState?.chartWatermarkSettings?.rotate
            ? settingState.chartWatermarkSettings.rotate
            : res.data.data.deliverableSetting?.chartWatermarkSettings?.rotate
            ? res.data.data.deliverableSetting.chartWatermarkSettings.rotate
            : 0,
        },
      })
    );
    // dispatch(hideLoader());
  };

  const setWatermarkDefaultData = () => {
    const productVariant = getProductVariant();
    const params = { filters: [["config_name", "eq", "watermark-setting"]] };
    // dispatch(showLoader());
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        let getVariantSetting = productVariant === "Insider" ? data.insider : productVariant === "WhatNext" ? data.whatnext : data.cognition;
        let settings = getVariantSetting.deliverable;
        let i = 0;
        let pushData = {};
        for (let key in settings) {
          formik.setFieldValue(exportTypes[i] + "Text", settings[key].watermarkText);
          formik.setFieldValue(exportTypes[i] + "FontFamily", settings[key].watermarkFontFamily);
          formik.setFieldValue(exportTypes[i] + "FontSize", settings[key].watermarkFontSize);
          formik.setFieldValue(exportTypes[i] + "Color", settings[key].watermarkColor);
          formik.setFieldValue(exportTypes[i] + "Opacity", settings[key].watermarkOpacity);
          formik.setFieldValue(exportTypes[i] + "TopPosition", settings[key].watermarkTopPosition);
          formik.setFieldValue(exportTypes[i] + "LeftPosition", settings[key].watermarkLeftPosition);
          formik.setFieldValue(exportTypes[i] + "Rotate", settings[key].watermarkRotate);

          let defaultData = {
            text: settings[key].watermarkText,
            fontFamily: settings[key].watermarkFontFamily,
            fontSize: settings[key].watermarkFontSize,
            color: settings[key].watermarkColor,
            opacity: settings[key].watermarkOpacity,
            topPosition: settings[key].watermarkTopPosition,
            leftPosition: settings[key].watermarkLeftPosition,
            rotate: settings[key].watermarkRotate,
          };

          if (key === "pdfFormat") pushData = { ...pushData, pdfWatermarkSettings: defaultData };

          if (key === "pptFormat") pushData = { ...pushData, pptWatermarkSettings: defaultData };

          if (key === "excelFormat") pushData = { ...pushData, excelWatermarkSettings: defaultData };

          if (key === "sectionFormat") pushData = { ...pushData, sectionWatermarkSettings: defaultData };

          if (key === "chartFormat") pushData = { ...pushData, chartWatermarkSettings: defaultData };
          i = i + 1;
        }

        dispatch(
          setDeliverableSettingDetails({
            ...settingState,
            pdfWatermarkSettings: pushData.pdfWatermarkSettings,
            ...settingState,
            excelWatermarkSettings: pushData.excelWatermarkSettings,
            ...settingState,
            pptWatermarkSettings: pushData.pptWatermarkSettings,
            ...settingState,
            sectionWatermarkSettings: pushData.sectionWatermarkSettings,
            ...settingState,
            chartWatermarkSettings: pushData.chartWatermarkSettings,
            ...settingState,
            allowChangeFont: "No",
            ...settingState,
            allowChangeColor: "No",
            ...settingState,
            allowChangeFormatting: "No",
            ...settingState,
            exportAsPdf: "No",
            ...settingState,
            exportAsExcel: "No",
            ...settingState,
            exportASection: "No",
            ...settingState,
            exportAsChart: "No",
            ...settingState,
            exportAsPpt: "No",
          })
        );
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
      });
  };

  const resetSettings = (type) => {
    formik.setFieldValue(type + "Text", "");
    formik.setFieldValue(type + "FontFamily", null);
    formik.setFieldValue(type + "FontSize", null);
    formik.setFieldValue(type + "Color", null);
    formik.setFieldValue(type + "Opacity", null);
    formik.setFieldValue(type + "TopPosition", "");
    formik.setFieldValue(type + "LeftPosition", "");
    formik.setFieldValue(type + "Rotate", null);
    if (type === "pdf") dispatch(setDeliverableSettingDetails({ ...settingState, pdfWatermarkSettings: {} }));

    if (type === "excel") dispatch(setDeliverableSettingDetails({ ...settingState, excelWatermarkSettings: {} }));

    if (type === "powerPoint") dispatch(setDeliverableSettingDetails({ ...settingState, pptWatermarkSettings: {} }));

    if (type === "section") dispatch(setDeliverableSettingDetails({ ...settingState, sectionWatermarkSettings: {} }));

    if (type === "charts") dispatch(setDeliverableSettingDetails({ ...settingState, chartWatermarkSettings: {} }));
  };

  const applyWatermarkSettings = (settingType) => {
    let currentSettings;

    if (settingType === "pdf") currentSettings = settingState.pdfWatermarkSettings;

    if (settingType === "excel") currentSettings = settingState.excelWatermarkSettings;

    if (settingType === "powerPoint") currentSettings = settingState.pptWatermarkSettings;

    if (settingType === "section") currentSettings = settingState.sectionWatermarkSettings;

    if (settingType === "charts") currentSettings = settingState.chartWatermarkSettings;

    for (const type of exportTypes) {
      formik.setFieldValue(type + "Text", currentSettings.text);
      formik.setFieldValue(type + "FontFamily", currentSettings.fontFamily);
      formik.setFieldValue(type + "FontSize", currentSettings.fontSize);
      formik.setFieldValue(type + "Color", currentSettings.color);
      formik.setFieldValue(type + "Opacity", currentSettings.opacity);
      formik.setFieldValue(type + "TopPosition", currentSettings.topPosition);
      formik.setFieldValue(type + "LeftPosition", currentSettings.leftPosition);
      formik.setFieldValue(type + "Rotate", currentSettings.rotate);
    }
    if (settingType === "pdf") {
      setApplyPdfSettingState(!applyPdfSettingState);
      setApplyExcelSettingState(false);
      setApplyPowerPointSettingState(false);
      setApplySectionSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyPdfSettingForAllFormat: !applyPdfSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.pdfWatermarkSettings,
          pptWatermarkSettings: settingState.pdfWatermarkSettings,
          sectionWatermarkSettings: settingState.pdfWatermarkSettings,
          chartWatermarkSettings: settingState.pdfWatermarkSettings,
        })
      );
    }
    if (settingType === "excel") {
      setApplyExcelSettingState(!applyExcelSettingState);
      setApplyPdfSettingState(false);
      setApplyPowerPointSettingState(false);
      setApplySectionSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyExcelSettingForAllFormat: !applyExcelSettingState ? "Yes" : "No",
          pdfWatermarkSettings: settingState.excelWatermarkSettings,
          pptWatermarkSettings: settingState.excelWatermarkSettings,
          sectionWatermarkSettings: settingState.excelWatermarkSettings,
          chartWatermarkSettings: settingState.excelWatermarkSettings,
        })
      );
    }
    if (settingType === "powerPoint") {
      setApplyPowerPointSettingState(!applyPowerPointSettingState);
      setApplyExcelSettingState(false);
      setApplyPdfSettingState(false);
      setApplySectionSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyPptSettingForAllFormat: !applyPowerPointSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.pptWatermarkSettings,
          pdfWatermarkSettings: settingState.pptWatermarkSettings,
          sectionWatermarkSettings: settingState.pptWatermarkSettings,
          chartWatermarkSettings: settingState.pptWatermarkSettings,
        })
      );
    }
    if (settingType === "section") {
      setApplySectionSettingState(!applySectionSettingState);
      setApplyPowerPointSettingState(false);
      setApplyExcelSettingState(false);
      setApplyPdfSettingState(false);
      setApplyChartsSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applySectionSettingForAllFormat: !applySectionSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.sectionWatermarkSettings,
          pptWatermarkSettings: settingState.sectionWatermarkSettings,
          pdfWatermarkSettings: settingState.sectionWatermarkSettings,
          chartWatermarkSettings: settingState.sectionWatermarkSettings,
        })
      );
    }
    if (settingType === "charts") {
      setApplyChartsSettingState(!applyChartsSettingState);
      setApplySectionSettingState(false);
      setApplyPowerPointSettingState(false);
      setApplyExcelSettingState(false);
      setApplyPdfSettingState(false);
      dispatch(
        setDeliverableSettingDetails({
          ...settingState,
          applyChartSettingForAllFormat: !applyChartsSettingState ? "Yes" : "No",
          excelWatermarkSettings: settingState.chartWatermarkSettings,
          pptWatermarkSettings: settingState.chartWatermarkSettings,
          sectionWatermarkSettings: settingState.chartWatermarkSettings,
          pdfWatermarkSettings: settingState.chartWatermarkSettings,
        })
      );
    }
  };

  const restoreDefaultSettings = async (settingType, settingName) => {
    dispatch(showLoader());
    const productVariant = getProductVariant();
    const params = { filters: [["config_name", "eq", "watermark-setting"]] };
    fetchConfig(params)
      .then((res) => {
        const data = res.data?.data ? res.data.data[0].configValue : [];
        let getVariantSetting = productVariant === "Insider" ? data.insider : productVariant === "WhatNext" ? data.whatnext : data.cognition;
        let settingFormat = [settingType];
        let settings = getVariantSetting.deliverable;
        for (let i = 0, n = settingFormat.length; i < n; ++i) {
          let k = settingFormat[i];
          if (k in settings) {
            settings = settings[k];
          } else {
            return;
          }
        }

        formik.setFieldValue(settingName + "Text", settings.watermarkText);
        formik.setFieldValue(settingName + "FontFamily", settings.watermarkFontFamily);
        formik.setFieldValue(settingName + "FontSize", settings.watermarkFontSize);
        formik.setFieldValue(settingName + "Color", settings.watermarkColor);
        formik.setFieldValue(settingName + "Opacity", settings.watermarkOpacity);
        formik.setFieldValue(settingName + "TopPosition", settings.watermarkTopPosition);
        formik.setFieldValue(settingName + "LeftPosition", settings.watermarkLeftPosition);
        formik.setFieldValue(settingName + "Rotate", settings.watermarkRotate);

        let defaultData = {
          text: settings.watermarkText,
          fontFamily: settings.watermarkFontFamily,
          fontSize: settings.watermarkFontSize,
          color: settings.watermarkColor,
          opacity: settings.watermarkOpacity,
          topPosition: settings.watermarkTopPosition,
          leftPosition: settings.watermarkLeftPosition,
          rotate: settings.watermarkRotate,
        };
        if (settingType === "pdfFormat") dispatch(setDeliverableSettingDetails({ ...settingState, pdfWatermarkSettings: defaultData }));

        if (settingType === "pptFormat") dispatch(setDeliverableSettingDetails({ ...settingState, pptWatermarkSettings: defaultData }));

        if (settingType === "excelFormat") dispatch(setDeliverableSettingDetails({ ...settingState, excelWatermarkSettings: defaultData }));

        if (settingType === "sectionFormat") dispatch(setDeliverableSettingDetails({ ...settingState, sectionWatermarkSettings: defaultData }));

        if (settingType === "chartFormat") dispatch(setDeliverableSettingDetails({ ...settingState, chartWatermarkSettings: defaultData }));

        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Something went wrong";
        dispatch(actionError(errMsg));
        dispatch(hideLoader());
      });
  };

  const taggedIndustries = useMemo(
    () => filterIndustryFromTrends(deliverableDetails?.deliverableIndustryTrendsTags),
    [deliverableDetails?.deliverableIndustryTrendsTags]
  );

  const taggedIndustriesIds = useMemo(
    () => filterIndustryIds(deliverableDetails?.deliverableIndustryTrendsTags),
    [deliverableDetails?.deliverableIndustryTrendsTags]
  );

  const { data: widgetUniqueData, isFetching: widgetUniqueFetching } = useQuery({
    queryKey: ["widget-unique", taggedIndustriesIds, props?.deliverableInformation?.deliverableContentType],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["widgetIndustryId", "in", taggedIndustriesIds],
        ["widgetContentType", "eq", props?.deliverableInformation?.deliverableContentType],
        ["widgetName", "neq", PINNED_CONTENT],
        ["productVariant.productName", "eq", PRODUCT_VARIANT.INSIDER],
        ["widgetActive", "eq", "YES"],
      ];
      params.sort = "widgetDisplayName:asc";
      return fetchWidget(params);
    },
    enabled:
      !!props?.deliverableInformation?.deliverableContentType &&
      taggedIndustriesIds?.length > 0 &&
      props?.deliverableInformation?.productVariant[0]?.productName === PRODUCT_VARIANT.INSIDER &&
      !disabledSaveOnContentType.includes(props?.deliverableInformation?.deliverableContentType),
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  let options = useMemo(
    () =>
      combineIndustriesWithWidgets(
        taggedIndustries,
        disabledSaveOnContentType.includes(props?.deliverableInformation?.deliverableContentType) ? [] : widgetUniqueData?.data?.data
      ),
    [taggedIndustries, widgetUniqueData?.data?.data]
  );
  const groupedOptions = useMemo(() => options?.flatMap(({ group, items }) => items.map(({ id, label }) => ({ id, group, label }))), [options]);

  // Find selected options based on IDs
  const selectedOptions = groupedOptions?.filter((option) => selectedIds?.includes(option.id));

  // Check if an ID is selected
  const isChecked = (id) => selectedIds?.includes(id);

  const { isFetching: widgetAttachedDataFetching } = useQuery({
    queryKey: ["widget-attached-data", taggedIndustriesIds, deliverableId],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["widgetIndustryId", "in", taggedIndustriesIds],
        ["widgetContent.widgetContentId", "eq", deliverableId],
        ["widgetName", "neq", PINNED_CONTENT],
        ["productVariant.productName", "eq", PRODUCT_VARIANT.INSIDER],
        ["widgetActive", "eq", "YES"],
      ];
      params.sort = "widgetDisplayName:asc";
      return fetchWidget(params);
    },
    enabled:
      !!deliverableId &&
      taggedIndustriesIds?.length > 0 &&
      props?.deliverableInformation?.productVariant[0]?.productName === PRODUCT_VARIANT.INSIDER &&
      !disabledSaveOnContentType.includes(props?.deliverableInformation?.deliverableContentType),
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    onSuccess: (response) => {
      if (!response?.data?.data?.length) return;
      const widgetIds = response?.data?.data?.map((widget) => widget.id);
      setSelectedIds([...selectedIds, ...widgetIds]);
    },
  });

  const handleSavewidgetSettings = async () => {
    if (!addToWidget) {
      if (deliverableDetails?.productVariant[0]?.isLive !== "YES") return dispatch(actionError("Content is not yet published."));
      dispatch(showLoader());
      let payload = {
        widgetContentId: deliverableId,
        widgetModifiedByUserId: user?._id,
        widgetModifiedByUserName: user?.user_name,
      };
      await deleteWidgetContent(payload)
        .then((response) => {
          dispatch(actionSuccess("Widget setting saved successfully."));
          setSelectedIds([]);
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } else {
      if (deliverableDetails?.productVariant[0]?.isLive !== "YES") return dispatch(actionError("Content is not yet published."));
      if (!selectedIds?.length) return dispatch(actionError("Please select widget(s)."));
      dispatch(showLoader());
      let payload = {
        widgetIds: selectedIds,
        widgetContent: {
          widgetContentId: deliverableId,
          widgetContentOrder: 1,
          widgetContentType: "Deliverable",
        },
        widgetModifiedByUserId: user?._id,
        widgetModifiedByUserName: user?.user_name,
      };
      await updateWidgetContent(payload)
        .then((response) => {
          dispatch(actionSuccess("Widget setting saved successfully."));
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    }
  };

  const handleSaveExportSettings = async () => {
    formik.validateForm().then(async (res) => {
      if (Object.keys(res).length) {
        const touched = {};

        Object.keys(res).map((field) => {
          touched[field] = true;

          return touched;
        });

        let errors = Object.keys(res);
        if (errors.length > 0) dispatch(actionError(res[errors[0]]));
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        if (settingState && Object.keys(settingState).length > 0) {
          dispatch(showLoader());
          let deliverableData = {
            deliverableSetting: {
              ...settingState,
            },
            userId: user._id,
          };
          await updateDeliverableSetting(deliverableId, deliverableData)
            .then((response) => {
              dispatch(actionSuccess("Export setting saved successfully."));
              props.fetchDeliverable(deliverableId);
              dispatch(hideLoader());
            })
            .catch((e) => {
              dispatch(hideLoader());
              if (e?.data?.message) {
                dispatch(actionError(e.data.message));
              } else {
                dispatch(actionError("Something Went Wrong...!"));
              }
            });
        }
      }
    });
  };

  const handleSaveContentBlockSettings = async () => {
    if (settingState && Object.keys(settingState).length > 0) {
      dispatch(showLoader());
      let deliverableData = {
        deliverableSetting: {
          ...settingState,
        },
        userId: user._id,
      };
      await updateDeliverableSetting(deliverableId, deliverableData)
        .then((response) => {
          dispatch(actionSuccess("Setting for Content Block Header, Footer & Watermark saved successfully."));
          props.fetchDeliverable(deliverableId);
          dispatch(hideLoader());
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    }
  };

  const handleSaveNewsletterSettings = () => {
    let deliverableIsMostResearch = mostReadResearch === true ? "YES" : "NO";
    let deliverableIsPopularReport = popularReport === true ? "YES" : "NO";

    let overviewData = {
      deliverableIsMostResearch: deliverableIsMostResearch,
      deliverableIsPopularReport: deliverableIsPopularReport,
    };

    let saveOverviewData = new FormData();
    for (let uKey in overviewData) {
      if (typeof overviewData[uKey] !== "object") {
        if (overviewData[uKey]) saveOverviewData.append(uKey, overviewData[uKey]);
      } else {
        if (overviewData[uKey]) saveOverviewData.append(uKey, JSON.stringify(overviewData[uKey]));
      }
    }

    dispatch(showLoader());
    try {
      saveOverviewData.append("userId", user?._id);
      saveOverviewData.append("deliverableCreatedByUserId", user?._id);
      saveOverviewData.append("deliverableCreatedByUserName", user?.user_fname + " " + user?.user_lname);

      updateOverView(deliverableId, saveOverviewData)
        .then((response) => {
          dispatch(actionSuccess("Newsletter setting saved successfully."));
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } catch (e) {
      dispatch(hideLoader());

      if (e?.data?.message) {
        dispatch(actionError(e.data.message));
      } else {
        dispatch(actionError("Something Went Wrong...!"));
      }
    }
  };

  useEffect(() => {
    if (!props.saveDeliverable) {
      {
        setSettingsData();
        getFontFamily();
        getWatermarkColors();
      }
    }
  }, [props.saveDeliverable]);

  useEffect(() => {
    if (selectedIds?.length) setAddToWidget(true);
  }, [selectedIds]);

  useEffect(() => {
    setMostReadResearch(deliverableDetails?.deliverableIsMostResearch === "YES" ? true : false);
    setPopularReport(deliverableDetails?.deliverableIsPopularReport === "YES" ? true : false);
  }, [deliverableDetails]);

  // Function to handle scroll events within the container
  const handleScroll = () => {
    setOpen(false);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (deliverableFetching || widgetUniqueFetching || widgetAttachedDataFetching) dispatch(showLoader());
    else dispatch(hideLoader());
  }, [deliverableFetching, widgetUniqueFetching, widgetAttachedDataFetching]);

  return (
    <div ref={containerRef} className="gennx-content-wrapper padding-bottom-150i pt-3" style={{ width: "99.5%", height: "100vh" }}>
      {props.selectedIndex === 4 && (
        <>
          {getProductVariant() === PRODUCT_VARIANT.INSIDER && (
            <>
              <div className="design-color mt-3 ml-2" style={{ width: "99%" }}>
                <span className="header-name">Widget Setting</span>
              </div>
              <div className="mx-2 bg-white" style={{ width: "99%" }}>
                <div
                  className="mx-2 pt-4"
                  style={{
                    height: "68px",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div className="ml-2 mr-1">
                    <label>Add to Widget</label>
                    <label className="custom-switch">
                      <input
                        id="newsAddToWidgets"
                        name="newsAddToWidgets"
                        type="checkbox"
                        value={addToWidget ? "YES" : "NO"}
                        checked={addToWidget}
                        onChange={(e) => {
                          setAddToWidget(!addToWidget);
                        }}
                      />
                      <div>
                        <span className="on">YES</span>
                        <span className="off">NO</span>
                      </div>
                      <i></i>
                    </label>
                  </div>
                  {addToWidget && (
                    <Autocomplete
                      multiple
                      // limitTags={2}
                      options={groupedOptions}
                      open={open} // Control the open state
                      onOpen={() => setOpen(true)} // Set to true when opened
                      onClose={() => setOpen(false)} // Set to false when closed
                      groupBy={(option) => option.group}
                      getOptionLabel={(option) => option.label}
                      disableCloseOnSelect
                      freeSolo={false} // Disable manual typing
                      renderInput={(params) => {
                        const maxDisplayedItems = 2; // Control how many items to display
                        const remainingCount = selectedOptions.length - maxDisplayedItems;

                        return (
                          <MuiTextField
                            {...params}
                            label="Select Widgets"
                            variant="outlined"
                            required
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {params.InputProps.endAdornment}
                                  {remainingCount > 0 && (
                                    <span style={{ fontSize: "12px", color: "#000000", position: "absolute", right: "60px" }}>
                                      +{remainingCount} more
                                    </span>
                                  )}
                                </>
                              ),
                            }}
                            // Add `onKeyDown` to prevent typing
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            sx={{
                              "& .MuiInputBase-input": {
                                height: "44px", // Ensure the input's height remains fixed
                                overflow: "hidden", // Prevent overflow
                                display: "flex", // Ensure chips are aligned correctly
                                alignItems: "center", // Center items vertically
                              },
                              "& .MuiOutlinedInput-root": {
                                height: "50px", // Ensure the root's height is consistent
                                overflow: "hidden", // Prevent overflow
                                flexWrap: "nowrap",
                                paddingRight: "0px",
                                "& .MuiAutocomplete-tag": {
                                  display: "none", // Hide chips to control the number of visible chips
                                },
                                "& .MuiAutocomplete-tag:nth-of-type(-n+2)": {
                                  display: "inline-flex", // Show only the first two chips
                                },
                              },
                            }}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            fontSize: "12px",
                            padding: "0px 0px 0px 26px",
                            margin: "0px",
                            display: "flex",
                            gap: "8px",
                            height: "36px",
                            alignItems: "center",
                            backgroundColor: isChecked(option.id) ? "transparent" : "transparent",
                            color: "black",
                            fontWeight: "400",
                          }}
                        >
                          <Checkbox
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                            checked={isChecked(option.id)}
                            sx={{
                              width: "16px",
                              height: "16px",
                              color: "#DBDBDD",
                            }}
                          />
                          {option.label}
                        </li>
                      )}
                      value={selectedOptions}
                      onChange={(_, newValue) => {
                        // Update selected IDs based on selected options
                        const newSelectedIds = newValue.map((option) => option.id);
                        setSelectedIds(newSelectedIds);
                      }}
                      sx={{
                        width: "400px",
                        height: "44px", // Fixed height for the container
                        "& .MuiOutlinedInput-root": {
                          height: "100%", // Ensure the height is consistent
                          "&.Mui-focused": {
                            "& fieldset": {
                              borderColor: "#f94f5e",
                            },
                          },
                        },
                        "& .MuiInputLabel-root": {
                          "&.Mui-focused": {
                            color: "#f94f5e",
                          },
                        },
                        "& .MuiButtonBase-root.MuiChip-root": {
                          maxWidth: "137px",
                          height: "26px",
                          fontSize: "12px",
                          color: "black",
                          backgroundColor: "#F2F2F2",
                          border: "1px solid #DBDBDD",
                          borderRadius: "4px",
                        },
                        "& .MuiChip-deleteIcon": {
                          fontSize: "16px !important",
                          color: "black !important",
                          margin: "-1px 4px 0px -6px !important",
                        },
                        "& .MuiChip-label": {
                          paddingLeft: "4px !important",
                          paddingRight: "10px !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div className="mt-1 mx-2 mb-2">
                  <span className="ml-2" style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Note :{" "}
                  </span>
                  <ul className="pb-2" style={{ marginLeft: "32px" }}>
                    <li style={{ fontSize: "14px" }}>Content must be published before adding to widget.</li>
                    <li style={{ fontSize: "14px" }}>
                      Deliverable with content type as Client Business Objective, Dashboard & Training cannot be added to widget.
                    </li>
                  </ul>
                </div>
                {accessActionWidgetSave && !disabledSaveOnContentType.includes(props?.deliverableInformation?.deliverableContentType) && (
                  <div style={{ display: "flex", justifyContent: "end", borderTop: "1px solid #DBDBDD" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#f94f5e",
                        "&:disabled": {
                          opacity: "0.5 !important ",
                          backgroundColor: "#DBDBDD",
                          color: "#FFFFFF",
                        },
                        textTransform: "inherit !important",
                        "&:hover": {
                          backgroundColor: "#f94f5e",
                        },
                      }}
                      className="pl-4 pr-4 my-2 mr-3"
                      onClick={() => handleSavewidgetSettings()}
                      // disabled={!selectedIds?.length}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
              <div className="design-color mt-3 ml-2" style={{ width: "99%" }}>
                <span className="header-name">Newsletter Setting</span>
              </div>
              <div className="mx-2 bg-white" style={{ width: "99%" }}>
                <div
                  className="mx-2"
                  style={{
                    height: "80px",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div className="ml-2 mr-1">
                    <label>Is Most Read Research for Newsletter? </label>
                    <label className="custom-switch">
                      <input
                        type="checkbox"
                        id="deliverableIsMostResearch"
                        name="deliverableIsMostResearch"
                        value={mostReadResearch ? "YES" : "NO"}
                        checked={mostReadResearch}
                        onChange={(e) => {
                          setMostReadResearch(!mostReadResearch);
                        }}
                      />
                      <div>
                        <span className="on">Yes</span>
                        <span className="off">No</span>
                      </div>
                      <i></i>
                    </label>
                  </div>

                  <div className="ml-2 mr-1">
                    <label>Is Popular Report for Newsletter?</label>
                    <label className="custom-switch">
                      <input
                        type="checkbox"
                        id="deliverableIsPopularReport"
                        name="deliverableIsPopularReport"
                        value={popularReport ? "YES" : "NO"}
                        checked={popularReport}
                        onChange={(e) => {
                          setPopularReport(!popularReport);
                        }}
                      />
                      <div>
                        <span className="on">Yes</span>
                        <span className="off">No</span>
                      </div>
                      <i></i>
                    </label>
                  </div>
                </div>
                {accessActionNewsletterSave && (
                  <div style={{ display: "flex", justifyContent: "end", borderTop: "1px solid #DBDBDD" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#f94f5e",
                        "&:disabled": {
                          opacity: "0.5 !important ",
                          backgroundColor: "#DBDBDD",
                          color: "#FFFFFF",
                        },
                        textTransform: "inherit !important",
                        "&:hover": {
                          backgroundColor: "#f94f5e",
                        },
                      }}
                      className="pl-4 pr-4 my-2 mr-3"
                      onClick={() => handleSaveNewsletterSettings()}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="design-color mt-3 ml-2" style={{ width: "99%" }}>
            <span className="header-name">Export Setting</span>
          </div>
          <div
            className="mx-2 bg-white"
            style={{
              width: "99%",
            }}
          >
            <form>
              <div>
                <div className="p-2">
                  <div
                    className=""
                    style={{
                      paddingTop: "1%",
                    }}
                  >
                    <div key={`acc-1`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                      <div className={` panel-heading ${forcedActiveState || exportToPDFState ? " active " : " "}`} role="tab">
                        <i
                          className={`fa accordion-chevron-icon ${forcedActiveState || exportToPDFState ? " fa-chevron-up " : " fa-chevron-down "}`}
                          style={{ top: "10px" }}
                        ></i>
                        <h4 className="panel-title">
                          <div
                            className={` custom-panel-title-action py-0 ${forcedActiveState || exportToPDFState ? " collapsed " : " "} ${
                              forcedActiveState ? " cursor-no-drop " : " "
                            }`}
                            style={{ display: "flex", fontWeight: "normal" }}
                          >
                            <div className="col-md-4">
                              <div className="my-3 ">
                                Export as PDF
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="exportAsPdf"
                                      name="exportAsPdf"
                                      type="checkbox"
                                      value={exportToPDFState ? "Yes" : "No"}
                                      checked={exportToPDFState}
                                      onChange={(e) => {
                                        formik.setFieldValue("exportAsPdf", !exportToPDFState);
                                        setExportToPDFState(!exportToPDFState);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            exportAsPdf: !exportToPDFState ? "Yes" : "No",
                                          })
                                        );
                                      }}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 row justify-content-end ml-4">
                              {exportToPDFState && (
                                <div className="my-2">
                                  <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("pdf")}>
                                    Clear
                                  </button>
                                </div>
                              )}
                              {exportToPDFState && (
                                <div className="my-2 ml-3">
                                  <button
                                    type="button"
                                    className="btn pl-4 pr-4 clear-reset-btn"
                                    onClick={() => restoreDefaultSettings("pdfFormat", "pdf")}
                                  >
                                    Reset to Default
                                  </button>
                                </div>
                              )}
                              <div className="my-3 ml-3">
                                Apply watermark settings for all formats
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="applyPdfSetting"
                                      name="applyPdfSetting"
                                      type="checkbox"
                                      value={applyPdfSettingState ? "Yes" : "No"}
                                      checked={applyPdfSettingState}
                                      onChange={(e) => applyWatermarkSettings("pdf")}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <div
                        className={` panel-collapse ${forcedActiveState || exportToPDFState ? " collapsed " : " collapse "}`}
                        style={{ border: "1px solid #DBDBDD" }}
                        role="tabpanel"
                      >
                        <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                          <div>
                            <span className="mt-3 ml-2">
                              <strong>Watermark Settings</strong>
                            </span>
                            <div
                              className="row"
                              style={{
                                paddingTop: "1%",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <div className="row mt-3">
                                <div className="col-12 mb-2">
                                  <TextField
                                    formik={formik}
                                    type="text"
                                    multiline={true}
                                    numberCount={true}
                                    maxLength={100}
                                    rows={5}
                                    name="pdfText"
                                    // value={}
                                    className="form-control"
                                    id="pdfText"
                                    placeholder="Text *"
                                    // {...props}
                                    characterLimitText={true}
                                    onChange={(e) => {
                                      formik.setFieldValue("pdfText", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            text: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  {fontFamilyList && fontFamilyList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="pdfFontFamily"
                                      placeholder="Font Family *"
                                      name="pdfFontFamily"
                                      fields={{ text: "name", value: "value" }}
                                      dataSource={fontFamilyList}
                                      change={(e) => {
                                        formik.setFieldValue("pdfFontFamily", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            pdfWatermarkSettings: {
                                              ...settingState.pdfWatermarkSettings,
                                              fontFamily: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <NumericTextField
                                    formik={formik}
                                    min={10}
                                    max={32}
                                    format="##.# px"
                                    id="pdfFontSize"
                                    placeholder="Font Size *"
                                    name="pdfFontSize"
                                    onChange={(e) => {
                                      formik.setFieldValue("pdfFontSize", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            fontSize: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  {colorList && colorList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="pdfColor"
                                      placeholder="Color *"
                                      name="pdfColor"
                                      fields={{ text: "name", value: "rgbValue" }}
                                      dataSource={colorList}
                                      change={(e) => {
                                        formik.setFieldValue("pdfColor", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            pdfWatermarkSettings: {
                                              ...settingState.pdfWatermarkSettings,
                                              color: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    min={0.1}
                                    max={1}
                                    step={0.1}
                                    format="#.#"
                                    id="pdfOpacity"
                                    placeholder="Opacity *"
                                    name="pdfOpacity"
                                    onChange={(e) => {
                                      formik.setFieldValue("pdfOpacity", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            opacity: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="pdfTopPosition"
                                    placeholder="Top Position *"
                                    name="pdfTopPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("pdfTopPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            topPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="pdfLeftPosition"
                                    placeholder="Left Position *"
                                    name="pdfLeftPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("pdfLeftPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            leftPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Dropdown
                                    formik={formik}
                                    id="pdfRotate"
                                    placeholder="Rotation/Angle *"
                                    name="pdfRotate"
                                    fields={{ text: "label", value: "value" }}
                                    dataSource={watermarkRotate}
                                    change={(e) => {
                                      formik.setFieldValue("pdfRotate", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pdfWatermarkSettings: {
                                            ...settingState.pdfWatermarkSettings,
                                            rotate: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div key={`acc-2`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                      <div className={` panel-heading ${forcedActiveState || exportToExcelState ? " active " : " "}`} role="tab">
                        <i
                          className={`fa accordion-chevron-icon ${forcedActiveState || exportToExcelState ? " fa-chevron-up " : " fa-chevron-down "}`}
                          style={{ top: "10px" }}
                        ></i>
                        <h4 className="panel-title">
                          <div
                            className={` custom-panel-title-action py-0 ${forcedActiveState || exportToExcelState ? " collapsed " : " "} ${
                              forcedActiveState ? " cursor-no-drop " : " "
                            }`}
                            style={{ display: "flex", fontWeight: "normal" }}
                          >
                            <div className="col-md-4">
                              <div className="my-3 ">
                                Export as Excel
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="exportAsExcel"
                                      name="exportAsExcel"
                                      type="checkbox"
                                      value={exportToExcelState ? "Yes" : "No"}
                                      checked={exportToExcelState}
                                      onChange={(e) => {
                                        formik.setFieldValue("exportAsExcel", !exportToExcelState);
                                        setExportToExcelState(!exportToExcelState);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            exportAsExcel: !exportToExcelState ? "Yes" : "No",
                                          })
                                        );
                                      }}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 row justify-content-end ml-4">
                              {exportToExcelState && (
                                <div className="my-2">
                                  <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("excel")}>
                                    Clear
                                  </button>
                                </div>
                              )}
                              {exportToExcelState && (
                                <div className="my-2 ml-3">
                                  <button
                                    type="button"
                                    className="btn pl-4 pr-4 clear-reset-btn"
                                    onClick={() => restoreDefaultSettings("excelFormat", "excel")}
                                  >
                                    Reset to Default
                                  </button>
                                </div>
                              )}
                              <div className="my-3 ml-3">
                                Apply watermark settings for all formats
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="applyExcelSetting"
                                      name="applyExcelSetting"
                                      type="checkbox"
                                      value={applyExcelSettingState ? "Yes" : "No"}
                                      checked={applyExcelSettingState}
                                      onChange={(e) => applyWatermarkSettings("excel")}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <div
                        className={` panel-collapse ${forcedActiveState || exportToExcelState ? " collapsed " : " collapse "}`}
                        style={{ border: "1px solid #DBDBDD" }}
                        role="tabpanel"
                      >
                        <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                          <div>
                            <span className="mt-3 ml-2">
                              <strong>Watermark Settings</strong>
                            </span>
                            <div
                              className="row"
                              style={{
                                paddingTop: "1%",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <div className="row mt-3">
                                <div className="col-12 mb-2">
                                  <TextField
                                    formik={formik}
                                    type="text"
                                    multiline={true}
                                    numberCount={true}
                                    maxLength={100}
                                    rows={5}
                                    name="excelText"
                                    className="form-control"
                                    id="excelText"
                                    placeholder="Text *"
                                    // {...props}
                                    characterLimitText={true}
                                    onChange={(e) => {
                                      formik.setFieldValue("excelText", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            text: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  {fontFamilyList && fontFamilyList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="excelFontFamily"
                                      placeholder="Font Family *"
                                      name="excelFontFamily"
                                      fields={{ text: "name", value: "value" }}
                                      dataSource={fontFamilyList}
                                      change={(e) => {
                                        formik.setFieldValue("excelFontFamily", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            excelWatermarkSettings: {
                                              ...settingState.excelWatermarkSettings,
                                              fontFamily: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <NumericTextField
                                    formik={formik}
                                    min={10}
                                    max={32}
                                    format="##.# px"
                                    id="excelFontSize"
                                    placeholder="Font Size *"
                                    name="excelFontSize"
                                    onChange={(e) => {
                                      formik.setFieldValue("excelFontSize", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            fontSize: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  {colorList && colorList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="excelColor"
                                      placeholder="Color *"
                                      name="excelColor"
                                      fields={{ text: "name", value: "rgbValue" }}
                                      dataSource={colorList}
                                      change={(e) => {
                                        formik.setFieldValue("excelColor", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            excelWatermarkSettings: {
                                              ...settingState.excelWatermarkSettings,
                                              color: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    min={0.1}
                                    max={1}
                                    step={0.1}
                                    format="#.#"
                                    id="excelOpacity"
                                    placeholder="Opacity *"
                                    name="excelOpacity"
                                    onChange={(e) => {
                                      formik.setFieldValue("excelOpacity", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            opacity: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="excelTopPosition"
                                    placeholder="Top Position *"
                                    name="excelTopPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("excelTopPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            topPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="excelLeftPosition"
                                    placeholder="Left Position *"
                                    name="excelLeftPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("excelLeftPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            leftPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Dropdown
                                    formik={formik}
                                    id="excelRotate"
                                    placeholder="Rotation/Angle *"
                                    name="excelRotate"
                                    fields={{ text: "label", value: "value" }}
                                    dataSource={watermarkRotate}
                                    change={(e) => {
                                      formik.setFieldValue("excelRotate", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          excelWatermarkSettings: {
                                            ...settingState.excelWatermarkSettings,
                                            rotate: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div key={`acc-3`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                      <div className={` panel-heading ${forcedActiveState || exportToPowerPointState ? " active " : " "}`} role="tab">
                        <i
                          className={`fa accordion-chevron-icon ${
                            forcedActiveState || exportToPowerPointState ? " fa-chevron-up " : " fa-chevron-down "
                          }`}
                          style={{ top: "10px" }}
                        ></i>
                        <h4 className="panel-title">
                          <div
                            className={` custom-panel-title-action py-0 ${forcedActiveState || exportToPowerPointState ? " collapsed " : " "} ${
                              forcedActiveState ? " cursor-no-drop " : " "
                            }`}
                            style={{ display: "flex", fontWeight: "normal" }}
                          >
                            <div className="col-md-4">
                              <div className="my-3 ">
                                Export as PowerPoint
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="exportAsPpt"
                                      name="exportAsPpt"
                                      type="checkbox"
                                      value={exportToPowerPointState ? "Yes" : "No"}
                                      checked={exportToPowerPointState}
                                      onChange={(e) => {
                                        formik.setFieldValue("exportAsPpt", !exportToPowerPointState);
                                        setExportToPowerPointState(!exportToPowerPointState);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            exportAsPpt: !exportToPowerPointState ? "Yes" : "No",
                                          })
                                        );
                                      }}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 row justify-content-end ml-4">
                              {exportToPowerPointState && (
                                <div className="my-2">
                                  <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("powerPoint")}>
                                    Clear
                                  </button>
                                </div>
                              )}
                              {exportToPowerPointState && (
                                <div className="my-2 ml-3">
                                  <button
                                    type="button"
                                    className="btn pl-4 pr-4 clear-reset-btn"
                                    onClick={() => restoreDefaultSettings("pptFormat", "powerPoint")}
                                  >
                                    Reset to Default
                                  </button>
                                </div>
                              )}
                              <div className="my-3 ml-3">
                                Apply watermark settings for all formats
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="applyPowerPointSetting"
                                      name="applyPowerPointSetting"
                                      type="checkbox"
                                      value={applyPowerPointSettingState ? "Yes" : "No"}
                                      checked={applyPowerPointSettingState}
                                      onChange={(e) => applyWatermarkSettings("powerPoint")}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <div
                        className={` panel-collapse ${forcedActiveState || exportToPowerPointState ? " collapsed " : " collapse "}`}
                        style={{ border: "1px solid #DBDBDD" }}
                        role="tabpanel"
                      >
                        <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                          <div>
                            <span className="mt-3 ml-2">
                              <strong>Watermark Settings</strong>
                            </span>
                            <div
                              className="row"
                              style={{
                                paddingTop: "1%",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <div className="row mt-3">
                                <div className="col-12 mb-2">
                                  <TextField
                                    formik={formik}
                                    type="text"
                                    multiline={true}
                                    numberCount={true}
                                    maxLength={100}
                                    rows={5}
                                    name="powerPointText"
                                    className="form-control"
                                    id="powerPointText"
                                    placeholder="Text *"
                                    // {...props}
                                    characterLimitText={true}
                                    onChange={(e) => {
                                      formik.setFieldValue("powerPointText", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            text: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  {fontFamilyList && fontFamilyList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="powerPointFontFamily"
                                      placeholder="Font Family *"
                                      name="powerPointFontFamily"
                                      fields={{ text: "name", value: "value" }}
                                      dataSource={fontFamilyList}
                                      change={(e) => {
                                        formik.setFieldValue("powerPointFontFamily", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            pptWatermarkSettings: {
                                              ...settingState.pptWatermarkSettings,
                                              fontFamily: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <NumericTextField
                                    formik={formik}
                                    min={10}
                                    max={32}
                                    format="##.# px"
                                    id="powerPointFontSize"
                                    placeholder="Font Size *"
                                    name="powerPointFontSize"
                                    onChange={(e) => {
                                      formik.setFieldValue("powerPointFontSize", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            fontSize: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  {colorList && colorList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="powerPointColor"
                                      placeholder="Color *"
                                      name="powerPointColor"
                                      fields={{ text: "name", value: "rgbValue" }}
                                      dataSource={colorList}
                                      change={(e) => {
                                        formik.setFieldValue("powerPointColor", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            pptWatermarkSettings: {
                                              ...settingState.pptWatermarkSettings,
                                              color: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    min={0.1}
                                    max={1}
                                    step={0.1}
                                    format="#.#"
                                    id="powerPointOpacity"
                                    placeholder="Opacity *"
                                    name="powerPointOpacity"
                                    onChange={(e) => {
                                      formik.setFieldValue("powerPointOpacity", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            opacity: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="powerPointTopPosition"
                                    placeholder="Top Position *"
                                    name="powerPointTopPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("powerPointTopPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            topPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="powerPointLeftPosition"
                                    placeholder="Left Position *"
                                    name="powerPointLeftPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("powerPointLeftPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            leftPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Dropdown
                                    formik={formik}
                                    id="powerPointRotate"
                                    placeholder="Rotation/Angle *"
                                    name="powerPointRotate"
                                    fields={{ text: "label", value: "value" }}
                                    dataSource={watermarkRotate}
                                    change={(e) => {
                                      formik.setFieldValue("powerPointRotate", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          pptWatermarkSettings: {
                                            ...settingState.pptWatermarkSettings,
                                            rotate: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div key={`acc-4`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                      <div className={` panel-heading ${forcedActiveState || exportToSectionState ? " active " : " "}`} role="tab">
                        <i
                          className={`fa accordion-chevron-icon ${
                            forcedActiveState || exportToSectionState ? " fa-chevron-up " : " fa-chevron-down "
                          }`}
                          style={{ top: "10px" }}
                        ></i>
                        <h4 className="panel-title">
                          <div
                            className={` custom-panel-title-action py-0 ${forcedActiveState || exportToSectionState ? " collapsed " : " "} ${
                              forcedActiveState ? " cursor-no-drop " : " "
                            }`}
                            style={{ display: "flex", fontWeight: "normal" }}
                          >
                            <div className="col-md-4">
                              <div className="my-3 ">
                                Export a Section
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="exportASection"
                                      name="exportASection"
                                      type="checkbox"
                                      value={exportToSectionState ? "Yes" : "No"}
                                      checked={exportToSectionState}
                                      onChange={(e) => {
                                        formik.setFieldValue("exportASection", !exportToSectionState);
                                        setExportToSectionState(!exportToSectionState);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            exportASection: !exportToSectionState ? "Yes" : "No",
                                          })
                                        );
                                      }}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 row justify-content-end ml-4">
                              {exportToSectionState && (
                                <div className="my-2">
                                  <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("section")}>
                                    Clear
                                  </button>
                                </div>
                              )}
                              {exportToSectionState && (
                                <div className="my-2 ml-3">
                                  <button
                                    type="button"
                                    className="btn pl-4 pr-4 clear-reset-btn"
                                    onClick={() => restoreDefaultSettings("sectionFormat", "section")}
                                  >
                                    Reset to Default
                                  </button>
                                </div>
                              )}
                              <div className="my-3 ml-3">
                                Apply watermark settings for all formats
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="applySectionSetting"
                                      name="applySectionSetting"
                                      type="checkbox"
                                      value={applySectionSettingState ? "Yes" : "No"}
                                      checked={applySectionSettingState}
                                      onChange={(e) => applyWatermarkSettings("section")}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <div
                        className={` panel-collapse ${forcedActiveState || exportToSectionState ? " collapsed " : " collapse "}`}
                        style={{ border: "1px solid #DBDBDD" }}
                        role="tabpanel"
                      >
                        <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                          <div>
                            <span className="mt-3 ml-2">
                              <strong>Watermark Settings</strong>
                            </span>
                            <div
                              className="row"
                              style={{
                                paddingTop: "1%",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <div className="row mt-3">
                                <div className="col-12 mb-2">
                                  <TextField
                                    formik={formik}
                                    type="text"
                                    multiline={true}
                                    numberCount={true}
                                    maxLength={100}
                                    rows={5}
                                    name="sectionText"
                                    className="form-control"
                                    id="sectionText"
                                    placeholder="Text *"
                                    // {...props}
                                    characterLimitText={true}
                                    onChange={(e) => {
                                      formik.setFieldValue("sectionText", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            text: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  {fontFamilyList && fontFamilyList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="sectionFontFamily"
                                      placeholder="Font Family *"
                                      name="sectionFontFamily"
                                      fields={{ text: "name", value: "value" }}
                                      dataSource={fontFamilyList}
                                      change={(e) => {
                                        formik.setFieldValue("sectionFontFamily", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            sectionWatermarkSettings: {
                                              ...settingState.sectionWatermarkSettings,
                                              fontFamily: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <NumericTextField
                                    formik={formik}
                                    min={10}
                                    max={32}
                                    format="##.# px"
                                    id="sectionFontSize"
                                    placeholder="Font Size *"
                                    name="sectionFontSize"
                                    onChange={(e) => {
                                      formik.setFieldValue("sectionFontSize", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            fontSize: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  {colorList && colorList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="sectionColor"
                                      placeholder="Color *"
                                      name="sectionColor"
                                      fields={{ text: "name", value: "rgbValue" }}
                                      dataSource={colorList}
                                      change={(e) => {
                                        formik.setFieldValue("sectionColor", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            sectionWatermarkSettings: {
                                              ...settingState.sectionWatermarkSettings,
                                              color: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    min={0.1}
                                    max={1}
                                    step={0.1}
                                    format="#.#"
                                    id="sectionOpacity"
                                    placeholder="Opacity *"
                                    name="sectionOpacity"
                                    onChange={(e) => {
                                      formik.setFieldValue("sectionOpacity", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            opacity: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="sectionTopPosition"
                                    placeholder="Top Position *"
                                    name="sectionTopPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("sectionTopPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            topPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="sectionLeftPosition"
                                    placeholder="Left Position *"
                                    name="sectionLeftPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("sectionLeftPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            leftPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Dropdown
                                    formik={formik}
                                    id="sectionRotate"
                                    placeholder="Rotation/Angle *"
                                    name="sectionRotate"
                                    fields={{ text: "label", value: "value" }}
                                    dataSource={watermarkRotate}
                                    change={(e) => {
                                      formik.setFieldValue("sectionRotate", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          sectionWatermarkSettings: {
                                            ...settingState.sectionWatermarkSettings,
                                            rotate: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div key={`acc-5`} className={` panel panel-default disabled`} style={{ borderRadius: "0px" }}>
                      <div className={` panel-heading ${forcedActiveState || exportToChartState ? " active " : " "}`} role="tab">
                        <i
                          className={`fa accordion-chevron-icon ${forcedActiveState || exportToChartState ? " fa-chevron-up " : " fa-chevron-down "}`}
                          style={{ top: "10px" }}
                        ></i>
                        <h4 className="panel-title">
                          <div
                            className={` custom-panel-title-action py-0 ${forcedActiveState || exportToChartState ? " collapsed " : " "} ${
                              forcedActiveState ? " cursor-no-drop " : " "
                            }`}
                            style={{ display: "flex", fontWeight: "normal" }}
                          >
                            <div className="col-md-4">
                              <div className="my-3 ">
                                Export Charts
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="exportAsChart"
                                      name="exportAsChart"
                                      type="checkbox"
                                      value={exportToChartState ? "Yes" : "No"}
                                      checked={exportToChartState}
                                      onChange={(e) => {
                                        formik.setFieldValue("exportAsChart", !exportToChartState);
                                        setExportToChartState(!exportToChartState);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            exportAsChart: !exportToChartState ? "Yes" : "No",
                                          })
                                        );
                                      }}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 row justify-content-end ml-4">
                              {exportToChartState && (
                                <div className="my-2">
                                  <button type="button" className="btn pl-4 pr-4 clear-reset-btn" onClick={() => resetSettings("charts")}>
                                    Clear
                                  </button>
                                </div>
                              )}
                              {exportToChartState && (
                                <div className="my-2 ml-3">
                                  <button
                                    type="button"
                                    className="btn pl-4 pr-4 clear-reset-btn"
                                    onClick={() => restoreDefaultSettings("chartFormat", "charts")}
                                  >
                                    Reset to Default
                                  </button>
                                </div>
                              )}
                              <div className="my-3 ml-3">
                                Apply watermark settings for all formats
                                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                                  <label className="custom-switch">
                                    <input
                                      id="applyChartsSetting"
                                      name="applyChartsSetting"
                                      type="checkbox"
                                      value={applyChartsSettingState ? "Yes" : "No"}
                                      checked={applyChartsSettingState}
                                      onChange={(e) => applyWatermarkSettings("charts")}
                                    />
                                    <div>
                                      <span className="on">Yes</span>
                                      <span className="off">No</span>
                                    </div>
                                    <i></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </h4>
                      </div>
                      <div
                        className={` panel-collapse ${forcedActiveState || exportToChartState ? " collapsed " : " collapse "}`}
                        style={{ border: "1px solid #DBDBDD" }}
                        role="tabpanel"
                      >
                        <div className="panel-body pb-0" style={{ borderRadius: "4px" }}>
                          <div>
                            <span className="mt-3 ml-2">
                              <strong>Watermark Settings</strong>
                            </span>
                            <div
                              className="row"
                              style={{
                                paddingTop: "1%",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <div className="row mt-3">
                                <div className="col-12 mb-2">
                                  <TextField
                                    formik={formik}
                                    type="text"
                                    multiline={true}
                                    numberCount={true}
                                    maxLength={100}
                                    rows={5}
                                    name="chartsText"
                                    className="form-control"
                                    id="chartsText"
                                    placeholder="Text *"
                                    // {...props}
                                    characterLimitText={true}
                                    onChange={(e) => {
                                      formik.setFieldValue("chartsText", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            text: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  {fontFamilyList && fontFamilyList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="chartsFontFamily"
                                      placeholder="Font Family *"
                                      name="chartsFontFamily"
                                      fields={{ text: "name", value: "value" }}
                                      dataSource={fontFamilyList}
                                      change={(e) => {
                                        formik.setFieldValue("chartsFontFamily", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            chartWatermarkSettings: {
                                              ...settingState.chartWatermarkSettings,
                                              fontFamily: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <NumericTextField
                                    formik={formik}
                                    min={10}
                                    max={32}
                                    format="##.# px"
                                    id="chartsFontSize"
                                    placeholder="Font Size *"
                                    name="chartsFontSize"
                                    onChange={(e) => {
                                      formik.setFieldValue("chartsFontSize", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            fontSize: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  {colorList && colorList.length > 0 && (
                                    <Dropdown
                                      formik={formik}
                                      id="chartsColor"
                                      placeholder="Color *"
                                      name="chartsColor"
                                      fields={{ text: "name", value: "rgbValue" }}
                                      dataSource={colorList}
                                      change={(e) => {
                                        formik.setFieldValue("chartsColor", e.value);
                                        dispatch(
                                          setDeliverableSettingDetails({
                                            ...settingState,
                                            chartWatermarkSettings: {
                                              ...settingState.chartWatermarkSettings,
                                              color: e.value,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    min={0.1}
                                    max={1}
                                    step={0.1}
                                    format="#.#"
                                    id="chartsOpacity"
                                    placeholder="Opacity *"
                                    name="chartsOpacity"
                                    onChange={(e) => {
                                      formik.setFieldValue("chartsOpacity", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            opacity: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="chartsTopPosition"
                                    placeholder="Top Position *"
                                    name="chartsTopPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("chartsTopPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            topPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <NumericTextField
                                    formik={formik}
                                    format="#.# px"
                                    id="chartsLeftPosition"
                                    placeholder="Left Position *"
                                    name="chartsLeftPosition"
                                    onChange={(e) => {
                                      formik.setFieldValue("chartsLeftPosition", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            leftPosition: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Dropdown
                                    formik={formik}
                                    id="chartsRotate"
                                    placeholder="Rotation/Angle *"
                                    name="chartsRotate"
                                    fields={{ text: "label", value: "value" }}
                                    dataSource={watermarkRotate}
                                    change={(e) => {
                                      formik.setFieldValue("chartsRotate", e.value);
                                      dispatch(
                                        setDeliverableSettingDetails({
                                          ...settingState,
                                          chartWatermarkSettings: {
                                            ...settingState.chartWatermarkSettings,
                                            rotate: e.value,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {accessActionDesignAndExportSave && (
              <div style={{ display: "flex", justifyContent: "end", borderTop: "1px solid #DBDBDD" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#f94f5e",
                    "&:disabled": {
                      opacity: "0.5 !important ",
                      backgroundColor: "#DBDBDD",
                      color: "#FFFFFF",
                    },
                    textTransform: "inherit !important",
                    "&:hover": {
                      backgroundColor: "#f94f5e",
                    },
                  }}
                  className="pl-4 pr-4 my-2 mr-3"
                  onClick={() => handleSaveExportSettings()}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
          <div className="design-color mt-3 ml-2" style={{ width: "99%" }}>
            <span className="header-name">Setting for Content Block Header, Footer & Watermark</span>
          </div>
          <div className="mx-2 bg-white" style={{ width: "99%" }}>
            <div
              className="mx-2"
              style={{
                height: "80px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div className="ml-2 mr-1">
                Change Font
                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                  <label className="custom-switch">
                    <input
                      id="allowChangeFont"
                      name="allowChangeFont"
                      type="checkbox"
                      value={fontstate ? "Yes" : "No"}
                      checked={fontstate}
                      onChange={(e) => {
                        setFontState(!fontstate);
                        dispatch(
                          setDeliverableSettingDetails({
                            ...settingState,
                            allowChangeFont: !fontstate ? "Yes" : "No",
                          })
                        );
                      }}
                    />
                    <div>
                      <span className="on">Yes</span>
                      <span className="off">No</span>
                    </div>
                    <i></i>
                  </label>
                </div>
              </div>
              <div className="ml-2 mr-1">
                Change Color
                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                  <label className="custom-switch">
                    <input
                      id="allowChangeColor"
                      name="allowChangeColor"
                      type="checkbox"
                      value={colorState ? "Yes" : "No"}
                      checked={colorState}
                      onChange={(e) => {
                        setcolorState(!colorState);
                        dispatch(
                          setDeliverableSettingDetails({
                            ...settingState,
                            allowChangeColor: !colorState ? "Yes" : "No",
                          })
                        );
                      }}
                    />
                    <div>
                      <span className="on">Yes</span>
                      <span className="off">No</span>
                    </div>
                    <i></i>
                  </label>
                </div>
              </div>
              <div className="ml-2 mr-1">
                Change Formatting
                <div className="text-center d-inline" style={{ marginLeft: "12px" }}>
                  <label className="custom-switch">
                    <input
                      id="allowChangeFormatting"
                      name="allowChangeFormatting"
                      type="checkbox"
                      value={formattingState ? "Yes" : "No"}
                      checked={formattingState}
                      onChange={(e) => {
                        setFormattingState(!formattingState);
                        dispatch(
                          setDeliverableSettingDetails({
                            ...settingState,
                            allowChangeFormatting: !formattingState ? "Yes" : "No",
                          })
                        );
                      }}
                    />
                    <div>
                      <span className="on">Yes</span>
                      <span className="off">No</span>
                    </div>
                    <i></i>
                  </label>
                </div>
              </div>
            </div>

            {accessActionDesignAndExportSave && (
              <div style={{ display: "flex", justifyContent: "end", borderTop: "1px solid #DBDBDD" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#f94f5e",
                    "&:disabled": {
                      opacity: "0.5 !important ",
                      backgroundColor: "#DBDBDD",
                      color: "#FFFFFF",
                    },
                    textTransform: "inherit !important",
                    "&:hover": {
                      backgroundColor: "#f94f5e",
                    },
                  }}
                  className="pl-4 pr-4 my-2 mr-3"
                  onClick={() => handleSaveContentBlockSettings()}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
Settings.propTypes = {
  deliverableId: PropTypes.string,
  moduleName: PropTypes.string,
  saveDeliverable: PropTypes.func,
  formik: PropTypes.object,
  deliverableInformation: PropTypes.shape({
    deliverableIsMostResearch: PropTypes.string,
    deliverableIsPopularReport: PropTypes.string,
    deliverableIndustryTrendsTags: PropTypes.array,
    deliverableContentType: PropTypes.string,
    productVariant: PropTypes.arrayOf(
      PropTypes.shape({
          productName: PropTypes.string,
      })
     ),
  }),
  fetchDeliverable: PropTypes.func,
  selectedIndex: PropTypes.number
};  
export default Settings;
