/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 14/May/2021
 * File Description: Add/Edit Video Details
 */

import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Tabs from "../../../../components/tabsComponent";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { CkEditorVideo } from "../../../../components/CustomFormElements/ProductInformation";
import { ckEditorMinimalConfig } from "../../../../../config/ckEditorConfig";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { accessRightActionCheck, accessRightInterfaceCheck, getLoggedInUser, getProductVariant } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { videoDetailsValidationSchema } from "../validationSchema/index";
import { VIDEO_THUMB_IMG_SIZE } from "../../../../../config";
import { fetchClientUser } from "../../../../../middleware/services/cmsApi";
import { fetchExternalUser } from "../../../../../middleware/services/userApi";
import { fetchSingleVideo, addNewVideo, updateVideo, fetchImageUrl, fetchCustomTags } from "../../../../../middleware/services/videoApi";
import { InputGroupText } from "../../component/formFields";
import { TextField, NumericTextField, Dropdown, MultiSelect } from "../../../../components/CustomFormElements/syncFusionFormFields";
import { VIDEO_TYPE_OPTIONS, STATUS_OPTIONS, VIDEO_OPTIONS, COMMERCIAL_IMPACT_OPTIONS } from "./formFieldOptions";
import RelatedQuestions from "../relatedQuestions";
import RelatedDocuments from "../relatedDocuments";
import HostedBy from "../hostedBy";
import Tagging from "../tagging";
import SURVEY_CONSTANT from "../../constants";
import ActionButtons from "../actionButtons";
import "../css/custom.scss";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import TimeZone from "./timeZone";
import moment from "moment";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setBasicTab } from "../../../../../middleware/actions/videoAction";
import AccessDenied from "../../../../components/accessDenied";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT, ROLES } from "../../../../../constants";
import Setting from "../setting";
import PropTypes from "prop-types";

const initialState = {
  pVariant: getProductVariant(),
  videoType: "",
  videoSource: "",
  videoTitle: "",
  vimeoVideoId: "",
  videoDescription: "",
  videoDurationInMinute: "",
  videoRegistrationLink: "",
  restrictContentForClient: "NO",
  clientUsers: [],
  status: "Active",
  videoThumbnail: "",
  commercialImpact: "",
  areasOfImpact: [],
  specificTechnology: [],
  typeOfOraganization: [],
  typeOfOraganizationAcademic: [],
  typeOfOraganizationResearch: [],
  typeOfOraganizationCompany: [],
  customTag: [],
  videoExpert: [],
  whatNextSourceUrl: "",
  whatNextVideoSource: "",
  whatNextSourceEmbedUrl: "",
  timezone1: {},
  timezone2: {},
  timezone3: {},
  videoPaywallSetting: "NO",
  videoIsBuzzingStory: "NO",
  videoIndustryTrendsTags: [],
  productVariant: [],
};
const VideoDetails = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const tabRef = useRef();
  const profileImageRef = useRef(null);
  const profileImageInputRef = useRef(null);
  const productVariant = getProductVariant();
  const loggedInUser = getLoggedInUser();
  const videoId = props.computedMatch?.params?.videoId || "";
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [checked, setChecked] = useState(undefined);
  const [impactareasDataSource, setImpactareasDataSource] = useState([]);
  const [specificTechnologyDataSource, setSpecificTechnologyDataSource] = useState([]);
  const [academicDataSource, setAcademicDataSource] = useState([]);
  const [researchDataSource, setResearchDataSource] = useState([]);
  const [companyTagDataSource, setCompanyTagDataSource] = useState([]);
  const [customTagDataSource, setCustomTagDataSource] = useState([]);
  const [videoExpertDataSource, setVideoExpertDataSource] = useState([]);
  const [videoExpertData, setVideoExpertData] = useState([]);
  const [saveTaxonomy, setSaveTaxonomy] = useState(false);
  const [users, setUsers] = useState(null);
  const [editData, setEditData] = useState(null);
  const [imageError, setImageError] = useState("");
  const [isBuzzingStory, setIsBuzzingStory] = useState(false);
  const [profileImage, setProfileImage] = useState(undefined);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  if (productVariant === "Insider") {
    delete VIDEO_TYPE_OPTIONS[0];
    delete VIDEO_TYPE_OPTIONS[3];
    delete VIDEO_TYPE_OPTIONS[4];
  }
  initialState.surveyUrl =
    productVariant === "WhatNext"
      ? SURVEY_CONSTANT.SURVEY_DOMAIN_WHATNEXT + "/"
      : productVariant === "Insider"
      ? SURVEY_CONSTANT.SURVEY_DOMAIN_INSIDER + "/"
      : SURVEY_CONSTANT.SURVEY_DOMAIN_COGNITION + "/";
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialState },
    validationSchema: Yup.object().shape(videoDetailsValidationSchema),
  });
  const productVariantWiseURL =
    productVariant === "WhatNext"
      ? SURVEY_CONSTANT.SURVEY_DOMAIN_WHATNEXT + "/"
      : productVariant === "Insider"
      ? SURVEY_CONSTANT.SURVEY_DOMAIN_INSIDER + "/"
      : SURVEY_CONSTANT.SURVEY_DOMAIN_COGNITION + "/";

  const TABS_NAME = ["Video Details", "Related Questions", "Related Documents", "Hosted By"];
  const hashArr = {
    "#videodetails": "Video Details",
    "#questions": "Related Questions",
    "#documents": "Related Documents",
    "#hostedby": "Hosted By",
    "#taxonomy": "Taxonomy",
    "#settings": "Settings",
  };

  if (productVariant === "WhatNext" || productVariant === PRODUCT_VARIANT.INSIDER) {
    productVariant === PRODUCT_VARIANT.INSIDER ? TABS_NAME.push("Taxonomy & Tags", "Settings") : TABS_NAME.push("Taxonomy");
  }

  const breadCrumbLinks = [
    { linkUrl: "/video", linkName: aR.moduleName, linkActive: false },
    {
      linkUrl: videoId ? `/video/${videoId}/edit` : "/video",
      linkName: (editData && editData?.videoTitle) || "Add New",
      linkActive: false,
    },
    {
      linkUrl: `/video/${videoId}/edit${Object.keys(hashArr)[selectedIndex]}`,
      linkName: Object.values(hashArr)[selectedIndex],
      linkActive: true,
    },
  ];

  const readVideoThumnail = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      profileImageRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  /**
   * @description Search client user
   */
  const getClientUsers = async function () {
    const response = await fetchClientUser({ productVariant: productVariant });
    if (response.data && response.data.data) {
      const userData =
        response &&
        response.data &&
        response.data.data.map((typesData) => {
          return {
            userid: typesData.id["$id"],
            fullname: `${typesData["userFname"]} ${typesData["userLname"]}`,
            userFname: typesData["userFname"],
            userLname: typesData["userLname"],
          };
        });
      setUsers(userData);
    }
  };

  const fetchVideoDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "videoTitle",
      "videoContent",
      "videos",
      "videoProfileImage",
      "videoStatus",
      "videoType",
      "videoDuration",
      "videoAccessType",
      "videoUser",
      "videoRegistrationLink",
      "timezone",
      "date",
      "time",
      "videoCommercialImpactTime",
      "videoAreaOfImpact",
      "videoSpecificTechnology",
      "videoTypeOfOrganizationAcademic",
      "videoTypeOfOrganizationResearch",
      "videoTypeOfOrganizationCompany",
      "videoCustomTags",
      "videoExpert",
      "productVariant",
      "surveyAudience",
      "videoIndustryTag",
      "videoIndustryTrendsTags",
      "videoTimezones",
      "videoSource",
      "videoSourceUrl",
      "videoSourceEndUrl",
      "videoPaywallSetting",
      "videoSourceEmbedUrl",
      "videoIsBuzzingStory",
    ];

    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleVideo(videoId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);
      searchUsers();
      data.videoAccessType && setChecked(data.videoAccessType === "Generic" ? false : true);
      setIsBuzzingStory(data.videoIsBuzzingStory);
      formik.setValues({
        videoTitle: data.videoTitle,
        videoDescription: data.videoContent,
        vimeoVideoId: data.videos.videoId,
        videoThumbnail: data.videos.videoThumbnail || "",
        videoSource: data.videos.videoSource,
        status: data.videoStatus,
        videoType: data.videoType,
        videoExpert: data.videoExpert?.length
          ? data?.videoExpert?.map((v) => {
              return v.userId;
            })
          : [],
        commercialImpact: data.videoCommercialImpactTime,
        videoAreaOfImpact: data.videoAreaOfImpact,
        specificTechnology: data.videoSpecificTechnology || [],
        typeOfOraganizationAcademic: data.videoTypeOfOrganizationAcademic || [],
        typeOfOraganizationResearch: data.videoTypeOfOrganizationResearch || [],
        typeOfOraganizationCompany: data.videoTypeOfOrganizationCompany || [],
        customTag: data.videoCustomTags || [],
        videoDurationInMinute: data.videoDuration,
        restrictContentForClient: data.videoAccessType && data.videoAccessType === "Generic" ? "NO" : "YES",
        videoRegistrationLink: data.videoRegistrationLink || "",
        whatNextVideoSource: data.videoSource || "",
        whatNextSourceUrl: data.videoSourceUrl || "",
        whatNextSourceEmbedUrl: data.videoSourceEmbedUrl || "",
        videoPaywallSetting: data.videoPaywallSetting && data.videoPaywallSetting?.length > 0 ? "YES" : "NO",

        timezone1: (data.videoTimezones && data.videoTimezones[0]) || {},
        timezone2: (data.videoTimezones && data.videoTimezones[1]) || {},
        timezone3: (data.videoTimezones && data.videoTimezones[2]) || {},
        videoIsBuzzingStory: data?.videoIsBuzzingStory,
        videoIndustryTrendsTags: data?.videoIndustryTrendsTags || [],
        productVariant: data?.productVariant || [],
      });
      if (productVariant === "Insider") {
        const userDetails =
          data.videoUser &&
          data.videoUser.map((c) => {
            return c.userId;
          });
        formik.setFieldValue("clientUsers", userDetails);
      }

      dispatch(hideLoader());
    });
  };

  useEffect(() => {
    getClientUsers();
    if (videoId) {
      !editData && fetchVideoDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, editData, selectedIndex]);

  const saveVideoDetails = async () => {
    if (formik.values?.videoDescription?.length < 10) return dispatch(actionError("Video Description Must be greater than 10 characters."));
    else if (formik.values?.videoDescription?.length > 200) return dispatch(actionError("Video Description cannot be greater than 200 characters."));
    if (imageError !== "") return;
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        let errors = Object.keys(res);
        if (errors.length > 0) dispatch(actionError(res[errors[0]]));
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        let payload = {};
        const {
          videoTitle,
          videoDescription,
          vimeoVideoId,
          videoThumbnail,
          videoSource,
          status,
          videoType,
          timezone1_date,
          timezone1_zone,
          timezone1_time,
          timezone2_date,
          timezone2_zone,
          timezone2_time,
          timezone3_date,
          timezone3_zone,
          timezone3_time,
          commercialImpact = [],
          areasOfImpact = [],
          specificTechnology = [],
          typeOfOraganizationAcademic = [],
          typeOfOraganizationResearch = [],
          typeOfOraganizationCompany = [],
          customTag = [],
          videoExpert = [],
          videoDurationInMinute,
          videoRegistrationLink,
          restrictContentForClient,
          videoPaywallSetting,
          clientUsers = [],
          whatNextVideoSource = "",
          whatNextSourceUrl = "",
          whatNextSourceEmbedUrl = "",
        } = formik.values;

        payload.videoTitle = videoTitle;
        payload.videoContent = videoDescription;
        payload.videoId = vimeoVideoId;
        payload.videoProfileImage = (typeof videoThumbnail === "object" && videoThumbnail) || "";
        payload.videoSourceType = videoSource;
        payload.videoStatus = status;
        payload.videoType = videoType;
        payload.videoSource = whatNextVideoSource;
        payload.videoSourceUrl = whatNextSourceUrl;
        payload.videoSourceEmbedUrl = whatNextSourceEmbedUrl;

        let timezones = [];
        timezone1_date &&
          timezones.push({
            date: moment(timezone1_date).format("DD-MM-YYYY"),
            timezone: timezone1_zone,
            time: moment(timezone1_time).format("h:mm A"),
          });
        timezone2_date &&
          timezones.push({
            date: moment(timezone2_date).format("DD-MM-YYYY"),
            timezone: timezone2_zone,
            time: moment(timezone2_time).format("h:mm A"),
          });
        timezone3_date &&
          timezones.push({
            date: moment(timezone3_date).format("DD-MM-YYYY"),
            timezone: timezone3_zone,
            time: moment(timezone3_time).format("h:mm A"),
          });
        payload.videoTimezones = JSON.stringify(timezones);
        payload.videoTimezones = JSON.stringify(timezones);
        payload.videoCommercialImpactTime = commercialImpact;

        payload.videoDuration = videoDurationInMinute;
        payload.videoAccessType = restrictContentForClient === "NO" ? "Generic" : "Client";
        if (status === "Upcoming" && videoType === "Webinar") {
          payload.videoRegistrationLink = videoRegistrationLink;
        }
        let videoPaywallProductPlatform = productVariant == "Cognition" ? "Signal V2" : `${productVariant} V2`;
        if (videoPaywallSetting) {
          let videoPaywallSettingData =
            formik.values.videoPaywallSetting == "YES"
              ? [
                  {
                    productVariant: productVariant,
                    productPlatform: videoPaywallProductPlatform,
                    taxonomyTagging: [],
                  },
                ]
              : [];
          payload.videoPaywallSetting = JSON.stringify(videoPaywallSettingData);
        }
        videoId && typeof videoThumbnail !== "object" && delete payload.videoProfileImage;

        const formData = new FormData();

        for (let key in payload) {
          formData.append(key, payload[key]);
        }

        formData.append("videoCreatedByUserId", loggedInUser._id);
        formData.append("videoCreatedByUserName", loggedInUser.user_fname + " " + loggedInUser.user_lname);

        !videoId && productVariant && formData.append("productVariant", JSON.stringify([{ productName: productVariant, isLive: "NO" }]));
        areasOfImpact && formData.append("videoAreaOfImpact", JSON.stringify(areasOfImpact));
        specificTechnology && formData.append("videoSpecificTechnology", JSON.stringify(specificTechnology));
        typeOfOraganizationAcademic &&
          typeOfOraganizationAcademic.length &&
          formData.append("videoTypeOfOrganizationAcademic", JSON.stringify(typeOfOraganizationAcademic));
        typeOfOraganizationResearch &&
          typeOfOraganizationResearch.length &&
          formData.append("videoTypeOfOrganizationResearch", JSON.stringify(typeOfOraganizationResearch));
        typeOfOraganizationCompany &&
          typeOfOraganizationCompany.length &&
          formData.append("videoTypeOfOrganizationCompany", JSON.stringify(typeOfOraganizationCompany));
        customTag && customTag.length && formData.append("videoCustomTags", JSON.stringify(customTag));
        videoExpert && videoExpert.length
          ? formData.append(
              "videoExpert",
              JSON.stringify(
                videoExpertData
                  ?.map((v) => {
                    return (
                      formik?.values?.videoExpert.includes(v.id) && {
                        userId: v.id,
                        userFname: v.userFname,
                        userLname: v.userLname,
                      }
                    );
                  })
                  .filter((f) => {
                    return f !== false;
                  })
              )
            )
          : formData.append("videoExpert", []);
        if (productVariant === "Insider") {
          const selectedUsers =
            users &&
            users
              .map((u) => {
                return (
                  clientUsers?.includes(u.userid) && {
                    userId: u.userid,
                    userFname: u.userFname,
                    userLname: u.userLname,
                  }
                );
              })
              .filter((v) => {
                return typeof v === "object";
              });

          clientUsers && formData.append("videoUser", JSON.stringify(selectedUsers));
        }
        if (videoId) {
          dispatch(showLoader());
          updateVideo(videoId, formData)
            .then((resp) => {
              fetchVideoDetails();
              dispatch(hideLoader());
              dispatch(actionSuccess("Video details updated successfully"));
            })
            .catch((err) => {
              dispatch(hideLoader());
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        } else {
          dispatch(showLoader());
          addNewVideo(formData)
            .then((res) => {
              if (res) {
                dispatch(hideLoader());
                dispatch(actionSuccess("Video details saved successfully"));
                history.push({
                  pathname: `/video/${res.data.data.id}/edit`,
                  hash: "",
                  state: { videoId: res.data.data.id },
                });

                tabRef.current.enableTab(1, true);
                tabRef.current.enableTab(2, true);
                tabRef.current.enableTab(3, true);
                productVariant === "WhatNext" && tabRef.current.enableTab(4, true);
                productVariant === PRODUCT_VARIANT.INSIDER && tabRef.current.enableTab(5, true);
                tabRef.current.select(0);
                window.location.reload();
              } else {
                dispatch(hideLoader());
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              dispatch(hideLoader());
              dispatch(actionError(err?.data?.message || "something went wrong"));
            });
        }
      }
    });
  };

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (!videoId) {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.enableTab(4, false);
      tabRef.current.enableTab(5, false);
      tabRef.current.select(0);
    }
  };

  const searchUsers = async (ids) => {
    let params = {};
    params.filters = [
      ["userActive", "eq", 1],
      ["userRole", "eq", ROLES.EXPERT],
    ];
    let fields = ["userFname", "userLname", "userEmail"];
    params.fields = fields;
    params.limit = 2000;
    params.sort = "userFname:asc";
    const response = await fetchExternalUser(params);
    if (response?.data && response?.data?.data) {
      setVideoExpertData(response?.data?.data);
      const resData = response.data.data.map((v) => {
        return {
          label: `${v.userFname} ${v.userLname} (${v.userEmail})`,
          value: v.id,
        };
      });
      if (resData) {
        setVideoExpertDataSource(resData);
      }
    }
  };

  const searchCustomTag = async (searchText, category) => {
    const response = await fetchCustomTags({
      fields: ["tagName"],
      filters: [
        ["tagName", "cn", searchText],
        ["categoryName", "eq", category],
      ],
      sort: "tagName:asc",
      limit: 10,
    });
    if (response.data && response.data.data) {
      const resData = response.data.data.map((v) => {
        return { label: v.tagName, value: v.tagName };
      });
      if (resData) {
        switch (category) {
          case "impactareas":
            setImpactareasDataSource(resData);
            break;
          case "specifictechnology":
            setSpecificTechnologyDataSource(resData);
            break;
          case "academic":
            setAcademicDataSource(resData);
            break;
          case "research":
            setResearchDataSource(resData);
            break;
          case "company":
            setCompanyTagDataSource(resData);
            break;

          default:
            setCustomTagDataSource(resData);
            break;
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setBasicTab(formik.values));
  }, [formik.values]);

  /*Interface access */
  const interfaces = ["Video Details", "Related Questions", "Related Documents", "Hosted By", "Taxonomy", "Settings"];
  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, interfaces);

  /* Interface functionality wise access  */
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);
  const accessActionTaxonomyAdd = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionTaxonomyAddTags = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.ADD_TAGS);
  const accessActionTaxonomyDelete = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionTaxonomyShowInProduct = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SHOW_IN_PRODUCT);

  return (
    <>
      <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
        <Tabs
          tabsName={TABS_NAME}
          tabRef={tabRef}
          created={created}
          handleClick={() => {
            setSelectedIndex(tabRef.current.selectedItem);
          }}
          cssClass="newHeaderTabCss"
        >
          <div key="videoDtls">
            {interfaceAccess ? (
              <div className="gennx-content-wrapper padding-bottom-100i pt-3 footer-button-wrapper" style={{ width: "99.5%" }}>
                <div className="mx-2" style={{ background: "#ffffff", padding: "1em" }}>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <input type="hidden" value={productVariant} name="pVariant" id="pVariant" />
                        <div className="col-md-3">
                          {VIDEO_TYPE_OPTIONS && (
                            <Dropdown
                              formik={formik}
                              required="required"
                              placeholder="Video Type *"
                              id="videoType"
                              name="videoType"
                              // enabled={false}
                              fields={{ text: "label", value: "value" }}
                              dataSource={VIDEO_TYPE_OPTIONS}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-11">
                          <TextField
                            formik={formik}
                            type="text"
                            id="videoTitle"
                            placeholder="Video Title *"
                            name="videoTitle"
                            required="required"
                            numberCount={true}
                            maxLength={125}
                            characterLimitText={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2">
                          {STATUS_OPTIONS && (
                            <Dropdown
                              formik={formik}
                              required="required"
                              placeholder="Status *"
                              id="status"
                              name="status"
                              fields={{ text: "label", value: "value" }}
                              dataSource={STATUS_OPTIONS}
                            />
                          )}
                        </div>
                        <div className="col-md-3">
                          {VIDEO_OPTIONS && (
                            <Dropdown
                              formik={formik}
                              required="required"
                              placeholder={`Video Source${formik.values.status !== "Upcoming" ? " *" : ""}`}
                              id="videoSource"
                              name="videoSource"
                              fields={{ text: "label", value: "value" }}
                              dataSource={VIDEO_OPTIONS}
                            />
                          )}
                        </div>
                        <div className="col-md-6 videoInputGroup">
                          <InputGroupText
                            formik={formik}
                            inputGroupText={
                              formik.values.videoSource === "Vimeo"
                                ? "https://player.vimeo.com/video/"
                                : (formik.values.videoSource === "YouTube" && "https://www.youtube.com/") || ""
                            }
                            type="text"
                            id="vimeoVideoId"
                            placeholder={`Video ID${formik.values.status !== "Upcoming" ? " *" : ""}`}
                            name="vimeoVideoId"
                          />
                        </div>
                        <div className="col-md-1 p-2">
                          <i
                            data-tip="e.g link : https://vimeo.com/428464282/899849fa06,<br/> id : 428464282/899849fa06"
                            className="text-muted fa-2x fa-lg fas fa-question-circle"
                          ></i>
                          <ReactTooltip multiline={true} delay={1000} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-11">
                          <label>Video Description *</label>
                          {
                            <CkEditorVideo
                              formik={formik}
                              properties={{
                                id: "videoDescription",
                                name: "videoDescription",
                                activeClass: "videoDescription",
                                label: "Video Description *",
                                config: ckEditorMinimalConfig,
                              }}
                            />
                          }
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-md-3">
                          <NumericTextField
                            formik={formik}
                            format="n0"
                            min={0}
                            id="videoDurationInMinute"
                            placeholder="Duration in Minute *"
                            name="videoDurationInMinute"
                          />
                        </div>
                        <div className="col-md-1 p-2">
                          <i
                            data-tip="Please note that on save, the system will overwrite this duration with actual duration. <br/> To ensure accuracy, please use the actual video duration."
                            className="text-muted fa-2x fa-lg fa fa-info-circle"
                          ></i>
                          <ReactTooltip multiline={true} delay={1000} />
                        </div>
                      </div>
                      {/* <div className="row">
                      <div className="col-md-4">
                        <ToggleSwitchNew
                          formik={formik}
                          required="required"
                          properties={{
                            id: "videoPaywallSetting",
                            name: "videoPaywallSetting",
                            label: "Enable Paywall",
                          }}
                        />
                      </div>
                    </div> */}

                      {productVariant === "Insider" && (
                        <div className="row">
                          <div className="col-md-8">
                            {/* <ToggleSwitchNew
                              formik={formik}
                              required="required"
                              properties={{
                                id: "restrictContentForClient",
                                name: "restrictContentForClient",
                                label: "Restrict access of the video to selected users only *",
                              }}
                            /> */}
                            {/* <label className="mb-2" htmlFor="restrictContentForClient">Restrict access of the video to selected users only *</label> */}
                          </div>
                          {/* <div className="col-md-1">
                                            <div className="form-group">
                                                <div style={{ marginLeft: "-10px" }}>
                                                    <label className="custom-switch3">
                                                        <input type="checkbox"
                                                            name="restrictContentForClient"
                                                            checked={checked ? true : false}
                                                            onChange={(e) => {
                                                                setChecked(e.target.checked)
                                                                formik.setFieldValue("restrictContentForClient", e.target.checked ? "YES" : "NO");

                                                            }}
                                                        />
                                                        <div>
                                                            <span className="on">Yes</span>
                                                            <span className="off">No</span>
                                                        </div>
                                                        <i></i>
                                                    </label>
                                                </div>


                                            </div>
                                        </div> */}
                        </div>
                      )}
                      {productVariant === "Insider" && formik.values.restrictContentForClient === "YES" && (
                        <div className="row">
                          <div className="col-md-11 mt-3">
                            {users && (
                              <MultiSelect
                                formik={formik}
                                //filtering={searchUser}
                                mode="CheckBox"
                                sortOrder="Acending"
                                showSelectAll={false}
                                required="required"
                                placeholder="Select Client Users *"
                                id="clientUsers"
                                name="clientUsers"
                                allowCustomValue={true}
                                value={formik.values.clientUsers}
                                fields={{ text: "fullname", value: "userid" }}
                                dataSource={users}
                                noRecordsTemplate={() => <div>Please type to search</div>}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      <div className="row">
                        {formik.values.status === "Upcoming" && formik.values.videoType === "Webinar" && (
                          <div className="col-md-8">
                            <TextField
                              formik={formik}
                              type="text"
                              id="videoRegistrationLink"
                              placeholder="Registration Link for Webinar *"
                              name="videoRegistrationLink"
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="image-holder mb-2">
                            <div className="d-flex align-item-left justify-content-left">
                              {formik.values.videoThumbnail && (
                                <img
                                  // style={{ borderRadius: "100px" }}
                                  src={formik.values.videoThumbnail && fetchImageUrl(formik.values.videoThumbnail)}
                                  alt="Product"
                                  width="146"
                                  height="128"
                                  ref={profileImageRef}
                                />
                              )}
                              {formik.values.videoThumbnail && (
                                <span
                                  onClick={() => {
                                    dispatch(
                                      showAlertBox({
                                        content: "Are you sure, you want to delete?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        title: "dialogAlertCssWarning",
                                        okCallback: async () => {
                                          dispatch(actionSuccess("Image deleted successfully"));
                                          formik.setValues({
                                            ...formik.values,
                                            videoThumbnail: "",
                                          });
                                          profileImageInputRef.current.value = "";
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <i
                                    className={`fa fa-trash text-muted mr-1 ${!formik.values.videoThumbnail ? "d-none" : undefined}`}
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                              {!formik.values.videoThumbnail && <div className="col-md-3 thumbnail"></div>}
                              {
                                <div className="align-item-left m-3">
                                  <button
                                    type="button"
                                    onClick={() => profileImageInputRef.current.click()}
                                    className="pt-4 pl-0 e-btn e-link text-capitalize text-danger"
                                  >
                                    Upload Thumbnail *
                                  </button>
                                  <br />
                                  (Image size should be less than or equal to 10MB)
                                </div>
                              }
                            </div>
                            <div className="image-action mr-4">
                              {/* <i className="fa fa-pencil-alt " aria-hidden="true"
                                      onClick={() => profileImageInputRef.current.click()}
                                  /> */}
                            </div>
                            <input
                              type="file"
                              className="d-none"
                              accept=".jpg, .jpeg, .png"
                              id="videoThumbnail"
                              name="videoThumbnail"
                              ref={profileImageInputRef}
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  if (
                                    (e.target.files[0].type == "image/png" ||
                                      e.target.files[0].type == "image/jpg" ||
                                      e.target.files[0].type == "image/jpeg") &&
                                    e.target.files[0].size <= VIDEO_THUMB_IMG_SIZE
                                  ) {
                                    setImageError("");
                                    setProfileImage(e.target.files[0].name);
                                    readVideoThumnail(e.target.files[0]);
                                    if (profileImageInputRef.current.files.length)
                                      formik.setValues({
                                        ...formik.values,
                                        videoThumbnail: profileImageInputRef.current.files[0],
                                      });
                                  } else {
                                    setProfileImage("");
                                    setImageError("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                                    formik.touched.videoThumbnail === true &&
                                      formik.setErrors("File must be JPG/JPEG or PNG format and size must not exceed 10 MB.");
                                  }
                                }
                              }}
                            />
                            <div
                              className="text-danger mt-2"
                              style={{
                                display: formik.touched.videoThumbnail === true || imageError ? "block" : "none",
                              }}
                              role="alert"
                            >
                              {
                                <small>
                                  {formik.touched.videoThumbnail === true ? formik.errors.videoThumbnail : imageError} <br />
                                </small>
                              }
                              {/* <small> */}
                              {/* {formik.touched.videoThumbnail === true ? (imageError ? imageError : formik.errors.videoThumbnail || "") : ""} */}
                              {/* </small> */}
                            </div>
                            {/* <div className="d-flex align-item-center justify-content-center text-danger" style={{ display: imageError ? "block" : "none" }} role="alert">
                                                     <small>{imageError}</small>
                                                 </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="pt-3" key="addMorelst" id="addMore">
                        <TimeZone formik={formik} name="timezone1" isMandatory={true} />
                        <TimeZone formik={formik} name="timezone2" />
                        <TimeZone formik={formik} name="timezone3" />
                        {/* <AddMoreList 
                                             columnDetails={addMoreColumnDtls}
                                             elementDetails={formik.values.status !== "Upcoming" && formik.values.videoType !== "Webinar" ? addMoreElementsData : addMoreElementsDataRequired} 
                                             add={addNewRow}
                                             delete={clickOnDelete} /> */}
                      </div>

                      {productVariant === "WhatNext" && (
                        <div className="row">
                          <div className="col-md-4">
                            {COMMERCIAL_IMPACT_OPTIONS.length > 0 && (
                              <Dropdown
                                formik={formik}
                                sortOrder="Acending"
                                showSelectAll={true}
                                placeholder="Possible Time Of Commercial Impact"
                                id="commercialImpact"
                                name="commercialImpact"
                                value={formik.values.commercialImpact}
                                fields={{ text: "label", value: "value" }}
                                dataSource={COMMERCIAL_IMPACT_OPTIONS}
                              />
                            )}
                          </div>
                          <div className="col-md-4">
                            <MultiSelect
                              formik={formik}
                              mode="Box"
                              allowCustomValue={true}
                              allowFiltering={true}
                              sortOrder="Acending"
                              showSelectAll={true}
                              required=""
                              placeholder="Possible Areas Of Impact"
                              id="areasOfImpact"
                              name="areasOfImpact"
                              value={formik.values.videoAreaOfImpact}
                              noRecordsTemplate={() => <div>Please type to search or create new</div>}
                              fields={{ text: "label", value: "value" }}
                              filtering={(e) => {
                                searchCustomTag(e.text, "impactareas");
                              }}
                              dataSource={impactareasDataSource}
                            />
                          </div>
                          <div className="col-md-3">
                            {
                              <MultiSelect
                                formik={formik}
                                mode="Box"
                                allowCustomValue={true}
                                allowFiltering={true}
                                sortOrder="Acending"
                                showSelectAll={true}
                                required=""
                                placeholder="Specific Technology"
                                id="specificTechnology"
                                name="specificTechnology"
                                value={formik.values.specificTechnology}
                                fields={{ text: "label", value: "value" }}
                                filtering={(e) => {
                                  searchCustomTag(e.text, "specifictechnology");
                                }}
                                noRecordsTemplate={() => <div>Please type to search or create new</div>}
                                dataSource={specificTechnologyDataSource}
                              />
                            }
                          </div>
                          <div className="col-md-4">
                            {
                              <MultiSelect
                                formik={formik}
                                mode="Box"
                                allowCustomValue={true}
                                allowFiltering={true}
                                sortOrder="Acending"
                                showSelectAll={true}
                                required=""
                                placeholder="Type Of Organization – Academic"
                                id="typeOfOraganizationAcademic"
                                name="typeOfOraganizationAcademic"
                                value={formik.values.typeOfOraganizationAcademic}
                                fields={{ text: "label", value: "value" }}
                                filtering={(e) => {
                                  searchCustomTag(e.text, "academic");
                                }}
                                noRecordsTemplate={() => <div>Please type to search or create new</div>}
                                dataSource={academicDataSource}
                              />
                            }
                          </div>
                          <div className="col-md-4">
                            {
                              <MultiSelect
                                formik={formik}
                                mode="Box"
                                allowCustomValue={true}
                                allowFiltering={true}
                                sortOrder="Acending"
                                showSelectAll={true}
                                required=""
                                placeholder="Type Of Organization – Research"
                                id="typeOfOraganizationResearch"
                                name="typeOfOraganizationResearch"
                                value={formik.values.typeOfOraganizationResearch}
                                fields={{ text: "label", value: "value" }}
                                filtering={(e) => {
                                  searchCustomTag(e.text, "research");
                                }}
                                noRecordsTemplate={() => <div>Please type to search or create new</div>}
                                dataSource={researchDataSource}
                              />
                            }
                          </div>

                          <div className="col-md-3">
                            {
                              <MultiSelect
                                formik={formik}
                                mode="Box"
                                allowCustomValue={true}
                                allowFiltering={true}
                                sortOrder="Acending"
                                showSelectAll={true}
                                required=""
                                placeholder="Type Of Organization – Company"
                                id="typeOfOraganizationCompany"
                                name="typeOfOraganizationCompany"
                                value={formik.values.typeOfOraganizationCompany}
                                fields={{ text: "label", value: "value" }}
                                filtering={(e) => {
                                  searchCustomTag(e.text, "company");
                                }}
                                noRecordsTemplate={() => <div>Please type to search or create new</div>}
                                dataSource={companyTagDataSource}
                              />
                            }
                          </div>
                          <div className="col-md-4">
                            <TextField
                              formik={formik}
                              type="text"
                              id="whatNextVideoSource"
                              placeholder="Source of Video"
                              name="whatNextVideoSource"
                              // required="required"
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              formik={formik}
                              type="text"
                              id="whatNextSourceUrl"
                              placeholder="Website/Source URL of Video"
                              name="whatNextSourceUrl"
                              // required="required"
                            />
                          </div>
                          <div className="col-md-3">
                            <TextField
                              formik={formik}
                              type="text"
                              id="whatNextSourceEmbedUrl"
                              placeholder="URL to be embedded in the body"
                              name="whatNextSourceEmbedUrl"
                              // required="required"
                            />
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-4">
                          {
                            <MultiSelect
                              formik={formik}
                              mode="Box"
                              allowCustomValue={true}
                              allowFiltering={true}
                              required=""
                              placeholder="Custom Tag"
                              id="customTag"
                              name="customTag"
                              value={formik.values.customTag}
                              fields={{ text: "label", value: "value" }}
                              filtering={(e) => {
                                searchCustomTag(e.text, "custom");
                              }}
                              noRecordsTemplate={() => <div>Please type to search or create new</div>}
                              dataSource={customTagDataSource}
                            />
                          }
                        </div>
                        <div className="col-md-4">
                          {videoExpertDataSource.length > 0 && (
                            <MultiSelect
                              formik={formik}
                              mode="CheckBox"
                              allowCustomValue={false}
                              allowFiltering={true}
                              enableRtl={true}
                              showClearButton={false}
                              required=""
                              placeholder="Video Expert (Multiple Select)"
                              id="videoExpert"
                              name="videoExpert"
                              value={formik?.values?.videoExpert}
                              fields={{ text: "label", value: "value" }}
                              // filtering={(e) => { searchText(e.text) }}
                              // noRecordsTemplate={() => <div>Please type to search</div>}
                              dataSource={videoExpertDataSource}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-12 ">
                          <div
                            style={{
                              border: "1px solid #ccc",
                              background: "#ddd",
                              height: "250px",
                              paddingTop: "5rem !important",
                              textAlign: "center",
                            }}
                          >
                            {!videoId && !formik.values.vimeoVideoId && (
                              <div
                                style={{
                                  position: "relative",
                                  paddingTop: "83px",
                                }}
                              >
                                <i
                                  className="fas fa-video"
                                  style={{
                                    mixBlendMode: "overlay",
                                    fontSize: "30px",
                                  }}
                                ></i>
                                <br />
                                <span style={{ color: "#999" }}>(Video will be display here)</span>
                              </div>
                            )}
                            {videoId && formik.values.videoSource === "Vimeo" && (
                              <iframe
                                src={`https://player.vimeo.com/video/${formik.values.vimeoVideoId}`}
                                width="100%"
                                height="250"
                                frameBorder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                              ></iframe>
                            )}
                            {videoId && formik.values.videoSource === "YouTube" && (
                              <div>
                                <iframe
                                  src={`https://www.youtube.com/embed/${formik.values.vimeoVideoId}?rel=0&autoplay=0&showinfo=0`}
                                  width="100%"
                                  height="250"
                                  frameBorder="0"
                                  allow="autoplay; encrypted-media"
                                  allowfullscreen
                                ></iframe>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    <div className="row">
                      <div className="col-md-12 pb-3"></div>
                    </div>
                  }
                </div>
              </div>
            ) : (
              <AccessDenied />
            )}
          </div>
          <div key="qstnList">
            {selectedIndex === 1 && videoId && interfaceAccess ? (
              <RelatedQuestions
                videoId={videoId}
                tabRef={tabRef}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                selectedIndex={selectedIndex}
                hashArr={hashArr}
                interfaceActionAccess={interfaceActionAccess}
              />
            ) : (
              <AccessDenied />
            )}
          </div>
          <div key="docList">
            {selectedIndex === 2 && videoId && interfaceAccess ? (
              <RelatedDocuments
                videoId={videoId}
                tabRef={tabRef}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                selectedIndex={selectedIndex}
                hashArr={hashArr}
                interfaceActionAccess={interfaceActionAccess}
              />
            ) : (
              <AccessDenied />
            )}
          </div>
          <div key="hostList">
            {selectedIndex === 3 && videoId && interfaceAccess ? (
              <HostedBy
                videoId={videoId}
                tabRef={tabRef}
                setCurrentTab={(selectedIndex) => {
                  tabRef.current.select(selectedIndex);
                  setSelectedIndex(selectedIndex);
                }}
                selectedIndex={selectedIndex}
                hashArr={hashArr}
                interfaceActionAccess={interfaceActionAccess}
              />
            ) : (
              <AccessDenied />
            )}
          </div>
          {(productVariant === "WhatNext" || productVariant === "Insider") && (
            <div key="taxonomy">
              {selectedIndex === 4 && videoId && interfaceAccess ? (
                <Tagging
                  videoId={videoId}
                  resetFlag={() => {
                    setSaveTaxonomy(false);
                  }}
                  saveTaxonomy={saveTaxonomy}
                  tabRef={tabRef}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  selectedIndex={selectedIndex}
                  hashArr={hashArr}
                  {...props}
                  interfaceActionAccess={interfaceActionAccess}
                  accessPermissionTaxonomy={accessActionTaxonomyAdd}
                  accessActionTaxonomyAddTags={accessActionTaxonomyAddTags}
                  accessPermissionTaxonomyDelete={accessActionTaxonomyDelete}
                  accessActionTaxonomyShowInProduct={accessActionTaxonomyShowInProduct}
                />
              ) : (
                <AccessDenied />
              )}
            </div>
          )}
          {productVariant === "Insider" && (
            <div key="videoSetting">
              {selectedIndex === 5 && videoId && interfaceAccess ? (
                <Setting
                  videoId={videoId}
                  tabRef={tabRef}
                  setCurrentTab={(selectedIndex) => {
                    tabRef.current.select(selectedIndex);
                    setSelectedIndex(selectedIndex);
                  }}
                  selectedIndex={selectedIndex}
                  hashArr={hashArr}
                  {...props}
                  productVariant={formik.values.productVariant}
                  interfaceActionAccess={interfaceActionAccess}
                  fetchVideoDetails={fetchVideoDetails}
                />
              ) : (
                <AccessDenied />
              )}
            </div>
          )}
        </Tabs>
      </div>
      <ActionButtons
        accessRights={props.accessRights}
        selectedIndex={selectedIndex}
        setCurrentTab={(selectedIndex) => {
          tabRef.current.select(selectedIndex);
          setSelectedIndex(selectedIndex);
        }}
        // nextPath=""
        nextPath={videoId ? `/vedio/${videoId}/edit` : null}
        videoId={videoId ? videoId : null}
        handleSave={
          [0, 4].includes(selectedIndex)
            ? selectedIndex === 0
              ? saveVideoDetails
              : () => {
                  setSaveTaxonomy(true);
                }
            : null
        }
        showPublished={videoId && selectedIndex === 0 && formik.values.status !== "Inactive" ? true : false}
        moduleName={props?.accessRights?.moduleName}
        hashArr={hashArr}
        fetchVideoDetails={fetchVideoDetails}
      />
    </>
  );
};
VideoDetails.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  isMandatory: PropTypes.string,
  accessRights: PropTypes.arrayOf(PropTypes.string),
  computedMatch: PropTypes.string,
};

export default VideoDetails;
