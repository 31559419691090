import { CMS_API_URL, SITESEARCH_URL, TV_URL } from "../../config";
import { useLocation } from "react-router-dom";
import { NEWS_TYPE, CONTENT_TYPE, DELIVERABLE_TYPE } from "../../constants/index";
import http from "./http-common";
import { getContentTypesSearch } from "../../middleware/actions/searchAction";

export const getParams = (params) => {
  let obj = {
    fields: JSON.stringify(params.fields),
    filters: JSON.stringify(params.queries),
  };

  if (params.skip) {
    Object.assign(obj, { skip: params.skip });
  }

  if (params.limit) {
    Object.assign(obj, { limit: params.limit });
  }

  if (params.sort) {
    Object.assign(obj, { sort: params.sort });
  }

  return obj;
};

export const useQueryHelper = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * @description build json for tree
 * @param {Array} data
 * @param {String} parentId
 */
const buildTreeJson = (data, parentId) => {
  const result = [];
  data
    .filter((ele) => ele.trendIsHidden === "NO" || ele.trendIsHidden === undefined)
    .forEach((element) => {
      element["value"] = element.trendParentId + ">" + element.id + ">" + element.trendName + ">taxonomy";
      element["label"] = element.trendDisplayName ? element.trendDisplayName : element.trendName;
      element["disabled"] = element?.trendIsDisabled === "YES" ? true : false;
      if (element["trendParentId"] === parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0 && element.trendIsDisabled !== "YES" && element.trendIsHidden !== "YES") {
          element["children"] = children;
        }
        result.push(element);
      }
    });
  return result;
};

// Function to recursively modify label property in nested arrays
function modifyLabels(obj) {
  if (obj.children) {
    obj.children = obj.children.map((child) => ({
      ...child,
      label: `${child.label} (0)`,
    }));
    obj.children.forEach(modifyLabels);
  }
}

// Fetch Auto Sugeestion Method
export const fetchAutoSuggestion = (query, trendIds, sgfIds, productIndustryIds) => {
  let params = {
    platform: "IndustryInsider",
    q: query,
    trendId: trendIds,
    sgfId: sgfIds,
    industryId: productIndustryIds,
  };
  return http
    .post(`${SITESEARCH_URL}/sitesearch-autosuggest-cms`, params)
    .then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data.terms;
      } else {
        return [];
      }
    })
    .catch((err) => {
      console.log("error >>>", err);
      // toast.error('Something went wrong!');
    });
};

const getHighlightedElements = (item, score, keywords) => {
  if (!item.highlighting) return {};

  // Extract unique keywords from the provided data
  const uniqueKeywords = new Set();

  for (const key in item.highlighting) {
    if (item.highlighting.hasOwnProperty(key)) {
      item.highlighting[key].forEach((text) => {
        const matches = text.match(/<em>(.*?)<\/em>/g);
        if (matches) {
          matches.forEach((match) => {
            const keyword = match.replace(/<\/?em>/g, "").toLowerCase(); // Convert to lowercase
            uniqueKeywords.add(keyword);
          });
        }
      });
    }
  }

  // Convert the set of unique keywords to an array
  const uniqueKeywordsArray = Array.from(uniqueKeywords);

  const title = item.highlighting?.title || item.highlighting?.name || [];
  const text = item.highlighting?.text || item.highlighting?._text_ || [];
  const whatsUnique = item.highlighting?.whatsUnique || [];
  const content = item.highlighting?.content || item.highlighting?.description || [];
  const sgfTags = item.highlighting?.sgfTags || [];
  const trends = item.highlighting?.trends || [];
  const taxonomyTags = item.highlighting?.taxonomyTags || [];
  const deliverableSelectedTaxonomyTags = item.highlighting?.deliverableSelectedTaxonomyTags || [];
  const companySelectedTaxonomyTags = item.highlighting?.companySelectedTaxonomyTags || [];
  const newsSelectedTaxonomyTags = item.highlighting?.newsSelectedTaxonomyTags || [];
  const deliverableContentTags = item.highlighting?.deliverableContentTags || [];
  const videoTags = item.highlighting?.videoTags || [];
  const source = item.highlighting?.newsSource || [];
  const company = item.highlighting?.newsCompany || item.highlighting?.docCompany || [];
  const pageTitle = item.highlighting?.pageTitle || item.highlighting?.pageTitle || [];
  const pageTags = item.highlighting?.pageSeoMetatagsTitle || item.highlighting?.pageSeoMetatagsTitle || [];
  const pageTagsKeyWord = item.highlighting?.pageSeoMetatagsKeyword || item.highlighting?.pageSeoMetatagsKeyword || [];
  const pageContentHtml = item.highlighting?.pageContentHtml || item.highlighting?.pageContentHtml || [];
  const newsCustomTags = item.highlighting?.newsCustomTags || item.highlighting?.newsCustomTags || [];
  const customTags = item.highlighting?.customTags || item.highlighting?.customTags || [];

  // Combine the values into a new "tags" array
  const tags = [
    ...sgfTags,
    // ...trends,
    ...taxonomyTags,
    ...deliverableSelectedTaxonomyTags,
    ...companySelectedTaxonomyTags,
    ...newsSelectedTaxonomyTags,
    ...deliverableContentTags,
    ...videoTags,
    ...pageTags,
    ...pageTagsKeyWord,
    ...newsCustomTags,
    ...customTags,
  ];

  const combinedcontent = [...content, ...pageContentHtml];

  const comibinedHighlighted = {
    relevanceScore: [Number.isInteger(score) ? score.toFixed(0) + "%" : score.toFixed(2) + "%"],
    matchedKeywords: [uniqueKeywordsArray?.filter((item) => item?.trim())?.join(", ")],
    title: [title?.filter((item) => item?.trim())?.join("...")],
    tags: [tags?.filter((item) => item?.trim())?.join(", ")],
    whatsUnique: [whatsUnique?.filter((item) => item?.trim())?.join("...")],
    content: [combinedcontent?.filter((item) => item?.trim())?.join("...")],
    source: [source?.filter((item) => item?.trim())?.join(", ")],
    company: [company?.filter((item) => item?.trim())?.join(", ")],
    pdfContent: [text?.filter((item) => item?.trim())?.join("...")],
    pageTitle: [pageTitle?.filter((item) => item?.trim())?.join("...")],
  };
  for (const key in comibinedHighlighted) {
    if (comibinedHighlighted.hasOwnProperty(key) && comibinedHighlighted[key]?.filter((item) => item !== "")?.length == 0) {
      delete comibinedHighlighted[key];
    }
  }
  return comibinedHighlighted;
};

const getUnqiueKeywords = (item) => {
  // Extract unique keywords from the provided data
  const uniqueKeywords = new Set();

  for (const key in item.highlighting) {
    if (item.highlighting.hasOwnProperty(key)) {
      item.highlighting[key].forEach((text) => {
        const matches = text.match(/<em>(.*?)<\/em>/g);
        if (matches) {
          matches.forEach((match) => {
            const keyword = match.replace(/<\/?em>/g, "").toLowerCase(); // Convert to lowercase
            uniqueKeywords.add(keyword);
          });
        }
      });
    }
  }

  // Convert the set of unique keywords to an array
  return Array.from(uniqueKeywords);
};

const calculateScore = (item, keywords, maxScore) => {
  if (!item.highlighting) return item.score;
  // Extract unique keywords from the provided data
  const uniqueKeywords = new Set();

  for (const key in item.highlighting) {
    if (item.highlighting.hasOwnProperty(key)) {
      item.highlighting[key].forEach((text) => {
        const matches = text.match(/<em>(.*?)<\/em>/g);
        if (matches) {
          matches.forEach((match) => {
            const keyword = match.replace(/<\/?em>/g, "").toLowerCase(); // Convert to lowercase
            uniqueKeywords.add(keyword);
          });
        }
      });
    }
  }
  const uniqueKeywordsArray = Array.from(uniqueKeywords);
  if (uniqueKeywordsArray.length === keywords.length) return (maxScore / maxScore) * 100;
  else {
    //Manupulate the score to adjust reduce the difference between max score and lower score without imapcting the order of the results
    //0.75 is a constant
    const derivedScore = item.score + (maxScore - item.score) * 0.75;
    return (derivedScore / maxScore) * 100;
  }
};

export const getModifiedSearchContent = async (array, sort, selectedContentType, keywords) => {
  let finalArray = [];
  let companyList;
  let newsList;
  let articleList;
  let videoList;
  let documentList;
  let newData;

  const companyIndex = 0;
  const newsIndex = 1;
  const videoIndex = 2;
  const documentIndex = 3;

  const allMaxScore = array?.map((obj) => obj?.maxScore || 0);
  let maxScore = Math.max(...allMaxScore);
  let recordsWithSameMaxScore = [];
  array.forEach((r) => {
    const values = r?.data?.map((obj) => obj.score || 0) || [0];
    const maxValue = Math.max(...values);
    allMaxScore.push(maxValue);
  });
  if (recordsWithSameMaxScore.length > 0) maxScore = maxScore + 1;

  if (companyIndex !== -1 && array[companyIndex]) {
    companyList = array[companyIndex].data.map((ele) => {
      return {
        id: ele.id,
        title: ele.name,
        date: ele.publishedDate,
        label: CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES,
        entityType: "company",
        infoText: ele.whatsUnique || ele.description,
        technologyId: ele.technologyId,
        profileImage: ele.companyLogo,
        selectedTaxonomyTags: ele.companySelectedTaxonomyTags,
        score: calculateScore(ele, keywords, maxScore),
        originalScore: ele.score,
        highlighting: getHighlightedElements(ele, calculateScore(ele, keywords, maxScore), keywords),
        isPublished: ele.isLive?.includes("YES"),
        companyCreatedBy: ele.companyCreatedBy,
      };
    });
  }

  if (companyList) finalArray = [...finalArray, ...companyList];

  if (newsIndex !== -1 && array[newsIndex]) {
    newsList = array[newsIndex].data
      .map((ele) => {
        if (ele.newsType !== NEWS_TYPE.ARTICLES) {
          return {
            id: ele.id,
            title: ele.title,
            date: ele.publishedDate,
            // label: ele.newsType ? ele.newsType : "News",
            label: ele.newsType,
            entityType: "news",
            infoText: ele.newsSummary || ele.description,
            technologyId: ele.technologyId,
            profileImage: ele.newsImage?.replace("/upload/images/news/", ""),
            readTime: ele.newsReadTime,
            selectedTaxonomyTags: ele.newsSelectedTaxonomyTags,
            score: calculateScore(ele, keywords, maxScore),
            originalScore: ele.score,
            highlighting: getHighlightedElements(ele, calculateScore(ele, keywords, maxScore), keywords),
            isPublished: ele.isLive?.includes("YES"),
            newsCreatedBy: ele.newsCreatedBy,
          };
        }
      })
      .filter((e) => e);

    articleList = array[newsIndex].data
      .map((ele) => {
        if (ele.newsType === NEWS_TYPE.ARTICLES) {
          return {
            id: ele.id,
            title: ele.title,
            date: ele.publishedDate,
            label: CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
            ribbon: NEWS_TYPE.ARTICLES,
            entityType: "news",
            infoText: ele.newsSummary || ele.description,
            technologyId: ele.technologyId,
            profileImage: ele.newsImage?.replace("/upload/images/news/", ""),
            readTime: ele.newsReadTime,
            selectedTaxonomyTags: ele.newsSelectedTaxonomyTags,
            score: calculateScore(ele, keywords, maxScore),
            originalScore: ele.score,
            highlighting: getHighlightedElements(ele, calculateScore(ele, keywords, maxScore), keywords),
            isPublished: ele.isLive?.includes("YES"),
            newsCreatedBy: ele.newsCreatedBy,
          };
        }
      })
      .filter((e) => e);
  }

  if (
    selectedContentType.length === 0 ||
    (selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.DEVELOPMENTS_NEWS)) &&
      selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH)))
  ) {
    if (newsList) finalArray = [...finalArray, ...newsList];
    if (articleList) finalArray = [...finalArray, ...articleList];
  } else if (selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.DEVELOPMENTS_NEWS))) {
    if (newsList) finalArray = [...finalArray, ...newsList];
  } else if (selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH))) {
    if (articleList) finalArray = [...finalArray, ...articleList];
  }

  if (videoIndex !== -1 && array[videoIndex]) {
    newData = await getVideoOtherDetails(array[videoIndex].data);
    videoList = newData.map((ele) => {
      return {
        id: ele.id,
        title: ele.title,
        date: ele.publishedDate,
        label: CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS,
        entityType: "video",
        infoText: ele.videoContent || ele.description,
        technologyId: ele.technologyId,
        profileImage: ele.videoThumbnail,
        video: ele.videos,
        ribbon: ele.videoType,
        readTime: ele.videoDuration,
        status: ele.videoStatus,
        registrationLink: ele.videoRegistrationLink,
        selectedTaxonomyTags: ele.videoSelectedTaxonomyTags,
        score: calculateScore(ele, keywords, maxScore),
        originalScore: ele.score,
        highlighting: getHighlightedElements(ele, calculateScore(ele, keywords, maxScore), keywords),
        isPublished: ele.isLive?.includes("YES"),
        videoCreatedBy: ele.videoCreatedBy,
      };
    });
  }

  if (videoList) finalArray = [...finalArray, ...videoList];
  if (documentIndex !== -1 && array[documentIndex]) {
    documentList = array[documentIndex].data.map((ele) => {
      if (
        (selectedContentType.length === 0 ||
          selectedContentType.some((entry) => entry.includes(ele.docContentType)) ||
          selectedContentType.some((entry) => entry.includes(ele.docType))) &&
        ele.docContentType !== CONTENT_TYPE.DASHBOARD
      ) {
        return {
          id: ele.id,
          title: ele.title,
          date: ele.publishedDate,
          label: ele.docContentType ? ele.docContentType : ele.docType,
          entityType: "document",
          infoText: ele.deliverableSummary || ele.description,
          technologyId: ele.technologyId,
          profileImage: ele.docImage,
          ribbon: ele.docType,
          readTime: ele.deliverableReadTime,
          selectedTaxonomyTags: ele.deliverableSelectedTaxonomyTags,
          score: calculateScore(ele, keywords, maxScore),
          originalScore: ele.score,
          highlighting: getHighlightedElements(ele, calculateScore(ele, keywords, maxScore), keywords),
          isPublished: ele.isLive?.includes("YES"),
          deliverableTaskReviewers: ele.deliverableTaskReviewers,
          deliverableTaskPublishers: ele.deliverableTaskPublishers,
          deliverableTaskWriters: ele.deliverableTaskWriters,
          deliverableCreatedBy: ele.documentCreatedBy,
        };
      }
    });
  }

  if (documentList) finalArray = [...finalArray, ...documentList];
  finalArray = finalArray.filter((e) => e);

  let allMacthed = finalArray.filter((f) => f.score === 100);
  allMacthed = allMacthed.sort((a, b) => b.originalScore - a.originalScore);
  let unMacthed = finalArray.filter((f) => f.score !== 100);
  finalArray = [...allMacthed, ...unMacthed];
  //Based on sort value passed from props sort finalArray
  if (sort === "score:desc") return finalArray.sort((a, b) => b.score - a.score);
  else if (sort === "score:asc") return finalArray.sort((a, b) => a.score - b.score);
  else if (sort === "publishedDate:desc")
    return finalArray.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  else if (sort === "publishedDate:asc") return finalArray.sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const getSearchIndustryTags = (itemTaxonomyIds, masterTaxonomyList, selectedTaxonomyTags) => {
  if (selectedTaxonomyTags) {
    let sortedArray = selectedTaxonomyTags.sort((a, b) => {
      const intValueA = parseInt(a.split("|")[2]);
      const intValueB = parseInt(b.split("|")[2]);
      return intValueA - intValueB;
    });
    let transformedArray = sortedArray.map((tag) => {
      const [id, trendName] = tag.split("|");
      return { id, trendName };
    });
    return transformedArray.map((ele) =>
      Object.assign({}, ele, {
        trendParentId: ele.id,
        trendId: ele.id,
      })
    );
  } else {
    let taxonomyTags = [];
    taxonomyTags = masterTaxonomyList?.filter(
      (tag) => tag.trendParentId !== 0 && tag.trendIsSgf !== 1 && tag.trendSubRoot !== undefined && tag.trendSubRoot !== ""
    );
    let newList = [];
    if (taxonomyTags) {
      itemTaxonomyIds.forEach((itemTaxonomyId) => {
        let isFound = masterTaxonomyList?.filter((t) => t.id == itemTaxonomyId);
        if (isFound.length > 0) newList.push(isFound[0]);
      });
    }
    newList = newList.map((ele) =>
      Object.assign({}, ele, {
        trendId: ele.trendParentId ? ele.trendParentId : ele.trendId,
      })
    );
    // remove duplicate taxonomy names
    const isPropValuesEqual = (subject, target, propNames) => propNames.every((propName) => subject[propName] === target[propName]);
    const propNamesArray = Array.from(["trendName"]);
    return newList.filter((item, index, array) => index === array.findIndex((foundItem) => isPropValuesEqual(foundItem, item, propNamesArray)));
  }
};

export async function siteSearch(
  query,
  searchInContent,
  trendId,
  productIndustryIds,
  sgfIds,
  selectedContentType,
  sort,
  paywallTrendIdFlag,
  fromDate,
  toDate,
  statusFilter,
  autoCorrect,
  originalWord
) {
  let formattedFromDate, formattedToDate, isLive;

  if (statusFilter === "In Progress") isLive = "NO";
  else if (statusFilter === "Published") isLive = "YES";
  else isLive = "ALL";

  if (fromDate) {
    let fromDateNew = new Date(fromDate);
    fromDateNew.setDate(fromDateNew.getDate() - 1);
    fromDateNew.setUTCHours(18, 30, 0, 0);
    formattedFromDate = fromDateNew.toISOString();
  }

  if (toDate) {
    let toDateNew = new Date(toDate);
    toDateNew.setUTCHours(18, 29, 59, 0);
    formattedToDate = toDateNew.toISOString();
  }

  const keywords = query.split('" "').map((item) => item.replace(/"/g, ""));

  //const buildQuery = keywords.map((item) => `("${item}")^=10`).join(' OR '); //New Algo
  const buildQuery = generateSearchQuery(keywords, searchInContent); //Old Algo
  // const rqqQuery = keywords.map((item) => `"${item}"`).join(" OR ");
  let params = {
    q: buildQuery,
    searchInContent,
    // rqq: searchInContent === "Company" ? `name:(${rqqQuery})` : `title:(${rqqQuery})`,
    trendId: trendId,
    offset: 0,
    limit: 10000,
    platform: "IndustryInsider",
    sort: sort,
    // paywallProductPlatform: "Insider V2",
    industryId: productIndustryIds,
    sgfId: sgfIds,
    // paywallTrendId: paywallTrendIdFlag ? [] : productIndustryIds,
    dateFrom: formattedFromDate,
    dateTo: formattedToDate,
    isLive: isLive,
    fields: `id,name,title,content,description,whatsUnique,companyLogo,publishedDate,companyEntityType,taxonomyTags,fname,lname,profileImage,
      expertRating,totalExperience,hqCountry,empSize,isStartUp,productLogo,newsImage,newsType,videoThumbnail,videoType,docImage,docType,kiaExpertise,
      companyName,technologyMaturityLevel,technologyId,docContentType,videoStatus,videoRegistrationLink,videoDuration,newsReadTime,deliverableReadTime,
      deliverableSelectedTaxonomyTags,newsSelectedTaxonomyTags,companySelectedTaxonomyTags,productSelectedTaxonomyTags,videoSelectedTaxonomyTags,trendId,
      sgfId,newsLike,companyLike,videoLike,productLike,deliverableLike,isLive,deliverableTaskReviewers, deliverableTaskPublishers,deliverableTaskWriters,
      newsCreatedBy,companyCreatedBy,videoCreatedBy,documentCreatedBy`,
    originalWord: originalWord,
    autoCorrect: autoCorrect,
  };

  if (
    searchInContent === "Document" &&
    selectedContentType?.length !== 0 &&
    searchInContent !== "News" &&
    searchInContent !== "Company" &&
    searchInContent !== "Video"
  ) {
    const result = selectedContentType
      .filter((item) => !item.includes("Company"))
      .map((entry) => {
        return entry.replace(/>.*?>/g, "");
      });
    Object.assign(params, {
      docContentType: result,
    });
  }

  if (searchInContent === "Document") {
    Object.assign(params, {
      applicableProductPlatform: "Insider V2",
      excludeDocType: [DELIVERABLE_TYPE.CLIENT_BUSINESS_OBJECTIVE],
      excludeDocContentType: [CONTENT_TYPE.DASHBOARD, CONTENT_TYPE.SIGNALS],
    });
  }

  try {
    const response = await http.post(`${SITESEARCH_URL}/sitesearch-cms`, params);
    if (response.status !== 200) {
      return new Error("Something went wrong!");
    }
    return response.data;
  } catch (error) {
    // Error handling here!
    console.log("error in site search api call", error);
    return [];
  }
}

const getVideoOtherDetails = async (array) => {
  let videoIds = array.map((res) => res.id);

  let response = await getVideoDataMethod({
    queries: [["_id", "in", videoIds]],
    fields: ["videos"],
    limit: videoIds.length,
  });

  if (response.status !== 200) {
    return array;
  }

  return mergeArrays([array, response.data.data], "id");
};

export function generateSearchQuery(items, searchInContent) {
  const expressions = [];
  let mainQuery = "";
  const noOfItems = items.length;
  const importance = 1.0;
  const primaryQuery = items.map((item) => `"${item}"`).join(" AND ");
  const mainWeight = items.length === 1 ? `2` : String(importance + (items.length - 1) * 0.2);
  if (items.length === 1) {
    mainQuery = `(${primaryQuery})`;
  } else {
    mainQuery = `(${primaryQuery})^${mainWeight}`;
  }
  if (items.length > 2) {
    let secondaryQuery = "";
    let queryArray = [];
    let secondaryNoOfItems = items.length - 1;
    while (secondaryNoOfItems !== 1) {
      let query = buildQuery(secondaryNoOfItems, items);
      secondaryQuery = secondaryQuery + " " + query + " OR";
      secondaryNoOfItems--;
    }
    mainQuery = mainQuery + " OR" + secondaryQuery;
  } else if (items.length === 2) {
    mainQuery = mainQuery + " OR";
  }
  if (items.length > 1) {
    const lastQuery = items.map((item) => `("${item}")^0.5`).join(" OR ");
    if (lastQuery) {
      mainQuery = mainQuery + " " + lastQuery;
    }
  }
  const rqQuery =
    searchInContent === "Company"
      ? `name:(${items.map((item) => `"${item}"`).join(" AND ")})^${String(importance + items.length * 0.2)}`
      : `title:(${items.map((item) => `"${item}"`).join(" AND ")})^${String(importance + items.length * 0.2)}`;
  mainQuery = rqQuery + " OR " + mainQuery;
  return mainQuery;
}

export const getVideoDataMethod = async (params) => {
  return http.get(`${TV_URL}/video`, {
    params: getParams(params),
  });
};

const mergeArrays = (arrays, prop) => {
  const merged = {};

  arrays.forEach((arr) => {
    arr.forEach((item) => {
      merged[item[prop]] = Object.assign({}, merged[item[prop]], item);
    });
  });

  return Object.values(merged);
};

function buildQuery(noOfItems, array) {
  const importance = 1.0;
  let finalQuery = "";
  let queryArray = [];
  const possibleCombinations = generateCombinations(noOfItems, array);
  for (let i = 0; i < possibleCombinations.length; i++) {
    let combinationArr = possibleCombinations[i];
    let query = combinationArr.map((item) => `"${item}"`).join(" AND ");
    const secondaryWeight = String(importance + (noOfItems - 1) * 0.2);
    queryArray.push(`(${query})^${secondaryWeight}`?.replace("^0", ""));
    finalQuery = queryArray.join(" OR ");
  }
  return finalQuery;
}

function generateCombinations(pairSize, items) {
  const combinations = [];

  function generateCombination(currentCombination, remainingItems) {
    if (currentCombination.length === pairSize) {
      combinations.push([...currentCombination]);
    } else {
      for (let i = 0; i < remainingItems.length; i++) {
        const newCombination = [...currentCombination, remainingItems[i]];
        const newRemainingItems = remainingItems.slice(i + 1);
        generateCombination(newCombination, newRemainingItems);
      }
    }
  }

  generateCombination([], items);
  return combinations;
}

export const getTrendsTaxonomy = async (trendParentId) => {
  let trendsData = await getTrendsTaxonomyData(trendParentId);
  const treeJosnArray = buildTreeJson(trendsData, trendParentId);
  return treeJosnArray;
};

// sgf data get method
const getTrendsTaxonomyData = async (trendParentId) => {
  const response = await http.get(`${CMS_API_URL}/taxonomy/trend`, {
    params: getParams({
      fields: ["id", "trendName", "trendParentId", "trendParentName", "trendDisplayName", "trendIsHidden", "trendIsDisabled"],
      queries: [["trendSubRoot", "eq", trendParentId]],
      sort: "trendSortOrder:asc",
      limit: 2000,
    }),
  });
  return response.data.data;
};

// content type get method for site search
export const getContentTypeSearch = () => {
  return async (dispatch) => {
    // dispatch(toggleLoading(true));
    await http
      .get(`${CMS_API_URL}/common-access/content-type`, {
        params: {
          filters: JSON.stringify([["contentTypeProductVariant", "eq", "InsiderV2"]]),
          sort: "id:asc",
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.data) {
          const desiredOrder = [
            CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH,
            CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS,
            CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES,
            CONTENT_TYPE.DEVELOPMENTS_NEWS,
          ];
          // Sort the array based on the desired order
          const orderList = desiredOrder.map((contentType) => res.data.data.find((item) => item.contentType === contentType));
          let list = orderList.map((ele) => {
            return {
              value: ">" + ele.id + ">" + ele.contentType,
              contentType: ele.contentType,
              label: `${ele.contentType} (0)`,
            };
          });
          dispatch(getContentTypesSearch(list));
          // dispatch(toggleLoading(false));
        }
      });
  };
};

// sgf data get method
export const getSgfData = async (products) => {
  return http.get(`${CMS_API_URL}/common-access/sgf`, {
    params: {
      filters: JSON.stringify([
        ["sgfEnable", "eq", "1"],
        ["_id", "in", products],
      ]),
    },
  });
};
