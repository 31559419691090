// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : image component
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :6 Jun 22
// Author:Umesh
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { getPresentationImageUrl } from "../../../middleware/services/imageService";

const ImageFile = ({ fileName, className, entityType, draggable = true }) => {
  const [img, setImg] = useState();

  // set image as per entity type
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) setImg(getPresentationImageUrl(fileName));
    return () => (isSubscribed = false);
  }, [entityType, fileName]);

  // get default image of all entity
  const getDefaultImage = (type) => {
    if (type === "document") {
      return "/images/default_document_image.jpg";
    } else if (type === "video" || type === "presentation") {
      return "/images/default_video_image.png";
    }
  };

  return (
    <div className="globalImageStyle">
      {img && (
        <Image
          src={img}
          alt="Question"
          onError={(event) => {
            event.target.src = getDefaultImage(entityType);
          }}
          className={className}
          loading="lazy"
          draggable={draggable}
        />
      )}
    </div>
  );
};

export default ImageFile;
