/**
 * @fileoverview This file contains datagird helpers.
 * @date 03/Mar/2023
 * @author Copyright © 2022, Cheers Interactive Pvt Ltd.  All rights reserved.
 */

import React, { useState } from "react";

export function HeaderActionTemplate() {
  return (
    <div>
      <h6>Actions</h6>
    </div>
  );
}

export const StringFilter = (e) => (
  <div className="form-group">
    <div className="e-float-input e-control-wrapper e-input-group e-outline">
      <input
        autoComplete="off"
        type="text"
        placeholder="Enter the value"
        className="form-control e-control e-textbox e-lib"
        defaultValue={e?.value}
      />
    </div>
  </div>
);

export const ItemFilter = (value) => {
  let sourceData = [];
  if (value.column.field === "permission") {
    sourceData = [
      { id: "Can View", text: "Can View" },
      { id: "Can Modify", text: "Can Modify" },
    ];
  }

  return <DropDownFilter value={value} sourceData={sourceData} />;
};

export const DropDownFilter = (props) => {
  const { value, sourceData } = props;
  const [dropDownValue, setDropDownValue] = useState(sourceData[0].id);
  const onChange = (e) => {
    e.preventDefault();
    setDropDownValue(e.target.value);
  };
  return (
    <select
      className="form-control form-control-sm"
      onChange={onChange}
      value={dropDownValue}
      name={`fl-${value.column.field}`}
      id={value.column.field}
    >
      {sourceData &&
        sourceData.map((item, i) => {
          return (
            <option key={`${value.column.field}-${i}`} value={item.text}>
              {item.text}
            </option>
          );
        })}
    </select>
  );
};
