import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import * as React from "react";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import { saveDeliverableType } from "../../../../../../middleware/services/cmsApi";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import PropTypes from 'prop-types';


const SideBar = (props) => {
  const { showSideBar, existingDeliverableTypes } = props;
  const dispatch = useDispatch();

  const sidebarRef = useRef();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      deliverableType: "",
    },
    validationSchema: Yup.object().shape({
      deliverableType: Yup.string()
        .required("Please Enter Deliverable Type")
        .max(30, "Please Enter Deliverable Type of maximum 30 characters.")
        .typeError("Please Enter Deliverable Type of maximum 30 characters."),
    }),
  });

  const handleSave = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });

        const err =
          res &&
          Object.values(res).map((e) => {
            return e;
          });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        if (existingDeliverableTypes.includes(formik.values.deliverableType)) {
          dispatch(actionError("Deliverable Type already exists!"));
        } else {
          saveDeliverableType({
            deliverableType: formik.values.deliverableType,
          })
            .then((response) => {
              formik.handleReset();
              dispatch(actionSuccess("Deliverable Type added successfully"));
              sidebarRef.current.toggle();
              props.setToggleDeliverableDropdown(true);
              props.setShowSideBar(false);
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
            });
        }
      }
    });
  };

  const created = () => {
    sidebarRef.current.element.style.visibility = "";
  };

  const toggleClick = () => {
    sidebarRef.current.toggle();
    props.setShowSideBar(false);
  };

  return (
    <div className="deliverableSideBar">
      <SidebarComponent
        id="deliverableSideBar"
        ref={sidebarRef}
        style={{ visibility: "hidden" }}
        created={created}
        type={"Over"}
        isOpen={showSideBar}
        position="Right"
        animate={false}
        enableGestures={false}
        className={"deliverableSideBar"}
      >
        <div className="col-12 p-0 bg-white">
          <div className="row m-0">
            <div className="roundedTop pl-4 pr-4 " style={{ height: "56px" }}>
              <span className="font-weight-bold" style={{ paddingTop: "2.2vh" }}>
                Add Deliverable Type
              </span>
            </div>
            <hr style={{ borderBottom: "1px solid #DBDBDD", width: "100%" }} />
          </div>

          {/* {showAddEdit && */}
          <>
            <div className="col-md-12 pt-3">
              <TextField
                formik={formik}
                type="text"
                id="deliverableType"
                enabled={true}
                value={formik.values.deliverableType}
                placeholder="Add Deliverable Type*"
                name="deliverableType"
                required="required"
                numberCount={true}
                maxLength={30}
                characterLimitText={true}
              />
            </div>
            <hr className="bottomHr" />
            <div className="pt-3 bottomAction">
              <button
                type="button"
                className={`btn btn-outline-dark  mr-3`}
                onClick={() => {
                  toggleClick();
                }}
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className={`btn btn-primary ${btnDisabled ? " disabled " : " "}`} onClick={handleSave}>
                Save
              </button>
            </div>
          </>
          {/* } */}
        </div>
      </SidebarComponent>
    </div>
  );
};
SideBar.PropTypes ={
  showSideBar: PropTypes.bool,
  existingDeliverableTypes: PropTypes.array
}

export default SideBar;
