/*
================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Application constants
----------------------------------------------------------------
    Creation Details
    Date Created				: 26/Jun/2020
    Author						: YOGESH N. GUPTA
================================================================
*/

// constants
export const INITIAL_STATE = "initial_state";
export const GET_INITIAL_STATE = "get_initial_state";
export * from "./bo.constant";
// user roles (sorted by user_role_name)
export const USER_ROLES = [
  { user_role_name: "Expert", user_role_value: "expert" },
  { user_role_name: "Freelancer", user_role_value: "freelancer" },
  { user_role_name: "Processor", user_role_value: "processor" },
  { user_role_name: "Publisher", user_role_value: "publisher" },
  { user_role_name: "QC", user_role_value: "qc" },
  { user_role_name: "Sales Admin", user_role_value: "client_admin" },
  { user_role_name: "Screener", user_role_value: "screener" },
];
// ProductVariant
export const PRODUCT_VARIANT = {
  WHATNEXT: "WhatNext",
  INSIDER: "Insider",
  GENNX: "GenNx",
  CHEERSIN: "Cheersin",
  COGNITION: "Cognition",
};
// Education Year
export const EDUCATION_YEAR = ["1st Year", "2nd Year", "3rd Year", "4th Year"];
// Highest Education
export const HIGHEST_EDUCATION = ["Ph.D.", "Masters", "Graduate", "Under-Graduate"];
// Time Availability
export const TIME_AVAILABILITY = ["1-2 Hrs. Per Day", "2-4 Hrs. Per Day", "4-6 Hrs. Per Day", "6+ Hrs. Per Day"];
// Internet Connetion
export const INTERNET_SPEED = ["100", "100-200", "200-300", "300-500", "More than 500", "No Internet"];

// user empanelment list (sorted by value)
export const EMPANELMENT_LIST = ["FutureBridge", "Cognition", "WhatNext", "Support Function"];

// user gender list (sorted by value)
export const GENDER_LIST = ["Male", "Female", "Transgender"];

// user salutation list (sorted by value)
export const SALUTATION_LIST = ["Dr.", "Mr.", "Ms.", "Miss", "Mrs.", "Professor"];

// Currency
export const CURRENCY = ["USD", "EUR", "GBP", "INR"];

// user future-bridge relevancy (sorted by value)
export const FB_RELEVANCY = [
  "Article Writing",
  "Article/News Abstraction",
  "Basic Company Research",
  "Basic People Research",
  "Company Profile Creation",
  "Design & Aesthetics",
  "Expert Consultation",
  "Media Monitoring",
  "News Writing",
  "Primary Interviews",
  "Report Writing",
  "Statistical Analysis",
  "Technology Profile Creation",
  "Translation",
];

// company queue status
export const QUEUE_STATUS = {
  ALLOCATION: "Allocation",
  SCREENING_FRESH: "Screening - Fresh",
  SCREENING_REJECTED: "Screening - Rejected",
  SCREENING_HOLD: "Screening - Hold",
  PROCESSING_FRESH: "Processing - Fresh",
  PROCESSING_REWORK: "Processing - Rework",
  QC_ALLOCATION: "QC - Allocation",
  QC_FRESH: "QC - Fresh",
  QC_REJECTED: "QC - Rejected",
  QC_ACCEPTED: "QC - Complete",
  PUBLISHED: "Published",
};

export const COLLABORATION_TYPE = [
  {
    label: "Acquisition",
    value: "Acquisition",
  },
  {
    label: "Technology Co-development",
    value: "Technology Co-development",
  },
  {
    label: "Technology Adoption",
    value: "Technology Adoption",
  },
  {
    label: "Funding/Investment",
    value: "Funding/Investment",
  },
  {
    label: "Commercial Partnership",
    value: "Commercial Partnership",
  },
  {
    label: "In-Licensing",
    value: "In-Licensing",
  },
  {
    label: "Partnership/Joint Venture",
    value: "Partnership/Joint Venture",
  },
];

export const WORKINPROGESS_OPTIONS = [
  {
    label: "Yes",
    value: "YES",
  },
  {
    label: "No",
    value: "NO",
  },
];

export const IS_STARTUP = [
  {
    label: "Yes",
    value: "YES",
  },
  {
    label: "No",
    value: "NO",
  },
];

export const STATUS = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Acquired By",
    value: "Acquired By",
  },
  {
    label: "Non-Operational",
    value: "Non-Operational",
  },
];

export const ENTITY_TYPE = {
  DELIVERABLE: "deliverable",
  CONTENT_BLOCK: "content_block",
  NOTIFICATION: "notification",
};
// Max file size 90Mb
export const MAX_FILE_SIZE = 1024 * 1024 * 90;
//Supported files
export const SUPPORTED_FILES = ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx";
//Max file
export const MAX_FILE_NUMBER = 10;

export const SALT = import.meta.env.VITE_SALT || "Cheers1234";
export const PROJECT_MANAGEMENT_URL = import.meta.env.VITE_PROJECT_MANAGEMENT_URL || "";
export const NOTIFICATION_MANAGEMENT_URL = import.meta.env.VITE_NOTIFICATION_MANAGEMENT_URL || "http://localhost:3002";
export const SUBSCRIPTION_MANAGEMENT_URL = import.meta.env.VITE_SUBSCRIPTION_MANAGEMENT_URL || "http://localhost:5173";
export const PLATFORM_PERSONALIZATION_URL = import.meta.env.VITE_PLATFORM_PERSONALIZATION_URL || "http://localhost:5173";
export const CUSTOM_PROJECTS_URL = import.meta.env.VITE_CUSTOM_PROJECTS_URL || "http://localhost:5173";
export const DATA_MODEL_LIBRARY_URL = import.meta.env.VITE_DATA_MODEL_LIBRARY_URL || "http://localhost:5173";
export const VISUALIZATION_LIBRARY_URL = import.meta.env.VITE_VISUALIZATION_LIBRARY_URL || "http://localhost:5173";
export const TAXONOMY_MANAGEMENT_URL = import.meta.env.VITE_TAXONOMY_MANAGEMENT_URL || "http://localhost:5173";

export const ADMIN_USER = "Admin";

export const CUSTOM_DISPOSITION_NUMBER = 10;

export const SEARCH_TYPES = ["Company", "News", "Video", "Document"];

export const NEWS_TYPE = {
  NEWS: "News",
  ARTICLES: "Articles",
};

// Content Type Constants
export const CONTENT_TYPE = {
  DEVELOPMENTS_NEWS: "Developments & News",
  DASHBOARD: "Dashboard",
  SIGNALS: "Signals",
  PROFILES_COMPANIES_TECHNOLOGIES: "Profiles - Companies & Technologies",
  INSIGHTS_REPORTS_RESEARCH: "Insights, Reports & Research",
  WEBINARS_INTERVIEWS_PODCASTS: "Webinars, Interviews & Podcasts",
  TRAINING: "Training",
  CLIENT_BUSINESS_OBJECTIVE: "Client Business Objective",
};

/* Common access */
export const COMMON_ACCESS_ACTION = {
  LISTING: "Listing",
  ADD_NEW: "Add New",
  SAVE: "Save",
  RESET_PASSWORD: "Reset Password",
  DELETE: "Delete",
  IMPORT: "Import",
  EXPORT: "Export",
  IMPORT_FOR_REJECTION: "Import For Rejection",
  ALLOCATION: "Allocate",
  DEALLOCATION: "De-allocate",
  TAXONOMY_BULK_TAGGING: "Taxonomy Bulk Tagging",
  FILTER_BY_TAXONOMY: "Filter By Taxonomy",
  SUBMIT: "Submit",
  FINAL_SUBMIT: "Final Submit",
  SAVE_SUBMIT: "Save & Submit",
  PUBLISH: "Publish",
  ADD: "Add",
  IMPORT_PATENT: "Import Patent",
  REJECT: "Reject",
  APPROVE: "Approve",
  INACTIVE: "Inactive",
  REPORT_CREATOR: "Report Creator",
  REPORT_REVIEWER: "Report Reviewer",
  REPORT_PUBLISHER: "Report Publisher",
  REPORT_ADMINISTRATOR: "Report Administrator",
  EDIT: "Edit",
  VIEW: "View",
  SETTINGS: "Settings",
  DATA_UPLOAD: "Data Upload",
  SELECT: "Select",
  HOLD: "Hold",
  ADD_TAGS: "Add Tags",
  SAVE_OVERVIEW: "Save Overview",
  ADD_ADDRESS: "Add New Address",
  DELETE_ADDRESS: "Delete Address",
  EDIT_ADDRESS: "Edit Address",
  ADD_FUNDINGS: "Add New Fundings",
  DELETE_FUNDINGS: "Delete Fundings",
  EDIT_FUNDINGS: "Edit Fundings",
  ADD_REVENUE: "Add New Revenue",
  DELETE_REVENUE: "Delete Revenue",
  EDIT_REVENUE: "Edit Revenue",
  ADD_INVESTMENTS: "Add New Investments",
  DELETE_INVESTMENTS: "Delete Investments",
  EDIT_INVESTMENTS: "Edit Investments",
  ADD_DOCUMENTS: "Add New Documents",
  DELETE_DOCUMENTS: "Delete Documents",
  EDIT_DOCUMENTS: "Edit Documents",
  ADD_IMAGES: "Add New Images",
  ADD_COMPANY_IMAGES: "Add Images",
  DELETE_IMAGES: "Delete Images",
  ADD_VIDEOS: "Add New Videos",
  ADD_COMPANY_VIDEOS: "Add Videos",
  DELETE_VIDEOS: "Delete Videos",
  EDIT_VIDEOS: "Edit Videos",
  REMARKS: "Remarks",
  QC_COMPLETE: "QC Complete",
  SEND_BACK: "Send Back",
  SHOW_IN_PRODUCT: "Show In Product",
  PREVIEW: "Preview",
  ADD_QUESTION: "Add New Question",
  DELETE_QUESTION: "Delete Question",
  EDIT_QUESTION: "Edit Question",
  ADD_DOCUMENT: "Add New Document",
  DELETE_DOCUMENT: "Delete Document",
  EDIT_DOCUMENT: "Edit Document",
  ADD_HOST: "Add New Host",
  DELETE_HOST: "Delete Host",
  EDIT_HOST: "Edit Host",
  EXPORT_RESPONSE: "Export Response",
  SEND_INVITATION: "Send Invitation",
  EXPORT_AUDIENCE: "Export Audience",
  IMPORT_AUDIENCE: "Import Audience",
  ADD_VIDEO: "Add New Video",
  DELETE_VIDEO: "Delete Video",
  EDIT_VIDEO: "Edit Video",
  SAVE_SURVEY: "Save Survey",
  ADD_EXPERIENCE: "Add Experience",
  DELETE_EXPERIENCE: "Delete Experience",
  EDIT_EXPERIENCE: "Edit Experience",
  ADD_EDUCATION: "Add Education",
  DELETE_EDUCATION: "Delete Education",
  EDIT_EDUCATION: "Edit Education",
  SEARCH: "Search",
  EDIT_TASK: "Edit Task",
  EDIT_CONTENT: "Edit Content",
  MOVE: "Move",
  DOWNLOAD: "Download",
  SEND_CREDENTIALS: "Send Credentials",
  UPDATE: "Update",
  EDIT_PROFILE_IMAGE: "Edit Profile Image",
  DELETE_PROFILE_IMAGE: "Delete Profile Image",
  REACTIVATE: "Reactivate",
  SEND_LOGIN_DETAILS: "Send Login Details",
  ADD_ASSESSMENT: "Add Assessment",
};

/* Common access Actions for Admin */
export const COMMON_ACCESS_ACTION_ADMIN = [
  "Listing",
  "Add New",
  "Save",
  "Edit",
  "Settings",
  "Reset Password",
  "Delete",
  "Import",
  "Export",
  "Import For Rejection",
  "Allocate",
  "De-allocate",
  "Taxonomy Bulk Tagging",
  "Filter By Taxonomy",
  "Submit",
  "Final Submit",
  "Save & Submit",
  "Publish",
  "Add",
  "Import Patent",
  "Reject",
  "Approve",
  "Inactive",
  "Report Creator",
  "Report Publisher",
  "Report Reviewer",
  "Report Administrator",
  "Select",
  "Hold",
  "Add Tags",
  "Save Overview",
  "Add New Address",
  "Delete Address",
  "Edit Address",
  "Add New Fundings",
  "Delete Fundings",
  "Edit Fundings",
  "Add New Revenue",
  "Delete Revenue",
  "Edit Revenue",
  "Add New Investments",
  "Delete Investments",
  "Edit Investments",
  "Add New Documents",
  "Delete Documents",
  "Edit Documents",
  "Add New Images",
  "Add Images",
  "Delete Images",
  "Add New Videos",
  "Add Videos",
  "Delete Videos",
  "Edit Videos",
  "Remarks",
  "QC Complete",
  "Send Back",
  "Show In Product",
  "Preview",
  "Add New Question",
  "Delete Question",
  "Edit Question",
  "Add New Document",
  "Delete Document",
  "Edit Document",
  "Add New Host",
  "Delete Host",
  "Edit Host",
  "Export Response",
  "Send Invitation",
  "Export Audience",
  "Import Audience",
  "Add New Video",
  "Delete Video",
  "Edit Video",
  "Save Survey",
  "Add Experience",
  "Edit Experience",
  "Delete Experience",
  "Add Education",
  "Edit Education",
  "Delete Education",
  "Search",
  "Edit Task",
  "Edit Content",
  "Move",
  "Download",
  "Send Credentials",
  "Update",
  "Edit Profile Image",
  "Delete Profile Image",
  "Reactivate",
  "Send Login Details",
  "Add Assessment",
];

export const BOREPORTDEFAULTDATERANGE = 179;
export const DATE_RANGE_MAX_DAYS = 179;
export const ANALYTICS_REPORTS_DEFAULT_DATE_RANGE = 179;
export const PUBLICATION_STATUS_REPORT_DATE_RANGE_MAX = 365;
export const KEYWORD_SEARCH_REPORT_DATE_RANGE_MAX = 365;
export const DEFAULTANALYSTREPORTPAGEVIEW = 50;
export const CLIENT_DETAILS_DATE_RANGE = 365;

// Deliverable Type Constants
export const DELIVERABLE_TYPE = {
  DASHBOARD: "Dashboard",
  SIGNALS: "Signals",
  PROFILES_COMPANIES_TECHNOLOGIES: "Profiles - Companies & Technologies",
  DEVELOPMENTS_NEWS: "Developments & News",
  INSIGHTS_REPORTS_RESEARCH: "Insights, Reports & Research",
  WEBINARS_INTERVIEWS_PODCASTS: "Webinars, Interviews & Podcasts",
  CLIENT_BUSINESS_OBJECTIVE: "Client Business Objective",
  TRAINING: "Training",
};

export const CONTENT_CATEGORY_FOR_WIDGET = [
  { text: "Newly Added", value: "Newly Added" },
  { text: "Recently Updated", value: "Recently Updated" },
];

export const ROLES = {
  EXPERT: "expert",
};
