/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
File Description : ImageList component
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 07/Dec/2023 
	Author						  : Lawrence Anthony
================================================================ 
*/

import React, { useState, useEffect } from "react";
import { fetchImageURL } from "../../../../../middleware/services/companyApi";
import PropTypes from 'prop-types';

const ImageItem = ({ image, setSelectedImage, selectedImage }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelect = () => {
    // Unselect any previously selected image
    setSelectedImage(null);

    // Select the current image
    setIsSelected(true);
    setSelectedImage(image.url); // Pass the image identifier to the parent component
  };

  useEffect(() => {
    // Check if the current image is selected based on the prop
    setIsSelected(selectedImage === image.url);
  }, [selectedImage, image.url]);

  return (
    <div style={{ display: "inline-block", margin: "10px" }}>
      <img
        src={image.cdnUrl}
        alt={image.title}
        onClick={handleSelect}
        style={{ cursor: "pointer", border: isSelected ? "2px solid red" : "2px solid transparent" }}
      />
    </div>
  );
};

const ImageList = ({ images, selectedImage, setSelectedImage }) => {
  return (
    <div className="gennx-content-wrapper" style={{ height: "calc(100vh - 532px)" }}>
      <div>
        {images.map((image) => (
          <ImageItem key={image.id} image={image} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
        ))}
      </div>
    </div>
  );
};

ImageList.propTypes = {
  images: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    map: PropTypes.string
   }),
   setSelectedImage: PropTypes.func.isRequired,
   selectedImage: PropTypes.shape({
    url: PropTypes.string,
  }),
};

ImageItem.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
   }),
   setSelectedImage: PropTypes.func.isRequired,
   selectedImage: PropTypes.shape({
    url: PropTypes.string,
  }),
 };
export default ImageList;
