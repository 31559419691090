/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 14/May/2021
 * File Description: Add/Edit Video Details
 */

import React, { useState, useEffect } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { useDispatch } from "react-redux";
import Accordion from "../../../../components/accordion";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getProductVariant } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { getQuestions, addQuestion, editQuestion, deleteQuestion, fetchSingleVideo } from "../../../../../middleware/services/videoApi";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import PopupModal from "./questionModal";
import _ from "underscore";
import { useFormik } from "formik";
import { fetchExternalUser } from "../../../../../middleware/services/userApi";
import { COMMON_ACCESS_ACTION } from "../../../../../constants";
import "../css/custom.scss";
import PropTypes from 'prop-types';


const RelatedQuestions = (props) => {
  const dispatch = useDispatch();
  const [videoExpertData, setVideoExpertData] = useState([]);
  const [videoExpertDataSource, setVideoExpertDataSource] = useState([]);
  const [checked, setChecked] = useState(undefined);
  const videoId = props.videoId || "";
  const [questionsData, setQuestionsData] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const [componentReload, setComponentReload] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const productVariant = getProductVariant();
  const [editData, setEditData] = useState(null);

  const breadCrumbLinks = [
    { linkUrl: "/video", linkName: "Video", linkActive: false },
    { linkUrl: videoId ? `/video/${videoId}/edit` : "/video", linkName: (editData && editData?.videoTitle) || "Add New", linkActive: false },
    {
      linkUrl: `/video/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  let actionAccessAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_QUESTION);
  let actionAccessEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_QUESTION);
  let actionAccessDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_QUESTION);

  useEffect(() => {
    if (videoId) {
      dispatch(showLoader());
      !editData && fetchVideoDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, editData, props.selectedIndex]);

  const fetchVideoDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "videoTitle",
      "videoContent",
      "videos",
      "videoProfileImage",
      "videoStatus",
      "videoType",
      "videoDuration",
      "videoAccessType",
      "videoUser",
      "videoRegistrationLink",
      "timezone",
      "date",
      "time",
      "videoCommercialImpactTime",
      "videoAreaOfImpact",
      "videoSpecificTechnology",
      "videoTypeOfOrganizationAcademic",
      "videoTypeOfOrganizationResearch",
      "videoTypeOfOrganizationCompany",
      "videoCustomTags",
      "videoExpert",
      "productVariant",
      "surveyAudience",
      "videoIndustryTag",
      "videoIndustryTrendsTags",
      "videoTimezones",
      "videoSource",
      "videoSourceUrl",
      "videoSourceEndUrl",
    ];

    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleVideo(videoId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);

      if (data?.videoExpert) {
        const userData = data?.videoExpert?.map((v) => {
          return v.userId;
        });
        userData && userData.length && searchUserByIds(userData);
      }
      data.videoAccessType && setChecked(data.videoAccessType === "Generic" ? false : true);
      formik.setValues({
        videoTitle: data.videoTitle,
        videoDescription: data.videoContent,
        vimeoVideoId: data.videos.videoId,
        videoThumbnail: data.videos.videoThumbnail || "",
        videoSource: data.videos.videoSource,
        status: data.videoStatus,
        videoType: data.videoType,
        commercialImpact: data.videoCommercialImpactTime,
        videoAreaOfImpact: data.videoAreaOfImpact,
        specificTechnology: data.videoSpecificTechnology || [],
        typeOfOraganizationAcademic: data.videoTypeOfOrganizationAcademic || [],
        typeOfOraganizationResearch: data.videoTypeOfOrganizationResearch || [],
        typeOfOraganizationCompany: data.videoTypeOfOrganizationCompany || [],
        customTag: data.videoCustomTags || [],
        videoDurationInMinute: data.videoDuration,
        restrictContentForClient: data.videoAccessType && data.videoAccessType === "Generic" ? "NO" : "YES",
        videoRegistrationLink: data.videoRegistrationLink || "",
        whatNextVideoSource: data.videoSource || "",
        whatNextSourceUrl: data.videoSourceUrl || "",
        whatNextSourceEmbedUrl: data.videoSourceEmbedUrl || "",

        timezone1: (data.videoTimezones && data.videoTimezones[0]) || {},
        timezone2: (data.videoTimezones && data.videoTimezones[1]) || {},
        timezone3: (data.videoTimezones && data.videoTimezones[2]) || {},
      });
      if (productVariant === "Insider") {
        formik.setFieldValue("clientUsers", data.videoUser && _.pluck(data.videoUser, "userId"));
        // formik.setFieldValue("selIndustry", (data.videoIndustryTag && data.videoIndustryTag[0].industryId) || "");
        // formik.setFieldValue("selTrends", data.videoIndustryTrendsTags && _.pluck(data.videoIndustryTrendsTags, "trendId"));
      }

      dispatch(hideLoader());
    });
  };
  const searchUserByIds = async (ids) => {
    const response = await fetchExternalUser({
      fields: ["userFname", "userLname", "userEmail"],
      filters: [["_id", "in", ids]],
      sort: "userFname:asc",
      limit: 10,
    });
    if (response?.data && response?.data?.data) {
      setVideoExpertData(response?.data?.data);
      const resData = response.data.data.map((v) => {
        return { label: `${v.userFname} ${v.userLname} (${v.userEmail})`, value: v.id };
      });
      if (resData) {
        setVideoExpertDataSource(resData);
        formik.setFieldValue(
          "videoExpert",
          resData.map((v) => {
            return v.value;
          })
        );
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    // initialValues: { ...initialState },
    // validationSchema: Yup.object().shape(videoDetailsValidationSchema)
  });

  const saveQuestion = async (payload) => {
    await addQuestion(videoId, payload)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Question saved successfully"));
          fetchAllQuestions();
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "something went wrong"));
      });
  };
  const updateQuestion = async (questionId, payload) => {
    await editQuestion(videoId, payload, questionId)
      .then((res) => {
        if (res) {
          dispatch(actionSuccess("Question updated successfully"));
          fetchAllQuestions();
        } else {
          dispatch(actionError("Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "something went wrong"));
      });
  };

  const fetchAllQuestions = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["question", "answer"];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    getQuestions(videoId, params).then((res) => {
      let data = res.data.data || {};
      data && setQuestionsData(data);
      dispatch(hideLoader());
    });
  };
  useEffect(() => {
    if (videoId) {
      dispatch(showLoader());
      if (!questionsData) fetchAllQuestions();
      questionsData && dispatch(hideLoader());
    }
  }, [dispatch, questionsData, componentReload]);
  return (
    <>
      <div className=" gennx-content-wrapper padding-bottom-100i pt-3" style={{ width: "99.5%" }}>
        <div className="mx-2">
          {actionAccessAdd && questionsData && questionsData.videoQuestion.length > 0 && (
            <div className=" pr-3 pb-5 mb-2">
              <ButtonComponent
                cssClass="btn btn-dark text-capitalize float-right"
                style={{ "margin-right": "-0.8em", height: "40px" }}
                onClick={() => {
                  setQuestionId(null);
                  setIsOpen(true);
                }}
              >
                Add New Question
              </ButtonComponent>
            </div>
          )}
          {questionsData &&
            questionsData.videoQuestion.length > 0 &&
            questionsData.videoQuestion.map((d, i) => {
              return (
                <React.Fragment key={d?.id}>
                  <Accordion step={i + 1} heading={d.question} activeState={true} stepCompleted={true}>
                    <div className="row p-3">
                      <div className="col-md-12  rounded-bottom">
                        <div className="row">
                          <div className="col-lg-12 nopadding">
                            <p>{d.answer}</p>
                            <br />

                            {actionAccessDelete && (
                              <button
                                className="btn btn-light mr-3 float-right cy-btn"
                                onClick={() => {
                                  dispatch(
                                    showAlertBox({
                                      okCallback: async () => {
                                        deleteQuestion(videoId, d.id)
                                          .then((res) => {
                                            if (res) {
                                              dispatch(actionSuccess("Question deleted successfully"));
                                              fetchAllQuestions();
                                            } else {
                                              dispatch(actionError("Something went wrong"));
                                            }
                                          })
                                          .catch((err) => {
                                            dispatch(actionError(err?.data?.message || "Failed to delete video"));
                                          });
                                      },
                                      content: "Are you sure you want to delete?",
                                      okText: "Delete",
                                      cancelText: "Cancel",
                                      title: "dialogAlertCssWarning",
                                    })
                                  );
                                }}
                              >
                                Delete
                              </button>
                            )}
                            {actionAccessEdit && (
                              <button
                                className="btn btn-light mr-3 float-righte float-right mb-2 ml-3 cy-btn"
                                onClick={() => {
                                  setQuestionId(d.id);
                                  setIsOpen(true);
                                }}
                              >
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </React.Fragment>
              );
            })}

          {questionsData && questionsData.videoQuestion.length === 0 && (
            <div className="e-content pt-4">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="text-muted text-center">You don't have any items here.</h5>
                    <div className="text-center">
                      {actionAccessAdd && (
                        <ButtonComponent
                          cssClass="btn btn-dark text-capitalize"
                          style={{ height: "40px" }}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          Add New Question
                        </ButtonComponent>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <PopupModal
          onSaveData={saveQuestion}
          onUpdateData={updateQuestion}
          questionId={questionId}
          productVariant={productVariant}
          closeHandler={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          videoId={videoId}
          popupTitle={questionId ? "Edit Question" : "Add Question"}
        />
      )}
    </>
  );
};
RelatedQuestions.propTypes={
  videoId: PropTypes.number,
  interfaceActionAccess: PropTypes.string,
  selectedIndex: PropTypes.string,
};

export default RelatedQuestions;
