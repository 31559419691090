/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase Container
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import classes from "./myBriefcase.module.css";
import BriefPopUp from "../../components/briefPopup";
import EmptyData from "../../components/emptyData/EmptyData";
import DraggableCard from "./DraggableCard";
import BriefcaseCard from "./BriefcaseCards";
import "./container.css";

const ContainerCard = (props) => {
  const scrollRef = useRef(null);
  const [cards, setCards] = useState([]);
  const [briefcaseCards, setBriefcaseCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState();
  const handleModalShow = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setCards(props.categoryBriefcase);
    setBriefcaseCards(props.categoryBriefcase);
  }, [props.categoryBriefcase]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      // Update local state
      setCards((prevCards) => {
        const updatedCards = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });
        return updatedCards;
      });
    },
    [cards, setCards]
  );

  useEffect(() => {
    setTimeout(() => {
      const rowsClass = document.querySelectorAll(".row");
      if (rowsClass.length > 0) {
        rowsClass.forEach((row) => {
          if (!row) return;
          row.style.marginLeft = "0px";
          row.style.marginRight = "0px";
        });
      }
      const embeded = document.querySelectorAll(".embed-responsive");
      if (embeded.length > 0) {
        embeded.forEach((embeded) => {
          if (embeded) embeded.style.height = "42vh";
        });
      }
      const embededImgs = document.querySelectorAll(".embed-responsive img");
      if (embededImgs.length > 0) {
        embededImgs.forEach((embeded) => {
          if (embeded) embeded.style.display = "none";
        });
      }
    }, 100);
  }, [content]);

  // auto scroll function to make the selected item appear at the top of its parent container
  useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, 500);
  }, [content, scrollRef]);

  return (
    <>
      {/* {props?.briefcaseSortValue === "SortBySequence" && cards?.length > 0 && ( */}
      {props?.briefcaseFilterValue === "All" && cards?.length > 0 && props?.showDragAndDrop && (
        <div style={{ width: "100%" }} className={classes.bookMarkFiltersHeader}>
          {cards.map((card, i) => {
            return (
              <DraggableCard
                key={card.id}
                index={i}
                id={card.id}
                itemCard={card}
                cards={cards}
                briefcaseCards={briefcaseCards}
                moveCard={moveCard}
                handleModalShow={handleModalShow}
                setContent={setContent}
                {...props}
              />
            );
          })}
        </div>
      )}
      {/* {props?.briefcaseSortValue !== "SortBySequence" && cards?.length > 0 && ( */}
      {((props?.briefcaseFilterValue !== "All" && cards?.length > 0) || !props?.showDragAndDrop) && (
        <div style={{ width: "100%" }} className={classes.bookMarkFiltersHeader}>
          {cards.map((card, i) => {
            return (
              <BriefcaseCard
                key={card.id}
                itemCard={card}
                cards={cards}
                briefcaseCards={briefcaseCards}
                handleModalShow={handleModalShow}
                setContent={setContent}
                {...props}
              />
            );
          })}
        </div>
      )}
      {!props?.lodingBriefcase && !cards?.length && <EmptyData msg="No briefcase found for the selected content type." />}
      {showModal && (
        <BriefPopUp
          open={showModal}
          containerStyle={{
            maxHeight: "84vh",
            width: "100vw",
            paddingTop: "0",
            paddingBottom: "0",
            marginBottom: "20px",
            marginTop: "4rem",
          }}
          content={<div style={{ width: "100%", marginRight: "1.5rem", marginTop: "10px" }}></div>}
          customHeader={
            <div style={{ width: "100%" }}>
              <div
                style={{
                  borderBottom: "1px solid #E3E3E3",
                  paddingTop: "0.5rem",
                  paddingLeft: "0.75rem",
                  paddingRight: "0.75rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "black",
                    marginBottom: "0px",
                  }}
                >
                  {content?.presentationTitle}
                </p>
                <div
                  style={{ cursor: "pointer", fontSize: "20px", color: "black", marginRight: "10px" }}
                  onClick={() => {
                    setShowModal(false);
                    setContent();
                  }}
                >
                  <img src="/assets/cross.svg" alt="cross" width={18} />
                </div>
              </div>
              <div style={{ padding: "0.1rem" }}>
                <div
                  ref={scrollRef}
                  className={classes.presentationHtmlBox}
                  dangerouslySetInnerHTML={{ __html: content?.presentationEntityPageHtmlContent }}
                ></div>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default ContainerCard;
