/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description            :  Company Advanced info Action Buttons
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 12/Dec/2023 
    Author						  : Lawrence Anthony
================================================================ 
*/

import React, { useEffect } from "react";
import { processingSubmit } from "../../../../../middleware/services/cmsApi";
import { Link, useHistory } from "react-router-dom";
import { getLoggedInUser } from "../../../../../utilities";
import { actionSuccess, actionError } from "../../../../../middleware/actions/utilityAction";
import { setClickSaveSubmitState, setOverviewUpdateStatus } from "../../../../../middleware/actions/companyAction";
import { useDispatch, useSelector } from "react-redux";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import PropTypes from 'prop-types';


const ActionButtons = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = getLoggedInUser();
  const companyId = props.companyId;

  const cancelPath = props.back;
  const updateStatus = useSelector((state) => state.companyState.updateStatus);

  useEffect(() => {
    if (updateStatus) {
      dispatch(
        showAlertBox({
          okCallback: async () => {
            processingSubmit(companyId, "advanced", {
              userId: loggedInUser._id,
            })
              .then((response) => {
                dispatch(actionSuccess(`Company successfully Saved & Submitted`));
                dispatch(setClickSaveSubmitState());
                dispatch(setOverviewUpdateStatus(false));
              })
              .catch((err) => {
                console.log(err.data.message);
                dispatch(actionError(err.data.message || "Something went wrong"));
              });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to submit ? ",
          title: "dialogAlertCss",
        })
      );
    }
  }, [updateStatus]);

  return (
    <div className="form-submit-box" style={{ padding: "10px 20px 10px 20px" }}>
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-LEFT col-md-6 text-left">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="pl-4" style={{ marginLeft: "-3em" }}>
                <span className="span-link">
                  <button
                    type="button"
                    className="btn btn-outline-dark pl-4 pr-4 cy-btn "
                    onClick={() => {
                      (props.selectedIndex === undefined && props.setCurrentTab(4)) ||
                        (props.selectedIndex < 6 && props.setCurrentTab(props.selectedIndex - 1));
                    }}
                  >
                    Previous
                  </button>
                </span>
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-6 text-right ">
            <Link to={cancelPath}>
              <button
                type="button"
                className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3"
                onClick={() => {
                  history.push(`${cancelPath}`);
                }}
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
ActionButtons.propTypes = {
  companyId: PropTypes.string,
  selectedIndex: PropTypes.number,
  setCurrentTab: PropTypes.func,
  back: PropTypes.string
};
export default ActionButtons;
