/**
 * @author: Satya Prakash Mall | Cheers Interactive
 * @date : 12/June/2024
 * File Description: Add/Edit Video Details
 */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TreeView from "../../../../../components/treeView/taxonomy";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import { setBreadCrumb } from "../../../../../../middleware/actions/breadCrumbAction";
import {
  fetchVideoTaxonomy,
  saveVideoTaxonomy,
  deleteSigleVideoTaxonomy,
  fetchSingleVideo,
  fetchVideoKia,
  deleteVideoKia,
  saveVideoKiaTaxonomy,
  deleteManyVideoTechnology,
} from "../../../../../../middleware/services/videoApi";
import { setSideNavForcedActiveLink } from "../../../../../../middleware/actions/sideNavAction";
import _ from "underscore";
import { useFormik } from "formik";
import { fetchExternalUser } from "../../../../../../middleware/services/userApi";
import { getProductVariant } from "../../../../../../utilities";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";
import PropTypes from 'prop-types';


const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const videoId = props.videoId;
  const [videoExpertData, setVideoExpertData] = useState([]);
  const [videoExpertDataSource, setVideoExpertDataSource] = useState([]);
  const [checked, setChecked] = useState(undefined);
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [saveTreeDataAction, setSaveTreeDataAction] = useState(false);
  const [editData, setEditData] = useState(null);
  const productVariant = getProductVariant();

  const breadCrumbLinks = [
    { linkUrl: "/video", linkName: "Video", linkActive: false },
    { linkUrl: videoId ? `/video/${videoId}/edit` : "/video", linkName: (editData && editData?.videoTitle) || "Add New", linkActive: false },
    {
      linkUrl: `/video/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  /* Common access */
  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  useEffect(() => {
    if (videoId) {
      dispatch(showLoader());
      !editData && fetchVideoDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, editData, props.selectedIndex]);
  const fetchVideoDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = [
      "videoTitle",
      "videoContent",
      "videos",
      "videoProfileImage",
      "videoStatus",
      "videoType",
      "videoDuration",
      "videoAccessType",
      "videoUser",
      "videoRegistrationLink",
      "timezone",
      "date",
      "time",
      "videoCommercialImpactTime",
      "videoAreaOfImpact",
      "videoSpecificTechnology",
      "videoTypeOfOrganizationAcademic",
      "videoTypeOfOrganizationResearch",
      "videoTypeOfOrganizationCompany",
      "videoCustomTags",
      "videoExpert",
      "productVariant",
      "surveyAudience",
      "videoIndustryTag",
      "videoIndustryTrendsTags",
      "videoTimezones",
      "videoSource",
      "videoSourceUrl",
      "videoSourceEndUrl",
    ];

    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleVideo(videoId, params).then((res) => {
      let data = res.data.data || {};
      setEditData(data);

      if (data?.videoExpert) {
        const userData = data?.videoExpert?.map((v) => {
          return v.userId;
        });
        userData && userData.length && searchUserByIds(userData);
      }
      data.videoAccessType && setChecked(data.videoAccessType === "Generic" ? false : true);
      formik.setValues({
        videoTitle: data.videoTitle,
        videoDescription: data.videoContent,
        vimeoVideoId: data.videos.videoId,
        videoThumbnail: data.videos.videoThumbnail || "",
        videoSource: data.videos.videoSource,
        status: data.videoStatus,
        videoType: data.videoType,
        commercialImpact: data.videoCommercialImpactTime,
        videoAreaOfImpact: data.videoAreaOfImpact,
        specificTechnology: data.videoSpecificTechnology || [],
        typeOfOraganizationAcademic: data.videoTypeOfOrganizationAcademic || [],
        typeOfOraganizationResearch: data.videoTypeOfOrganizationResearch || [],
        typeOfOraganizationCompany: data.videoTypeOfOrganizationCompany || [],
        customTag: data.videoCustomTags || [],
        videoDurationInMinute: data.videoDuration,
        restrictContentForClient: data.videoAccessType && data.videoAccessType === "Generic" ? "NO" : "YES",
        videoRegistrationLink: data.videoRegistrationLink || "",
        whatNextVideoSource: data.videoSource || "",
        whatNextSourceUrl: data.videoSourceUrl || "",
        whatNextSourceEmbedUrl: data.videoSourceEmbedUrl || "",

        timezone1: (data.videoTimezones && data.videoTimezones[0]) || {},
        timezone2: (data.videoTimezones && data.videoTimezones[1]) || {},
        timezone3: (data.videoTimezones && data.videoTimezones[2]) || {},
      });
      if (productVariant === "Insider") {
        formik.setFieldValue("clientUsers", data.videoUser && _.pluck(data.videoUser, "userId"));
        // formik.setFieldValue("selIndustry", (data.videoIndustryTag && data.videoIndustryTag[0].industryId) || "");
        // formik.setFieldValue("selTrends", data.videoIndustryTrendsTags && _.pluck(data.videoIndustryTrendsTags, "trendId"));
      }

      dispatch(hideLoader());
    });
  };
  const searchUserByIds = async (ids) => {
    const response = await fetchExternalUser({
      fields: ["userFname", "userLname", "userEmail"],
      filters: [["_id", "in", ids]],
      sort: "userFname:asc",
      limit: 10,
    });
    if (response?.data && response?.data?.data) {
      setVideoExpertData(response?.data?.data);
      const resData = response.data.data.map((v) => {
        return { label: `${v.userFname} ${v.userLname} (${v.userEmail})`, value: v.id };
      });
      if (resData) {
        setVideoExpertDataSource(resData);
        formik.setFieldValue(
          "videoExpert",
          resData.map((v) => {
            return v.value;
          })
        );
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    // initialValues: { ...initialState },
    // validationSchema: Yup.object().shape(videoDetailsValidationSchema)
  });
  const handleSave = (selectedTech, checkValues, tab, callback, kiaFlag) => {
    if (tab === 0) {
      saveTaxonomy(selectedTech, checkValues, callback);
    }
  };

  useEffect(() => {
    props.saveTaxonomy && setSaveTreeDataAction("save");
  }, [props.saveTaxonomy]);

  useEffect(() => {
    if (saveTreeDataAction) {
      setSaveTreeData(true);
    }
  }, [saveTreeDataAction]);
  const saveTaxonomy = (selectedTech, taxonomyIds, callback) => {
    if (selectedTech) {
      deleteSigleVideoTaxonomy(videoId, selectedTech)
        .then(async () => {
          if (taxonomyIds.length > 0) {
            try {
              await saveVideoTaxonomy(videoId, taxonomyIds)
                .then((res) => {
                  if (res && res.status === 200) {
                    callback();
                    dispatch(actionSuccess("Taxonomy Updated Successfully"));
                    props.resetFlag();
                  }
                })
                .catch((e) => {
                  dispatch(actionError("Something Went Wrong...!"));
                  props.resetFlag();
                });
            } catch (e) {
              dispatch(actionError("Something Went Wrong...!"));
              props.resetFlag();
            }
          } else {
            dispatch(actionError("Please select taxonomy"));
            props.resetFlag();
            setSaveTreeData(false);
            setSaveTreeDataAction(false);
          }
        })
        .catch((e) => {
          props.resetFlag();
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };

  /*
@param selectedIndustry : Selected Industry to save their respective kia
@param kiaIds : Selected KiaIds
@param callback : use for re-render tree component

@description : Save kia for respective industry
            1.Delete all kia for selected industry
            2.Save all kia for selected industry
*/
  const saveKiaTagging = (selectedIndustry, kiaIds, tab, callback) => {
    if (selectedIndustry) {
      dispatch(showLoader());
      deleteVideoKia(videoId, { industryId: selectedIndustry })
        .then(() => {
          dispatch(hideLoader());
          if (kiaIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveVideoKiaTaxonomy(videoId, { taxonomyIds: kiaIds });
              response
                .then((response) => {
                  callback();
                  dispatch(hideLoader());
                  dispatch(actionSuccess("KIA Updated Successfully"));
                  props.resetFlag();
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...1!"));
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...2!"));
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...3!"));
        });
    }
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };

  const fetchTagging = () => {
    fetchVideoTaxonomy(videoId).then((res) => {});
  };

  const deleteTechnology = (checkedTrends, callback) => {
    deleteManyVideoTechnology(videoId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  const deleteKia = (checkedTrends, callback) => {
    let payload = { taxonomyIds: checkedTrends };
    deleteVideoKia(videoId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  useEffect(() => {
    fetchTagging();
  }, []);
  return (
    <TreeView
      handleSave={saveTaxonomy}
      saveTreeData={saveTreeData}
      contentId={videoId}
      fetchContentTagging={fetchVideoTaxonomy}
      fetchContentData={fetchSingleVideo}
      loadOtherTagInfo={props.loadOtherTagInfo}
      //handleSaveValueChain={handleSaveValueChain}
      fetchKiaTagging={fetchVideoKia}
      showValueChainButton={false}
      contentType="video"
      handleSaveKIA={saveKiaTagging}
      deleteTechnology={deleteTechnology}
      isDelete={true}
      setSaveTreeData={setSaveTreeData}
      deleteKia={deleteKia}
      accessPermissionTaxonomyDelete={accessActionDelete}
      accessPermissionTaxonomy={accessActionAdd}
    />
  );
};
TaxomonyUI.propTypes={
  resetFlag: PropTypes.object,
  videoId: PropTypes.number,
  interfaceActionAccess: PropTypes.string,
  selectedIndex: PropTypes.string,
  saveTaxonomy: PropTypes.func,
  loadOtherTagInfo: PropTypes.object
};
export default TaxomonyUI;
