import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { actionStart, actionError, actionSuccess } from "../../../../../../middleware/actions/utilityAction";
import { fetchProductCollaborationsByProductId, deleteProductCollaboration } from "../../../../../../middleware/services/productApi";
import LicensingModal from "./licensingModal";
import ThreeDotMenu from "../../../../../components/threeDotMenu";
import { accessRightActionCheck } from "../../../../../../utilities";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import ReactTooltip from "react-tooltip";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const LicensingCoDevelopment = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const [collaborationListing, setCollaborationListing] = useState("");
  const [collaborationId, setCollaborationId] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [editCollaborationData, setEditCollaborationData] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = "Licensing/Co-Development";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  /* Common access */
  const accessPermissionEdit = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessPermissionDelete = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);

  const handleDelete = (id) => {
    dispatch(actionStart());
    deleteProductCollaboration(props.productId, id)
      .then((res) => {
        fetchProductCollaborations();
        dispatch(actionSuccess("Deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Deletion Failed"));
      });
  };
  const productLicenseNameTemplate = (value) => {
    const stringValue = value.map((e) => e.companyName).join(", ");
    return (
      <>
        <span data-tip={stringValue.substring(140, stringValue.length - 1)} style={{ cursor: stringValue.length > 100 ? "pointer" : "" }}>
          {stringValue.length > 140 ? stringValue.substring(0, 100) + " ..." : stringValue}
        </span>
        {stringValue.length > 40 && <ReactTooltip />}
      </>
    );
  };

  const fetchProductCollaborations = () => {
    const response = fetchProductCollaborationsByProductId(props.productId);
    response.then((response2) => {
      if (response2.status === 200) {
        const { data: collabData = [] } = response2.data;
        const tableData =
          collabData &&
          collabData?.map((data, i) => {
            const { id, collaborationCompany, collaborationDate, collaborationType } = data;
            return (
              <React.Fragment key={id}>
                <tr>
                  <td>{productLicenseNameTemplate(collaborationCompany)}</td>
                  <td className="text-center" style={{ width: "20%" }}>
                    {moment(collaborationDate).format("YYYY")}
                  </td>
                  <td style={{ width: "20%" }}>{collaborationType}</td>
                  <td className="text-center">
                    {accessPermissionEdit || accessPermissionDelete ? (
                      <ThreeDotMenu
                        rowID={id}
                        Edit={
                          accessPermissionEdit
                            ? () => {
                                setCollaborationId(id);
                                setShowModal(true);
                                setEditCollaborationData(true);
                              }
                            : undefined
                        }
                        Delete={
                          accessPermissionDelete
                            ? () => {
                                dispatch(showAlertBox({ okCallback: handleDelete, data: id }));
                              }
                            : undefined
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              </React.Fragment>
            );
          });
        setCollaborationListing(tableData);
      }
    });
  };

  useEffect(() => {
    fetchProductCollaborations();
  }, []);
  useEffect(() => {
    if (props.refreshState) {
      fetchProductCollaborations();
      props.refreshStateFunc();
    }
  }, [props.refreshState]);
  return (
    <div className="px-3">
      <div className="table-responsive  mt-2 mb-1" style={{ height: window.innerWidth < 1500 ? "55vh" : "60vh" }}>
        <table className="normal-table table table-bordered table-internal-product">
          <thead>
            <tr>
              <th>Name of the Organization</th>
              <th className="text-center">Year of Engagement</th>
              <th>Nature of Relationship</th>
              <th className="text-center internal-grid-action">Action</th>
            </tr>
          </thead>
          <tbody>
            {(collaborationListing.length > 0 && collaborationListing) || (
              <tr>
                <td colSpan="4" className="text-center text-secondary">
                  You have not added any Licensing/Co-Development information for this product.
                  <br />
                  Please click on "Add New" button to add.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showModal && (
        <LicensingModal
          clicked={() => {
            setShowModal(!showModal);
          }}
          showModal={showModal}
          editCollaborationData={editCollaborationData}
          productId={props.productId}
          collaborationId={collaborationId}
          callbackFunc={fetchProductCollaborations}
        />
      )}
    </div>
  );
};

export default LicensingCoDevelopment;

LicensingCoDevelopment.propTypes = {
  productId: PropTypes.string.isRequired,
  refreshState: PropTypes.bool,
  refreshStateFunc: PropTypes.func,
  accessRights: PropTypes.arrayOf(PropTypes.string), 
};
