/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : Company Overview data source Elements and validation 
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 08/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React from "react";
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import PropTypes from 'prop-types';

export const DataSource = (props) => {
    const displayNone = (props.properties.dataSourceCount < 2 || props.properties.id === '' || props.properties.value === '') ? {
        display: 'none'
    } : {
        top: "0",
        left: "0",
        height: "20px",
        width: "25px",
        paddingLeft: "5px",
    };

    return (
        <div className="d-flex ml-2" >
            {displayNone.display !== "none" && <div className="radhio" style={displayNone}>
                <RadioButtonComponent label="" className="e-primary custom_button ml-2 mt-2" id={props.properties.id} value={props.properties.value} name={props.properties.name} />
            </div>}

            {displayNone.display === "none" && <input type="hidden" id={props.properties.id} value={props.properties.value} name={props.properties.name} />}
            <label className="mt-3" style={{ wordWrap: "break-word", textAlign: "left", paddingLeft: "20px" }} htmlFor={props.properties.id}>{props.properties.label}</label>
            {/* </div> */}
        </div>
    )
}
DataSource.propTypes = {
    properties: PropTypes.object, 
};
export const DataSourcebutton = (props) => {

    return (
        <div>
            <input className="form-control ml-2" id={props.properties.id} value={props.properties.value} name={props.properties.name} type="radio" />
        </div>
    )
}
DataSourcebutton.propTypes = {
    properties: PropTypes.object, 
};


