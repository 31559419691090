import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TreeView from "../../../../../components/treeView/taxonomy";
import { getLoggedInUser } from "../../../../../../utilities";
import { actionSuccess, showLoader, hideLoader, actionError, showMessage } from "../../../../../../middleware/actions/utilityAction";
import {
  fetchProductTaxonomy,
  saveProductTaxonomy,
  deleteProductTaxonomy,
  fetchSingleProduct,
  saveProductValueChain,
  fetchProductKia,
  deleteProductKia,
  saveProductKiaTaxonomy,
  deleteManyProductTechnology,
  deleteManyProductValueChain,
} from "../../../../../../middleware/services/productApi";
import { processingSubmitProduct } from "../../../../../../middleware/services/cmsApi";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import "../../../../../components/popup/index.scss";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";
import PropTypes from 'prop-types';


const TaxomonyUI = (props) => {
  const [clickSaveSubmit, setClickSaveSubmit] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);

  /* Common access */
  const accessActionAdd = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionSubmit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SUBMIT);

  const history = useHistory();
  const dispatch = useDispatch();
  const productId = props.productId;
  const loggedInUser = getLoggedInUser();
  const [saveTreeData, setSaveTreeData] = useState(false);
  const [saveTreeDataAction, setSaveTreeDataAction] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [backLink, setBackLink] = useState(
    props.editData
      ? props.productId
        ? {
            path: `/company/${props.productId}/edit#product`,
            data: { productId: props.productId },
          }
        : { path: `/product`, data: { productId: undefined } }
      : undefined
  );

  const handleSubmit = (selectedTech, checkValues, tab, callback, kiaFlag) => {
    if (
      props.path === "/processing/company/:companyId/product/:productId/edit" ||
      props.path === "/company/:companyId/product/:productId/edit" ||
      props.path == "/product/:productId/edit" ||
      props.path == "/data-qc/company/:companyId/product/:productId/edit"
    ) {
      if (checkValues.length || Object.keys(checkValues).length) {
        //save and submit logic
        if (tab == "technology") {
          saveTaxonomy(selectedTech, checkValues, callback);
        }
        if (tab == "kia") {
          saveKiaTagging(selectedTech, checkValues, tab, callback);
        }
        if (tab == "valueChain") {
          handleSaveValueChain(selectedTech, checkValues, callback);
        }
      } else {
        dispatch(showMessage("Please select Taxonomy...!"));
        setSubmitStatus(false);
      }
    }
  };

  const saveTaxonomy = (selectedTech, taxonomyIds, callback) => {
    if (selectedTech) {
      deleteProductTaxonomy(productId, selectedTech)
        .then(() => {
          if (taxonomyIds.length > 0) {
            try {
              const response = saveProductTaxonomy(productId, taxonomyIds);
              response
                .then(() => {
                  callback();
                  dispatch(actionSuccess("Taxonomy Updated Successfully"));

                  if (submitStatus) {
                    setTimeout(() => {
                      saveTaxonomyProcessing(selectedTech, "technology");
                    }, [2000]);
                  }
                })
                .catch((e) => {
                  dispatch(actionError("Something Went Wrong...!"));
                  setSubmitStatus(false);
                  setSaveTreeData(false);
                });
            } catch (e) {
              dispatch(actionError("Something Went Wrong...!"));
              setSubmitStatus(false);
              setSaveTreeData(false);
            }
          } else {
            dispatch(actionError("Please select taxonomy"));

            setSaveTreeDataAction(false);
            setClickSaveSubmit(false);
            setSubmitStatus(false);
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
          setSubmitStatus(false);
        });
    }
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };

  const saveTaxonomyProcessing = (selectedTech, tab) => {
    const payload = {
      userId: loggedInUser._id,
      dtId: selectedTech,
    };
    dispatch(
      showAlertBox({
        okCallback: async () => {
          processingSubmitProduct(productId, tab, payload)
            .then((response) => {
              if (response.status === 200) {
                setClickSaveSubmit(false);
                setUpdateClick(false);
                dispatch(actionSuccess(`Product successfully submitted`));

                return { status: "success" };
              }
            })
            .catch((err) => {
              dispatch(actionError(err.data.message || "Something went wrong"));
              setSubmitStatus(false);
            });
        },
        okText: "Submit",
        cancelText: "Cancel",
        content: "Are you sure you want to submit ?",
        title: "dialogAlertCss",
      })
    );
    setClickSaveSubmit(false);
    setUpdateClick(false);
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
    setSubmitStatus(false);
  };
  /*
	@param selectedIndustry : Selected Industry to save their respective kia
	@param kiaIds : Selected KiaIds
	@param callback : use for re-render tree component

	@description : Save kia for respective industry
				1.Delete all kia for selected industry
				2.Save all kia for selected industry
	*/
  const saveKiaTagging = (selectedIndustry, kiaIds, tab, callback) => {
    if (selectedIndustry) {
      dispatch(showLoader());
      deleteProductKia(productId, { industryId: selectedIndustry })
        .then(() => {
          if (kiaIds.length > 0) {
            try {
              const response = saveProductKiaTaxonomy(productId, { taxonomyIds: kiaIds });
              response
                .then((response) => {
                  callback();
                  if (submitStatus) {
                    setTimeout(() => {
                      saveTaxonomyProcessing(selectedIndustry, "kia");
                    }, [2000]);
                  }

                  dispatch(actionSuccess("KIA Updated Successfully"));
                })
                .catch((e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                  setSubmitStatus(false);
                  setSaveTreeData(false);
                });
            } catch (e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
              setSubmitStatus(false);
              setSaveTreeData(false);
            }
          }
        })
        .catch((e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
          setSubmitStatus(false);
          setSaveTreeData(false);
        });
    }
    setClickSaveSubmit(false);
    setUpdateClick(false);
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };
  const handleSaveValueChain = (selectedTech, checkValues, callback1, callback2) => {
    dispatch(showLoader());
    const response = saveProductValueChain(productId, checkValues);
    response
      .then((response) => {
        callback1();

        dispatch(hideLoader());
        if (submitStatus) {
          setTimeout(() => {
            saveTaxonomyProcessing(selectedTech, "valueChain");
          }, [2000]);
        }
        dispatch(actionSuccess("Value Chain Updated Successfully"));
      })
      .catch((e) => {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
        setSaveTreeData(false);
      });
    setClickSaveSubmit(false);
    setUpdateClick(false);
    setSaveTreeData(false);
    setSaveTreeDataAction(false);
  };

  const deleteTechnology = (checkedTrends, callback) => {
    deleteManyProductTechnology(productId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  const deleteKia = (checkedTrends, callback) => {
    let payload = { taxonomyIds: checkedTrends };
    deleteProductKia(productId, payload)
      .then(() => {
        callback();
        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };
  const deleteValueChain = (payload, callback1, callback2) => {
    deleteManyProductValueChain(productId, payload)
      .then(() => {
        callback1();

        dispatch(actionSuccess("Taxonomy deleted Successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <>
      <TreeView
        handleSave={saveTaxonomy}
        saveTreeData={saveTreeData}
        contentId={productId}
        fetchContentTagging={fetchProductTaxonomy}
        fetchKiaTagging={fetchProductKia}
        fetchContentData={fetchSingleProduct}
        loadOtherTagInfo={props.loadOtherTagInfo}
        handleSaveKIA={saveKiaTagging}
        handleSaveValueChain={handleSaveValueChain}
        contentType="product"
        deleteTechnology={deleteTechnology}
        isDelete={true}
        setSaveTreeData={setSaveTreeData}
        deleteKia={deleteKia}
        deleteValueChain={deleteValueChain}
        accessPermissionTaxonomyDelete={accessActionDelete}
        accessPermissionTaxonomy={accessActionAdd}
        handleSubmit={handleSubmit}
        submitStatus={submitStatus}
        setSubmitStatus={setSubmitStatus}
      />

      <div className="form-submit-box" style={{ padding: "10px 18px 10px 20px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="float-left col-md-6 text-left">
              {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
                <span className="pl-4" style={{ marginLeft: "-3em" }}>
                  <span className="span-link">
                    <button
                      type="button"
                      className="cy-tag-btn btn btn-outline-dark pl-4 pr-4 cy-btn "
                      onClick={() => {
                        (props.selectedIndex === undefined && props.setCurrentTab(3)) ||
                          (props.selectedIndex < 5 && props.setCurrentTab(props.selectedIndex - 1));
                      }}
                    >
                      Previous
                    </button>
                  </span>
                </span>
              ) : null}
            </div>
            <div className="float-right col-md-6 text-right">
              <button
                type="button"
                className="btn btn-outline-dark cy-btn pl-3 pr-3 mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(props.backPath, { companyId: props.companyId });
                }}
              >
                Cancel
              </button>
              {accessActionSubmit ? (
                <>
                  {(props.path === "/processing/company/:companyId/product/:productId/edit" ||
                    props.path == "/company/:companyId/product/:productId/edit" ||
                    props.path == "/product/:productId/edit" ||
                    props.path == "/data-qc/company/:companyId/product/:productId/edit") &&
                  productId ? (
                    <button
                      type="button"
                      className="btn btn-primary pl-4 pr-4 "
                      onClick={() => {
                        setSubmitStatus(true);
                      }}
                    >
                      Submit
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
TaxomonyUI.propTypes = {
  interfaceActionAccess: PropTypes.bool, 
  companyId: PropTypes.number,
  productId:PropTypes.string,
  path: PropTypes.string,
  selectedIndex: PropTypes.number,
  setCurrentTab: PropTypes.func,
  loadOtherTagInfo: PropTypes.func,
  backPath:  PropTypes.string,
  editData:  PropTypes.string,
};
export default TaxomonyUI;
