/*================================================================
    Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
    File Description :  News Content Add | Edit Page
    Summary : Use CKEditor for news content editor
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 11/Sep/2020 
    Author						: YOGESH N. GUPTA
================================================================ 
*/

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateNews, fetchSingleNews } from "../../../../../../middleware/services/newsApi";
import { showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import CKEditor5 from "../../../../../components/ckEditor5";
import PropTypes from "prop-types";

const BasicContent = (props) => {
  const { newsId, content, setContent } = props;
  const dispatch = useDispatch();
  let preservedContent = sessionStorage.getItem("content-design");

  /**
   * @description initialize editor with default value if have
   */
  const initEditor = () => {
    if (newsId != "" && !preservedContent) {
      dispatch(showLoader());
      try {
        const response = fetchSingleNews(newsId, { fields: ["newsContent"] });
        response
          .then((response) => {
            dispatch(hideLoader());
            if (response.status == 200) {
              if (typeof response.data.data.newsContent != "undefined" && response.data.data.newsContent != "") {
                setTimeout(() => {
                  setContent(response.data.data.newsContent);
                }, 200);
              }
            } else {
              dispatch(actionError("Something Went Wrong...!"));
            }
          })
          .catch((e) => {
            dispatch(hideLoader());
            dispatch(actionError("Something Went Wrong...!"));
            console.log(e);
          });
      } catch (e) {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      setContent(preservedContent);
    }
  };

  useEffect(() => {
    if (content) sessionStorage.setItem("content-design", content);
  }, [content]);

  useEffect(() => {
    if (props?.saveNews) {
      dispatch(showLoader());
      try {
        if (newsId !== "") {
          if (content) {
            let contentData = { newsContent: content };
            let saveContentData = new FormData();
            for (let uKey in contentData) {
              if (typeof contentData[uKey] != "object") {
                if (contentData[uKey] && contentData[uKey] !== "") saveContentData.append(uKey, contentData[uKey]);
              } else {
                if (contentData[uKey] && contentData[uKey] !== "") saveContentData.append(uKey, JSON.stringify(contentData[uKey]));
              }
            }
            const response = updateNews(newsId, saveContentData);
            response
              .then((response) => {
                dispatch(hideLoader());
              })
              .catch((e) => {
                dispatch(hideLoader());
                dispatch(actionError("Something Went Wrong...!"));
              });
          } else {
            dispatch(actionError("News Content Could Not be Empty"));
          }
        }
      } catch (e) {
        dispatch(hideLoader());
        dispatch(actionError("Something Went Wrong...!"));
      }
      props.handleSave(false);
    }
  }, [props.saveNews]);

  const onChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };

  useEffect(initEditor, []);

  return (
    <CKEditor5
      onChange={onChange}
      content={content}
      editorHeight={props?.properties?.config?.height || props?.height || "600px"}
      enableImageUpload={true}
    />
  );
};

BasicContent.propTypes = {
  properties: PropTypes.object,
  height: PropTypes.string,
  newsId: PropTypes.string, 
  content: PropTypes.string, 
  setContent: PropTypes.func,
  saveNews: PropTypes.func
};

export default BasicContent;
