/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Modal Dropdown Input
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/

import React, { useState, useEffect } from "react";
import classes from "./ModalDropdownInput.module.css";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { getLoggedInUser } from "../../../utilities";

// Dropdown input used in modals
const ModalDropdownInput = (props) => {
  const [folderValue, setFolderValue] = useState("");
  const user = getLoggedInUser();

  // get value of selected folder
  const selectFolder = (key, e) => {
    props.setFolderID(key);
    setFolderValue(e.target.innerText);
  };

  const filteredAndSortedCategories = props?.categoryList
    ?.filter((folder) => {
      const isOwner = folder?.categoryCreatedByUserId === user?._id;
      const canEditSharedFolder = folder?.categoryShared?.some((shared) => shared?.userId === user?._id && shared?.canEdit === "Yes");
      return isOwner || canEditSharedFolder;
    })
    ?.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

  // set new added folder dinamically
  useEffect(() => {
    if (props.newFolderId !== "") {
      const newFolderName = props.categoryList.filter((x) => x.id === props.newFolderId);
      if (newFolderName.length !== 0) {
        setFolderValue(newFolderName[0].categoryName);
      }
    }
  }, [props.categoryList, props.newFolderId]);

  return (
    <div>
      <div className={classes.input_group}>
        <label>Folder</label>

        <DropdownButton
          title={folderValue ? <div className={classes.folderValue}>{folderValue}</div> : "Select Folder"}
          className={`${classes.selectFolder} selectFolderMenu`}
          onSelect={selectFolder}
        >
          <div className={classes.folderDiv}>
            {filteredAndSortedCategories?.map((category) => {
              const isSharedFolder = category?.categoryShared?.length > 0;
              const isOwner = category?.categoryCreatedByUserId === user?._id;

              const getFolderIcon = () => {
                if (isSharedFolder && !isOwner) {
                  return <img src="/assets/other-shared-folder.svg" alt="other-shared-folder" />;
                }
                if (isSharedFolder && isOwner) {
                  return <img src="/assets/shared-folder.svg" alt="shared-folder" />;
                }
                return <img src="/assets/new-folder.svg" alt="new-folder" style={{ marginLeft: "4px" }} />;
              };

              return (
                <Dropdown.Item value={category.id} eventKey={category.id} key={category.id} className={classes.dropdownItem}>
                  {getFolderIcon()}
                  <div className={classes.folderNameDropdown}>{category.categoryName}</div>
                </Dropdown.Item>
              );
            }) || null}
          </div>
          <Dropdown.Divider />
          <Dropdown.Item onClick={props.onAddFolderClick} className={classes.addNewFolder}>
            <img src="/assets/add-folder.svg" alt="" />
            Add New Folder
          </Dropdown.Item>
        </DropdownButton>
      </div>

      {folderValue === "" && props.showError && <p style={{ fontSize: "0.75rem", color: "#f94f5e", marginBottom: "0px" }}>Please select a folder.</p>}
    </div>
  );
};

export default ModalDropdownInput;
