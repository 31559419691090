/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description : Footer bar for news Add | Edit Page
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 21/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React from "react";
import { Link } from "react-router-dom";
import DataPublishingAction from "../../../dataPublishing/news/actionButton";
import PropTypes from "prop-types";

const FooterBar = (props) => {
  const { selectedIndex } = props;
  const saveAccess = selectedIndex === 0 ? props?.accessActionSave : props?.accessActionContentSave;
  return (
    <div className="form-submit-box" style={{ padding: "10px 24px 10px 20px" }}>
      <div className="container-fluid nopadding">
        <div className="row">
          <div className="float-LEFT col-md-4 text-left pl-0">
            {props.selectedIndex > 0 || props.selectedIndex === undefined ? (
              <span className="span-link mr-3">
                <button
                  type="button"
                  className="cy-prev-btn btn btn-outline-dark pl-4 pr-4 cy-btn "
                  onClick={() => {
                    (props.selectedIndex === undefined && props.setCurrentTab(2)) ||
                      (props.selectedIndex < 4 && props.setCurrentTab(props.selectedIndex - 1));
                  }}
                >
                  Previous
                </button>
              </span>
            ) : null}
            {props.newsId !== "" && props.selectedIndex > -1 && props.selectedIndex < 3 ? (
              <span className="span-link">
                <button
                  type="button"
                  className="cy-next-btn btn btn-outline-dark pl-4 pr-4 cy-btn"
                  onClick={() => {
                    props.selectedIndex < 3 && props.setCurrentTab(props.selectedIndex + 1);
                  }}
                >
                  Next
                </button>
              </span>
            ) : null}
          </div>
          <div className="float-right col-md-8 text-right pr-1">
            <Link to="/news">
              <button type="button" className="btn btn-outline-dark cy-btn pl-4 pr-4 mr-3">
                Cancel
              </button>
            </Link>

            {saveAccess && selectedIndex !== 2 && selectedIndex !== 3 ? (
              <button
                type="button"
                className="btn btn-primary cy-btn pl-4 pr-4 "
                id="dev-news-save"
                onClick={() => {
                  props.handleSave(true);
                }}
              >
                Save
              </button>
            ) : null}
            {props?.accessActionBasicPublish ? <DataPublishingAction newsId={props.newsId} {...props} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

FooterBar.propTypes = {
	selectedIndex: PropTypes.number,
	handleSave: PropTypes.func,
  accessActionSave: PropTypes.string,
  setCurrentTab: PropTypes.func,
  accessActionBasicPublish: PropTypes.bool, 
  accessActionContentSave: PropTypes.bool, 
  newsId: PropTypes.string
};

export default FooterBar;
