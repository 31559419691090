import React from "react";

import { Card, CardContent, Typography } from "@mui/material";
import CommonDateTagSection from "../CommonDateTagSection/CommonDateTagSection";
import "./index.css";
import ImageFile from "app/views/customizableWidgets/ImageFile";
import { getIndustryTags } from "utils";
import { faThumbtack } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewContentCard = (props) => {
  const {
    profileImage,
    bgStyle,
    textStyle,
    item,
    industryTag,
    label,
    title,
    infoText,
    date,
    ribbon,
    readTime,
    darkBg,
    entityType,
    thumbnailImage = false,
  } = props;

  return (
    <div className="h-full w-full">
      {!thumbnailImage ? (
        <Card id="pinned-card" className="h-full w-full">
          {title !== "" && (
            <div className="h-full w-full">
              <div className="image-container">
                <div
                  style={{
                    width: "26px",
                    height: "26px",
                    position: "absolute",
                    left: "4px",
                    top: "4px",
                    zIndex: 99,
                    backgroundColor: "white",
                    padding: "4px",
                    borderRadius: "4px",
                    border: "1px solid #4B5563",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faThumbtack} className="pinned-icon" height={16} width={16} />
                </div>
                <ImageFile fileName={profileImage} entityType={entityType} item={item} imageCardStyle={{ borderRadius: "0px", border: "0px" }} />
                <div className="card-container-icons">
                  <img src={"/assets/tick-icon.svg"} alt="tick" className="pr-2" />
                  <img src={"/assets/bookmark-icon.svg"} alt="bookmark" />
                </div>
                <div className="read-time-container">
                  {readTime !== 0 && readTime && (
                    <div className="read-time">
                      <img src="/assets/clock-white.svg" alt="clockIcon" width={12} />
                      <span style={{ marginLeft: "4px", fontSize: "0.75rem" }}>{readTime} min</span>
                    </div>
                  )}
                </div>
              </div>

              <CardContent className="card-content" style={{ backgroundColor: bgStyle, color: textStyle }}>
                <Typography gutterBottom fontSize="14px" fontWeight="bold" component="div" className="title">
                  {title}
                </Typography>
                <div className="flex-container">
                  <CommonDateTagSection
                    date={date}
                    label={label}
                    tags={getIndustryTags(industryTag)}
                    ribbon={ribbon}
                    textStyle={textStyle}
                    darkBg={darkBg}
                    showReadTime={true}
                    readTime={readTime}
                  />
                </div>
                <p className="card-info" style={{ color: textStyle }}>
                  {infoText}
                </p>
              </CardContent>
            </div>
          )}
        </Card>
      ) : (
        <div className="thumbnail-card-container">
          <div className="thumbnail-card-img thumbnail-hover-img-card ">
            <FontAwesomeIcon icon={faThumbtack} className="pinned-icon" height={20} width={20} />

            <ImageFile
              fileName={profileImage}
              entityType={entityType}
              item={item}
              imageStyle={{ opacity: "0.4" }}
              imageCardStyle={{ backgroundColor: "#000000", border: "0px" }}
            />
            <div className="card-container-icons">
              <img src={"/assets/tick-icon.svg"} alt="tick" className="pr-2" />
              <img src={"/assets/bookmark-icon.svg"} alt="bookmark" />
            </div>
            <div className="thumbnail-read-time-container">
              {readTime !== 0 && readTime && (
                <div className="read-time">
                  <img src="/assets/clock-white.svg" alt="clockIcon" width={12} />
                  <span style={{ marginLeft: "4px", fontSize: "0.75rem" }}>{readTime} min</span>
                </div>
              )}
            </div>
          </div>
          <div className="thumbnail-card-info-container" style={{ color: textStyle }}>
            <Typography gutterBottom fontSize="14px" fontWeight="bold" component="div" className="thumbnail-card-title" style={{ color: textStyle }}>
              {title}
            </Typography>
            <div className="thumbnail-flex-container">
              <CommonDateTagSection
                date={date}
                label={label}
                tags={getIndustryTags(industryTag)}
                ribbon={ribbon}
                textStyle={textStyle}
                darkBg={darkBg}
                showReadTime={true}
                readTime={readTime}
              />
            </div>
            <p className="card-info" style={{ color: textStyle }}>
              {infoText}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewContentCard;
