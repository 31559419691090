import React from "react";
import Taxonomy from "./taxonomy";
import Trend from "./trend";
import { getProductVariant } from "../../../../../utilities";
import PropTypes from 'prop-types';


const Tagging = (props) => {
  const productId = props.productId;

  const loadOtherTagInfo = (info) => {
    // setLoadTaggingInfo(info)
  };
  if (productId == "") {
    return null;
  }
  const productVariant = getProductVariant();
  if (!productVariant || productVariant == "") return null;
  return (
    <div className="gennx-content-wrapper content-wrapper" style={{ height: "78vh", overflowY: "hidden" }}>
      {productVariant == "Insider" ? (
        <Trend {...props} loadOtherTagInfo={loadOtherTagInfo} />
      ) : (
        <Taxonomy {...props} loadOtherTagInfo={loadOtherTagInfo} />
      )}
    </div>
  );
};
Tagging.propTypes = {
  productId: PropTypes.string
};

export default Tagging;
