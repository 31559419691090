import React from "react";
import { Typography } from "@mui/material";
import CommonDateTagSection from "../CommonDateTagSection/CommonDateTagSection";
import "./index.css";
import ImageFile from "app/views/customizableWidgets/ImageFile";
import { getIndustryTags } from "utils";
import { faThumbtack } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MergedCard = (props) => {
  const {
    bgStyle,
    textStyle,
    item,
    industryTag,
    label,
    title,
    profileImage,
    infoText,
    date,
    ribbon,
    readTime,
    darkBg,
    entityType,
    thumbnailImage = false,
  } = props;

  return (
    <>
      <div
        className={thumbnailImage ? "thumbnail-merge-card-container merge-card-container" : "merge-card-container"}
        style={{ backgroundColor: bgStyle, color: textStyle }}
      >
        <div className={thumbnailImage && "thumbnail-merge-content-container"}>
          <Typography gutterBottom fontSize="14px" fontWeight="bold" component="div" className="title" style={{ color: textStyle }}>
            {title}
          </Typography>
          <p className="card-container-info" style={{ color: textStyle }}>
            {infoText}
          </p>
          <div className="flex-container">
            <CommonDateTagSection
              date={date}
              label={label}
              tags={getIndustryTags(industryTag)}
              ribbon={ribbon}
              textStyle={textStyle}
              darkBg={darkBg}
              showReadTime={true}
              readTime={readTime}
              tagLength={4}
            />
          </div>
        </div>
        <div className={thumbnailImage ? "thumbnail-merge-card-image-container" : "card-image-container"}>
          <div
            style={{
              width: "26px",
              height: "26px",
              position: "absolute",
              left: "4px",
              top: "4px",
              zIndex: 99,
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "4px",
              border: "1px solid #4B5563",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faThumbtack} className="pinned-icon" height={16} width={16} />
          </div>

          <ImageFile
            fileName={profileImage}
            entityType={entityType}
            item={item}
            imageStyle={{ opacity: thumbnailImage ? "0.4" : "unset" }}
            imageCardStyle={{ backgroundColor: thumbnailImage ? "#000000" : "unset", border: "none" }}
          />
          <div className="card-container-icons">
            <img src={"/assets/tick-icon.svg"} alt="tick" className="pr-2" />
            <img src={"/assets/bookmark-icon.svg"} alt="bookmark" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MergedCard;
