import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { getLoggedInUser } from "../../../../../utilities";
import ActionButtons from "./actionButtons";
import { fetchConfig } from "../../../../../middleware/services/cmsApi";
import { getCompanyDisplay, saveCompanyDisplay, fetchCompanyTrend, fetchSingleCompany } from "../../../../../middleware/services/companyApi";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import ImageList from "./imageList";
import { useQuery } from "@tanstack/react-query";
import { deleteWidgetContent, fetchWidget, updateWidgetContent } from "middleware/services/customizableWidgetApi";
import { PINNED_CONTENT } from "constants/widget-constants";
import { combineIndustriesWithWidgets, filterIndustryFromTrends, filterIndustryIds } from "utils";
import { COMMON_ACCESS_ACTION, CONTENT_CATEGORY_FOR_WIDGET, CONTENT_TYPE, PRODUCT_VARIANT } from "../../../../../constants";
import { Autocomplete, Button, Checkbox, TextField } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import "./index.scss";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import PropTypes from 'prop-types';


const DisplaySettings = (props) => {
  const dispatch = useDispatch();
  let userId = getLoggedInUser();
  const [images, setImages] = useState([]);
  const [addToWidgets, setAddToWidgets] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [companyDetails, setCompanyDetails] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [contentCategory, setContentCategory] = useState("Newly Added");
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null); // Create a ref for the container

  /* Common access */
  const accessActionWidgetSave = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.SAVE);

  const { data: companyData, isFetching: companyFetching } = useQuery({
    queryKey: ["company-data"],
    queryFn: () => fetchSingleCompany(props?.companyId),
    enabled: Boolean(props?.companyId),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    setCompanyDetails(companyData?.data?.data);
  }, [companyData?.data?.data]);

  useEffect(() => {
    if (props?.companyId !== "") {
      fetchKeyPlayerImages();
      // fetchDisplaySettings();
    }
  }, []);

  useEffect(() => {
    if (selectedIds?.length) setAddToWidgets(true);
  }, [selectedIds]);

  const taggedIndustries = useMemo(
    () => filterIndustryFromTrends(companyDetails?.companyIndustryTrendsTags),
    [companyDetails?.companyIndustryTrendsTags]
  );

  const taggedIndustriesIds = useMemo(
    () => filterIndustryIds(companyDetails?.companyIndustryTrendsTags),
    [companyDetails?.companyIndustryTrendsTags]
  );

  const { data: widgetUniqueData, isFetching: widgetUniqueFetching } = useQuery({
    queryKey: ["widget-unique", taggedIndustriesIds],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["widgetIndustryId", "in", taggedIndustriesIds],
        ["widgetContentType", "eq", CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES],
        ["widgetName", "neq", PINNED_CONTENT],
        ["productVariant.productName", "eq", PRODUCT_VARIANT.INSIDER],
        ["widgetActive", "eq", "YES"],
      ];
      params.sort = "widgetDisplayName:asc";
      return fetchWidget(params);
    },
    enabled:
      taggedIndustriesIds?.length > 0 &&
      companyDetails?.productVariant?.find((item) => item?.productName === PRODUCT_VARIANT.INSIDER)?.productName === PRODUCT_VARIANT.INSIDER,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  let options = useMemo(
    () => combineIndustriesWithWidgets(taggedIndustries, widgetUniqueData?.data?.data),
    [taggedIndustries, widgetUniqueData?.data?.data]
  );
  const groupedOptions = useMemo(() => options?.flatMap(({ group, items }) => items.map(({ id, label }) => ({ id, group, label }))), [options]);

  // Find selected options based on IDs
  const selectedOptions = groupedOptions?.filter((option) => selectedIds?.includes(option.id));

  // Check if an ID is selected
  const isChecked = (id) => selectedIds?.includes(id);

  const { isFetching: widgetAttachedDataFetching } = useQuery({
    queryKey: ["widget-attached-data", taggedIndustriesIds, props?.companyId],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["widgetIndustryId", "in", taggedIndustriesIds],
        ["widgetContent.widgetContentId", "eq", props?.companyId],
        ["widgetName", "neq", PINNED_CONTENT],
        ["productVariant.productName", "eq", PRODUCT_VARIANT.INSIDER],
        ["widgetActive", "eq", "YES"],
      ];
      params.sort = "widgetDisplayName:asc";
      return fetchWidget(params);
    },
    enabled:
      !!props?.companyId &&
      taggedIndustriesIds?.length > 0 &&
      companyDetails?.productVariant?.find((item) => item?.productName === PRODUCT_VARIANT.INSIDER)?.productName === PRODUCT_VARIANT.INSIDER,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    onSuccess: (response) => {
      if (!response?.data?.data?.length) return;
      const widgetIds = response?.data?.data?.map((widget) => widget.id);
      setSelectedIds([...selectedIds, ...widgetIds]);
      const category =
        response?.data?.data[0]?.widgetContent?.find((widget) => widget?.widgetContentId === props?.companyId)?.widgetContentKeyPlayerCategory ||
        "Newly Added";
      setContentCategory(category);
      fetchDisplaySettings();
    },
  });

  const fetchDisplaySettings = async () => {
    try {
      dispatch(showLoader());
      const response = await getCompanyDisplay(props.companyId);
      if (response) {
        let data = response?.data?.data;
        setSelectedImage(data.companyKeyPlayerImage);
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error("err >>>", err);
      dispatch(actionError(err?.data?.message || "Something went wrong"));
    }
  };

  const fetchKeyPlayerImages = async () => {
    try {
      const companyTrendResponse = await fetchCompanyTrend(props.companyId);
      const companyTrend = companyTrendResponse?.data?.data || [];

      const taggedIndustry = companyTrend
        .filter((item) => item.trendParentId === 0 && item.trendRoot === 0)
        .map(({ trendName, trendId }) => ({ trendName, trendId }));

      const filters = [["config_name", "eq", "company-key-player-images"]];
      const params = {
        filters,
        fields: ["configValue"],
        limit: 1000,
      };

      const configResponse = await fetchConfig(params);
      const data = configResponse?.data?.data || [];

      let seenTitles = new Set();

      let filteredImages = data[0]?.configValue
        .filter((image) => {
          // Check if there is a match in taggedIndustry for trendId and trendName
          const match = taggedIndustry.find((tag) => tag.trendId === image.industryId && tag.trendName === image.industryName);

          // If there is a match, include the image in the filtered array
          return match !== undefined;
        })
        .filter((image) => {
          // Only include images with unique titles
          if (!seenTitles.has(image.title)) {
            seenTitles.add(image.title);
            return true;
          }
          return false;
        });

      setImages(filteredImages);
    } catch (err) {
      console.error("err >>>", err);
      dispatch(actionError(err?.data?.message || "Something went wrong"));
    }
  };

  const handleSave = async () => {
    if (!addToWidgets) {
      if (companyDetails?.productVariant?.find((item) => item?.productName === PRODUCT_VARIANT.INSIDER)?.isLive !== "YES")
        return dispatch(actionError("Content is not yet published."));
      dispatch(showLoader());
      let payload = {
        widgetContentId: props.companyId,
        widgetModifiedByUserId: userId?._id,
        widgetModifiedByUserName: userId?.user_name,
      };
      await deleteWidgetContent(payload)
        .then((response) => {
          dispatch(actionSuccess("Widget setting saved successfully."));
          setSelectedIds([]);
          dispatch(hideLoader());
          let overviewData = {
            companyKeyPlayerImage: "DEFAULT.png",
          };
          saveCompanyDisplay(props.companyId, overviewData)
            .then((res) => {})
            .catch((err) => {
              dispatch(hideLoader());
              dispatch(actionError(err?.data?.message || "Something went wrong"));
            });
        })
        .catch((e) => {
          dispatch(hideLoader());
          if (e?.data?.message) {
            dispatch(actionError(e.data.message));
          } else {
            dispatch(actionError("Something Went Wrong...!"));
          }
        });
    } else {
      if (companyDetails?.productVariant?.find((item) => item?.productName === PRODUCT_VARIANT.INSIDER)?.isLive !== "YES")
        return dispatch(actionError("Content is not yet published."));
      if (!selectedIds?.length) return dispatch(actionError("Please select widget(s)."));
      if (!selectedImage) return dispatch(actionError("Please select a widget image."));
      dispatch(showLoader());
      let overviewData = {
        companyKeyPlayerImage: selectedImage,
      };
      let saveOverviewData = new FormData();

      for (let uKey in overviewData) {
        if (typeof overviewData[uKey] != "object") {
          if (overviewData[uKey] && overviewData[uKey] !== "") saveOverviewData.append(uKey, overviewData[uKey]);
        }
      }

      handleSavewidgetSettings();
      saveCompanyDisplay(props.companyId, saveOverviewData)
        .then((res) => {})
        .catch((err) => {
          dispatch(hideLoader());
          dispatch(actionError(err?.data?.message || "Something went wrong"));
        });
    }
  };

  const handleSavewidgetSettings = async () => {
    let payload = {
      widgetIds: selectedIds,
      widgetContent: {
        widgetContentId: props?.companyId,
        widgetContentOrder: 1,
        widgetContentType: "Company",
        widgetContentKeyPlayerCategory: contentCategory || "Newly Added",
      },
      widgetModifiedByUserId: userId?._id,
      widgetModifiedByUserName: userId?.user_name,
    };
    await updateWidgetContent(payload)
      .then((response) => {
        dispatch(actionSuccess("Widget setting saved successfully."));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        if (e?.data?.message) {
          dispatch(actionError(e.data.message));
        } else {
          dispatch(actionError("Something Went Wrong...!"));
        }
      });
  };

  // Function to handle scroll events within the container
  const handleScroll = () => {
    setOpen(false);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (companyFetching || widgetUniqueFetching || widgetAttachedDataFetching) dispatch(showLoader());
    else dispatch(hideLoader());
  }, [companyFetching, widgetUniqueFetching, widgetAttachedDataFetching]);

  return (
    <div className="padding-bottom-150i pt-3" style={{ width: "99.5%" }}>
      <div className="design-color mt-3 ml-2" style={{ width: "99%" }}>
        <span className="header-name"> Widget Setting</span>
      </div>
      <div ref={containerRef} className="mx-2 bg-white" style={{ width: "99%", height: "calc(100vh - 370px)" }}>
        <div
          className="mx-2 pt-4"
          style={{
            height: "68px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div className="ml-2 mr-1">
            <label>Add to Widget</label>
            <label className="custom-switch">
              <input
                id="newsAddToWidgets"
                name="newsAddToWidgets"
                type="checkbox"
                value={addToWidgets ? "YES" : "NO"}
                checked={addToWidgets}
                onChange={(e) => {
                  setAddToWidgets(!addToWidgets);
                }}
              />
              <div>
                <span className="on">YES</span>
                <span className="off">NO</span>
              </div>
              <i></i>
            </label>
          </div>
          {addToWidgets && (
            <Autocomplete
              multiple
              // limitTags={2}
              options={groupedOptions}
              open={open} // Control the open state
              onOpen={() => setOpen(true)} // Set to true when opened
              onClose={() => setOpen(false)} // Set to false when closed
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label}
              disableCloseOnSelect
              freeSolo={false} // Disable manual typing
              renderInput={(params) => {
                const maxDisplayedItems = 2; // Control how many items to display
                const remainingCount = selectedOptions.length - maxDisplayedItems;

                return (
                  <TextField
                    {...params}
                    label="Select Widgets"
                    variant="outlined"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {remainingCount > 0 && (
                            <span style={{ fontSize: "12px", color: "#000000", position: "absolute", right: "60px" }}>+{remainingCount} more</span>
                          )}
                        </>
                      ),
                    }}
                    // Add `onKeyDown` to prevent typing
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "44px", // Ensure the input's height remains fixed
                        overflow: "hidden", // Prevent overflow
                        display: "flex", // Ensure chips are aligned correctly
                        alignItems: "center", // Center items vertically
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "50px", // Ensure the root's height is consistent
                        overflow: "hidden", // Prevent overflow
                        flexWrap: "nowrap",
                        paddingRight: "0px",
                        "& .MuiAutocomplete-tag": {
                          display: "none", // Hide chips to control the number of visible chips
                        },
                        "& .MuiAutocomplete-tag:nth-of-type(-n+2)": {
                          display: "inline-flex", // Show only the first two chips
                        },
                      },
                    }}
                  />
                );
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontSize: "12px",
                    padding: "0px 0px 0px 26px",
                    margin: "0px",
                    display: "flex",
                    gap: "8px",
                    height: "36px",
                    alignItems: "center",
                    backgroundColor: isChecked(option.id) ? "transparent" : "transparent",
                    color: "black",
                    fontWeight: "400",
                  }}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    checked={isChecked(option.id)}
                    sx={{
                      width: "16px",
                      height: "16px",
                      color: "#DBDBDD",
                    }}
                  />
                  {option.label}
                </li>
              )}
              value={selectedOptions}
              onChange={(_, newValue) => {
                // Update selected IDs based on selected options
                const newSelectedIds = newValue.map((option) => option.id);
                setSelectedIds(newSelectedIds);
              }}
              sx={{
                width: "400px",
                height: "44px", // Fixed height for the container
                "& .MuiOutlinedInput-root": {
                  height: "100%", // Ensure the height is consistent
                  "&.Mui-focused": {
                    "& fieldset": {
                      borderColor: "#f94f5e",
                    },
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#f94f5e",
                  },
                },
                "& .MuiButtonBase-root.MuiChip-root": {
                  maxWidth: "137px",
                  height: "26px",
                  fontSize: "12px",
                  color: "black",
                  backgroundColor: "#F2F2F2",
                  border: "1px solid #DBDBDD",
                  borderRadius: "4px",
                },
                "& .MuiChip-deleteIcon": {
                  fontSize: "16px !important",
                  color: "black !important",
                  margin: "-1px 4px 0px -6px !important",
                },
                "& .MuiChip-label": {
                  paddingLeft: "4px !important",
                  paddingRight: "10px !important",
                },
              }}
            />
          )}
          {selectedIds?.length > 0 && addToWidgets && (
            <div className="ml-4 mt-2">
              <DropDownListComponent
                value={contentCategory}
                change={(value) => {
                  setContentCategory(value?.itemData.value);
                }}
                cssClass={"customCss e-outline"}
                floatLabelType="Auto"
                dataSource={CONTENT_CATEGORY_FOR_WIDGET}
                fields={{ text: "text", value: "value" }}
                placeholder="Widget Category *"
                style={{ height: "49px" }}
              />
            </div>
          )}
        </div>
        {addToWidgets && (
          <>
            <div className="mt-2 ml-3">
              <strong>Select Image:</strong>
            </div>
            <ImageList images={images} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
          </>
        )}
        <div className="mt-1 mx-2 mb-4">
          <span className="ml-2" style={{ fontSize: "14px", fontWeight: "bold" }}>
            Note :{" "}
          </span>
          <ul className="pb-2" style={{ marginLeft: "32px" }}>
            <li style={{ fontSize: "14px" }}>Content must be published before adding to widget.</li>
          </ul>
        </div>
      </div>
      {accessActionWidgetSave && (
        <div
          className="gennx-content-wrapper mx-2 bg-white"
          style={{ width: "99%", display: "flex", justifyContent: "end", borderTop: "1px solid #DBDBDD" }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#f94f5e",
              "&:disabled": {
                opacity: "0.5 !important ",
                backgroundColor: "#DBDBDD",
                color: "#FFFFFF",
              },
              textTransform: "inherit !important",
              "&:hover": {
                backgroundColor: "#f94f5e",
              },
            }}
            className="pl-4 pr-4 my-2 mr-3"
            onClick={() => handleSave()}
            // disabled={!selectedIds?.length}
          >
            Save
          </Button>
        </div>
      )}

      {props.path == "/company/:companyId/edit" && (
        <div className={!props.companyId && "d-none"}>
          <ActionButtons
            accessRights={props.accessRights}
            companyId={props.companyId}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            back={props.path === "/processing/company/:companyId/edit" ? "/processing/company" : "/company"}
            previousPath={`/company/${props.companyId}/edit#taxonomy`}
            hashArr={props.hashArr}
          />
        </div>
      )}
    </div>
  );
};

DisplaySettings.propTypes = {
  interfaceActionAccess: PropTypes.bool, 
  companyId: PropTypes.number,
  accessRights: PropTypes.arrayOf(PropTypes.string),
  selectedIndex: PropTypes.number,
  setCurrentTab: PropTypes.func,
  path: PropTypes.string,
  hashArr: PropTypes.array
};

export default DisplaySettings;
