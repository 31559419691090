/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  Customizable Widgets Add | Edit
   Summary : Customizable Widgets
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 01/Oct/2023 
  Author						  : Satya Prakash Mall
================================================================ 

  Creation Details 
  Date Created				: 19/June/2024 
  Author						  : Narenkumar Krishnan
  Description         : React grid layout implemented for dynamic render along with merge and split functionalites responsively. 
================================================================ 
*/
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxonomyTrend, getCombinedData, fetchWidgetList, getContentType, getObjectiveSgfTag } from "../../../middleware/services/cmsApi";
import {
  fetchWidget,
  fetchWidgetLayout,
  updateWidget,
  saveSingleWidget,
  saveWidget,
  deleteWidget,
  updateSingleWidgetLayout,
} from "../../../middleware/services/customizableWidgetApi";
import { getLoggedInUser, getProductVariant, accessRightActionCheck } from "../../../utilities";
import { actionError, actionSuccess, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import "./index.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import { COMMON_ACCESS_ACTION } from "../../../constants";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import {
  PINNED_CONTENT,
  WIDGETS,
  ADD_WIDGETS,
  ADD_CONTENT,
  WIDGET_FILTER,
  GRID_MEASURES,
  MERGE_TEXT,
  SPLIT_TEXT,
  WIDGET_DISPLAY_NAME_MAX_VALIDATION_TEXT,
  WIDGET_DISPLAY_NAME_MIN_VALIDATION_TEXT,
  WIDGET_DISPLAY_NAME_REQUIRED_VALIDATION_TEXT,
  WIDGET_DISPLAY_NAME_MATCHES_VALIDATION_TEXT,
  SAVE_DASHBOARD_TEXT,
  LAYOUT_SUCCESS_MESSAGE,
  LAYOUT_CONFIRMATION_MESSAGE,
  WIDGET_REMOVE_CONFIRMATION_MESSAGE,
  WIDGET_REMOVE_SUCCESS_MESSAGE,
  WIDGET_DELETE_FAILED_MESSAGE,
  PINNED_UPDATED_SUCCESS_MESSAGE,
  PINNED_ADDED_SUCCESS_MESSAGE,
  WIDGET_UPDATE_MESSAGE,
  WIDGET_ADDED_SUCCESS_MESSAGE,
  LAYOUT_ORDER,
  KEYPLAYERS_CONFLICT_MESSAGE_FIRST_LINE,
  KEYPLAYERS_CONFLICT_MESSAGE_SECOND_LINE,
  KEY_PLAYER_WIDGETS,
} from "../../../constants/widget-constants";

import Merge from "../../../assets/images/merge-icon.svg?react";
import Split from "../../../assets/images/split-icon.svg?react";
import CustomizableWidgetSidebar from "app/components/CustomizableWidgetSidebar";
import { useRenderComponent } from "../../../hooks/useRenderComponent";
import AddWidgetSideBarModal from "app/components/widgetAddSidebar";
import { fetchConfig } from "middleware/services/cmsApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getData, getKeyPlyersData } from "../../../utils/index";
import {
  setCombinedData,
  setRightSideWidgetList,
  setSelectedWidgetData,
  hideWidgetLoader,
  setPreviewData,
  setRelatedContentList,
} from "middleware/actions/customizableWidgetActions";
import "react-grid-layout/css/styles.css";
import { fetchSingleUser } from "middleware/services/userApi";
import { GridLoaderComponent } from "app/components/loaders";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

/** Tabs used in the key players */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
/** */

const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const cols = { lg: 12, md: 8, sm: 6, xs: 4, xxs: 2 };

const GridComponent = ({
  name,
  text,
  layout,
  resolution,
  splitConfig,
  compactType,
  onLayoutChange,
  onDrag,
  handleAddClick,
  mergeWidgets,
  splitWidgets,
  onDeleteClick,
  onSettingClick,
  widgetData,
  widgetConfig,
  isDroppable,
  isDraggable,
  isResizable,
  signalRelatedContentData,
}) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(resolution);
  const onBreakpointChange = (newBreakpoint) => {
    setCurrentBreakpoint(newBreakpoint);
  };
  const renderComponent = useRenderComponent();

  return (
    <ResponsiveReactGridLayout
      // style={{ background: '#f0f0f0', width: '100%', height: '372px' }}
      style={{ background: "#f0f0f0", width: "100%", height: "40vh" }}
      layouts={layout}
      breakpoints={breakpoints}
      cols={cols}
      rowHeight={70}
      maxRows={4}
      measureBeforeMount={false}
      useCSSTransforms={true}
      compactType={compactType}
      preventCollision={false}
      onLayoutChange={(layout, allLayouts) => {
        onLayoutChange(layout, allLayouts, name, currentBreakpoint);
      }}
      onDrag={onDrag}
      onBreakpointChange={onBreakpointChange}
      isDroppable={isDroppable}
      isDraggable={isDraggable}
      isResizable={isResizable}
      draggableHandle=".drag-handle"
    >
      {layout?.[currentBreakpoint]?.length > 0 &&
        layout?.[currentBreakpoint]?.map((widget, index) => (
          // <div className={`widget-box ${index % 2 == 0 ? 'merge-icon-view-even' : 'merge-icon-view-odd'}`} key={widget?.i}>
          <div
            className={`widget-box ${
              splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged === false ? "merge-icon-view-even" : "merge-icon-view-odd"
            }`}
            key={widget?.i}
          >
            {renderComponent(widget?.i, name, {
              name: name,
              text: text,
              onDeleteClick: onDeleteClick,
              onSettingClick: onSettingClick,
              widgetName: widget?.i,
              widgetData: widgetData?.find((w) => String(w.widgetUniqueName) === String(widget?.i)),
              handleAddClick: handleAddClick,
              isMerge: splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged,
              dragHandleClass: "drag-handle",
              signalRelatedContentData: signalRelatedContentData,
            })}

            {splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.dataKeys?.length === 0 &&
              name === "pinned" &&
              !widgetData?.find((w) => String(w.widgetUniqueName) === String(widget?.i)) &&
              splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged === false && (
                <div className="merge-icon">
                  <Tooltip title={MERGE_TEXT} arrow>
                    <Merge
                      height={"24px"}
                      width={"24px"}
                      cursor={"pointer"}
                      onClick={() => mergeWidgets(widget?.i, layout?.[currentBreakpoint]?.[index + 1]?.i, name, currentBreakpoint)}
                      data-tip={MERGE_TEXT}
                    />
                  </Tooltip>
                </div>
              )}
            {splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.dataKeys?.length === 0 &&
              name === "pinned" &&
              !widgetData?.find((w) => String(w.widgetUniqueName) === String(widget?.i)) &&
              splitConfig?.[name]?.[currentBreakpoint]?.[widget?.i]?.isMerged === true && (
                <div className="split-icon">
                  <Tooltip title={SPLIT_TEXT} arrow>
                    <Split
                      height={"24px"}
                      width={"24px"}
                      cursor={"pointer"}
                      onClick={() => splitWidgets(widget?.i, name, currentBreakpoint)}
                      data-tip={SPLIT_TEXT}
                    />
                  </Tooltip>
                </div>
              )}
          </div>
        ))}
    </ResponsiveReactGridLayout>
  );
};

const CustomizableWidgets = (props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  let searchBoxRef = useRef();
  const productVariant = getProductVariant();
  const [industry, setIndustry] = useState([]);
  const [widgetIndustryName, setWidgetIndustryName] = useState("");
  const [widgetIndustryId, setWidgetIndustryId] = useState("");
  const [openTopSideBar, setOpenTopSideBar] = useState(false);
  const [widgetName, setWidgetName] = useState("");
  const [widgetId, setWidgetId] = useState("");
  const [isEditWidget, setIsEditWidget] = useState(false);
  const [sortedWidgetOneIds, setSortedWidgetOneIds] = useState(null);
  const [sortedWidgetTwoIds, setSortedWidgetTwoIds] = useState(null);
  const [sortedWidgetTopSignalsIds, setSortedWidgetTopSignalsIds] = useState(null);
  const [sortedWidgetKeyPlayersIds, setSortedWidgetKeyPlayersIds] = useState(null);
  const [sortedWidgetIds, setSortedWidgetIds] = useState([]);
  const [sortedWidgetOneList, setSortedWidgetOneList] = useState(null);
  const [sortedWidgetTwoList, setSortedWidgetTwoList] = useState(null);
  const [sortedWidgetTopSignalsList, setSortedWidgetTopSignalsList] = useState(null);
  const [sortedWidgetKeyPlayersList, setSortedWidgetKeyPlayersList] = useState(null);
  const [sortedWidgetList, setSortedWidgetList] = useState([]);
  const [contentTypeDataSource, setContentTypeDataSource] = useState([]);
  const [deliverableTypeDataSource, setDeliverableTypeDataSource] = useState([]);
  const [widgetList, setWidgetList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [widgetContentTypeFilter, setWidgetContentTypeFilter] = useState([]);
  const [widgetDeliverableTypeFilter, setWidgetDeliverableTypeFilter] = useState([]);
  const [widgetSGFFilter, setWidgetSGFFilter] = useState("");
  const [widgetTrendFilter, setWidgetTrendFilter] = useState("");
  const [sgfName, setSgfName] = useState("");
  const [selectedTrend, setSelectedTrend] = useState(undefined);
  const { accessRights: aR } = props;
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const rightWidgetList = useSelector((state) => state.customizableWidgetState.rightWidgetList);
  let userId = getLoggedInUser();

  //responsive layout config
  const [layouts, setLayouts] = useState({
    dashboard: {},
    pinned: {},
  });
  const [changeLayouts, setChangeLayouts] = useState({
    dashboard: {},
    pinned: {},
  });
  const [currentLayout, setCurrentLayout] = useState([]);
  const [widgetLayout, setWidgetLayout] = useState("");
  const [pinnedContentMerge, setPinnedContentMerge] = useState({});
  const [grid, setGrid] = useState("");

  /* Common access */
  const accessActionSettings = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SETTINGS);

  /** State for the key players for tabs
   * - Newly Added: newlyaddedWidgetList, newlyAddedWidgetIds
   * - Recently Updated: recentlyUpdatedWidgetList, recentlyUpdatedWidgetIds
   */
  const [updatedWidgetList, setUpdatedWidgetList] = useState([]);
  const [newlyaddedWidgetList, setNewlyaddedWidgetList] = useState([]);
  const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);
  const [newsContentCuration, setNewsContentCuration] = useState("manual");
  const [selectedWidgetUniqueName, setSelectedWidgetUniqueName] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const [widgetDataIds, setWidgetDataIds] = useState([]);
  const { combinedWidgetData } = useSelector((state) => state?.customizableWidgetState);
  const { selectedWidgetData } = useSelector((state) => state?.customizableWidgetState);
  const [newsTabValue, setNewsTabValue] = useState("1");
  const [newlyAddedWidgetList, setNewlyAddedWidgetList] = useState([]);
  const [recentlyUpdatedWidgetList, setRecentlyUpdatedWidgetList] = useState([]);
  const [widgetStatus, setWidgetStatus] = useState({
    featured_content: false,
    signals: false,
    news: false,
    key_players: false,
    upcoming: false,
    new: false,
    key_players_2: false,
  });
  const [sortValue, setSortValue] = React.useState("mostViews");
  const [newsCount, setNewsCount] = React.useState(15);
  const [isNewsCuration, setIsNewsCuration] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [signalRelatedContentIds, setSignalRelatedContentIds] = useState([]);

  const formik = useFormik({
    initialValues: {
      widgetName: "",
      widgetUniqueName: "",
      widgetDisplayName: "",
      widgetContentType: [],
      widgetDeliverableType: [],
      widgetTopSignalsContentType: "Signals",
      widgetTopSignalsDeliverableType: "Top Signals",
      widgetKeyPlayersContentType: "Company/Startups",
      widgetSGF: "",
      widgetTrend: "",
      widgetDashboardContentLink: "",
      widgetTheme: {
        themeColor: "",
        themeName: "",
      },
      isMerged: "NO",
    },
    validationSchema: Yup.object().shape({
      widgetDisplayName: Yup.string()
        .trim()
        .min(3, WIDGET_DISPLAY_NAME_MIN_VALIDATION_TEXT)
        .max(25, WIDGET_DISPLAY_NAME_MAX_VALIDATION_TEXT)
        .required(WIDGET_DISPLAY_NAME_REQUIRED_VALIDATION_TEXT)
        .matches(/^(?=.*[a-zA-Z])(?=.*[&-]?)[a-zA-Z\d\s&-]+$/, WIDGET_DISPLAY_NAME_MATCHES_VALIDATION_TEXT),
    }),
  });

  const resetFormik = () => {
    formik.setValues(formik.initialValues);
  };

  const handleCardClick = (widget) => {
    const deliverableType = WIDGET_FILTER[widget?.name]?.deliverableType || [];
    const contentType = WIDGET_FILTER[widget?.name]?.contentType || [];
    formik.setValues({
      ...formik.values,
      widgetDisplayName: widget?.displayName,
      widgetName: widget?.displayName,
      widgetConfigName: widget?.name,
      widgetDashboardContentLink: widget?.widgetDashboardContentLink,
      widgetContentType: contentType,
      widgetDeliverableType: deliverableType,
      widgetDefaultDashboardContentLink: widget?.widgetDashboardContentLink,
    });

    setSelectedWidgetId(widget?.value);
  };

  const { data: widgetData, isFetching: isSearchFetching } = useQuery({
    queryKey: ["widget-content"],
    queryFn: () => {
      let params = {};
      params.filters = [["configName", "eq", "Add Widget"]];
      return fetchConfig(params);
    },
    suspense: false,
    enabled: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  const {
    data: widgetInitialData,
    refetch: fetingWidgetLayout,
    isFetching: fetchingWidgetLayout,
  } = useQuery({
    queryKey: ["widget-initial", widgetIndustryId],
    queryFn: () => {
      let params = {};
      params.filters = [["widgetIndustryId", "eq", widgetIndustryId]];
      return fetchWidgetLayout(params);
    },
    enabled: !!widgetIndustryId,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  const {
    data: widgetUniqueData,
    refetch: refetchUniqueData,
    isFetching: fetchingWidgetUniqueData,
  } = useQuery({
    queryKey: ["widget-unique", widgetIndustryId],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["widgetIndustryId", "eq", widgetIndustryId],
        ["productVariant.productName", "eq", productVariant],
        ["widgetActive", "eq", "YES"],
      ];
      return fetchWidget(params);
    },
    enabled: !!widgetIndustryId?.length,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  const pinedContentIds = useMemo(
    () => widgetUniqueData?.data?.data.flatMap((a) => a.widgetContent.map((d) => d.widgetContentId)),
    [widgetUniqueData?.data?.data]
  );
  const {
    data: pinnedContentData,
    refetch: refetchPinnedContent,
    isFetching: fetchingPinnedContent,
  } = useQuery({
    queryKey: ["pinned-content-data"],
    queryFn: () => {
      let params = {
        filters: [
          ["industry_tag.trend_id", "eq", widgetIndustryId],
          ["_id", "in", pinedContentIds],
        ],
        limit: 2000,
      };
      return fetchWidgetList(params);
    },
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    enabled: !!pinedContentIds?.length > 0,
  });

  const { data: combinedData, isFetching: fetchingCombineData } = useQuery({
    queryKey: ["combined-data", widgetDataIds],
    enabled: !!widgetDataIds?.length,
    queryFn: () => {
      let params = {};
      params.filters = [["_id", "in", widgetDataIds]];
      params.limit = widgetDataIds?.length || 2000;
      return getCombinedData(params);
    },
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });
  const { data: userDetails, isFetching: fetchingUserData } = useQuery({
    queryKey: ["user-data", userId?._id],
    enabled: !!userId?._id,
    queryFn: () => fetchSingleUser(userId?._id),
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    if (!combinedWidgetData?.length) return setSignalRelatedContentIds([]);
    const ids = combinedWidgetData
      .filter((obj) => obj.signalRelatedContentType === "Report")
      .map((signal) => signal.signalRelatedContentReportId)
      .filter((s) => s);
    setSignalRelatedContentIds(ids);
  }, [combinedWidgetData, openTopSideBar]);

  const { data: signalRelatedContentData, isFetching: fetchingRelatedData } = useQuery({
    queryKey: ["signalRelatedSidebar", signalRelatedContentIds],
    queryFn: () => {
      const params = {
        filters: [["_id", "in", signalRelatedContentIds]],
        fields: ["profileImage", "entityType"],
        limit: signalRelatedContentIds?.length,
      };
      return getCombinedData(params);
    },
    enabled: !!signalRelatedContentIds?.length,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    if (pinnedContentData?.data?.data?.length < 1) return;
    dispatch(setCombinedData(pinnedContentData?.data?.data));
  }, [pinnedContentData?.data]);

  /** For newly Added */
  const newlyAddedItems = useMemo(() => {
    return sortedWidgetKeyPlayersIds?.filter((item) => item && item?.widgetContentKeyPlayerCategory === "Newly Added");
  }, [sortedWidgetKeyPlayersIds]);

  /** Fetch companies in newlyAdded Tab */
  const f = useMemo(
    () => sortedWidgetKeyPlayersList?.flatMap((d) => (newlyAddedItems?.find((f) => f?.id === d?.id) ? [d] : [])),
    [sortedWidgetKeyPlayersList, newlyAddedItems]
  );

  /** */

  /** For Recently Updated */
  const recenltlyUpdatedItems = useMemo(() => {
    return sortedWidgetKeyPlayersIds?.filter((item) => item && item?.widgetContentKeyPlayerCategory === "Recently Updated");
  }, [sortedWidgetKeyPlayersIds]);

  /** Fetch companies in recently Tab */
  const recentlyUpdated = useMemo(
    () => sortedWidgetKeyPlayersList?.flatMap((d) => (recenltlyUpdatedItems?.find((f) => f?.id === d?.id) ? [d] : [])),
    [sortedWidgetKeyPlayersList, recenltlyUpdatedItems]
  );

  useEffect(() => {
    if ((formik?.values?.widgetConfigName !== "key_players" || formik?.values?.widgetConfigName !== "key_players_2") && !rightWidgetList?.length) {
      setNewlyAddedWidgetList([]);
      setRecentlyUpdatedWidgetList([]);
      return;
    }
    const newlyAddedArray = rightWidgetList
      .filter((c) => c.widgetContentKeyPlayerCategory === "Newly Added")
      .sort((a, b) => a.widgetContentOrder - b.widgetContentOrder);

    const recentlyUpdatedArray = rightWidgetList
      .filter((c) => c.widgetContentKeyPlayerCategory === "Recently Updated")
      .sort((a, b) => a?.widgetContentOrder - b?.widgetContentOrder);

    setNewlyAddedWidgetList(newlyAddedArray);
    setRecentlyUpdatedWidgetList(recentlyUpdatedArray);
  }, [rightWidgetList, formik?.values]);

  /** Tab panel for MUI tabs */
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleNewsContentCurationChange = (event) => {
    setNewsContentCuration(event.target.value);
    setIsNewsCuration(true);
    dispatch(setRightSideWidgetList([]));
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const sourceIndex = result?.source?.index;
    const destIndex = result?.destination?.index;

    const newItems = Array.from(rightWidgetList);
    const [reorderedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(destIndex, 0, reorderedItem);
    newItems.forEach((element, i) => {
      element.widgetContentOrder = i + 1;
    });
    dispatch(setRightSideWidgetList(newItems));
  };

  const updateWidgetListOrder = (list, sourceIndex, destIndex, setListFunction) => {
    const newItems = Array.from(list);
    const [reorderedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(destIndex, 0, reorderedItem);
    newItems.forEach((element, i) => {
      element.widgetContentOrder = i + 1;
    });
    setListFunction(newItems);
  };

  const onDragEndKeyPlayer = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (newsTabValue === "1") {
      updateWidgetListOrder(newlyAddedWidgetList, sourceIndex, destIndex, setNewlyAddedWidgetList);
    } else {
      updateWidgetListOrder(recentlyUpdatedWidgetList, sourceIndex, destIndex, setRecentlyUpdatedWidgetList);
    }
  };

  const handleDelete = (Id) => {
    setSortedWidgetIds(sortedWidgetIds.filter((item) => item.id !== Id));
    let newArray = sortedWidgetList.filter((ele) => ele.id !== Id);
    newArray.map((e, i) => {
      e.widgetContentOrder = i + 1;
      return e;
    });

    setSortedWidgetList(newArray);
    const updatedData = JSON.parse(JSON.stringify(widgetList));

    for (const item of updatedData) {
      if (item.id === Id) {
        item.isSelected = false;
      }
    }
    setWidgetList(updatedData);
  };

  /**
   * @description build json for tree
   * @param {Array} data
   * @param {String} parentId
   */
  const buildTreeJson = (data, parentId) => {
    const result = [];
    data.forEach((element) => {
      element["value"] = element.id;
      element["label"] = element.trendName;
      element["showCheckbox"] = false;
      element["taxonomySortOrder"] = element.trendSortOrder;
      element["level"] = element.trendLevel;
      if (element?.trendIsHidden === "YES") {
        element["icon"] = "hidden";
      }
      if (element?.trendIsDisabled === "YES") {
        element["icon"] = "disabled";
      }
      if (element?.trendIsDisabled === "YES" && element?.trendIsHidden === "YES") {
        element["icon"] = "hidden-disabled";
      }
      if (element["trendParentId"] == parentId) {
        const children = buildTreeJson(data, element["id"]);
        if (children.length > 0) {
          element["children"] = children;
        }
        result.push(element);
      }
    });
    return result;
  };

  const fetchIndustry = () => {
    dispatch(showLoader());
    fetchTaxonomyTrend({
      fields: ["id", "trendName"],
      filters: [["trendParentId", "eq", 0]],
      limit: import.meta.env.VITE_TAXONOMY_LIMIT || 10000,
      sort: "trendName:asc",
    })
      .then((res) => {
        setWidgetIndustryName(res?.data?.data[0]?.trendName);
        setWidgetIndustryId(res?.data?.data[0]?.id);
        setIndustry(res?.data?.data);
        setTimeout(() => {
          dispatch(hideLoader());
        }, [100]);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.error(err);
      });
  };

  const industryDropdownData = useMemo(() => {
    if (!userDetails?.data?.data || industry?.length < 1) return;
    if (userDetails?.data?.data?.userCompentencyIndustry?.length > 0) {
      const compentencyIndustryId = userDetails?.data?.data?.userCompentencyIndustry?.map((industry) => industry.industryId);
      const response = industry?.filter((a) => compentencyIndustryId.includes(a?.id));
      setWidgetIndustryName(response[0]?.trendName);
      setWidgetIndustryId(response[0]?.id);
      return response;
    }
    return industry;
  }, [userDetails?.data?.data, industry]);

  const fetchContentType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "InsiderV2"]);
    const response = await getContentType({ filters: filters });
    if (response?.data && response?.data?.data) {
      const resData = response.data.data.map((v) => {
        return { label: v.contentType, value: v.contentType };
      });
      if (resData) {
        setContentTypeDataSource(resData);
      } else {
        setContentTypeDataSource([]);
      }
    }
  };

  const fetchDeliverableType = async () => {
    let filters = [];
    filters.push(["contentTypeProductVariant", "eq", "Insider"]);
    const response = await getContentType({ filters: filters });

    if (response?.data && response?.data?.data) {
      const resData = response.data.data.map((v) => {
        return { label: v.contentType, value: v.contentType };
      });
      if (resData) {
        setDeliverableTypeDataSource(resData);
      } else {
        setDeliverableTypeDataSource([]);
      }
    }
  };

  const handleSaveWidget = async () => {
    const res = await formik.validateForm();
    if (Object.keys(res)?.length) {
      const touched = {};
      Object.keys(res).map((field) => {
        touched[field] = true;
        return touched;
      });
      return formik.setFormikState({ ...formik, touched: touched, errors: res });
    }
    if (Object.keys(formik.errors).length !== 0 || formik?.values?.widgetDisplayName == "") return;
    dispatch(
      showAlertBox({
        okCallback: handleSaveDashboardContent,
        okText: "Save",
        cancelText: "Cancel",
        content: `Are you sure you want to publish the ${formik.values?.widgetName?.toLowerCase()}? Once published, the ${formik.values?.widgetName?.toLocaleLowerCase()} will be displayed on the platform homepage.`,
        title: "dialogAlertCss",
      })
    );
  };

  const getWidgetContentType = (contentType) => {
    if (contentType === "company") return "Company";
    else if (contentType === "video") return "Video";
    else if (contentType === "news") return "News";
    else if (contentType === "document") return "Deliverable";
  };

  const resetWidgetState = useCallback(() => {
    setOpenTopSideBar(false);
    setWidgetId("");
    setIsEditWidget(false);
    setWidgetName("");
    formik.setFormikState({ ...formik, touched: {}, errors: {} });
    dispatch(hideLoader());
    dispatch(setRightSideWidgetList([]));
    dispatch(setSelectedWidgetData({}));
    setNewsTabValue("1");
    setNewlyAddedWidgetList([]);
    setRecentlyUpdatedWidgetList([]);
    resetFormik();
    resetSelectedWidgetId();
    setNewsContentCuration("manual");
    setNewsCount(15);
    setIsNewsCuration(false);
    setSortValue("mostViews");
    dispatch(setPreviewData([]));
  }, [formik, dispatch]);

  const handleSavePinnedContent = async () => {
    dispatch(showLoader());
    formik.validateForm().then(async (res) => {
      if (Object.keys(res).length) {
        dispatch(hideLoader());
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        try {
          let payload = {
            widgetUniqueName: formik?.values?.widgetUniqueName,
            widgetName: formik?.values?.widgetName,
            widgetDisplayName: formik?.values?.widgetDisplayName,
            widgetIndustryId: widgetIndustryId,
            widgetIndustryName: widgetIndustryName,
            widgetIsMerged: formik?.values?.isMerged,
            widgetTheme: { themeName: formik?.values?.widgetTheme?.themeName, themeColor: formik?.values?.widgetTheme?.themeColor },
            widgetProductPlatform: "Insider V2",
            productVariant: [{ productName: productVariant }],
            widgetActive: "YES",
            widgetConfigName: formik?.values?.widgetConfigName,
            widgetDeliverableType: formik?.values?.widgetDeliverableType,
            widgetContentType: formik?.values?.widgetContentType,
            widgetContent: rightWidgetList.map((content) => ({
              widgetContentId: content?.id,
              widgetContentType: content?.entityType === "document" ? "Deliverable" : content?.entityType,
            })),
          };

          if (isEditWidget) {
            payload.widgetModifiedByUserId = userId?._id;
            payload.widgetModifiedByUserName = userId?.user_fname + " " + userId?.user_lname;
            await updateWidget(selectedWidgetData?.id, payload);
          } else {
            payload.widgetCreatedByUserId = userId?._id;
            payload.widgetCreatedByUserName = userId?.user_fname + " " + userId?.user_lname;
            await saveSingleWidget(payload);
          }
          updateSplitConfigData(payload?.widgetUniqueName, widgetName, "save");
          formik.setFormikState({ ...formik, touched: {}, errors: {} });
          resetWidgetState();
          queryClient.invalidateQueries(["widget-unique"]);
          queryClient.invalidateQueries(["pinned-content-data"]);
          dispatch(hideLoader());
          if (isEditWidget) return dispatch(actionSuccess(PINNED_UPDATED_SUCCESS_MESSAGE));
          return dispatch(actionSuccess(PINNED_ADDED_SUCCESS_MESSAGE));
        } catch (error) {
          dispatch(hideLoader());
          dispatch(actionError(error?.data?.message));
        }
      }
    });
  };

  const enableWarningModal = (data) => {
    const classNameWarningContainer = "modal-warning-container",
      classNamePaddingLeft = "modal-warning-list";
    let { keyPlayers } = data,
      message = `<div class=${classNameWarningContainer}><span>${KEYPLAYERS_CONFLICT_MESSAGE_FIRST_LINE} ${KEYPLAYERS_CONFLICT_MESSAGE_SECOND_LINE}</span><br/>`;
    if (keyPlayers && Array.isArray(keyPlayers)) {
      message += `<div><ul class=${classNamePaddingLeft}>`;
      for (let keyPlayer of keyPlayers) {
        message += `<li><b>${keyPlayer?.companyName}</b> to <b>${keyPlayer?.widgetContentKeyPlayerCategory}</b></li>`;
      }
      message += `</ul></div></div>`;
      dispatch(
        showAlertBox({
          okCallback: async () => {
            // process.nextTick(() => {
            //   dispatch(hideLoader());
            // });
          },
          okText: "OK",
          hideCancelButton: true,
          content: message,
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const handleSaveDashboardContent = async () => {
    try {
      const res = await formik.validateForm();
      if (Object.keys(res)?.length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        let errors = Object.keys(res);
        if (errors.length > 0) dispatch(actionError(res[errors[0]]));
        return formik.setFormikState({ ...formik, touched: touched, errors: res });
      }
      const deliverableType = WIDGET_FILTER[formik.values?.widgetConfigName]?.deliverableType || [],
        contentType = WIDGET_FILTER[formik.values?.widgetConfigName]?.contentType || [];
      let payload = {
        widgetName: formik.values?.widgetName,
        widgetDisplayName: formik.values?.widgetDisplayName.trim(),
        widgetIndustryId: widgetIndustryId,
        widgetIndustryName: widgetIndustryName,
        widgetProductPlatform: "Insider V2",
        productVariant: [{ productName: productVariant }],
        widgetDashboardContentLink: formik.values?.widgetDashboardContentLink,
        widgetUniqueName: formik.values?.widgetUniqueName,
        widgetConfigName: formik.values?.widgetConfigName,
        /** Start of deliverable type and content type comments
         * For future case if needs to update the deliverable type and content type based on changes use these commentted codes below
         */
        // widgetDeliverableType: formik?.values?.widgetDeliverableType,
        // widgetContentType: formik?.values?.widgetContentType,
        /** End of commented code for deliverable type and content type*/
        widgetDeliverableType: deliverableType,
        widgetContentType: contentType,
      };
      if (formik?.values?.widgetConfigName === "news") payload["widgetContentCuration"] = newsContentCuration;

      let combinedList = [];
      if (formik?.values?.widgetConfigName === "key_players" || formik?.values?.widgetConfigName === "key_players_2") {
        combinedList = [
          ...newlyAddedWidgetList.map((item, index) => ({
            ...item,
            widgetContentOrder: item.widgetContentOrder ? item.widgetContentOrder : index + 1,
          })),
          ...recentlyUpdatedWidgetList.map((item, index) => ({
            ...item,
            widgetContentOrder: item.widgetContentOrder ? item.widgetContentOrder : index + 1,
          })),
        ];
      } else {
        combinedList = rightWidgetList.map((item, index) => ({
          ...item,
          widgetContentOrder: item.widgetContentOrder ? item.widgetContentOrder : index + 1,
        }));
      }
      payload.widgetContent = combinedList.map((item, i) => ({
        widgetContentId: item.id,
        widgetContentOrder: item.widgetContentOrder,
        widgetContentType: getWidgetContentType(item.entityType),
        ...(item.widgetContentKeyPlayerCategory && { widgetContentKeyPlayerCategory: item.widgetContentKeyPlayerCategory }),
      }));

      dispatch(showLoader());
      if (isEditWidget) {
        payload["widgetCreatedByUserId"] = userId._id;
        payload["widgetCreatedByUserName"] = userId.user_fname + " " + userId.user_lname;
        let response = await updateWidget(selectedWidgetData?.id, payload);
        dispatch(hideLoader());
        if (KEY_PLAYER_WIDGETS[formik?.values?.widgetConfigName]) {
          if (response?.data?.data?.isConflict) {
            enableWarningModal(response?.data?.data);
            return;
          } else {
            setWidgetStatus((prev) => ({ ...prev, [formik.values.widgetConfigName]: true }));
            dispatch(actionSuccess(WIDGET_UPDATE_MESSAGE));
          }
        } else {
          setWidgetStatus((prev) => ({ ...prev, [formik.values.widgetConfigName]: true }));
          dispatch(actionSuccess(WIDGET_UPDATE_MESSAGE));
        }
      } else {
        payload["widgetCreatedByUserId"] = userId._id;
        payload["widgetCreatedByUserName"] = userId.user_fname + " " + userId.user_lname;
        let response = await saveWidget(payload);
        dispatch(hideLoader());
        if (KEY_PLAYER_WIDGETS[formik?.values?.widgetConfigName]) {
          if (response?.data?.data?.isConflict) {
            enableWarningModal(response?.data?.data);
            return;
          } else {
            setWidgetStatus((prev) => ({ ...prev, [formik.values.widgetConfigName]: true }));
            dispatch(actionSuccess(WIDGET_ADDED_SUCCESS_MESSAGE));
            resetSelectedWidgetId();
          }
        } else {
          setWidgetStatus((prev) => ({ ...prev, [formik.values.widgetConfigName]: true }));
          dispatch(actionSuccess(WIDGET_ADDED_SUCCESS_MESSAGE));
          resetSelectedWidgetId();
        }
      }
      updateSplitConfigData(payload?.widgetUniqueName, widgetName, "save");
      dispatch(hideLoader());
      refetchUniqueData();
      resetWidgetState();
    } catch (error) {
      console.log(error, "error");
      dispatch(hideLoader());
      dispatch(actionError(error?.data?.message));
    }
  };

  // Define a function to handle checkbox changes
  const handleCheckboxChange = (index) => {
    const updatedData = JSON.parse(JSON.stringify(widgetList)); // Create a copy of the array
    updatedData[index].isSelected = !updatedData[index].isSelected; // Toggle the isSelected property

    let tempAddArray = sortedWidgetList;
    let tempAddId = sortedWidgetIds;

    // Iterate through the widget list
    updatedData.forEach((item) => {
      if (item.isSelected === true && !tempAddArray.some((obj) => obj.id === item.id)) {
        const newItem = { ...item, widgetContentOrder: tempAddArray.length + 1 };
        const newItemId = { id: item.id, widgetContentOrder: tempAddArray.length + 1, widgetContentType: getWidgetContentType(item.entityType) };
        tempAddArray.push(newItem);
        tempAddId.push(newItemId);
      }
    });
    setSortedWidgetList(tempAddArray);
    setSortedWidgetIds(tempAddId);
    setWidgetList(updatedData);
  };

  const searchWidgets = (index) => {
    let text = searchBoxRef.current.element.value;
    setSearchText(text);
  };

  useEffect(() => {
    dispatch(setBreadCrumb([]));
    fetchIndustry();
    fetchContentType();
    fetchDeliverableType();
  }, []);

  useEffect(() => {
    if (
      widgetList?.length ||
      sortedWidgetOneIds?.length ||
      sortedWidgetTwoIds?.length ||
      sortedWidgetTopSignalsIds?.length ||
      sortedWidgetKeyPlayersIds?.length
    ) {
      setSortedWidgetOneList(
        sortedWidgetOneIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
      setSortedWidgetTwoList(
        sortedWidgetTwoIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
      setSortedWidgetTopSignalsList(
        sortedWidgetTopSignalsIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
      setSortedWidgetKeyPlayersList(
        sortedWidgetKeyPlayersIds?.map((obj) => {
          const matchingItem = widgetList.find((item) => item.id === obj.id);
          if (matchingItem) {
            return {
              ...matchingItem,
              id: obj.id,
              widgetContentOrder: obj.widgetContentOrder,
            };
          }
          return null; // Return null for items not found in widgetList
        })
      );
    }
    if (!sortedWidgetOneIds) setSortedWidgetOneList(null);
    else if (!sortedWidgetOneIds?.length) setSortedWidgetOneList([]);
    if (!sortedWidgetTwoIds) setSortedWidgetTwoList(null);
    else if (!sortedWidgetTwoIds?.length) setSortedWidgetTwoList([]);
    if (!sortedWidgetTopSignalsIds) setSortedWidgetTopSignalsList(null);
    else if (!sortedWidgetTopSignalsIds?.length) setSortedWidgetTopSignalsList([]);
    if (!sortedWidgetKeyPlayersIds) setSortedWidgetKeyPlayersList(null);
    else if (!sortedWidgetKeyPlayersIds?.length) setSortedWidgetKeyPlayersList([]);
  }, [widgetList, sortedWidgetOneIds, sortedWidgetTwoIds, sortedWidgetTopSignalsIds, sortedWidgetKeyPlayersIds]);

  /* Interface actions */
  useEffect(() => {
    const interfaceName = "Listing";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  /** Widget count for key players*/
  const widgetCount = updatedWidgetList
    .concat(newlyaddedWidgetList || [])
    .reduce((count, item) => (item?.sgfId === sgfName && item?.trendId === selectedTrend ? count + 1 : count), 0);

  const totalCount = widgetList
    ? widgetList.filter(
        (item) =>
          !item.isSelectedUploaded &&
          !item.isSelectedNewlyAdded &&
          !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
          item.title?.toLowerCase()?.includes(searchTextFilter.toLowerCase()) &&
          (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
          (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType)) &&
          item.sgfTags.some((trend) => trend.trendName === widgetSGFFilter) &&
          item.trendTags.some((trend) => trend.trendName === widgetTrendFilter)
      ).length
    : 0;

  // for save dashboard functionality
  /**const synchronizeLayouts = () => {
    let currentLayouts = { ...changeLayouts };
    let curentLayoutChange = [...currentLayout];
    if (Array.isArray(curentLayoutChange) && curentLayoutChange.length > 0) {
      for (let layout of curentLayoutChange) {
        const { i: widgt_name, x: widget_x, y: widget_y } = layout;
        let orderIndex = LAYOUT_ORDER?.[grid]?.findIndex((layout) => layout.x == widget_x && layout.y == widget_y);
        for (let breakPoint of GRID_MEASURES) {
          if (breakPoint !== grid && orderIndex !== -1) {
            let index = currentLayouts?.[widgetName]?.[breakPoint]?.findIndex((layout) => layout?.i == widgt_name);
            if (index !== -1) {
              const { x, y } = LAYOUT_ORDER?.[breakPoint][orderIndex];
              currentLayouts[widgetName][breakPoint][index] = {
                ...currentLayouts[widgetName][breakPoint][index],
                x: x,
                y: y,
              };
            }
          }
        }
      }
      return currentLayouts;
    }
    return layouts;
  };*/

  {
    /* 
    * save dashboard functionality
    * This is used to save the drag and drop things in dashboard widgets
    * Later if we need to enable drag and drop functionality enable this section and check the functionality 

  const handleSaveLayout = () => {
    try {
      const changedLayout = synchronizeLayouts();
      if (isDrag && changedLayout) {
        dispatch(
          showAlertBox({
            okText: "Save",
            cancelText: "Cancel",
            content: LAYOUT_CONFIRMATION_MESSAGE,
            title: "dialogAlertCss",
            okCallback: async () => {
              handleLayoutUpdate(changedLayout, pinnedContentMerge);
              dispatch(actionSuccess(LAYOUT_SUCCESS_MESSAGE));
            },
            cancelCallback: async () => {
              setIsDrag(true);
            },
          })
        );
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
 */
  }
  const handleLayoutChange = (layout, allLayouts, type, breakpoint) => {
    if (isDrag && type === "dashboard") {
      let currentLayouts = { ...layouts };
      const layoutChanges = currentLayouts?.[type]?.[breakpoint]?.filter(
        ({ i: i1, x: x1, y: y1 }) => !layout.some(({ i: i2, x: x2, y: y2 }) => i2 === i1 && x2 === x1 && y2 === y1)
      );
      if (Array.isArray(layoutChanges) && layoutChanges?.length > 0) {
        if (allLayouts && currentLayouts) {
          currentLayouts = {
            ...currentLayouts,
            [type]: allLayouts,
          };
          // curent layout it should not affect
          // setLayouts((prevLayouts) => ({
          //   ...prevLayouts,
          //   [type]: allLayouts,
          // }));
          setChangeLayouts(currentLayouts);
          setCurrentLayout(layout);
          setWidgetName(type);
        }
      } else {
        setIsDrag(false);
      }
    }
    // else {
    //   if(type === "pinned"){
    //       setLayouts((prevLayouts) => ({
    //         ...prevLayouts,
    //         [type]: allLayouts,
    //       }));
    //   }
    // }
  };

  const handleDrag = (layout, oldItem, newItem, placeholder, e, element) => {
    // if (newItem.x < 0) newItem.x = 0;
    // if (newItem.y < 0) newItem.y = 0;

    // const gridWidth = 10; // Number of columns in the grid
    // if (newItem.x + newItem.w > gridWidth) {
    //   newItem.x = gridWidth - newItem.w;
    // }
    setIsDrag(true);
  };

  const handleAddClick = (name, widgetUniqueName) => {
    setWidgetName(name);
    const deliverableType = WIDGET_FILTER["pinned"]?.deliverableType || [];
    const contentType = WIDGET_FILTER["pinned"]?.contentType || [];
    if (name !== "dashboard") {
      formik.setValues({
        ...formik.values,
        widgetDisplayName: PINNED_CONTENT,
        widgetName: PINNED_CONTENT,
        widgetUniqueName: widgetUniqueName,
        widgetContentType: contentType,
        widgetDeliverableType: deliverableType,
      });
      return setOpenTopSideBar(true);
    }
    setShowAddWidgetModal(true);
    formik.setValues({ ...formik.values, widgetUniqueName: widgetUniqueName });
  };

  const updateSplitConfigData = (i, type, action) => {
    let previousConfig = { ...pinnedContentMerge };
    let currentLayouts = { ...layouts };
    //update all grids
    if (previousConfig && currentLayouts) {
      for (let grid of GRID_MEASURES) {
        let widgetKey = pinnedContentMerge?.[type]?.[grid]?.["ref"]?.[i];
        let splitConfig = pinnedContentMerge?.[type]?.[grid]?.[widgetKey];
        let dataKeys = splitConfig?.dataKeys;
        let index = Array.isArray(dataKeys) && dataKeys.indexOf(i);
        if (splitConfig) {
          if (action === "save" && index) {
            //if data is save & publish
            if (index === -1) {
              dataKeys = [...dataKeys, i];
            }
            splitConfig = {
              ...splitConfig,
              ["isData"]: true,
              ["dataKeys"]: dataKeys,
            };
            //When drag and drop will start need to check this
            // let dashoardLayout = currentLayouts?.[type]?.[grid];
            // for (let index in dashoardLayout) {
            //   if (dashoardLayout[index]?.i == i) {
            //     dashoardLayout[index].static = false;
            //     break; //Stop this loop, we found it!
            //   }
            // }
            // currentLayouts = {
            //   ...currentLayouts,
            //   [type]: {
            //     ...currentLayouts?.[type],
            //     [grid]: dashoardLayout,
            //   },
            // };
          } else if (action === "delete" && index >= 0) {
            //if data is delete
            if (index !== -1) {
              dataKeys.splice(index, 1);
              splitConfig = {
                ...splitConfig,
                ["dataKeys"]: dataKeys,
                ["isData"]: dataKeys.length > 0 ? true : false,
              };
            }
            //When drag and drop will start need to check this
            // let dashoardLayout = currentLayouts?.[type]?.[grid];
            // for (let index in dashoardLayout) {
            //   if (dashoardLayout[index]?.i == i) {
            //     dashoardLayout[index].static = true;
            //     break; //Stop this loop, we found it!
            //   }
            // }
            // currentLayouts = {
            //   ...currentLayouts,
            //   [type]: {
            //     ...currentLayouts?.[type],
            //     [grid]: dashoardLayout,
            //   },
            // };
          }
          previousConfig = {
            ...previousConfig,
            [type]: {
              ...previousConfig?.[type],
              [grid]: {
                ...previousConfig?.[type]?.[grid],
                [widgetKey]: splitConfig,
              },
            },
          };
        }
      }
      handleLayoutUpdate(layouts, previousConfig);
    }
  };

  function updateKeys(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(updateKeys);
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = key.replace(/(\D+)(\d+)/, "$1_$2");
      acc[newKey] = updateKeys(value); // Recursively update keys for nested objects
      return acc;
    }, {});
  }

  const createMergeandSplitConfig = () => {
    let splitConfig = { ...pinnedContentMerge };
    if (widgetInitialData?.data?.data[0]?.widgetSplitConfig) {
      const updatedWidgetSplitConfig = updateKeys(widgetInitialData?.data?.data[0]?.widgetSplitConfig);
      return setPinnedContentMerge(updatedWidgetSplitConfig);
    }
    /** New split config structure with type and grid basis*/
    let widgetTypes = Object.keys(layouts);

    for (let type of widgetTypes) {
      for (let grid of GRID_MEASURES) {
        let gridLayout = layouts?.[type]?.[grid];
        if (Array.isArray(gridLayout) && gridLayout?.length > 0) {
          for (let index in gridLayout) {
            let nextIndex = Number(index) + 1;
            if (index % 2 === 0 && layouts?.[type]?.[grid]?.length > index) {
              const config1 = layouts?.[type]?.[grid]?.[index];
              const config2 = layouts?.[type]?.[grid]?.[nextIndex];
              let dataKey = [];
              const configData1 = widgetUniqueData?.data?.data?.find((widgetData) => widgetData?.widgetUniqueName === config1?.i);
              configData1?.widgetUniqueName && dataKey.push(configData1?.widgetUniqueName);
              const configData2 = widgetUniqueData?.data?.data?.find((widgetData) => widgetData?.widgetUniqueName === config2?.i);
              configData2?.widgetUniqueName && dataKey.push(configData2?.widgetUniqueName);
              if (config1?.i && config2?.i) {
                splitConfig = {
                  ...splitConfig,
                  [type]: {
                    ...splitConfig?.[type],
                    [grid]: {
                      ...splitConfig?.[type]?.[grid],
                      ref: {
                        ...splitConfig?.[type]?.[grid]?.ref,
                        [config1?.i]: config1?.i,
                        [config2?.i]: config1?.i,
                      },
                      [config1?.i]: {
                        // [config1?.i]: config1,
                        // [config2?.i]: config2,
                        layouts: [config1, config2],
                        isMerged: false,
                        isData: configData1 || configData2 ? true : false,
                        dataKeys: dataKey,
                      },
                    },
                  },
                };
              }
            }
          }
          setPinnedContentMerge(splitConfig);
        }
      }
    }
  };
  const handleLayoutUpdate = async (prevLayouts, splitConfig) => {
    try {
      dispatch(showLoader());
      const layoutPayload = {
        widgetSequence: prevLayouts,
        widgetSplitConfig: splitConfig,
        widgetModifiedByUserId: userId?._id,
        widgetModifiedByUserName: userId?.user_fname + " " + userId?.user_lname,
        widgetProductPlatform: widgetLayout?.widgetProductPlatform,
        widgetIndustryId: widgetLayout?.widgetIndustryId,
      };
      await updateSingleWidgetLayout(widgetLayout?.id, layoutPayload);
      fetingWidgetLayout();
      dispatch(hideLoader());
      setIsDrag(false);
    } catch (error) {
      dispatch(hideLoader());
      dispatch(actionError(error?.data?.message));
    }
  };

  const handleMergeClick = (i1, i2, type, grid) => {
    // update for all grids
    let currentLayouts = { ...layouts };
    let splitConfig = { ...pinnedContentMerge };
    if (currentLayouts && splitConfig) {
      for (let grid of GRID_MEASURES) {
        let layout = currentLayouts?.[type]?.[grid];
        const config1 = layout.find((item) => item?.i === i1);
        const config2 = layout.find((item) => item?.i === i2);
        if (config1 && config2) {
          const mergedConfig = {
            i: config1?.i,
            x: Math.min(config1?.x, config2?.x),
            y: Math.min(config1?.y, config2?.y),
            w: config1?.w + config2?.w,
            h: Math.max(config1?.h, config2?.h),
            moved: false,
            static: true,
          };
          const newLayout = layout
            .filter((item) => item?.i !== i2)
            .map((item) => (item?.i === i1 ? mergedConfig : item))
            .sort((a, b) => a?.x - b?.x);

          splitConfig = {
            ...splitConfig,
            [type]: {
              ...splitConfig?.[type],
              [grid]: {
                ...splitConfig?.[type]?.[grid],
                [config1?.i]: {
                  ...splitConfig?.[type]?.[grid]?.[config1?.i],
                  isMerged: true,
                },
              },
            },
          };
          currentLayouts = {
            ...currentLayouts,
            [type]: {
              ...currentLayouts?.[type],
              [grid]: newLayout,
            },
          };
        }
      }
      setPinnedContentMerge(splitConfig);
      setLayouts(currentLayouts);
      handleLayoutUpdate(currentLayouts, splitConfig);
    }
  };

  const handleSplitClick = (i, type, grid) => {
    // update for all grids
    let splitConfig = { ...pinnedContentMerge };
    let currentLayout = { ...layouts };
    if (splitConfig && currentLayout) {
      for (let grid of GRID_MEASURES) {
        let newLayout = currentLayout?.[type]?.[grid]?.filter((item) => item?.i !== i);
        let widgetIndex = splitConfig?.[type]?.[grid]?.ref?.[i];
        let specificSplitConfig = splitConfig?.[type]?.[grid]?.[widgetIndex];
        newLayout = [...newLayout, ...specificSplitConfig?.layouts];
        newLayout = newLayout.sort((a, b) => a?.x - b?.x);
        currentLayout = {
          ...currentLayout,
          [type]: {
            ...currentLayout?.[type],
            [grid]: newLayout,
          },
        };
        specificSplitConfig = { ...specificSplitConfig, isMerged: false };
        splitConfig = {
          ...splitConfig,
          [type]: {
            ...splitConfig?.[type],
            [grid]: {
              ...splitConfig?.[type]?.[grid],
              [widgetIndex]: specificSplitConfig,
            },
          },
        };
      }

      setLayouts(currentLayout);
      setPinnedContentMerge(splitConfig);
      handleLayoutUpdate(currentLayout, splitConfig);
    }
  };

  const onDeleteClick = (type, id, i) => {
    id &&
      dispatch(
        showAlertBox({
          okCallback: async () => {
            deleteWidget(id)
              .then((response) => {
                refetchUniqueData();
                refetchPinnedContent();
                updateSplitConfigData(i, type, "delete");
                dispatch(actionSuccess(WIDGET_REMOVE_SUCCESS_MESSAGE));
              })
              .catch((err) => {
                dispatch(actionError(err.data?.message || WIDGET_DELETE_FAILED_MESSAGE));
              });
          },
          okText: "Remove",
          cancelText: "Cancel",
          content: WIDGET_REMOVE_CONFIRMATION_MESSAGE,
          title: "dialogAlertCssWarning",
        })
      );
  };

  const onSettingClick = async (data) => {
    const deliverableType = WIDGET_FILTER[data?.widgetConfigName]?.deliverableType || [],
      contentType = WIDGET_FILTER[data?.widgetConfigName]?.contentType || [];
    dispatch(setSelectedWidgetData(data));
    setWidgetName(data?.widgetName === PINNED_CONTENT ? "pinned" : "dashboard");
    let rightData = null;
    if (data?.widgetConfigName === "key_players" || data?.widgetConfigName === "key_players_2")
      rightData = getKeyPlyersData(null, data, combinedWidgetData);
    else rightData = getData(null, data, combinedWidgetData);
    if (rightData?.length > 0) dispatch(setRightSideWidgetList(rightData));
    let widgetDashboardContentLink = widgetData?.data?.data?.[0]?.configValue?.find(
      (d) => d?.name === data?.widgetConfigName
    )?.widgetDashboardContentLink;
    setOpenTopSideBar(true);
    setIsEditWidget(true);
    formik.setValues({
      ...formik.values,
      widgetDisplayName: data?.widgetDisplayName,
      widgetName: data?.widgetName,
      widgetUniqueName: data?.widgetUniqueName,
      widgetDeliverableType: deliverableType || data?.widgetDeliverableType || [],
      widgetContentType: contentType || data?.widgetContentType || [],
      widgetConfigName: data?.widgetConfigName,
      widgetDashboardContentLink: data?.widgetDashboardContentLink,
      widgetDefaultDashboardContentLink: widgetDashboardContentLink,
    });
    if (data.widgetName === PINNED_CONTENT)
      formik.setValues({
        ...formik.values,
        widgetDisplayName: data?.widgetDisplayName,
        widgetName: data?.widgetName,
        widgetUniqueName: data?.widgetUniqueName,
        widgetDeliverableType: data?.widgetDeliverableType,
        widgetContentType: data?.widgetContentType,
        widgetTheme: {
          themeColor: data?.widgetTheme?.themeColor,
          themeName: data?.widgetTheme?.themeName,
        },
      });
    if (data?.widgetContentCuration) setNewsContentCuration(data?.widgetContentCuration);
    if (data?.widgetConfigName === "news" && data?.widgetContentCuration === "automatic" && Array.isArray(data?.widgetContent))
      setNewsCount(data?.widgetContent?.length);
  };

  const handlePreviousClick = useCallback(() => {
    resetWidgetState();
    setSelectedWidgetId(null);
    setOpenTopSideBar(false);
    setShowAddWidgetModal(true);
  }, []);

  useEffect(() => {
    createMergeandSplitConfig();
  }, [widgetInitialData?.data]);

  useEffect(() => {
    if (window.innerWidth >= 1200) {
      setGrid("lg");
    } else if (window.innerWidth < 1200 && window.innerWidth >= 996) {
      setGrid("md");
    } else if (window.innerWidth < 996 && window.innerWidth >= 768) {
      setGrid("sm");
    } else if (window.innerWidth < 768 && window.innerWidth >= 480) {
      setGrid("xs");
    } else {
      setGrid("xs");
    }
  }, []);

  const getWidgetLeftList = useCallback(() => {
    let filteredWidgetList = [];
    filteredWidgetList = widgetList?.filter(
      (item) =>
        !item.isSelected &&
        !sortedWidgetIds?.some((objItem) => objItem.id === item.id) &&
        // !updatedWidgetIds?.some((objItem) => objItem.id === item.id) &&
        // !newlyAddedWidgetIds?.some((objItem) => objItem.id === item.id) &&
        item.title?.toLowerCase()?.includes(searchTextFilter.toLowerCase()) &&
        (!widgetContentTypeFilter?.length || widgetContentTypeFilter?.includes(item.label)) &&
        (!widgetDeliverableTypeFilter?.length || widgetDeliverableTypeFilter?.includes(item.subType))
    );
    return filteredWidgetList;
  }, [widgetList]);

  const getWidgetRightList = useCallback(() => {
    let filteredWidgetList = [];
    filteredWidgetList = sortedWidgetList?.filter((item) => item);
    return filteredWidgetList;
  }, [widgetList]);

  //** Handle content click
  const handleArrowClick = useCallback(
    (item, action, maxLimit) => {
      let array = [...rightWidgetList];

      if (action === "Add") {
        // Check if the item already exists in the array
        const exists = array.some((val) => val.id === item.id);

        if (!exists) {
          if (formik?.values?.widgetConfigName === "key_players" || formik?.values?.widgetConfigName === "key_players_2") {
            item.widgetContentKeyPlayerCategory = newsTabValue === "1" ? "Newly Added" : "Recently Updated";
          }
          array.push(item);
          if (maxLimit && array.length > maxLimit) return dispatch(actionError(`You can select maximum ${maxLimit} content for this widget.`));
        }
      }

      if (action === "Delete") array = array.filter((val) => val.id !== item?.id);

      dispatch(setRightSideWidgetList(array));
    },
    [rightWidgetList, dispatch, newsTabValue, formik]
  );

  const handleNewsTabChange = useCallback((event, newValue) => setNewsTabValue(newValue), []);

  const widgetSelectionStatus = useMemo(() => {
    // let prevWidgetStatus = { ...widgetStatus };
    let prevWidgetStatus = {
      featured_content: false,
      signals: false,
      news: false,
      key_players: false,
      upcoming: false,
      new: false,
      key_players_2: false,
    };
    if (widgetUniqueData?.data?.data?.length > 0) {
      for (let index in widgetUniqueData?.data?.data) {
        let widgetData = widgetUniqueData?.data?.data?.[index];
        if (widgetData?.widgetConfigName) {
          prevWidgetStatus = { ...prevWidgetStatus, [widgetData?.widgetConfigName]: true };
        } else {
          if (widgetData?.widgetName) prevWidgetStatus = { ...prevWidgetStatus, [widgetData?.widgetName]: true };
        }
      }
    }
    return prevWidgetStatus;
  }, [widgetIndustryId, widgetUniqueData]);

  const resetSelectedWidgetId = () => {
    setSelectedWidgetId(null);
  };

  useEffect(() => {
    if (!widgetInitialData?.data?.data?.length) {
      setLayouts((prev) => ({ dashboard: {}, pinned: {} }));
      return; // Early return if the condition is met
    }
    // If the data is available, set the layouts with the appropriate widget sequence
    setLayouts(widgetInitialData.data.data[0]?.widgetSequence);
    setWidgetLayout(widgetInitialData?.data?.data[0]);
  }, [widgetInitialData?.data]);

  useEffect(() => {
    if (!widgetUniqueData?.data?.data?.length) return setWidgetDataIds([]);
    const ids = widgetUniqueData.data.data.map((i) => i?.widgetContent?.map((c) => c?.widgetContentId) || []).flat();
    setWidgetDataIds(ids);
    //    widgetSelectionStatus && setWidgetStatus(widgetSelectionStatus);
    dispatch(hideWidgetLoader());
    createMergeandSplitConfig();
  }, [widgetUniqueData]);

  useEffect(() => {
    if (!combinedData?.data?.data?.length) {
      dispatch(setCombinedData([]));
      return;
    }
    dispatch(setCombinedData(combinedData?.data?.data));
  }, [combinedData]);

  useEffect(() => {
    if (!fetchingPinnedContent || !fetchingWidgetLayout || !fetchingWidgetUniqueData || !fetchingCombineData || !fetchingUserData) {
      dispatch(hideLoader());
    } else {
      dispatch(showLoader());
    }
    // Cleanup function
    return () => {
      dispatch(hideLoader());
    };
  }, [fetchingPinnedContent, fetchingWidgetLayout, fetchingWidgetUniqueData, fetchingCombineData, dispatch]);

  useEffect(() => {
    widgetSelectionStatus && setWidgetStatus(widgetSelectionStatus);
  }, [widgetIndustryId]);

  if (!widgetInitialData?.data?.data[0] || (!fetchingWidgetLayout && !grid && layouts?.dashboard?.lg)) {
    return <GridLoaderComponent size={15} />;
  }
  return (
    <>
      <div style={{ background: "#F2F2F2", overflow: "hidden" }} className="gennx-envelope">
        {layouts?.dashboard?.lg && grid && (
          <div className="grid-wrapper">
            <div className="grid-dashboard-widget-container">
              <div
                className={widgetUniqueData?.data?.data.some((a) => a?.widgetDashboardContentLink !== "") ? "dashboard-widget-box" : "widget-boxes"}
              >
                <div className="section-heading ht-55 pl-10 m-t-r-l-20">
                  <div className="section-text pt-20">{WIDGETS}</div>
                  {/* Existing code */}
                  <div>
                    {industryDropdownData?.length > 0 && (
                      <div className="col-md-12 pt-2">
                        <DropDownListComponent
                          value={widgetIndustryName}
                          change={(value) => {
                            setWidgetIndustryName(value.itemData.trendName);
                            setWidgetIndustryId(value.itemData.id);
                            dispatch(showLoader());
                          }}
                          cssClass={"customCss e-outline"}
                          floatLabelType="Auto"
                          popupHeight="250px"
                          dataSource={industryDropdownData}
                          fields={{ text: "trendName", value: "trendName" }}
                          placeholder="Industry Dashboard *"
                        />
                      </div>
                    )}
                  </div>
                  {/*
                   *save dashboard code
                   * This is used to save the drag and drop things in dashboard widgets
                   * Later if we need to enable drag and drop functionality enable this section and check the functionality
                   */}
                  {/* <div className="right-side-wrapper">
                {industryDropdownData?.length > 0 && (
                  <div className="dropdown-container">
                    <DropDownListComponent
                      value={widgetIndustryName}
                      change={(value) => {
                        setIsDrag(false);
                        setWidgetIndustryName(value.itemData.trendName);
                        setWidgetIndustryId(value.itemData.id);
                        dispatch(showLoader());
                      }}
                      cssClass={"customCss e-outline"}
                      floatLabelType="Auto"
                      popupHeight="250px"
                      dataSource={industryDropdownData}
                      fields={{ text: "trendName", value: "trendName" }}
                      placeholder="Industry Dashboard *"
                    />
                  </div>
                )}
                {widgetInitialData?.data?.data[0]?.widgetSequence && <div className="save-dashboard-btn-container">
                  <button
                    type="button"
                    className="btn btn-primary ml-2 save-dashboard-btn"
                    disabled={!isDrag}
                    style={{ fontSize: "14px", paddingLeft: "16px", paddingRight: "16px" }}
                    onClick={handleSaveLayout}
                  >
                    {SAVE_DASHBOARD_TEXT}
                  </button>
                </div>
                }
              </div> */}
                  {/*End of save dashboard code*/}
                </div>
                <div className="dashboard-box-top">
                  <GridComponent
                    name="dashboard"
                    text={ADD_WIDGETS}
                    layout={layouts?.dashboard}
                    resolution={grid}
                    splitConfig={pinnedContentMerge}
                    compactType={"horizontal"}
                    onLayoutChange={handleLayoutChange}
                    onDrag={handleDrag}
                    handleAddClick={handleAddClick}
                    onDeleteClick={onDeleteClick}
                    onSettingClick={onSettingClick}
                    widgetData={widgetUniqueData?.data?.data || []}
                    widgetConfig={widgetData?.data?.data?.[0]?.configValue}
                    isDroppable={true}
                    isDraggable={true}
                    isResizable={false}
                    signalRelatedContentData={signalRelatedContentData?.data?.data || []}
                  />
                </div>
              </div>
            </div>
            <div className="grid-pinned-content-widget-container">
              <div className="pinned-content-boxes">
                <div className="section-heading pl-10 ml-20 ht-25 m-t-b-10">
                  <div className="section-text">{PINNED_CONTENT}</div>
                </div>
                <div className="dashboard-box-bottom">
                  <GridComponent
                    name="pinned"
                    text={ADD_CONTENT}
                    layout={layouts?.pinned}
                    resolution={grid}
                    splitConfig={pinnedContentMerge}
                    compactType={"horizontal"}
                    onLayoutChange={handleLayoutChange}
                    onDrag={handleDrag}
                    handleAddClick={handleAddClick}
                    mergeWidgets={handleMergeClick}
                    splitWidgets={handleSplitClick}
                    widgetData={widgetUniqueData?.data?.data || []}
                    onDeleteClick={onDeleteClick}
                    onSettingClick={onSettingClick}
                    isDroppable={false}
                    isDraggable={false}
                    isResizable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {openTopSideBar && (
        <CustomizableWidgetSidebar
          formik={formik}
          contentTypeDataSource={contentTypeDataSource}
          deliverableTypeDataSource={deliverableTypeDataSource}
          searchBoxRef={searchBoxRef}
          searchWidgets={searchWidgets}
          widgetLeftList={getWidgetLeftList()}
          widgetRightList={getWidgetRightList()}
          handleCheckboxChange={handleCheckboxChange}
          onClose={resetWidgetState}
          widgetData={widgetData?.data?.data || []}
          selectedWidgetId={selectedWidgetId}
          newsContentCuration={newsContentCuration}
          handleNewsContentCurationChange={handleNewsContentCurationChange}
          onDragEnd={onDragEnd}
          handleDelete={handleDelete}
          selectedWidgetUniqueName={selectedWidgetUniqueName}
          widgetName={widgetName}
          handleSaveWidget={widgetName === "pinned" ? handleSavePinnedContent : handleSaveWidget}
          widgetIndustryId={widgetIndustryId}
          handleArrowClick={handleArrowClick}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          splitConfig={pinnedContentMerge}
          isEditWidget={isEditWidget}
          newsTabValue={newsTabValue}
          handleNewsTabChange={handleNewsTabChange}
          newlyAddedWidgetList={newlyAddedWidgetList}
          recentlyUpdatedWidgetList={recentlyUpdatedWidgetList}
          onDragEndKeyPlayer={onDragEndKeyPlayer}
          isNewsCuration={isNewsCuration}
          newsCount={newsCount}
          newsCurateSortValue={sortValue}
          handleNewsCurateSortValue={(e) => {
            setSortValue(e?.target?.value);
            setIsNewsCuration(true);
          }}
          setIsNewsCuration={setIsNewsCuration}
          setNewsCount={setNewsCount}
          resolution={grid}
          handlePreviousClick={handlePreviousClick}
          setSignalRelatedContentIds={setSignalRelatedContentIds}
          signalRelatedContentData={signalRelatedContentData}
          loader={fetchingRelatedData}
        />
      )}
      {showAddWidgetModal && (
        <AddWidgetSideBarModal
          setter={setShowAddWidgetModal}
          handleSaveClick={() => setOpenTopSideBar(true)}
          handleCardClick={handleCardClick}
          widgetData={widgetData}
          isSearchFetching={isSearchFetching}
          selectedWidgetId={selectedWidgetId}
          widgetStatus={widgetSelectionStatus}
        />
      )}
    </>
  );
};

export default CustomizableWidgets;
