// ‘Copyright ©2022, Cheers Interactive Pvt Ltd. All rights reserved. //
// File Description : search result page
// ---------------------------------------------------------------------------------
// Creation Details
// Date Created :21 Nov 23
// Author: Lawrence Anthony
// FDD Ref:
// TDD Ref:
// RTM Ref:
// Test Case Ref:

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { FiChevronDown } from "react-icons/fi";
import { createPortal } from "react-dom";
import { getModifiedSearchContent, useQueryHelper, getSearchIndustryTags, siteSearch } from "../../../middleware/services/searchServiceApi";
import { hideLoader, showLoader } from "../../../middleware/actions/utilityAction";
import { CONTENT_TYPE, SEARCH_TYPES } from "../../../constants/index";
import ContentCard from "../../components/contentCard/contentCard";
import ListLoader from "../../components/listLoader/listLoader";
import EmptyData from "../../components/emptyData/EmptyData";
import { getProductVariant, getLoggedInUser } from "../../../utilities";
import { PRODUCT_VARIANT } from "../../../constants";
import classes from "./searchResult.module.css";
import {
  setFirstTreeCheckedNodes,
  setSecondTreeCheckedNodes,
  setAllSearchCount,
  setFixedAllSearchCount,
  setSearchSort,
  setDateFilterValue,
  setFromDate,
  setToDate,
  setKeywords,
  setContentTypeCount,
  setResultCount,
  setFixedContentTypeCount,
  setCurrentQuery,
  setCurrentQueryNewSearch,
  fetchTechnologyList,
  getSgfTypeSearch,
  getContentTypeSearch,
  clearAllSearchFilter,
  toggleLoading,
  fetchTrendsList,
  fetchIndustriesList,
  setStatusFilter,
} from "../../../middleware/actions/searchAction";
import ListDropdown from "../../components/listDropdown/listDropdown";
import ContentTypeFilterSearch from "../../components/filterSection/contentTypeFilterSearch";
import IndustryFilterSearch from "../../components/filterSection/industryFilterSearch";
import DateFilterSearch from "../../components/filterSection/dateFilterSearch";
import StatusFilterSearch from "../../components/filterSection/statusFilterSearch";
import ScrollToTop from "../../components/ScrollToTop/SctollToTop";
import { IoSearch } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";

const filters = [
  {
    name: "Industry",
    component: IndustryFilterSearch,
  },
  {
    name: "Content Type",
    component: ContentTypeFilterSearch,
  },
  {
    name: "Date",
    component: DateFilterSearch,
  },
  {
    name: "Status",
    component: StatusFilterSearch,
  },
];

const SearchResult = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const productVariant = getProductVariant();
  let user = getLoggedInUser();
  const queryTag = useQueryHelper().get("query");
  const getAutoCorrect = useQueryHelper().get("autoCorrect") === "true" ? "YES" : "NO";

  const trendIds = useSelector((state) => state["searchState"]["subscribeSgfs"], shallowEqual);
  const productIndustryIds = useSelector((state) => state["searchState"]["productIndustryIds"]);
  const selectedContentType = useSelector((state) => state["searchState"]["selectedSearchFilters"]["selectedContentType"], shallowEqual);
  const selectedSgf = useSelector((state) => state["searchState"]["selectedSearchFilters"]["selectedSgf"]);
  const sgfIdsSearch = useSelector((state) => state["searchState"]["sgfIdsSearch"], shallowEqual);
  const loadingStatus = useSelector((state) => state["searchState"]["loadingStatus"]);
  const technologyList = useSelector((state) => state["searchState"]["technologyList"]);
  const selectedTrendIds = useSelector((state) => state["searchState"]["searchFilters"]["sgfFilters"], shallowEqual);
  const currentQuery = useSelector((state) => state["searchState"]["currentQuery"]);
  const currentQueryNewSearch = useSelector((state) => state["searchState"]["currentQueryNewSearch"]);

  const sort = useSelector((state) => state["searchState"]["searchSort"], shallowEqual);
  const fromDate = useSelector((state) => state["searchState"]["fromDate"], shallowEqual);
  const toDate = useSelector((state) => state["searchState"]["toDate"], shallowEqual);
  const fixedAllSearchCount = useSelector((state) => state["searchState"]["fixedAllSearchCount"]);
  const fixedContentTypeCount = useSelector((state) => state["searchState"]["fixedContentTypeCount"]);
  const selectedDateFilter = useSelector((state) => state["searchState"]["dateFilterValue"]);
  const statusFilter = useSelector((state) => state["searchState"]["statusFilter"], shallowEqual);

  const [skipCount, setSkipCount] = useState(21);
  const [allSearchData, setAllSearchData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [industryFilterClick, setIndustryFilterClick] = useState(false);
  const [contentFilterClick, setContentFilterClick] = useState(false);
  const [currentResultCount, setCurrentResultCount] = useState(0);
  const isOpen = Boolean(anchorEl);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [autoCorrect, setAutoCorrect] = useState(getAutoCorrect || "YES");
  const [correctedWord, setCorrectedWord] = useState([]);
  const [highlightedWords, setHighlightedWords] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAllSearchCount(fixedAllSearchCount));
    }, 200);
  }, [fixedAllSearchCount]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setContentTypeCount(fixedContentTypeCount));
    }, 200);
  }, [fixedContentTypeCount]);

  useEffect(() => {
    let contentDiv = document.getElementById("content");
    if (contentDiv) contentDiv.style.height = "auto";
    dispatch(fetchIndustriesList());
    dispatch(fetchTrendsList());
    dispatch(getSgfTypeSearch());
    if (!selectedContentType.length) {
      dispatch(getContentTypeSearch());
    }
    dispatch(
      setKeywords(
        queryTag.split(" | ").map((part) => ({
          label: part,
          value: part,
        }))
      )
    );
  }, []);

  useEffect(() => {
    if (trendIds && trendIds.length) dispatch(hideLoader());
    else dispatch(showLoader());
  }, [trendIds]);

  // Function to check if a word is enclosed by double quotes
  function isEnclosedInQuotes(word) {
    return /^"([^"]+)"$/.test(word);
  }

  // API call for search
  const getAll = useCallback(() => {
    let checkFilterIndustry = false;
    let checkFilterContent = false;

    if (selectedSgf.length && industryFilterClick) {
      checkFilterIndustry = true;
    }
    if (selectedContentType.length && contentFilterClick) {
      checkFilterContent = true;
    }
    if (!selectedSgf.length && !selectedContentType.length && currentQuery === queryTag) {
      checkFilterIndustry = false;
      checkFilterContent = false;
    }
    if (currentQuery !== queryTag) {
      checkFilterIndustry = false;
      checkFilterContent = false;
    }

    dispatch(showLoader());
    dispatch(toggleLoading(true));

    Promise.all(
      SEARCH_TYPES.map((entity) => {
        if (
          selectedContentType?.length == 0 ||
          (entity === "Company" && selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.PROFILES_COMPANIES_TECHNOLOGIES))) ||
          (entity === "News" && selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.DEVELOPMENTS_NEWS))) ||
          (entity === "News" && selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH))) ||
          (entity === "Video" && selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS))) ||
          (entity === "Document" &&
            (selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH)) ||
              selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH)) ||
              selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.DEVELOPMENTS_NEWS)) ||
              selectedContentType.some((entry) => entry.includes(CONTENT_TYPE.WEBINARS_INTERVIEWS_PODCASTS))))
        ) {
          return siteSearch(
            queryTag
              .split(" | ")
              .map((word) => {
                if (isEnclosedInQuotes(word)) {
                  return `${word}`;
                } else {
                  return `"${word}"`;
                }
              })
              .join(" "),
            entity,
            selectedTrendIds.length ? selectedTrendIds : trendIds,
            productIndustryIds,
            sgfIdsSearch,
            selectedContentType,
            sort,
            selectedTrendIds.length,
            fromDate,
            toDate,
            statusFilter,
            autoCorrect,
            queryTag.split("|").map((tag) => tag.trim())
          );
        }
      })
    )
      .then(async (res) => {
        dispatch(showLoader());
        dispatch(toggleLoading(true));
        const keywords = queryTag?.split(" | ");
        let searchData = await getModifiedSearchContent(res, sort, selectedContentType, keywords);
        searchData = searchData.map((item) => ({
          ...item,
          score: Number.isInteger(item?.score) ? item?.score.toFixed(0) : item?.score.toFixed(2),
        }));

        setTotalCount(searchData.length);
        setCurrentResultCount(searchData.length);
        setAllSearchData(searchData);
        setSearchResults(searchData.slice(0, 21));
        setCorrectedWord(Array.from(new Set(res.flatMap((obj) => obj?.correctedWord))));
        setHighlightedWords(Array.from(new Set(res.flatMap((obj) => obj?.highlightedWords))));

        if (currentQuery !== queryTag) {
          checkFilterIndustry = false;
          checkFilterContent = false;
        }

        if (!checkFilterIndustry) {
          let industryCountObject = res.map((obj) => {
            if (obj) {
              const docIndustryCountCount = obj.industryId;
              const entries = Object.entries(docIndustryCountCount);

              // Convert the entries back to an object
              return Object.fromEntries(entries);
            }
          });
          let combinedObjectIndustry = {};

          industryCountObject = industryCountObject.filter((e) => e);

          for (const obj of industryCountObject) {
            for (const [key, value] of Object.entries(obj)) {
              if (combinedObjectIndustry.hasOwnProperty(key)) {
                combinedObjectIndustry[key] += value;
              } else {
                combinedObjectIndustry[key] = value;
              }
            }
          }

          let sgfCountObject = res.map((obj) => {
            if (obj) {
              const docIndustryCountCount = obj?.sgfId;
              const entries = Object.entries(docIndustryCountCount);

              // Convert the entries back to an object
              return Object.fromEntries(entries);
            }
          });
          let combinedObjectSgf = {};

          sgfCountObject = sgfCountObject.filter((e) => e);

          for (const obj of sgfCountObject) {
            for (const [key, value] of Object.entries(obj)) {
              if (combinedObjectSgf.hasOwnProperty(key)) {
                combinedObjectSgf[key] += value;
              } else {
                combinedObjectSgf[key] = value;
              }
            }
          }

          let trendCountObject = res.map((obj) => {
            if (obj) {
              const docIndustryCountCount = obj?.trendId;
              const entries = Object.entries(docIndustryCountCount);

              // Convert the entries back to an object
              return Object.fromEntries(entries);
            }
          });
          let combinedObjectTrend = {};

          trendCountObject = trendCountObject.filter((e) => e);

          for (const obj of trendCountObject) {
            for (const [key, value] of Object.entries(obj)) {
              if (combinedObjectTrend.hasOwnProperty(key)) {
                combinedObjectTrend[key] += value;
              } else {
                combinedObjectTrend[key] = value;
              }
            }
          }
          if (currentQuery !== queryTag) {
            dispatch(
              setAllSearchCount({
                ...combinedObjectIndustry,
                ...combinedObjectSgf,
                ...combinedObjectTrend,
              })
            );
          }
          dispatch(
            setFixedAllSearchCount({
              ...combinedObjectIndustry,
              ...combinedObjectSgf,
              ...combinedObjectTrend,
            })
          );
        }

        if (
          (!selectedContentType.length && Object.entries(fixedAllSearchCount).length > 0 && currentQuery === queryTag) ||
          selectedDateFilter !== "All" ||
          statusFilter !== "All"
        ) {
          dispatch(setAllSearchCount(fixedAllSearchCount));
        }
        if (!checkFilterContent) {
          let articleCount = 0;

          let contentTypeCountObject = res.map((obj) => {
            if (obj) {
              const docContentTypeCount = obj?.contentTypeCount;
              const entries = Object.entries(docContentTypeCount);

              if (obj?.contentTypeCount?.["News"]) {
                const countArticles = obj?.data.reduce((count, e) => {
                  if (e.newsType === "Articles") {
                    return count + 1;
                  }
                  return count;
                }, 0);
                articleCount += countArticles;
              }

              // Convert the entries back to an object
              return Object.fromEntries(entries);
            }
          });
          let combinedObjectContentType = {};
          let contentTypeTotalCount = 0;

          contentTypeCountObject = contentTypeCountObject.filter((e) => e);

          for (const obj of contentTypeCountObject) {
            for (const [key, value] of Object.entries(obj)) {
              if (combinedObjectContentType.hasOwnProperty(key)) {
                combinedObjectContentType[key] += value;
                contentTypeTotalCount += value;
              } else {
                combinedObjectContentType[key] = value;
                contentTypeTotalCount += value;
              }
            }
          }

          if (articleCount) {
            if (combinedObjectContentType[CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH])
              combinedObjectContentType[CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH] += articleCount;
            else combinedObjectContentType[CONTENT_TYPE.INSIGHTS_REPORTS_RESEARCH] = articleCount;
            combinedObjectContentType[CONTENT_TYPE.DEVELOPMENTS_NEWS] -= articleCount;
          }

          dispatch(
            setContentTypeCount({
              ...combinedObjectContentType,
            })
          );

          if (currentQuery !== queryTag) {
            dispatch(setResultCount(contentTypeTotalCount));
          }
          dispatch(
            setFixedContentTypeCount({
              ...combinedObjectContentType,
            })
          );
        }

        if (
          (!selectedSgf.length && Object.entries(fixedContentTypeCount).length > 0 && currentQuery === queryTag) ||
          selectedDateFilter !== "All" ||
          statusFilter !== "All"
        ) {
          dispatch(setContentTypeCount(fixedContentTypeCount));
        }
        setTimeout(() => {
          dispatch(hideLoader());
          dispatch(toggleLoading(false));
        }, 2000);
        dispatch(setCurrentQuery(queryTag));
      })
      .catch((error) => {
        console.log("error >>> ", error);
        setTimeout(() => {
          dispatch(hideLoader());
          dispatch(toggleLoading(false));
        }, 2000);
      });
  }, [
    dispatch,
    queryTag,
    selectedTrendIds,
    productIndustryIds,
    sort,
    selectedContentType,
    sgfIdsSearch,
    currentQuery,
    fromDate,
    toDate,
    statusFilter,
    autoCorrect,
  ]);

  // fetch filter category
  useEffect(() => {
    if (
      queryTag &&
      currentQueryNewSearch != "" &&
      queryTag != currentQueryNewSearch &&
      (selectedTrendIds?.length || selectedContentType?.length || selectedDateFilter !== "All" || sort !== "score:desc" || statusFilter !== "All")
    ) {
      dispatch(setFirstTreeCheckedNodes([]));
      dispatch(setSecondTreeCheckedNodes([]));
      dispatch(clearAllSearchFilter());
      dispatch(setSearchSort("score:desc"));
      dispatch(setDateFilterValue("All"));
      dispatch(setFromDate(undefined));
      dispatch(setToDate(undefined));
      dispatch(setStatusFilter("All"));
    } else if (trendIds.length && queryTag && sgfIdsSearch?.length) {
      if (!technologyList.length) dispatch(fetchTechnologyList());
      getAll();
      dispatch(setCurrentQueryNewSearch(queryTag));
    }
  }, [queryTag, trendIds, sort, selectedContentType, sgfIdsSearch, selectedTrendIds, fromDate, toDate, statusFilter, autoCorrect]);

  useEffect(() => {
    setAutoCorrect(getAutoCorrect);
  }, [getAutoCorrect]);

  // lazy loading for search result
  const loadMore = () => {
    let list = allSearchData;

    let count = skipCount + 21;
    setSearchResults(list.slice(0, count));
    setSkipCount(count);
  };

  const handleDeliverablePreview = (deliverableId) => {
    let link = `/deliverable/${deliverableId}/preview`;
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      link = `${import.meta.env.VITE_INSIDER_V2_URL}preview/${deliverableId}?userId=${user?._id}`;
    }
    const win = window.open(link, "_blank");
    win.focus();
  };

  const handleCompanyPreview = (companyId) => {
    let link = `/company/${companyId}/edit#basic`;
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      link = `${import.meta.env.VITE_INSIDER_V2_URL}company-profile/${companyId}?focusMode=true&previewMode=true`;
    }
    const win = window.open(link, "_blank");
    win.focus();
  };

  const handleNewsPreview = (newsId) => {
    let link = `/news/${newsId}/edit#basic`;
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      link = `${import.meta.env.VITE_INSIDER_V2_URL}news-and-commentary/${newsId}?focusMode=true&previewMode=true`;
    }
    const win = window.open(link, "_blank");
    win.focus();
  };

  // redirect to specific entity detail page
  const redirectToPage = (id, entityType) => {
    if (entityType === "document") handleDeliverablePreview(id);
    else if (entityType === "company") handleCompanyPreview(id);
    else if (entityType === "news") handleNewsPreview(id);
    else if (entityType === "video") window.open(`/video/${id}/edit#basic`, "_blank");
  };

  const availableFilters = useMemo(
    () =>
      filters.map((e) => ({
        name: e.name,
        component: React.createElement(e.component, {
          anchorEl,
          setAnchorEl,
          setActiveIndex,
        }),
      })),
    [filters, anchorEl]
  );

  const changeFilter = (event) => {
    const filterValue = getFilterValue(event.target.value);
    dispatch(setSearchSort(filterValue));
  };

  const getFilterValue = (targetValue) => {
    if (targetValue === "1") {
      return "score:desc";
    } else if (targetValue === "2") {
      return "score:asc";
    } else if (targetValue === "3") {
      return "publishedDate:desc";
    } else if (targetValue === "4") {
      return "publishedDate:asc";
    }
  };

  const getSortValueForListDropdown = (sortValue) => {
    if (sortValue === "score:desc") {
      return "1";
    } else if (sortValue === "score:asc") {
      return "2";
    } else if (sortValue === "publishedDate:desc") {
      return "3";
    } else if (sortValue === "publishedDate:asc") {
      return "4";
    }
  };

  const handleReset = (event) => {
    dispatch(
      setKeywords(
        queryTag.split(" | ").map((part) => ({
          label: part,
          value: part,
        }))
      )
    );
    dispatch(setFirstTreeCheckedNodes([]));
    dispatch(setSecondTreeCheckedNodes([]));
    dispatch(clearAllSearchFilter());
    dispatch(setSearchSort("score:desc"));
    dispatch(setDateFilterValue("All"));
    dispatch(setFromDate(undefined));
    dispatch(setToDate(undefined));
    dispatch(setStatusFilter("All"));
  };

  const handleMenuClick = (index, e) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      setAnchorEl(null);
    } else {
      setActiveIndex(index);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleScroll = () => {
    const scrollableDiv = document.getElementById("scrollableDiv");
    if (scrollableDiv.scrollTop > 20) {
      setShowScrollTopButton(true);
    } else {
      setShowScrollTopButton(false);
    }
  };

  return (
    <>
      {trendIds && trendIds.length ? (
        <div className={`site-search-result ${classes.searchMain}`}>
          <div className={classes.searchInsider}>
            <div className={classes.searchTitleTop}>
              <div className="d-flex flex-column" style={{ gap: "0.375rem" }}>
                {!loadingStatus && (
                  <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
                    <p className="p-0 m-0" style={{ fontSize: "0.875rem", color: "#4B4B55" }}>
                      Showing <span style={{ fontWeight: "bold" }}>{currentResultCount}</span>
                      {` matching ${currentResultCount === 1 ? "result" : "results"} for similar terms:  `}
                    </p>
                    {correctedWord && Array.isArray(correctedWord) && correctedWord.length > 0
                      ? highlightedWords.map((word, index) => (
                          <div
                            id={`Search_Keyword_${index + 1}`}
                            key={word + index}
                            className={`d-flex justify-content-center align-items-center m-0 px-2 border rounded ${classes.queryChips}`}
                            style={{
                              minHeight: "30px",
                              borderWidth: "1px",
                              borderColor: "#DBDBDD",
                              borderRadius: "4px",
                              color: "#4B4B55",
                              fontSize: "12px",
                            }}
                          >
                            {word}
                          </div>
                        ))
                      : queryTag.split("|").map((word, index) => (
                          <div
                            id={`Search_Keyword_${index + 1}`}
                            key={word + index}
                            className={`d-flex justify-content-center align-items-center m-0 px-2 border rounded ${classes.queryChips}`}
                            style={{
                              minHeight: "30px",
                              borderWidth: "1px",
                              borderColor: "#DBDBDD",
                              borderRadius: "4px",
                              color: "#4B4B55",
                              fontSize: "12px",
                            }}
                          >
                            {word.replace(/|/g, "")}
                          </div>
                        ))}
                  </div>
                )}

                {!loadingStatus &&
                  autoCorrect === "YES" &&
                  JSON.stringify(correctedWord) !== JSON.stringify(queryTag.split("|").map((tag) => tag.trim())) && (
                    <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
                      <p className="p-0 m-0" style={{ fontSize: "0.875rem", color: "#4B4B55" }}>{`Retain my original search: `}</p>
                      {queryTag.split("|").map((word, index) => (
                        <div
                          id={`Search_Keyword_${index + 1}`}
                          key={word + index}
                          className={`d-flex justify-content-center align-items-center m-0 px-2 border rounded ${classes.queryChips}`}
                          style={{
                            minHeight: "30px",
                            borderWidth: "1px",
                            borderColor: "#DBDBDD",
                            color: "#4B4B55",
                            fontSize: "12px",
                          }}
                        >
                          {word.replace(/|/g, "")}
                        </div>
                      ))}
                      <div
                        className="d-flex justify-content-center align-items-center rounded-circle ml-2"
                        style={{
                          height: "24px",
                          width: "24px",
                          backgroundColor: "#f94f5e",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setAutoCorrect("NO");
                          const urlParams = new URLSearchParams(location.search);
                          urlParams.set("autoCorrect", "false");
                          // Rebuild the URL and update the browser history
                          history.push({
                            pathname: location.pathname,
                            search: urlParams.toString(), // Updated query string
                          });
                          setCorrectedWord([]);
                        }}
                      >
                        <IoSearch fontSize="14px" fill="#FFF" />
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="border-bottom w-100 my-3"></div>

            <div className={`${classes.searchFilterContainer} TabletAndMobile:!flex-wrap TabletAndMobile:gap-y-2`}>
              <button
                onClick={(e) => {
                  handleMenuClick(0, e);
                  setIndustryFilterClick(true);
                  setContentFilterClick(false);
                }}
                className={`${classes.blackBtn} TabletAndMobile:text-xs`}
                type="button"
              >
                Filter by Industry, Strategic Growth Fields and Trend
                <FiChevronDown fontSize={"1.5em"} />
              </button>

              <button
                onClick={(e) => {
                  handleMenuClick(1, e);
                  setIndustryFilterClick(false);
                  setContentFilterClick(true);
                }}
                className={`${classes.blackBtn}`}
                type="button"
              >
                Filter by Content Type
                <FiChevronDown fontSize={"1.5em"} />
              </button>

              <button
                onClick={(e) => {
                  handleMenuClick(2, e);
                }}
                className={`${classes.blackBtn} ${classes.dateBtn}`}
                type="button"
              >
                Filter by Date
                <FiChevronDown fontSize={"1.5em"} />
              </button>

              <button
                onClick={(e) => {
                  handleMenuClick(3, e);
                }}
                className={`${classes.blackBtn} ${classes.dateBtn}`}
                type="button"
              >
                Filter by Status
                <FiChevronDown fontSize={"1.5em"} />
              </button>

              {isOpen && createPortal(availableFilters[activeIndex]?.component, document.getElementById("menu-popper"))}
              <div className={`${classes.searchFilterRightContainer} TabletAndMobile:!ml-0 TabletAndMobile:float-right`}>
                <ListDropdown
                  changeFilter={changeFilter}
                  showCustomTextSearch={"Sort by"}
                  value={getSortValueForListDropdown(sort)}
                  isSearchDropdown={true}
                />
                <button id="resetButton" onClick={handleReset} className={`${classes.pinkBtn}`} type="button">
                  Reset
                </button>
              </div>
            </div>
            {searchResults?.length !== 0 && (
              <div className={classes.searchListDiv} id="scrollableDiv" onScroll={handleScroll}>
                <ScrollToTop showScrollTopButton={showScrollTopButton} />
                <InfiniteScroll
                  dataLength={searchResults.length}
                  next={() => loadMore()}
                  hasMore={searchResults.length >= totalCount ? false : true}
                  loader={<ListLoader />}
                  scrollableTarget="scrollableDiv"
                  scrollThreshold="10px"
                  style={{ overflow: "unset" }}
                >
                  <div className={`${classes.recentContentMain} ${classes.searchList}`}>
                    {searchResults &&
                      searchResults.map((item, i) => {
                        return (
                          <ContentCard
                            contentCardClass="contentCardClass"
                            key={item.id + i}
                            item={Object.assign({}, item, {
                              industryTag: getSearchIndustryTags(
                                item.technologyId ? item.technologyId : [],
                                technologyList,
                                item.selectedTaxonomyTags
                              ),
                            })}
                            redirectToPage={() => redirectToPage(item.id, item.entityType)}
                            showProgressBar={true}
                            isSiteSearch={true}
                          />
                        );
                      })}
                  </div>
                </InfiniteScroll>
              </div>
            )}
            {searchResults.length === 0 && !loadingStatus && (
              <EmptyData msg="Your search did not match any results. Please try different keywords or try changing the filters to see the relevant content." />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SearchResult;
