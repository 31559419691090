/*
================================================================
    Copyright © 2021, Cheers Interactive Pvt Ltd.  All rights reserved.
      File Description : KIA Expert comment
----------------------------------------------------------------
    Creation Details
    Date Created				: 19/Jan/2022
    Author						: पंकज कुलश्रेष्ठ
================================================================
*/
import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import * as Survey from "survey-react";
import _, { debounce } from "lodash";
import "survey-analytics/survey.analytics.css";
import "survey-analytics/survey.analytics.tabulator.css";
import "tabulator-tables/dist/css/tabulator.css";
import "showdown";
import * as SurveyAnalytics from "survey-analytics";
import Accordion from "../../../../components/accordion";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import { getProductVariant, removeTags } from "../../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { fetchSingleKia, saveKeyComments, deleteKeyComments } from "../../../../../middleware/services/kiaApi";
import { fetchExternalUser } from "../../../../../middleware/services/userApi";
import { fetchSurveys, getSurveyAnalytics } from "../../../../../middleware/services/surveyApi";
import { MultiSelect } from "../../../../components/CustomFormElements/syncFusionFormFields";
import * as widgets from "surveyjs-widgets";
import AccessDenied from "../../../../components/accessDenied";
import { ROLES } from "../../../../../constants";

const initialState = {
  selUsers: [],
  commentExpert: [],
  isKeyComment: "",
};
const CustomKia = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const dateRangeRef = useRef();
  const kiaId = props.kiaId;
  const productVariant = getProductVariant();
  const [editData, setEditData] = useState(null);
  const [keyExpertData, setKeyExpertData] = useState(null);
  const [surveyId, setSurveyId] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [questionsData, setQuestionsData] = useState([]);
  const [surveyData, setSurveyData] = useState({});
  // const [showComments, setShowComments] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [keyComments, setKeyComments] = useState(undefined);
  const [activeState, setActiveState] = useState("");
  const [apiStatus, setApiStatus] = useState("loading");
  const [queries, setQueries] = useState([]);
  const [expertUsers, setExpertUsers] = useState([]);
  const [expertUsersData, setExpertUsersData] = useState([]);

  /*
       @Description : Formik Initialization
   */
  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    // validationSchema: Yup.object().shape(fundingValidationSchema)
  });

  /*
       @Description : BreadCumb Defination
   */
  const breadCrumbLinks = [
    { linkUrl: "/kia", linkName: aR.moduleName, linkActive: false },
    { linkUrl: kiaId ? `/kia/${kiaId}/edit` : "//kia/add", linkName: (editData && editData?.kiaName) || "", linkActive: false },
    {
      linkUrl: `/kia/ ${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  enableRipple(true);

  const Dropdown = (props1) => {
    const [value, setValue] = useState("");
    useEffect(() => {
      let name = props1.name;
      props1.formik.values[name] && setValue(props1.formik.values[name]);
    }, [props1.formik.values, props1.name]);

    return (
      <div className={`form-group ${(props1.required && props1.required) || ""}`}>
        <DropDownListComponent
          value={value}
          change={(selectedOption) => {
            props1.formik.setFieldValue(props1.name, selectedOption.value);
          }}
          {...props1}
          cssClass={"customCss e-outline"}
          floatLabelType="Auto"
          popupHeight="250px"
        />
        <div
          className="text-danger"
          style={{
            display: (props1.formik.touched["react-select-2-input"] || props1.formik.touched[props1.name]) === true ? "block" : "none",
          }}
          role="alert"
        >
          <small>
            {(props1.formik.touched["react-select-2-input"] || props1.formik.touched[props1.name]) === true
              ? props1.formik.errors[props1.name] || ""
              : ""}
          </small>
        </div>
      </div>
    );
  };
  const handleSearch = () => {
    setSearchFlag(true);
    let usersFilter = [];
    let dateRangeFilter = [];
    if (formik.values.commentExpert && formik.values.commentExpert.length > 0) {
      usersFilter = [["userId", "in", formik.values.commentExpert]];
    }
    if (dateRange) {
      const dt = localStorage.getItem("dateRange2").split(",");
      let gte = { d: new Date(dt[0]) };
      let lt = { d: moment(dt[1]).add(2, "day") };
      dateRangeFilter = [
        ["userSurveyResponseDate", "gte", gte.d],
        ["userSurveyResponseDate", "lt", lt.d],
      ];
    }
    if (queries.length > 0 && editData?.kiaName) {
      getAnalysisData([[...queries], ...dateRangeFilter, ...usersFilter]);
      // fetchKiaDetails()
      formik.values.isKeyComment && setKeyComments(formik.values.isKeyComment);
    }
  };
  const handleReset = () => {
    fetchKiaDetails();
    formik.setFieldValue("commentExpert", []);
    setExpertUsers([]);
    setDateRange(undefined);
    setKeyComments(undefined);
    setSearchFlag(false);
    dateRangeRef.current.value = null;
    formik.setFieldValue("isKeyComment", null);
    localStorage.removeItem("dateRange2");
    getAnalysisData(["kiaName", "eq", editData?.kiaName]);
  };

  const searchUsers = async (val) => {
    if (val.length > 1) {
      let filters = [["userActive", "eq", 1]];
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["userRole", "eq", ROLES.EXPERT]);
      let val1 = val.split(" ");
      filters.push(["userFname", "cn", val1[0].trim()]);
      const response = await fetchExternalUser({
        fields: ["userFname", "userLname", "userEmail"],
        filters: filters,
        sort: "userFname:asc",
        limit: 10,
      });
      if (response?.data && response?.data?.data) {
        setExpertUsersData(response?.data?.data);
        const resData = response.data.data.map((v) => {
          return { label: `${v.userFname} ${v.userLname} (${v.userEmail})`, value: v.id };
        });
        if (resData) {
          setExpertUsers([...new Map([...expertUsers, ...resData].map((item) => [item["value"], item])).values()]);
        }
      }
    }
  };
  const searchUserByIds = async (ids) => {
    const response = await fetchExternalUser({
      fields: ["userFname", "userLname", "userEmail"],
      filters: [["_id", "in", ids]],
      sort: "userFname:asc",
      limit: 10,
    });
    if (response?.data && response?.data?.data) {
      setExpertUsersData(response?.data?.data);
      const resData = response.data.data.map((v) => {
        return { label: `${v.userFname} ${v.userLname} (${v.userEmail})`, value: v.id };
      });
      if (resData) {
        setExpertUsers(resData);
        formik.setFieldValue(
          "newsExpert",
          resData.map((v) => {
            return v.value;
          })
        );
      }
    }
  };

  const ToggleSwitch = ({ index, c, ele }) => {
    // const [value, setValue] = useState("NO")
    const [checked, setChecked] = useState(false);
    useEffect(() => {
      // value !== "object" && setValue(val.name)
      const isChecked =
        editData?.kiaExpertComment &&
        editData?.kiaExpertComment.filter(
          (f) => f.surveyUserResponseId === c.surveyUserResponseId && f.surveyQuestionCommentId === c.surveyQuestionCommentId
        );
      isChecked && isChecked.length > 0 ? setChecked(true) : setChecked(false);
    }, []);

    const handleChange = (e) => {
      // setSearchFlag(false)
      let payload = {};
      if (!e.target.checked) {
        payload.kiaExpertComment = [
          {
            userFname: c.userFname,
            userLname: c.userLname,
            surveyQuestionComment: c.comment,
            userId: c.userId,
            surveyId: surveyId,
            surveyUserResponseId: c.surveyUserResponseId,
            surveyQuestionCommentId: ele.name + "-Comment",
          },
        ];
        deleteKeyComments(kiaId, payload)
          .then((res) => {
            if (res.data.data) {
              setChecked(false);
              dispatch(actionSuccess(res.data.data));
              // searchFlag && setActiveState(payload.kiaExpertComment[0].surveyQuestionCommentId)
              // searchFlag && fetchKiaDetails()
            }
          })
          .catch((err) => {
            dispatch(actionError(err?.data?.message || "Something went wrong"));
          });
      } else {
        const objKeyComment = {
          userFname: c.userFname,
          userLname: c.userLname,
          surveyQuestionComment: c.comment,
          surveyId: surveyId,
          surveyUserResponseId: c.surveyUserResponseId,
          surveyQuestionCommentId: ele.name + "-Comment",
        };
        if (c.userId) {
          objKeyComment.userId = c.userId;
        }
        payload.kiaExpertComment = [objKeyComment];
        saveKeyComments(kiaId, payload)
          .then((res) => {
            if (res.data.data) {
              setChecked(true);
              dispatch(actionSuccess(res.data.data));
              // searchFlag && setActiveState(payload.kiaExpertComment[0].surveyQuestionCommentId)
              // searchFlag && fetchKiaDetails()
            }
          })
          .catch((err) => {
            // setChecked(false)
            dispatch(actionError(err?.data?.message || "Something went wrong"));
          });
      }
    };

    return (
      <div>
        <label className="custom-switch3">
          <input
            type="checkbox"
            className="expert-comment-toggle"
            id={ele.name + "-Comment-" + index}
            name={ele.name + "-Comment-" + index}
            value={1}
            checked={checked}
            onChange={handleChange}
          />
          <div>
            <span className="on">Yes</span>
            <span className="off">No</span>
          </div>
          <i></i>
        </label>
      </div>
    );
  };

  /*
       @Description : Fetch Single KIA Details
   */
  const fetchKiaDetails = () => {
    let params = {};
    let defaultFilter = [];
    let fields = ["kiaName", "kiaExpertComment"];
    params.fields = fields;
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params.filters = [...defaultFilter];
    fetchSingleKia(kiaId, params).then((res) => {
      if (res.data.data) {
        const data = res.data.data;
        setQueries(["kiaName", "eq", data?.kiaName]);
        setEditData(data);
        setKeyExpertData(
          data?.kiaExpertComment &&
            data?.kiaExpertComment.map((f) => ({ surveyUserResponseId: f.surveyUserResponseId, surveyQuestionCommentId: f.surveyQuestionCommentId }))
        );
      }
    });
  };

  /*
       @Description : Fetch Expert Comment Details
   */
  const fetchExpertCommentDetails = () => {
    let params = {};
    params.fields = ["id"];
    params.filters = [["surveyKiaTags.KiaId", "eq", kiaId]];
    fetchSurveys(params)
      .then((res) => {
        if (res && res?.data && res?.data?.data) {
          const data = res.data.data;
          if (data.length > 0) {
            setSurveyId(data[0].id);
          } else {
            setApiStatus("no-data");
            dispatch(hideLoader());
          }
        } else {
          setApiStatus("failed");
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || "Something went wrong"));
      });
  };

  /*
      @Description : Load KIA Overview Details on Edit and 
  */

  useEffect(() => {
    if (kiaId) {
      dispatch(showLoader());
      !editData && fetchKiaDetails();
      !surveyId && fetchExpertCommentDetails();
    }
    dispatch(setBreadCrumb(breadCrumbLinks));
    // dispatch(setSideNavForcedActiveLink("/kia"));
    return () => {
      // dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch, kiaId, editData, props.selectedIndex, props.location.hash]);

  // const viewAllComments = (ele) => {
  //     setShowComments(true);
  // };

  const renderAnalytics = useCallback(
    (res) => {
      widgets.select2tagbox(Survey);
      SurveyAnalytics.VisualizationManager.registerVisualizer("tagbox", SurveyAnalytics.SelectBasePlotly);
      const survey = new Survey.Model(res.surveyForm);

      let allQuestions = [];

      // get page by name (kia_name)
      if (kiaId && editData && editData.kiaName) {
        let page = survey.getPageByName(editData.kiaName);
        // get page questions
        if (page) {
          allQuestions = page.questions;
        }
      }
      // get questions for survey
      if (!kiaId && surveyId) {
        allQuestions = survey.getAllQuestions();
      }

      // filter allQuestions remove unwanted questions
      allQuestions.filter((element) => {
        var n = element.name;
        return (
          n !== "user_fname" &&
          n !== "user_lname" &&
          n !== "user_email" &&
          n !== "expert_background" &&
          n !== "country" &&
          n !== "technology_interest" &&
          n !== "industry_interest" &&
          n !== "Please tell us more about your knowledge on AI"
        );
      });
      setQuestionsData(allQuestions);
      dispatch(hideLoader());
      // setApiStatus("success");
    },
    [editData, kiaId, surveyId]
  );

  const sortArr = (arr) => {
    return arr.sort((a, b) => new Date(b.commentDate).getTime() - new Date(a.commentDate).getTime());
  };
  // survey analytics api method
  const getAnalysisData = useCallback(
    (query) => {
      const params = {};
      params.filters = [...query];
      // return
      dispatch(showLoader());
      setApiStatus("loading");
      // return
      surveyId &&
        getSurveyAnalytics(surveyId, params)
          .then((res) => {
            Survey.Serializer.addProperty("question", {
              name: "Weightage:number",
              category: "general",
              max: 10,
            });
            Survey.Serializer.addProperty("question", { name: "customTitle:text", category: "general" });
            if (res?.data?.data) {
              const data = res?.data?.data;
              !_.isEmpty(data.surveyQuestionComments) ? setApiStatus("success") : setApiStatus("no-data");
              setSurveyData(data);
              renderAnalytics(data);
            }
          })
          .catch(() => {
            setApiStatus("failed");
          });
    },
    [renderAnalytics]
  );

  useEffect(() => {
    // if condition is for preventing multiple request
    getAnalysisData(queries);
  }, [getAnalysisData, kiaId]);

  const commentsJsx = (data, ele, index) => {
    let newData = [];
    if (keyComments === "Yes") {
      newData = data.filter(
        (f, i) =>
          keyExpertData.filter((k) => k.surveyQuestionCommentId === ele.name + "-Comment" && k.surveyUserResponseId === f.surveyUserResponseId)
            .length > 0
      );
    } else if (keyComments === "No") {
      newData = data.filter(
        (f) =>
          keyExpertData.filter((k) => k.surveyQuestionCommentId === ele.name + "-Comment" && k.surveyUserResponseId === f.surveyUserResponseId)
            .length === 0
      );
    } else {
      newData = data;
    }
    return (
      <>
        {newData && newData.length > 0 && (
          <Accordion
            activeState={activeState === ele.name + "-Comment"}
            showStep={false}
            stepCompleted={true}
            key={index + 1}
            heading={removeTags(ele.customTitle ? ele.customTitle : ele.title)}
          >
            {newData.map((c, i) => {
              return (
                <React.Fragment key={`block-${index}-${i}`}>
                  <div className="row">
                    <div className="col-md-11">
                      <div className={`text-secondary ${i === 0 && "pt-2"}`}>
                        <small>
                          <b className="expert-name">
                            {c.userFname} {c.userLname}
                          </b>
                        </small>
                      </div>
                      <small className="text-secondary">{c.commentDate}</small>
                      <div className="expert-comment"> {c.comment}</div>
                    </div>
                    <div className={`col-md-1 pl-5 ${i === 0 && "pt-2"}`}>
                      <small>Key Comment</small>
                      <ToggleSwitch index={`${index + 1}-${i}`} ele={ele} c={c} />
                    </div>
                  </div>
                  <div className="row">
                    {newData.length - (i + 1) > 0 && (
                      <div className="col-md-12">
                        <hr />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </Accordion>
        )}
      </>
    );
  };

  return (
    <>
      {props?.interfaceAccess ? (
        <div className="e-content customkia mx-2" style={{ padding: "1%", marginTop: "1%", backgroundColor: "#ffffff", width: "98.7%" }}>
          {(searchFlag || apiStatus === "success") && (
            <div className="col-md-9 rounded-bottom nopadding cy-expert-comment-filter p-0 bg-white">
              <div className="row">
                <div className="col-4">
                  <MultiSelect
                    formik={formik}
                    mode="CheckBox"
                    allowCustomValue={false}
                    allowFiltering={true}
                    enableRtl={true}
                    showClearButton={false}
                    required=""
                    placeholder="Expert Users (Multiple Select)"
                    id="commentExpert"
                    name="commentExpert"
                    // value={[...formik?.values?.commentExpert]}
                    fields={{ text: "label", value: "value" }}
                    filtering={(e) => {
                      searchUsers(e.text);
                    }}
                    noRecordsTemplate={() => <div>Type min 2 chars to search expert users</div>}
                    dataSource={expertUsers}
                  />
                </div>
                <div className="col-md-3 ">
                  <div className={`form-group`}>
                    <DateRangePickerComponent
                      ref={dateRangeRef}
                      placeholder="Date Range"
                      openOnFocus
                      showClearButton
                      format="dd-MMM-yyyy"
                      separator="to"
                      cssClass="customCss e-outline"
                      floatLabelType="Auto"
                      // value={dateRange ? dateRange : ""}
                      // startDate={startDate} endDate={endDate}
                      onChange={(e) => {
                        if (e.value) setDateRange(e.value);
                        localStorage.setItem("dateRange2", e.value);
                      }}
                    ></DateRangePickerComponent>
                  </div>
                </div>
                <div className="col-md-2">
                  <Dropdown
                    formik={formik}
                    sortOrder="Acending"
                    placeholder="Key Comment"
                    id="isKeyComment"
                    name="isKeyComment"
                    dataSource={["Yes", "No"]}
                    value={formik.values.isKeyComment}
                  />
                </div>
                <div className="col-md-3 ">
                  <button type="button" onClick={handleSearch} className="search-btn px-4 btn btn-primary">
                    Search
                  </button>
                  <button type="reset" onClick={handleReset} className="btn btn-outline-dark px-4 ml-2 ">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="gennx-content-wrapper">
            <div className="row">
              <div className="col-md-12">
                {apiStatus === "success" &&
                  surveyData.surveyQuestionComments &&
                  questionsData.map((ele, index) => {
                    return (
                      <>
                        {surveyData.surveyQuestionComments[ele.name] &&
                          surveyData.surveyQuestionComments[ele.name].length > 0 &&
                          commentsJsx(sortArr(surveyData.surveyQuestionComments[ele.name]), ele, index)}
                      </>
                    );
                  })}
                {/* {apiStatus === "success" && !!surveyData.surveyQuestionComments && (<h6 height="565px" >No search result found!</h6>)} */}
                {apiStatus === "loading" && <h5 className="text-center">Data is loading...</h5>}

                {apiStatus === "failed" && <h5 className="text-center">Something went wrong!</h5>}

                {apiStatus === "no-data" && <h5 className="text-center">No data available!</h5>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default CustomKia;
