/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Trend Tagging Component 
	 Summary : - Import Trend tree view from common component
	  - Save selected trend data
 --------------------------------------------------------------------------------- 
	Creation Details 
	Date Created				: 20/Aug/2020 
	Author						: Aditya Tijare
================================================================ 
*/
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TreeView from "../../../../../components/treeView/trend";
import { actionSuccess, showLoader, hideLoader, actionError } from "../../../../../../middleware/actions/utilityAction";
import {
  saveNewsTrend,
  deleteNewsTrend,
  fetchNewsTrend,
  deleteManyNewsTrend,
  fetchSingleNews,
  updateContentTags,
  saveNewsTrendSequence,
} from "../../../../../../middleware/services/newsApi";
import PropTypes from 'prop-types';

const TaxomonyUI = (props) => {
  const dispatch = useDispatch();
  const newsId = props.newsId;
  const handleSave = (selectedTech, checkValues, callback, sgfData, callback2) => {
    saveTrend(selectedTech, checkValues, callback, sgfData, callback2);
  };
  useEffect(() => {
    if (props.saveNews) {
      props.handleSave(false);
    }
  }, [props.saveNews]);

  /*
	@param selectedTech : Selected trend to save their respective industry
	@param taxonomyIds : Selected taxonomy
	@param callback : use for re-render tree component

	@description : Save news trend 
		1. Deleted all news taxonomy for respecting news trend
		2. Add news taxonomy for respecting news trend
	*/
  const saveTrend = (selectedTech, taxonomyIds, callback, sgfData, callback2) => {
    if (selectedTech) {
      dispatch(showLoader());
      deleteNewsTrend(newsId, selectedTech)
        .then(() => {
          dispatch(hideLoader());
          if (taxonomyIds.length > 0) {
            dispatch(showLoader());
            try {
              const response = saveNewsTrend(newsId, { taxonomyIds: taxonomyIds, sgfData: sgfData });
              response
                .then((_response) => {
                  callback();

                  dispatch(hideLoader());
                  dispatch(actionSuccess("Trend Tagged Successfully"));
                })
                .catch((_e) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Something Went Wrong...!"));
                });
            } catch (_e) {
              dispatch(hideLoader());
              dispatch(actionError("Something Went Wrong...!"));
            }
          } else {
            callback();
          }
        })
        .catch((_e) => {
          dispatch(hideLoader());
          dispatch(actionError("Something Went Wrong...!"));
        });
    }
  };

  const deleteTrend = (checkedTrends, callback, callback2, selectedTech) => {
    deleteManyNewsTrend(newsId, checkedTrends)
      .then((res) => {
        callback();

        dispatch(actionSuccess("Taxonomy deleted successfully"));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  const handleSaveTagSequence = (sortedTrends, callback) => {
    saveNewsTrendSequence(newsId, sortedTrends)
      .then((res) => {
        callback();
        dispatch(actionSuccess("Taxonomy sequence added Successfully."));
      })
      .catch((err) => {
        dispatch(actionError("Something Went Wrong...!"));
      });
  };

  return (
    <TreeView
      handleSave={handleSave}
      saveTreeData={props.saveNews}
      contentId={newsId}
      fetchContentTagging={fetchNewsTrend}
      loadOtherTagInfo={props.loadOtherTagInfo}
      setSaveTreeData={props.setSaveNews}
      isDelete={true}
      deleteTrend={deleteTrend}
      accessPermissionTaxonomyDelete={props?.accessPermissionTaxonomyDelete}
      accessPermissionTaxonomy={props?.accessPermissionTaxonomy}
      accessPermissionAddTags={props?.accessActionTaxonomyAddTags}
      accessActionTaxonomyShowInProduct={props?.accessActionTaxonomyShowInProduct}
      fetchTags={fetchSingleNews}
      saveTags={updateContentTags}
      contentModule={"News"}
      handleSaveTagSequence={handleSaveTagSequence}
    />
  );
};
TaxomonyUI.propTypes = {
  newsId: PropTypes.string,
  saveNews:PropTypes.bool,
  loadOtherTagInfo:PropTypes.string,
  handleSave: PropTypes.func,
  accessPermissionTaxonomyDelete: PropTypes.bool,
  accessPermissionTaxonomy: PropTypes.bool,
  setSaveNews:PropTypes.bool,
  accessActionTaxonomyShowInProduct: PropTypes.bool,
  accessActionTaxonomyAddTags: PropTypes.bool,
};


export default TaxomonyUI;
