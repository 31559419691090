import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import moment from "moment";
import { TextField, TextEditor, MultiSelectNew, Dropdown } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import { collaborationValidationSchema } from "../../../../../helper/validationSchema/productValidationSchema";
import { fetchCompany } from "../../../../../../middleware/services/companyApi";
import {
  fetchCollaborationByCollaborationId,
  addProductCollaboration,
  editProductCollaboration,
  fetchProductDocumentUrl,
} from "../../../../../../middleware/services/productApi";
import { getProductVariant } from "../../../../../../utilities";
import { ckEditorMinimalConfig } from "../../../../../../config/ckEditorConfig";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";

const collaborationTypeOptions = [
  {
    label: "Out-Licensing",
    value: "Out-Licensing",
  },
  {
    label: "Co-Development",
    value: "Co-Development",
  },
  {
    label: "Customer",
    value: "Customer",
  },
];

const LicensingOrganizations = (props) => {
  const [value, setValue] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  useEffect(() => {
    setValue([...props.formik.values[props.name]]);
  }, [props.formik.values[props.name]]);
  // debounce search
  const searchOrganizations = useCallback(
    debounce(async (value) => {
      if (value.length > 2) {
        let filters = [];
        const productVariant = getProductVariant();
        if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
        filters.push(["companyName", "cn", value]);
        let fields = { fields: ["companyId", "companyName"] };
        const response = await fetchCompany({ filters: filters, ...fields });
        if (response.data && response.data.data) setOrganizations(response.data.data);
      }
    }, 300),
    []
  );
  return (
    <div className="form-group">
      <label htmlFor={props.name}>Name of the Organization</label>
      <span className="text-danger">*</span>
      <CreatableSelect
        inputProps={{ autoComplete: "off", autoCorrect: "off", spellCheck: "off" }}
        {...props}
        noOptionsMessage={() => <div>Please type at least 3 characters</div>}
        options={organizations.map((o) => {
          return { label: o.companyName, value: o.id };
        })}
        value={value}
        onChange={(selectedOption) => {
          selectedOption ? setValue({ ...value, collaborationCompany: selectedOption }) : setValue([]);
          const selectedType = selectedOption ? selectedOption : [];
          setValue(selectedType);
          props.formik.setFieldValue("collaborationCompany", selectedType);
        }}
        onInputChange={(inputValue) => {
          inputValue && searchOrganizations(inputValue);
        }}
      />
      <div className="text-danger" style={{ display: props.formik.touched[props.name] === true ? "block" : "none" }} role="alert">
        <small>{props.formik.touched[props.name] === true ? props.formik.errors[props.name] || "" : ""}</small>
      </div>
    </div>
  );
};

const LicensingModal = (props) => {
  const { callbackFunc = undefined } = props;
  const [saveBtnText, setSaveBtnText] = useState(props.editCollaborationData ? "Update" : "Save");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);
  const [oldData, setOldData] = useState(null);
  const toast = useRef(null);
  const onSaveData = (productId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = addProductCollaboration(productId, formData);
    return response
      .then((result) => {
        try {
          const responseData = result.data || {};
          if (result.status === 200) {
            dispatch(actionSuccess("Product Collaboration added successfully"));
            if (responseData === "success") {
              return { status: "success" };
            }
            if (callbackFunc) callbackFunc();
            props.clicked();
            return { status: "success" };
          } else {
            dispatch(actionError("Something went wrong"));
            throw Object.assign(new Error("SOMETHING WENT WRONG"), { code: 500 });
          }
        } catch (err) {
          dispatch(actionError("Something went wrong"));

          return { status: "failed" };
        }
      })
      .catch((err) => {
        dispatch(actionError("Something went wrong"));
        return { status: "failed" };
      });
  };

  const onUpdateData = (productId, collaborationId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = editProductCollaboration(productId, collaborationId, formData);
    return response
      .then((result) => {
        try {
          const responseData = result.data || {};
          if (result.status === 200) {
            dispatch(actionSuccess("Product Collaboration updated successfully"));
            if (responseData === "success") {
              return { status: "success" };
            }
            if (callbackFunc) callbackFunc();
            props.clicked();
            return { status: "success" };
          } else {
            dispatch(actionError("Something went wrong"));
            throw Object.assign(new Error("SOMETHING WENT WRONG"), { code: 500 });
          }
        } catch (err) {
          dispatch(actionError("Something went wrong"));
          return { status: "failed" };
        }
      })
      .catch((err) => {
        dispatch(actionError("Something went wrong"));
        return { status: "failed" };
      });
  };
  const UploadComponent = (props) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: ".pdf,.txt,.doc,.docx,.xls,.xlsx,.pptx,.csv",
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        props.formik.setValues({ ...props.formik.values, collobarationAttachment: acceptedFiles });
      },
    });
    return (
      <>
        <div className="form-group bg-light p-3 border-dotted mb-1" {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" className="btn btn-dark">
            Upload Document
          </button>{" "}
          or drop file here
        </div>
        <small>Only pdf,txt,doc,docx,xls,xlxs,pptx,csv files are allowed.</small>

        {props.editCollaborationData ? (
          <>
            <br />
            <a href={fetchProductDocumentUrl(props.formik.values.linkFile, props.formik.values.collobarationAttachmentFileName)}>
              <i className="fas fa-download" data-tip="Download" />
              {props.formik.values.collobarationAttachmentFileName}
            </a>
            <ReactTooltip />
          </>
        ) : (
          ""
        )}

        <div className="text-danger" style={{ display: props.formik.touched.collobarationAttachment === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched.collobarationAttachment === true ? props.formik.errors.collobarationAttachment || "" : ""}</small>
        </div>
      </>
    );
  };

  const searchTag = async (value) => {
    if (value.length > 2) {
      let filters = [];
      const productVariant = getProductVariant();
      if (productVariant) filters.push(["productVariant.productName", "eq", productVariant]);
      filters.push(["companyName", "cn", value]);
      let fields = { fields: ["companyId", "companyName"] };
      const response = await fetchCompany({ filters: filters, ...fields, sort: "companyName:asc", limit: 10 });
      if (response.data && response.data.data) {
        // const selectedVal = formik.values.collaborationCompany && formik.values.collaborationCompany.length > 0 &&
        //     formik.values.collaborationCompany?.map(v => v.value)
        let resData = response.data.data.map((v) => {
          return v.companyName && { label: v.companyName, value: v.id };
        });
        // if (selectedVal && selectedVal.length > 0) {
        //     resData = resData.filter(f => !selectedVal.includes(f.value))
        // }
        if (resData) {
          setOrganizations([...resData, ...organizations]);
        } else {
          setOrganizations([]);
        }
      }
    }
  };

  const initialFormValues = {
    collaborationCompany: [],
    collaborationType: "",
    collaborationDate: "",
    collaborationDescription: "",
    linkFile: "",
    collobarationAttachment: "",
    collobarationAttachmentFileName: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(collaborationValidationSchema),
  });
  const setFormikValues = (data) => {
    const oldData = {
      collaborationCompany: data.collaborationCompany
        ? [
            ...data.collaborationCompany.map((cC) => {
              let cCV = { label: cC.companyName };
              if (cC.companyId) {
                cCV.value = cC.companyId;
              } else {
                cCV.value = cC.companyName;
              }
              return cCV;
              // return cC.companyName
            }),
          ]
        : [],
      collaborationType: data.collaborationType ? data.collaborationType : [],
      collaborationDate: data.collaborationDate ? moment(data.collaborationDate).format("YYYY") : "",
      collaborationDescription: data.collaborationDescription || "",
      linkFile: data.collobarationAttachment ? data.collobarationAttachment[0]?.docFileName : "",
      collobarationAttachment: "",
      collobarationAttachmentFileName: data.collobarationAttachment ? data.collobarationAttachment[0]?.docName : "",
    };
    setOrganizations(oldData.collaborationCompany);
    setOldData(oldData);
    formik.setValues(oldData);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.editCollaborationData && props.collaborationId) {
      const response = fetchCollaborationByCollaborationId(props.productId, props.collaborationId);
      response
        .then((response) => {
          if (response.status === 200) {
            setFormikValues(response.data.data[0]);
          } else {
            setAlertMessage("Something Went Wrong");
            throw Object.assign(new Error("SOMETHING WENT WRONG"), { code: 500 });
          }
        })
        .catch((e) => {
          setAlertMessage("Something Went Wrong while processing.");
        });
    } else {
      formik.resetForm();
    }
    return () => {
      formik.resetForm();
    };
  }, [props.collaborationId]);

  const handleReset = () => {
    formik.resetForm();
    props.clicked();
    formik.setValues({ ...initialFormValues });
    if (props.editCollaborationData) {
      formik.setValues(oldData);
    }
  };
  const saveCollaboration = async () => {
    const { values } = formik;
    const productCollaborationData = {
      collaborationCompany: JSON.stringify([
        ...values.collaborationCompany.map((c) => {
          // VALID_MONGO_ID.test(c)
          if (c.label === c.value) {
            return { companyName: c.value };
          } else {
            return {
              companyName: c.label,
              companyId: c.value,
            };
          }
        }),
      ]),
      collaborationType: values.collaborationType,
      collaborationDate: values.collaborationDate + "-01-01",
      collaborationDescription: values.collaborationDescription,
      collobarationAttachment: values.collobarationAttachment[0],
    };
    if (props.editCollaborationData) {
      if (!values.collobarationAttachment[0]) delete productCollaborationData.collobarationAttachment;
    }
    const response = props.editCollaborationData
      ? onUpdateData(props.productId, props.collaborationId, productCollaborationData)
      : onSaveData(props.productId, productCollaborationData);
    setSaveBtnText(props.editCollaborationData ? "UPDATING..." : "SAVING...");
    setBtnDisabled(true);
    response.then((result) => {
      setSaveBtnText(props.editCollaborationData ? "Update" : "Save");
      setBtnDisabled(false);
      if (result.status === "success" && !props.editCollaborationData) handleReset();
    });
  };

  const handleSubmitCollaboration = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        saveCollaboration();
      }
    });
  };

  return (
    <>
      <ToastComponent
        id="toast_target"
        ref={toast}
        //  created={()=>{showAlert(toast,'','','')}}
      />
      <Modal show={props.showModal} backdrop="static" className="modal fade lg" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header bg-none text-dark">
          <h5 className="modal-title" id="exampleModalLabel">
            {oldData ? "Update" : "Add New"} Licensing/Co-Development
          </h5>
          <button type="button" className="close" aria-label="Close" onClick={handleReset}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              {
                <MultiSelectNew
                  formik={formik}
                  mode="Box"
                  // allowCustomValue={props.editCollaborationData ? true : false}
                  allowCustomValue={true}
                  allowFiltering={true}
                  sortOrder="Acending"
                  showSelectAll={true}
                  placeholder="Name of the Organization *"
                  id="collaborationCompany"
                  name="collaborationCompany"
                  value={
                    oldData &&
                    oldData?.collaborationCompany.length > 0 &&
                    oldData.collaborationCompany.map((v) => {
                      return v.label;
                    })
                  }
                  fields={{ text: "label", value: "value" }}
                  filtering={(e) => {
                    searchTag(e.text);
                  }}
                  noRecordsTemplate={() => <div className="norecord">Type min 3 characters to search Organization</div>}
                  dataSource={organizations}
                />
              }

              <div className="row">
                <div className="col-6">
                  <Dropdown
                    formik={formik}
                    id="collaborationType"
                    name="collaborationType"
                    placeholder="Nature of Relationship *"
                    dataSource={collaborationTypeOptions}
                    fields={{ text: "label", value: "value" }}
                  />
                </div>
                <div className="col-6">
                  <TextField
                    type="number"
                    formik={formik}
                    className="form-control"
                    id="collaborationDate"
                    placeholder="Year of Engagement *"
                    required="required"
                    name="collaborationDate"
                  />
                </div>
              </div>
              <label style={{ top: "20px", position: "relative" }}>Purpose / Description of Engagement</label>
              <div className="row">
                <div className="col-md-12">
                  <TextEditor
                    formik={formik}
                    properties={{
                      id: "collaborationDescription",
                      name: "collaborationDescription",
                      activeClass: "collaborationDescription",
                      // label: "About",
                      config: {
                        ...ckEditorMinimalConfig,
                        editorplaceholder: "Purpose / Description of Engagement",
                        height: "200px",
                      },
                    }}
                  />
                </div>
              </div>

              <UploadComponent editCollaborationData={props.editCollaborationData} formik={formik} />
              {formik.values.collobarationAttachment &&
                formik.values.collobarationAttachment.map((file, i) => <li key={i}>{`File:${file.name}`}</li>)}
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button
            type="button"
            onClick={handleReset}
            className={`btn btn-outline-dark cy-btn pl-2 pr-2 mr-3 ${btnDisabled ? " disabled " : " "}`}
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmitCollaboration}
            className={`btn btn-primary cy-btn pl-2 pr-2 ${btnDisabled ? " disabled " : " "}`}
          >
            {saveBtnText}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default LicensingModal;

LicensingModal.propTypes = {
  editCollaborationData: PropTypes.bool.isRequired,
  productId: PropTypes.string.isRequired,
  collaborationId: PropTypes.string,
  callbackFunc: PropTypes.func,
  formik: PropTypes.shape({
    setTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    setValues: PropTypes.func,
    touched: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func
  }),
  clicked: PropTypes.func
};
