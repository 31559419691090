/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Listing
 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDataGrid from "../../components/dataGrid";
import { showAlertBox } from "../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../middleware/actions/breadCrumbAction";
import { publishVideo } from "../../../middleware/services/cmsApi";
import {
  fetchVideos,
  deleteSingleVideo,
  deleteSelectedVideos,
  saveVideoTaxonomyTags,
  saveVideokiaTags,
  savevideoTrendTags,
  saveVideoBusinessObjectiveTaxonomy,
} from "../../../middleware/services/videoApi";
import { swap, getProductVariant, getDateFromMongoDate, getLoggedInUser, fetchTechTrendData } from "../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../middleware/actions/utilityAction";
import { StringFilter, DropDownFilter } from "../../components/dataGrid/fliters";
import ThreeDotMenu from "../../components/threeDotMenu";
import "./index.scss";
import { setMaintainedState, visitRecord } from "../../../middleware/actions/gridAction";
import { COMMON_ACCESS_ACTION, PRODUCT_VARIANT } from "../../../constants";
import { resetRedux, setFilteredTaxonomy } from "../../../middleware/actions/taxonomyFilterAction";
import {
  setInitialCheckedKiaNodes,
  setInitialCheckedObjectiveNodes,
  setInitialCheckedSgfNodes,
  setInitialCheckedTechnologyNodes,
  setInitialCheckedTrendNodes,
  setKiaCheckedNodes,
  setKiaUnCheckedNodes,
  setObjectiveTaxonomyCheckedNodes,
  setObjectiveTaxonomyUnCheckedNodes,
  setResetRedux,
  setTaxonomyCheckedNodes,
  setTaxonomyUnCheckedNodes,
  setTrendCheckedNodes,
  setTrendUnCheckedNodes,
} from "../../../middleware/actions/taxonomyTaggingAction";
import PropTypes from 'prop-types';

const TvListing = (props) => {
  const gridState = useSelector((state) => state.gridState);
  const [deleteId, setDeleteId] = useState(undefined);
  const { accessRights: aR } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const productVariant = getProductVariant();
  const loggedInUser = getLoggedInUser();
  const [initalState, setInitialState] = useState([]);
  const [bulkCheck, setBulkCheck] = useState([]);
  const [showTaxonomyBulk, setShowTaxonomyBulk] = useState(false);
  const [cssClass, setCssClass] = useState("treeClass");
  const [listClass, setListClass] = useState("select-lists");
  const [taxonomyState, setTaxonomyState] = useState([]);
  const breadCrumbLinks = [{ linkUrl: "/video", linkName: aR.moduleName, linkActive: true }];
  let moduleName = props.accessRights?.moduleName;
  const videoTaxonomy = useSelector((state) => state.taxonomyTaggingState);
  const [taxonomyTaggingTabName, setTaxonomyTaggingTabName] = useState(false);
  const taxonomyFilterRedux = useSelector((state) => state.taxonomyFilterState);
  const [filterData, setFilterData] = useState(taxonomyFilterRedux?.filteredTaxonomy ? taxonomyFilterRedux.filteredTaxonomy : []);
  const [techTrendData, setTechTrendData] = useState(undefined);

  /* Common access */
  const accessActionEdit = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT);
  const accessActionDelete = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE);
  const accessActionPublish = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.PUBLISH);
  const accessActionListingAddNew = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_NEW);
  const accessActionFilterByTaxonomy = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.FILTER_BY_TAXONOMY);
  const accessActionTaxonomyBulkTagging = props?.interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.TAXONOMY_BULK_TAGGING);

  useEffect(() => {
    if (!techTrendData) fetchTechData();
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/video"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
      dispatch(setBreadCrumb());
    };
  }, [dispatch]);

  // Reset Redux
  useEffect(() => {
    const locationPath = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];
    if (locationPath != `/video`) {
      setFilterData([]);
      dispatch(setFilteredTaxonomy([]));
      dispatch(resetRedux());
      localStorage.setItem("locationPath", window.location.pathname);
    }
  }, []);

  const fetchTechData = async () => {
    setTechTrendData(await fetchTechTrendData(productVariant));
  };

  const publish = (id) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await publishVideo(id, {
            userId: loggedInUser._id,
            platform: productVariant,
          })
            .then((res) => {
              if (res) {
                setDeleteId(new Date());
                dispatch(actionSuccess("Video has been published successfully"));
              } else {
                dispatch(actionError("Something went wrong"));
              }
            })
            .catch((err) => {
              let errMessage = "Video cannot published : <br>";
              const errMessageArr = err?.data?.message ? err.data.message.split(",") : ["Something went wrong"];
              errMessage += errMessageArr
                .map((v, index) => {
                  return ++index + "." + v + "<br>";
                })
                .join("");

              dispatch(actionError(errMessage));
            });
        },
        okText: "Publish",
        cancelText: "Cancel",
        content: "Are you sure you want to publish ?",
        title: "dialogAlertCss",
      })
    );
  };

  const deleteRecord = (id) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          deleteSingleVideo(id)
            .then((response) => {
              if (deleteId) setDeleteId(undefined);
              setDeleteId(new Date());
              dispatch(actionSuccess("Video deleted successfully"));
              const params = {};
              params.limit = 100;
              if (productVariant)
                params.filters = [
                  ["productVariant.productName", "eq", productVariant],
                  ["videoIsDeleted", "eq", "NO"],
                ];
              fetchVideos(params).then((v) => {
                formatRowData(v.data.data);
              });
            })
            .catch((err) => {
              dispatch(actionError(err.data?.message || "Failed to delete video"));
            });
        },
        okText: "Delete",
        cancelText: "Cancel",
        content: "Are you sure you want to delete ?",
        title: "dialogAlertCssWarning",
      })
    );
  };

  const actionTemplate = (value) => {
    return (
      (value.Status === "Inactive" && (
        <ThreeDotMenu
          rowID={value.id}
          {...(accessActionEdit
            ? {
                Edit: () => {
                  visitRecord();
                  history.push(`/video/${value.id}/edit`, {
                    videoId: value.id,
                    videoTitle: value["Video Title"],
                  });
                },
              }
            : {})}
          {...(accessActionDelete
            ? {
                Delete: () => {
                  deleteRecord(value.id);
                },
              }
            : {})}
        />
      )) || (
        <ThreeDotMenu
          rowID={value.id}
          {...(accessActionEdit
            ? {
                Edit: () => {
                  visitRecord();
                  history.push(`/video/${value.id}/edit`, {
                    videoId: value.id,
                    videoTitle: value["Video Title"],
                  });
                },
              }
            : {})}
          {...(accessActionPublish
            ? {
                Publish: () => {
                  publish(value.id);
                },
              }
            : {})}
          {...(accessActionDelete
            ? {
                Delete: () => {
                  deleteRecord(value.id);
                },
              }
            : {})}
        />
      )
    );
  };

  const deleteManyFunc = (flag) => {
    const selectedVideos = localStorage.getItem("selectedVideos") ? JSON.parse(localStorage.getItem("selectedVideos")) : [];
    if (flag) {
      if (selectedVideos.length) {
        dispatch(
          showAlertBox({
            okCallback: () => {
              deleteSelectedVideos({ videoIds: selectedVideos })
                .then((response) => {
                  if (response) {
                    if (deleteId) setDeleteId(undefined);
                    setDeleteId(true);
                    dispatch(actionSuccess("Selected record(s) deleted successfully"));
                    const params = {};
                    params.limit = 100;
                    if (productVariant) params.filters = [["productVariant.productName", "eq", productVariant]];
                    fetchVideos(params).then((v) => {
                      formatRowData(v.data.data);
                    });
                  }
                })
                .catch((err) => {
                  dispatch(actionError(err.data?.message || "Failed to delete video"));
                });
            },
            okText: "Delete",
            cancelText: "Cancel",
            content: "Are you sure you want to delete ?",
            title: "dialogAlertCssWarning",
          })
        );
      } else {
        dispatch(actionError("No record selected for deletion"));
      }
    }
  };

  const videoTitleTemplate = (value) => {
    return (
      (
        <span
          className="span-link"
          onClick={() => {
            history.push(`/video/${value.id}/edit`);
            dispatch(visitRecord());
          }}
        >
          {value["Video Title"]}
        </span>
      ) || <span>{value["Video Title"]}</span>
    );
  };

  /* 
        @Description : Date Template 
    */
  const dateTemplate = (value) => {
    const column = value.column.field;

    return ["Published Date", "Created Date"].includes(column) && !isNaN(Date.parse(value[`${column}`])) ? (
      <span>{getDateFromMongoDate(new Date(value[`${column}`]))}</span>
    ) : (
      ""
    );
  };

  const ItemFilter = (value) => {
    let sourceData = [];
    switch (value.column.field) {
      case "Is Live":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      case "Trend":
        sourceData = techTrendData;
        break;
      case "Status":
        sourceData = [
          { id: "Active", text: "Active" },
          { id: "Inactive", text: "Inactive" },
          { id: "Upcoming", text: "Upcoming" },
        ];
        break;
      case "Type":
        sourceData = [
          { id: "In Discussion With", text: "In Discussion With" },
          { id: "Interview", text: "Interview" },
          { id: "Podcast", text: "Podcast" },
          { id: "Start-up Spotlight", text: "Start-up Spotlight" },
          { id: "Top 5", text: "Top 5" },
          { id: "Webinar", text: "Webinar" },
        ];
        break;
      case "Buzzing Story":
        sourceData = [
          { id: "YES", text: "YES" },
          { id: "NO", text: "NO" },
        ];
        break;
      default:
        break;
    }

    return <DropDownFilter value={value} sourceData={sourceData} />;
  };
  const columnNames = {
    id: "id",
    "Video Title": "videoTitle",
    Status: "videoStatus",
    Type: "videoType",
    "Created Date": "videoCreatedDate",
    "Is Live": "videoIsLive",
    "Published Date": "videoPublishedDate",
  };
  if (productVariant) {
    columnNames["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  } else {
    columnNames["Is Live"] = "productVariant.isLive";
  }
  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) columnNames["Buzzing Story"] = "videoIsBuzzingStory";
  if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) columnNames["Trend"] = "videoIndustryTrendsTags.trendId";

  const columnFields = [
    {
      field: "id",
      type: "string",
      visible: false,
      allowFiltering: false,
      showInColumnChooser: false,
      isPrimaryKey: true,
    },
    {
      field: "Video Title",
      type: "String",
      template: videoTitleTemplate,
      filterTemplate: StringFilter,
      textAlign: "Left",
      showInColumnChooser: false,
    },
    productVariant &&
      productVariant === PRODUCT_VARIANT.INSIDER && {
        field: "Trend",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "contains" },
        textAlign: "Left",
        width: 250,
      },
    {
      field: "Type",
      type: "String",
      filterTemplate: ItemFilter,
      textAlign: "Left",
      showInColumnChooser: true,
      width: 140,
    },
    {
      field: "Status",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      width: 120,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    {
      field: "Created Date",
      template: dateTemplate,
      type: "Date",
      textAlign: "Center",
      width: 180,
      headerTextAlign: "Center",
      showInColumnChooser: true,
    },
    {
      field: "Published Date",
      type: "Date",
      template: dateTemplate,
      textAlign: "Center",
      width: 190,
      headerTextAlign: "Center",
    },
    {
      field: "Is Live",
      type: "String",
      filterTemplate: ItemFilter,
      filter: { operator: "equal" },
      textAlign: "Center",
      headerTextAlign: "Center",
      width: 130,
    },
    productVariant &&
      productVariant === PRODUCT_VARIANT.INSIDER && {
        field: "Buzzing Story",
        type: "String",
        filterTemplate: ItemFilter,
        filter: { operator: "equal" },
        textAlign: "Left",
        width: 150,
      },
  ];
  if (accessActionEdit || accessActionDelete || accessActionPublish) {
    columnFields.unshift({
      field: null,
      type: "checkbox",
      width: 32,
      textAlign: "Center",
      allowFiltering: false,
      showInColumnChooser: false,
    });
    columnFields.push({
      field: "Action",
      type: "String",
      template: actionTemplate,
      filterTemplate: StringFilter,
      allowSorting: false,
      allowFiltering: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      width: 65,
      showInColumnChooser: false,
      freeze: "Right",
    });
  }
  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];
    const columnNamesValues = swap(columnNames);
    formatedRowData = rowData.map((rD) => {
      const newRD = { Id: rD.id };
      Object.keys(rD).forEach((rDK) => {
        if (columnNamesValues[rDK]) {
          newRD[`${columnNamesValues[rDK]}`] = rD[rDK] && rD[rDK] !== "" ? rD[rDK] : "";
        }
        if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
          newRD.Trend = rD.videoIndustryTrendsTags
            .map((trend) => {
              if (trend.trendParentId != 0 && trend.trendRoot != 0 && trend.trendSubRoot == 0 && !trend.trendIsSgf) {
                return trend.trendName;
              }
              return "";
            })
            .filter((trend) => trend != "")
            .join(", ");
        }
        if (rD.productVariant?.length) {
          if (productVariant) {
            const rDPV = rD.productVariant.find((pV) => pV.productName === productVariant);
            newRD["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
            newRD["Published Date"] = rD.videoPublishedDate ? new Date(rD.videoPublishedDate) : "";
          } else {
            newRD["Is Live"] = rD.productVariant[0].isLive ? rD.productVariant[0].isLive : "NO";
            newRD["Published Date"] = rD.videoPublishedDate ? new Date(rD.videoPublishedDate) : "";
          }
        }
        if (productVariant && productVariant === PRODUCT_VARIANT.INSIDER) {
          newRD["Buzzing Story"] = rD?.videoIsBuzzingStory ? rD.videoIsBuzzingStory : "NO";
        }
        return newRD;
      });
      newRD["Created Date"] = new Date(rD.videoCreatedDate);
      return newRD;
    });
    return formatedRowData;
  };

  const getVideos = (params = {}) => {
    let defaultFilter = [["productVariant.productName", "eq", productVariant]];
    let { filters = [] } = params;
    let userCompetencyIndustry = localStorage.getItem("userCompetencyIndustry") !== "null" ? localStorage.getItem("userCompetencyIndustry") : "null";
    let trendFiltering = false;
    if (filters) {
      filters = filters.map((item) => {
        /**True-false is trend filter is selected */
        if (item.includes("videoIndustryTrendsTags.trendId")) trendFiltering = item.includes("videoIndustryTrendsTags.trendId");
        /** */
        const tech = techTrendData.filter((f) => {
          return ["videoIndustryTrendsTags.trendId"].includes(item[0]) && f.text === item[2];
        });
        if (tech.length > 0) {
          if (productVariant === PRODUCT_VARIANT.INSIDER) {
            return item[0] === "videoIndustryTrendsTags.trendId" ? [item[0], "in", [tech[0].id]] : item;
          }
        } else return item;
      });
    }
    if (!trendFiltering && userCompetencyIndustry !== "null" && userCompetencyIndustry !== undefined) {
      userCompetencyIndustry = JSON.parse(userCompetencyIndustry);
      const industryId = userCompetencyIndustry?.map((obj) => obj.id);
      productVariant === PRODUCT_VARIANT.INSIDER &&
        userCompetencyIndustry?.length > 0 &&
        defaultFilter.push(["videoIndustryTrendsTags.trendId", "in", industryId]);
    }
    let fields = [
      "videoTitle",
      "videoStatus",
      "videoType",
      "productVariant",
      "videoCreatedDate",
      "videoPublishedDate",
      "videoIsBuzzingStory",
      "videoIndustryTrendsTags",
    ];

    if (!params.sort) params.sort = "videoCreatedDate:desc";
    params.filters = [...filters, ...defaultFilter];

    if (!gridState.recordVisited) {
      if (!params.skip) {
        params.skip = 0;
      }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        sort: gridState.sortRecords,
        skip: gridState.skipRecords,
        limit: gridState.recordsPerPage,
        filters: gridState.filtersParams,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }

    params.fields = fields;
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchVideos(params);
    }

    if (Object.keys(filterData).length > 0) {
      let technologyFilter = filterData?.technology?.length > 0 ? ["videoTechnologyTags.dtId", "in", filterData.technology] : [];
      let kiaFilter = filterData?.kia?.length > 0 ? ["videoKiaTags.kiaId", "in", filterData.kia] : [];
      let boTaxonomy = filterData?.boTaxonomy?.length > 0 ? ["videoObjectiveTaxonomy.taxonomyId", "in", filterData.boTaxonomy] : [];
      let businessObjective =
        filterData?.businessObjective?.length > 0 ? ["videoBusinessObjective.objectiveId", "in", filterData.businessObjective] : [];

      let trendfilter = filterData?.trend?.length > 0 ? ["videoIndustryTrendsTags.trendId", "in", filterData.trend] : [];

      params.filters = [...filters, ...defaultFilter];

      technologyFilter.length > 0 && params.filters.push(technologyFilter);
      kiaFilter.length > 0 && params.filters.push(kiaFilter);
      businessObjective.length > 0 && params.filters.push(businessObjective);
      boTaxonomy.length > 0 && params.filters.push(boTaxonomy);
      trendfilter.length > 0 && params.filters.push(trendfilter);
    }
    return fetchVideos(params);
  };

  const objectiveTaxonomyTag = async () => {
    const selectedVideos = localStorage.getItem("selectedVideos") ? JSON.parse(localStorage.getItem("selectedVideos")) : [];
    if (selectedVideos.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedVideos],
      ];
      params.fields = ["productVariant"];
      dispatch(showLoader());
      const companyIsLive = await fetchVideos(params);
      dispatch(hideLoader());
      if (companyIsLive.data) {
        let nonLiveComp = companyIsLive.data.data.filter((comp) => {
          let isLIveComp = true;
          comp.productVariant.forEach((prod) => {
            if (prod.productName == productVariant && prod.isLive == "YES") {
              isLIveComp = false;
            }
          });
          return isLIveComp;
        });
        if (nonLiveComp.length) {
          dispatch(actionError("Please select only live video."));
        } else {
          history.push(`/business-objective/taxonomy/video`, {
            videoIds: selectedVideos,
            entityName: "video",
          });
        }
      }
    } else {
      dispatch(actionError("Please select video."));
    }
    dispatch(visitRecord());
  };

  /* 
        @Description : Bulk tagging technology tab
    */
  const handleSubmitTechnology = async (fetchGridData) => {
    let payload = {
      videoIds: initalState,
      taxonomyTagging: videoTaxonomy.taxonomyCheckedNodes,
      taxonomyUnTagging: videoTaxonomy.taxonomyUnCheckedNodes,
    };

    if (videoTaxonomy?.initialTechnologyCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveVideoTaxonomyTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select video"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchTechnologyTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedVideos = localStorage.getItem("selectedVideos") ? JSON.parse(localStorage.getItem("selectedVideos")) : [];
    if (selectedVideos.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedVideos],
      ];
      params.fields = ["videoTechnologyTags"];
      const contentTaxonomy = fetchVideos(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.videoTechnologyTags.length > 0 &&
              value.videoTechnologyTags.map((v) => {
                checkNodes.push(v.dtId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedVideos.length == d[1]) {
                commonIds.push(d[0]);
              }
            });
            setBulkCheck([...videoTaxonomy.taxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTaxonomyCheckedNodes([...videoTaxonomy.taxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const taxonomyBulkTagging = async () => {
    dispatch(visitRecord());
    const selectedVideos = localStorage.getItem("selectedVideos") ? JSON.parse(localStorage.getItem("selectedVideos")) : [];
    if (selectedVideos.length) {
      const params = {};
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedVideos],
      ];
      const companyIsLive = await fetchVideos(params);
      dispatch(hideLoader());
      if (companyIsLive) {
        dispatch(showLoader());
        setInitialState(selectedVideos);
        setShowTaxonomyBulk(true);
      }
    } else {
      dispatch(actionError("Please select video."));
    }
  };

  const addTaxonomyCheckedNodes = (id) => {
    let taxonomyCheck = videoTaxonomy.taxonomyCheckedNodes;
    let taxonomyUnCheck = videoTaxonomy.taxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = videoTaxonomy.initialTechnologyCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTechnologyNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTechnologyNodes([...videoTaxonomy.initialTechnologyCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTaxonomyUnCheckedNodes([...videoTaxonomy.taxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTaxonomyCheckedNodes([...videoTaxonomy.taxonomyCheckedNodes, id]));
  };

  /* 
        @Description : Bulk tagging for kia
    */
  const handleSubmitKia = async (fetchGridData) => {
    let payload = {
      videoIds: initalState,
      taxonomyTagging: videoTaxonomy.kiaCheckedNodes,
      taxonomyUnTagging: videoTaxonomy.kiaUnCheckedNodes,
    };

    if (videoTaxonomy?.initialKiaCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveVideokiaTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select video"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchKiaTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedVideos = localStorage.getItem("selectedVideos") ? JSON.parse(localStorage.getItem("selectedVideos")) : [];
    if (selectedVideos.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedVideos],
      ];
      params.fields = ["videoKiaTags"];
      const contentTaxonomy = fetchVideos(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.videoKiaTags.length > 0 &&
              value.videoKiaTags.map((v) => {
                checkNodes.push(v.kiaId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedVideos.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            commonIds.map((element) => {
              value.videoKiaTags.length > 0 &&
                value.videoKiaTags.map((v) => {
                  if (v.kiaId === element) {
                    commonIds.push(v.kiaIndustryId);
                  }
                });
            });
            commonIds = [...new Set(commonIds)];
            setBulkCheck([...videoTaxonomy.kiaCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setKiaCheckedNodes([...videoTaxonomy.kiaCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addKiaCheckedNodes = (id) => {
    let taxonomyCheck = videoTaxonomy.kiaCheckedNodes;
    let taxonomyUnCheck = videoTaxonomy.kiaUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = videoTaxonomy.initialKiaCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedKiaNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedKiaNodes([...videoTaxonomy.initialKiaCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setKiaCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setKiaUnCheckedNodes([...videoTaxonomy.kiaUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setKiaUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setKiaCheckedNodes([...videoTaxonomy.kiaCheckedNodes, id]));
  };

  /* 
        @Description : Bulk tagging for business objective
    */
  const handleSubmitObjective = async (fetchGridData) => {
    let payload = {
      videoIds: initalState,
      videoObjectiveTaxonomy: videoTaxonomy.objectiveTaxonomyCheckedNodes,
      videoUnCheckObjectiveTaxonomy: videoTaxonomy.objectiveTaxonomyUnCheckedNodes,
    };

    if (videoTaxonomy?.initialObjectiveCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              saveVideoBusinessObjectiveTaxonomy(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  setShowTaxonomyBulk(false);
                  dispatch(hideLoader());
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select video"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };

  const fetchObjectiveTagging = () => {
    setListClass("select-lists bulktagging");
    setCssClass("treeClass");
    const selectedVideos = localStorage.getItem("selectedVideos") ? JSON.parse(localStorage.getItem("selectedVideos")) : [];
    if (selectedVideos.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedVideos],
      ];
      params.fields = ["videoObjectiveTaxonomy"];
      const contentTaxonomy = fetchVideos(params);
      contentTaxonomy
        .then((res) => {
          let checkNodes = [];
          let count;
          res.data.data.map((value) => {
            value.videoObjectiveTaxonomy.length > 0 &&
              value.videoObjectiveTaxonomy.map((v) => {
                checkNodes.push(v.taxonomyId);
                count = {};
                for (const element of checkNodes) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });

            let checked = Object.entries(count);
            let commonIds = [];
            checked.map((d) => {
              if (selectedVideos.length == d[1]) {
                commonIds.push(d[0]);
              }
            });

            setBulkCheck([...videoTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setObjectiveTaxonomyCheckedNodes([...videoTaxonomy.objectiveTaxonomyCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addObjectiveCheckedNodes = (id) => {
    let taxonomyCheck = videoTaxonomy.objectiveTaxonomyCheckedNodes;
    let taxonomyUnCheck = videoTaxonomy.objectiveTaxonomyUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = videoTaxonomy.initialObjectiveCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedObjectiveNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedObjectiveNodes([...videoTaxonomy.initialObjectiveCheckedNodes, id]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setObjectiveTaxonomyUnCheckedNodes([...videoTaxonomy.objectiveTaxonomyUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setObjectiveTaxonomyUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setObjectiveTaxonomyCheckedNodes([...videoTaxonomy.objectiveTaxonomyCheckedNodes, id]));
  };

  const handleSubmitTrend = async (fetchGridData) => {
    let payload = {
      videoIds: initalState,
      taxonomyTagging: videoTaxonomy.trendCheckedNodes,
      taxonomyUnTagging: videoTaxonomy.trendUnCheckedNodes,
      sgfIds: videoTaxonomy.initialSgfCheckedNodes,
    };

    if (videoTaxonomy?.initialTrendCheckedNodes.length == 0) {
      try {
        dispatch(
          showAlertBox({
            okCallback: async () => {
              process.nextTick(() => {
                dispatch(hideLoader());
              });
            },
            cancelCallback: async () => {
              setShowTaxonomyBulk(false);
            },

            okText: "Ok",
            cancelText: "Cancel",
            content: "There is no change observed in taxonomy tagging, press Ok to continue or press Cancel to close.",
            title: "dialogAlertCssWarning",
          })
        );
      } catch (error) {
        dispatch(actionError("Something Went Wrong...!"));
      }
    } else {
      dispatch(
        showAlertBox({
          okCallback: () => {
            process.nextTick(() => {
              dispatch(showLoader());
              savevideoTrendTags(payload)
                .then((result) => {
                  dispatch(actionSuccess("Taxonomy updated successfully"));
                  dispatch(setResetRedux());
                  dispatch(hideLoader());
                  setShowTaxonomyBulk(false);
                  fetchGridData();
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(actionError("Please select video"));
                });
            });
          },
          okText: "Submit",
          cancelText: "Cancel",
          content: "Are you sure you want to update the tagging?",
          title: "dialogAlertCssWarning",
        })
      );
    }
  };
  // Get call for bulk tagging
  const fetchTrendTagging = () => {
    setCssClass("treeClass trend");
    setListClass("select-lists bulktagging");
    const selectedVideos = localStorage.getItem("selectedVideos") ? JSON.parse(localStorage.getItem("selectedVideos")) : [];
    if (selectedVideos.length) {
      setBulkCheck([]);
      const params = { limit: gridState.recordsPerPage };
      params.filters = [
        ["productVariant.productName", "eq", productVariant],
        ["_id", "in", selectedVideos],
      ];
      params.fields = ["videoIndustryTrendsTags"];
      const contentTaxonomy = fetchVideos(params);
      contentTaxonomy
        .then((res) => {
          let foo = [];
          let count;
          res.data.data.map((value) => {
            value.videoIndustryTrendsTags.length > 0 &&
              value.videoIndustryTrendsTags.map((v) => {
                foo.push(v.trendId);
                count = {};
                for (const element of foo) {
                  if (count[element]) {
                    count[element] += 1;
                  } else {
                    count[element] = 1;
                  }
                }
              });
            let checked = count && Object.entries(count);
            let commonIds = [];
            checked &&
              checked.map((d) => {
                if (selectedVideos.length == d[1]) {
                  commonIds.push(d[0]);
                }
              });
            setBulkCheck([...videoTaxonomy.trendCheckedNodes, ...commonIds]);
            setTaxonomyState([...commonIds]);
            dispatch(setTrendCheckedNodes([...videoTaxonomy.trendCheckedNodes, ...commonIds]));
          });
        })
        .catch((err) => {
          console.log("err fetchProductTaxonomy :: ", err);
        });
    }
  };

  const addTrendCheckedNodes = (taxonomyId) => {
    let id = taxonomyId.split("-")[1];
    let sgfId = taxonomyId.split("-")[0];
    let taxonomyCheck = videoTaxonomy.trendCheckedNodes;
    let taxonomyUnCheck = videoTaxonomy.trendUnCheckedNodes;

    // for initial nodes
    let initialCheckedNodes = videoTaxonomy.initialTrendCheckedNodes;
    if (initialCheckedNodes.includes(id)) {
      initialCheckedNodes.splice(initialCheckedNodes.indexOf(id), 1);
      dispatch(setInitialCheckedTrendNodes(initialCheckedNodes));
    }
    dispatch(setInitialCheckedTrendNodes([...videoTaxonomy.initialTrendCheckedNodes, id]));

    // set checked sgf node
    dispatch(setInitialCheckedSgfNodes([...videoTaxonomy.initialSgfCheckedNodes, sgfId]));

    if (taxonomyCheck.includes(id)) {
      taxonomyCheck.splice(taxonomyCheck.indexOf(id), 1);
      dispatch(setTrendCheckedNodes(taxonomyCheck));
      if (bulkCheck.includes(id)) {
        dispatch(setTrendUnCheckedNodes([...videoTaxonomy.trendUnCheckedNodes, id]));
      }
      return;
    }
    if (taxonomyUnCheck.includes(id)) {
      taxonomyUnCheck.splice(taxonomyUnCheck.indexOf(id), 1);
      dispatch(setTrendUnCheckedNodes(taxonomyUnCheck));
    }
    dispatch(setTrendCheckedNodes([...videoTaxonomy.trendCheckedNodes, id]));
  };
  //
  let tabNames;
  if (productVariant == PRODUCT_VARIANT.INSIDER) {
    tabNames = taxonomyTaggingTabName ? ["Tag Industry"] : ["By Industry"];
  } else {
    tabNames = taxonomyTaggingTabName
      ? ["Tag Technology", "Tag Key Impact Area", "Tag Business Objective"]
      : ["By Technology", "By Key Impact Area", "By Business Objective"];
  }

  // handle save for taxonomy bluk tagging
  const handleSave = (selectedIndex, fetchGridData) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        handleSubmitTechnology(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitKia(fetchGridData);
      }
      if (selectedIndex == 2) {
        handleSubmitObjective(fetchGridData);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        handleSubmitTrend(fetchGridData);
      }
      if (selectedIndex == 1) {
        handleSubmitObjective(fetchGridData);
      }
    }
  };

  const handleTaxonomyTagging = (args, selectedIndex) => {
    let valueId = args?.data[0].id;
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        addTaxonomyCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addKiaCheckedNodes(valueId);
      }
      if (selectedIndex == 2) {
        addObjectiveCheckedNodes(valueId);
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        addTrendCheckedNodes(valueId);
      }
      if (selectedIndex == 1) {
        addObjectiveCheckedNodes(valueId);
      }
    }
  };

  // fetch TaxonomyTagging data for taxonomy bluk tagging
  const fetchTaxonomyTagging = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        fetchTechnologyTagging();
      }
      if (selectedIndex == 1) {
        fetchKiaTagging();
      }
      if (selectedIndex == 2) {
        fetchObjectiveTagging();
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        fetchTrendTagging();
      }
      if (selectedIndex == 1) {
        fetchObjectiveTagging();
      }
    }
  };

  const setBulkCheckedNode = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      selectedIndex == 0 && setBulkCheck([...videoTaxonomy.taxonomyCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...videoTaxonomy.kiaCheckedNodes]);
      selectedIndex == 2 && setBulkCheck([...videoTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      selectedIndex == 0 && setBulkCheck([...videoTaxonomy.trendCheckedNodes]);
      selectedIndex == 1 && setBulkCheck([...videoTaxonomy.objectiveTaxonomyCheckedNodes]);
    }
  };
  const resetAllTaxonnomy = (selectedIndex) => {
    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      if (selectedIndex == 0) {
        dispatch(setTaxonomyCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setKiaCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 2) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    if (productVariant === PRODUCT_VARIANT.INSIDER) {
      if (selectedIndex == 0) {
        dispatch(setTrendCheckedNodes([...taxonomyState]));
      }
      if (selectedIndex == 1) {
        dispatch(setObjectiveTaxonomyCheckedNodes([...taxonomyState]));
      }
    }
    setBulkCheck([...taxonomyState]);
  };
  const locationPathName = localStorage.getItem("locationPath") ? localStorage.getItem("locationPath") : [];

  return (
    <div className="gennx-content-wrapper content-wrapper px-3">
      {/* <div className="col-md-12 p-2 border bg-secondary text-white rounded-top">All Videos</div> */}
      <div className="col-md-12 border rounded-bottom nopadding p-0 bg-white">
        {/* <GridTopToolBar add={{ route: "/survey/basic-details#basic" }} /> */}
        <div className="gennx-grid-container">
          {techTrendData && (
            <CommonDataGrid
              gridTitle="Videos"
              fetch={getVideos}
              showLoader={showLoaderGrid}
              hideLoader={hideLoaderGrid}
              columnNames={columnNames}
              columnFields={columnFields}
              formatRowData={formatRowData}
              deleteId={deleteId}
              getMultiSelectedRows={(data) => {
                localStorage.setItem("selectedVideos", JSON.stringify([...data]));
              }}
              getMultiDeSelectedRows={(data) => {
                localStorage.setItem("selectedVideos", JSON.stringify([...data]));
              }}
              addNew={() => history.push(`/video/add`)}
              addNewRight={accessActionListingAddNew}
              deleteRight={true}
              objectiveTaxonomyRight={true}
              objectiveTaxonomyTag={objectiveTaxonomyTag}
              deleteMany={accessActionDelete && deleteManyFunc}
              setFilterData={setFilterData}
              filterData={filterData}
              moduleName={moduleName}
              showFilterByTaxonomy={(productVariant !== PRODUCT_VARIANT.CHEERSIN ? true : false) && accessActionFilterByTaxonomy}
              boTagging={true}
              setBulkCheck={setBulkCheck}
              taxonomyBulkTagging={taxonomyBulkTagging}
              showTaxonomyBulk={showTaxonomyBulk}
              setShowTaxonomyBulk={setShowTaxonomyBulk}
              initalState={initalState}
              checked={bulkCheck}
              tabName={tabNames}
              handleTaxonomyTabName={setTaxonomyTaggingTabName}
              customDialogBoxClass="videoDialoagBoxClass"
              customArrowClass="video-arrow-top"
              customDialogBoxClassBulk="videoDialoagBoxClass"
              bulkTaggingArrowTop="videoBulk-arrow-top"
              handleSubmit={handleSave}
              cssClass={cssClass}
              setCssClass={setCssClass}
              listClass={listClass}
              setListClass={setListClass}
              handleTaxonomyTagging={handleTaxonomyTagging}
              fetchTaxonomyTagging={fetchTaxonomyTagging}
              setBulkCheckedNode={setBulkCheckedNode}
              resetAllTaxonnomy={resetAllTaxonnomy}
              accessPermissionBulk={accessActionTaxonomyBulkTagging}
              clearFilterByTaxonomy={locationPathName == "/video" ? true : false}
            />
          )}
        </div>
      </div>
    </div>
  );
};
TvListing.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.string), 
  interfaceActionAccess: PropTypes.arrayOf(PropTypes.string),
}


export default TvListing;
