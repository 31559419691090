/*
================================================================
	Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	  File Description : Internal User Profile
 ---------------------------------------------------------------
	Creation Details
	Date Created				: 14/Aug/2020
	Author						: YOGESH N. GUPTA
================================================================
*/

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, DatePicker, Dropdown, MultiSelect } from "../../../components/CustomFormElements/syncFusionFormFields";
import moment from "moment";
import _ from "lodash";
import Tabs from "../../../components/tabsComponent";
import { actionStart, actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import Accordion from "../../../components/accordion";
import { addUserValidationSchema, editUserValidationSchema } from "../../../helper/validationSchema/userValidationSchema";
import { addUser, updateUser, fetchSingleUser, fetchUserProfileImageUrl, fetchUserRoles } from "../../../../middleware/services/userApi";
import { fetchTaxonomyTechnology, fetchTaxonomyIndustry, fetchTaxonomyTrend, fetchTeam } from "../../../../middleware/services/cmsApi";
import { USER_ROLES, EMPANELMENT_LIST, PRODUCT_VARIANT, COMMON_ACCESS_ACTION } from "../../../../constants";
import userImage from "../../../../assets/images/user.jpg";
import { getLoggedInUser, isAdmin, getProductVariant, accessRightActionCheck, accessRightInterfaceCheck } from "../../../../utilities";
import AccessDenied from "../../../components/accessDenied";
import { useQuery } from "@tanstack/react-query";

const TABS_NAME = ["User Profile"];
const initialValues = {
  userFname: "",
  userLname: "",
  userEmail: "",
  userMobile: "",
  userPassword: "",
  userConfirmPassword: "",
  userDesignation: "",
  userRole: "",
  userEmpCode: "",
  userEmpaneledBy: "",
  userActivationDate: "",
  userCompentencyTechnology: [],
  userCompentencyIndustry: [],
  userType: "",
  userActive: undefined,
};

const UserProfile = (props) => {
  const tabRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { accessRights: aR } = props;
  const accessRightPermission = false;
  const userTokenDetails = getLoggedInUser();
  const sessonId = userTokenDetails._id;
  const history = useHistory();
  const editUser = props.path === "/internal-user/add" ? false : true;
  const isMultipleProductVariant = userTokenDetails.product_variant?.length > 1 ? true : false;
  const userId = editUser ? props.computedMatch?.params?.userId : props.userId || "";
  const profileAccessId = props?.computedMatch?.params?.userId;
  // specify breadcrumb here
  const hashArr = { "#profile": "User Profile" };

  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [existingUser, setExistingUser] = useState({});
  const [teams, setTeams] = useState([]);
  const productVariant = getProductVariant();
  const [userProfileImgSrc, setUserProfileImgSrc] = useState(undefined);
  const [technologies, setTechnologies] = useState([]);
  const [trends, setTrends] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [trendsMaster, setTrendsMaster] = useState([]);
  const [userFuturebridgeRelevancy, setUserFuturebridgeRelevancy] = useState(undefined);
  const [imageError, setImageError] = useState("");
  const [isDefaultImage, setIsDefaultImage] = useState(true);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);
  const [userRolesData, setUserRolesData] = useState([]);
  const [rolesDataSource, setRolesDataSource] = useState([]);

  /* Common access */
  const accessActionSave = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.SAVE);
  const accessActionEditProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.EDIT_PROFILE_IMAGE);
  const accessActionDeleteProfileImage = interfaceActionAccess.includes(COMMON_ACCESS_ACTION.DELETE_PROFILE_IMAGE);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editUser
      ? Yup.object().shape({
          ...editUserValidationSchema,
          productVariant: Yup.array().min(1, "Product Variant field must have at least 1 item"),
        })
      : Yup.object().shape({
          ...addUserValidationSchema,
          userRole: Yup.string().required("User role is required"),
          productVariant: Yup.array().min(1, "Product variant field must have at least 1 item").required("Product variant is required"),
        }),
  });

  /**
   * @description fetch single user detail
   * @param {String} userId
   */
  const fetchUserDetail = () => {
    dispatch(showLoader());
    fetchSingleUser(userId)
      .then((res) => {
        const userDetail = { ...res.data.data };
        const productVariant = userDetail.productVariant[0].productName;
        if (userDetail.userActivationDate) userDetail.userActivationDate = moment(userDetail.userActivationDate).format("DD-MMM-yyyy");
        if (userDetail.userProfileImage && userDetail.userProfileImage !== "") {
          setUserProfileImgSrc(fetchUserProfileImageUrl(userDetail.userProfileImage));
          setIsDefaultImage(false);
        } else {
          setIsDefaultImage(true);
        }

        setExistingUser(userDetail);
        formik.setValues({
          // ...userDetail,
          userFname: userDetail?.userFname || "",
          userLname: userDetail?.userLname || "",
          userEmail: userDetail?.userEmail || "",
          userMobile: userDetail?.userMobile || "",
          userDesignation: userDetail?.userDesignation || "",
          userEmpCode: userDetail?.userEmpCode || "",
          userRole: userDetail?.userRole,
          userEmpaneledBy: userDetail.userEmpaneledBy,
          userActivationDate: userDetail.userActivationDate || "",
        });

        formik.setFieldValue(
          "productVariant",
          userDetail?.productVariant?.map((v) => {
            return v.productName;
          })
        );

        formik.setFieldValue(
          "userCompentencyTechnology",
          userDetail?.userCompentencyTechnology?.map((v) => {
            return v.dtId;
          })
        );
        if (userDetail?.userCompentencyIndustry?.length > 0) {
          let userCompentencyIndustryTags = [];
          userDetail.userCompentencyIndustry.forEach((uCI) => {
            if (productVariant === PRODUCT_VARIANT.WHATNEXT || productVariant === PRODUCT_VARIANT.CHEERSIN) {
              userCompentencyIndustryTags.push({ id: uCI.industryId, industryName: uCI.industryName });
            } else {
              userCompentencyIndustryTags.push({ id: uCI.industryId, trendName: uCI.industryName });
            }
          });
          userDetail.userCompentencyIndustry = userCompentencyIndustryTags;
        }

        userDetail?.userCompentencyIndustry &&
          formik.setFieldValue("userCompentencyIndustry", [...new Set(userDetail?.userCompentencyIndustry?.map((item) => item.id))]);
        dispatch(hideLoader());
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch User Details Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch vendors to tag with user
   */
  const fetchTechnologies = () => {
    fetchTaxonomyTechnology({ filters: [["dtParentId", "eq", 0]], fields: ["id", "dtName"], sort: "dtName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTechnologies(data.map((d) => ({ dtId: d.id, dtName: d.dtName })));
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Technology Failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch technologies to tag with user
   */
  const fetchTrends = () => {
    fetchTaxonomyTrend({ filters: [["isTrendNode", "eq", 1]], sort: "trendParentName:asc", limit: 2000 })
      .then((res) => {
        const { data = [] } = res.data;
        setTrendsMaster(data);
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.trendParentId] = { id: sTT.trendParentId, trendName: sTT.trendParentName };
        });
        setTrends([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch Taxonomy Trend Failed";
        dispatch(actionError(errMsg));
      });
  };
  /**
   * @description fetch industry to tag with user
   */
  const fetchIndustry = () => {
    fetchTaxonomyIndustry({ fields: ["industryName"], sort: "industryName:asc", limit: 50 })
      .then((res) => {
        const { data = [] } = res.data;
        const setTrendsTags = JSON.parse(JSON.stringify(data));
        let industryDropdownValues = {};
        setTrendsTags.forEach((sTT) => {
          industryDropdownValues[sTT.id] = { id: sTT.id, industryName: sTT.industryName };
        });
        setIndustry([...Object.values(industryDropdownValues)]);
      })
      .catch((err) => {
        let errMsg = err?.data?.message ? err?.data?.message : "Fetch industry failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description fetch teams for user product variant tagging (whatNext, Insider)
   */
  const fetchTeamsForUser = async () => {
    if (isAdmin()) {
      const res = await fetchTeam({ sort: "teamName:asc" });
      const { data = [] } = res.data;
      setTeams([...data.map((d) => ({ productName: d.teamName }))]);
    } else if (isMultipleProductVariant) {
      setTeams([...userTokenDetails.product_variant.map((p) => ({ productName: p.product_name }))]);
    }
  };

  /**
   * @description : Get roles data
   */
  useQuery({
    queryKey: ["roles-data"],
    queryFn: () => {
      let params = {};
      params.filters = [
        ["app_id", "eq", import.meta.env.VITE_ENTERPRISE_APP_ID],
        ["role_is_enable", "eq", "YES"],
      ];
      params.fields = ["_id", "appId", "roleName"];
      params.limit = 2000;
      params.sort = "roleName:asc";
      return fetchUserRoles(params);
    },
    enabled: true,
    suspense: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    onSuccess: (res) => {
      let rolesData = res?.data?.data || [];
      setUserRolesData(rolesData);
      let sourceData = rolesData?.map((role) => {
        return role?.roleName;
      });
      setRolesDataSource(sourceData);
    },
  });

  /**
   * @description add new user
   * @param {Object} userData
   */
  const addNewUser = (userData) => {
    dispatch(actionStart());
    addUser(userData)
      .then((res) => {
        setUser({});
        userProfileImgRef.current.setAttribute("src", userImage);
        dispatch(actionSuccess("User profile has been added"));
        history.push(`/internal-user/${res.data.data.id}/edit#profile`);
        window.location.reload();
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter a valid email"
            : err?.data?.message
          : "Add new user failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description update existing user
   * @param {Object} userData
   */
  const updateExistingUser = (userData) => {
    dispatch(actionStart());
    updateUser(userId, userData)
      .then((res) => {
        setUser({});
        dispatch(actionSuccess("User profile has been updated"));
      })
      .catch((err) => {
        let errMsg = err?.data?.message
          ? err?.data?.message === 'payload: "userEmail" must be a valid email'
            ? "Please enter valid email"
            : err?.data?.message
          : "User profile updation failed";
        dispatch(actionError(errMsg));
      });
  };

  /**
   * @description save user (new / existing) using form submit
   */
  const saveUser = () => {
    const userDetail = { ...formik.values };
    if (!editUser && !isAdmin() && !isMultipleProductVariant) userDetail.productVariant = [{ productName: getProductVariant() }];
    formik.setValues({ ...userDetail });
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        const user = { ...formik.values };
        let saveUserData = new FormData();
        if (user.userActivationDate) user.userActivationDate = moment(user.userActivationDate).format("DD-MMM-yyyy");
        for (let uKey in user) {
          if (user[uKey] && user[uKey] !== "") saveUserData.append(uKey, user[uKey]);
        }

        saveUserData.delete("userConfirmPassword");
        saveUserData.delete("userProfileImage");
        if (!editUser) {
          saveUserData.append("userType", "Internal");
        }
        if (
          (!editUser && _.isEmpty(user)) ||
          (editUser &&
            _.isEmpty(user) &&
            userProfileImgInputRef.current.files.length === 0 &&
            (!existingUser.userProfileImage ||
              (existingUser.userProfileImage && existingUser.userProfileImage !== "" && userProfileImgSrc !== undefined)))
        ) {
          return true;
        }
        if (user?.productVariant) {
          let productVariantTags = user?.productVariant?.map((v) => {
            return { productName: v };
          });
          saveUserData.set("productVariant", JSON.stringify(productVariantTags));
        }
        if (userDetail?.userCompentencyTechnology) {
          let userCompentencyTechnologyTags = [];
          technologies &&
            technologies.map((t) => {
              if ([...userDetail.userCompentencyTechnology].includes(t.dtId)) {
                userCompentencyTechnologyTags.push({
                  dtId: t.dtId,
                  dtName: t.dtName,
                });
              }
            });
          saveUserData.delete("userCompentencyTechnology");
          userCompentencyTechnologyTags && saveUserData.set("userCompentencyTechnology", JSON.stringify(userCompentencyTechnologyTags));
        }
        if (user?.userCompentencyIndustry) {
          let userCompentencyIndustryTags = [];
          if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
            industry &&
              industry.map((t) => {
                if ([...user.userCompentencyIndustry].includes(t.id)) {
                  userCompentencyIndustryTags.push({
                    industryId: t.id,
                    industryName: t.industryName,
                  });
                }
              });
          } else {
            trends &&
              trends.map((t) => {
                if ([...user.userCompentencyIndustry].includes(t.id)) {
                  trendsMaster
                    .filter((tM) => tM.trendParentId === t.id)
                    .forEach((tC) => {
                      userCompentencyIndustryTags.push({
                        industryId: t.id,
                        industryName: t.trendName,
                        trendId: tC.id,
                        trendName: tC.trendName,
                      });
                    });
                }
              });
          }
          saveUserData.delete("userCompentencyIndustry");
          saveUserData.set("userCompentencyIndustry", JSON.stringify(userCompentencyIndustryTags));
        }
        if (userFuturebridgeRelevancy) {
          let userFuturebridgeRelevancyTags = userFuturebridgeRelevancy.length ? [...userFuturebridgeRelevancy] : [];
          saveUserData.set("userFuturebridgeRelevancy", JSON.stringify(userFuturebridgeRelevancyTags));
        }
        if (userProfileImgInputRef.current.files.length) {
          saveUserData.append("userProfileImage", userProfileImgInputRef.current.files[0]);
        } else {
          saveUserData.append("userProfileImage", "");
        }
        // * Auto Access of Application as per role
        let roleId = userRolesData?.find((r) => r.roleName === formik.values.userRole)?.id || "";
        if (roleId) {
          saveUserData.append("roleId", roleId);
          saveUserData.append("appId", import.meta.env.VITE_ENTERPRISE_APP_ID);
        }

        dispatch(
          showAlertBox({
            okCallback: editUser ? updateExistingUser : addNewUser,
            data: saveUserData,
            okText: editUser ? "Update" : "Add",
            cancelText: "Cancel",
            content: editUser ? "Are you sure, you want to update user details?" : "Are you sure, you want to add user details?",
            title: "dialogAlertCss",
          })
        );
      }
    });
  };

  /**
   * @param {File} imageFile
   * @description read & set image for user profile
   */
  const readProfileImage = (imageFile) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      userProfileImgRef.current.setAttribute("src", e.target.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const created = (e) => {
    tabRef.current.animation.previous = { effect: "None" };
    tabRef.current.animation.next = { effect: "None" };
    if (userId) {
      if (selectedIndex !== Object.keys(hashArr).indexOf(props.location.hash)) {
        setSelectedIndex(Object.keys(hashArr).indexOf(props.location.hash));
        tabRef.current.select(Object.keys(hashArr).indexOf(props.location.hash));
      }
      if (!accessRightPermission && sessonId === profileAccessId && ![0, 2].includes(selectedIndex)) {
        // history.push(`/external-user/${userId}/edit/#profile`)
        tabRef.current.enableTab(1, false);
        tabRef.current.enableTab(3, false);
      }
    } else {
      tabRef.current.enableTab(1, false);
      tabRef.current.enableTab(2, false);
      tabRef.current.enableTab(3, false);
      tabRef.current.select(0);
    }
  };

  useEffect(() => {
    editUser && fetchUserDetail(userId);
    fetchTechnologies();
    getProductVariant() === "WhatNext" ? fetchIndustry() : fetchTrends();
    fetchTeamsForUser();
  }, [dispatch, selectedIndex, props.location.hash, userId]);

  /*
    @Description : Set active link on sidebar for news
  */
  useEffect(() => {
    const breadCrumbLinks = [
      { linkUrl: "/internal-user", linkName: aR.moduleName, linkActive: false },
      {
        linkUrl: userId ? `/internal-user/${userId}/edit#profile` : `/internal-user/add`,
        linkName:
          formik.values.userFname.length === 0 && formik.values.userLname.length === 0
            ? "Add New"
            : `${formik.values.userFname} ${formik.values.userLname}`,
        linkActive: false,
      },
      {
        linkUrl: `/internal-user/${userId}/edit${Object.keys(hashArr)[selectedIndex]}`,
        linkName: Object.values(hashArr)[selectedIndex],
        linkActive: true,
      },
    ];

    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/internal-user"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, selectedIndex, props.location.hash, userId, formik]);

  useEffect(() => {
    setTimeout(() => {
      const emailInput = document.querySelector("#userEmail");
      emailInput.style.display = "";
    }, 100);
  }, []);

  const userProfileImgInputRef = useRef(null);
  const userProfileImgRef = useRef(null);
  const userCompetencyPanelRef = useRef(null);

  const accessRightInterfaceRights = accessRightInterfaceCheck(aR.moduleName, TABS_NAME);
  useEffect(() => {
    let interfaceName = Object.values(hashArr)[selectedIndex];
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, [selectedIndex]);

  let interfaceAccess = accessRightInterfaceRights.includes(Object.values(hashArr)[selectedIndex]);

  return (
    <div style={{ background: "#F2F2F2" }} className="gennx-envelope">
      <Tabs
        tabsName={TABS_NAME}
        tabRef={tabRef}
        created={created}
        handleClick={() => {
          setSelectedIndex(tabRef.current.selectedItem);
        }}
        cssClass="newHeaderTabCss"
      >
        <div key="userProfile" style={{ width: "99.5%" }}>
          {interfaceAccess ? (
            <>
              <div className=" gennx-content-wrapper padding-bottom-50i mx-2 pt-3 footer-button-wrapper">
                <Accordion heading="Basic Details" step={1} stepCompleted={formik.values.userFname ? true : false} activeState={true}>
                  <div className="container-fluid mb-3 pl-0">
                    <div className="pt-3">
                      <div className="row">
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userFname" name="userFname" placeholder="First Name *" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userLname" name="userLname" placeholder="Last Name *" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userEmail" placeholder="Email *" name="userEmail" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userMobile" placeholder="Mobile" name="userMobile" />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userDesignation" placeholder="Designation" name="userDesignation" />
                            </div>
                            <div className="col-md-4">
                              <DatePicker
                                formik={formik}
                                properties={{
                                  placeholder: "User Activation Date",
                                  id: `userActivationDate`,
                                  name: `userActivationDate`,
                                  format: "dd MMM yyyy",
                                  floatLabelType: "Auto",
                                  allowEdit: false,
                                  openOnFocus: true,
                                  disabled: false,
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextField formik={formik} type="text" id="userEmpCode" placeholder="Employee Code" name="userEmpCode" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-1">{editUser && <label className="mt-2">User Status</label>}</div>
                            <div className="col-md-2">
                              {editUser ? (
                                typeof existingUser.userActive !== "undefined" ? (
                                  existingUser.userActive ? (
                                    <blockquote className="active">Active</blockquote>
                                  ) : (
                                    <blockquote className="inactive">Inactive </blockquote>
                                  )
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" style={{ top: "-30px", position: "relative" }}>
                          <div className="image-holder mb-1">
                            <img src={userProfileImgSrc || userImage} alt="User" ref={userProfileImgRef} width="150" height="150" />
                            <div className="image-action" style={{ right: "122px" }}>
                              {accessActionDeleteProfileImage && (
                                <span
                                  onClick={() => {
                                    dispatch(
                                      showAlertBox({
                                        content: "Are you sure, you want to delete?",
                                        okText: "Delete",
                                        cancelText: "Cancel",
                                        title: "dialogAlertCssWarning",
                                        okCallback: async () => {
                                          dispatch(actionSuccess("Image deleted successfully"));
                                          if (userProfileImgInputRef.current.files.length > 0) {
                                            userProfileImgRef.current.setAttribute("src", userProfileImgSrc || userImage);
                                            userProfileImgInputRef.current.value = "";
                                            setImageError("");
                                            setIsDefaultImage(true);
                                          } else {
                                            setUserProfileImgSrc(undefined);
                                            setIsDefaultImage(true);
                                            setImageError("");
                                            userProfileImgRef.current.setAttribute("src", userImage);
                                          }
                                        },
                                      })
                                    );
                                  }}
                                >
                                  {" "}
                                  <i className={`fa fa-trash mr-1 ${isDefaultImage ? "d-none" : undefined}`} aria-hidden="true"></i>
                                </span>
                              )}
                              {accessActionEditProfileImage && (
                                <span onClick={() => userProfileImgInputRef.current.click()}>
                                  <i className="fa fa-pencil-alt " aria-hidden="true"></i>
                                </span>
                              )}
                            </div>
                          </div>

                          <input
                            type="file"
                            className="d-none"
                            accept=".jpg, .png"
                            id="userProfileImage"
                            name="userProfileImage"
                            ref={userProfileImgInputRef}
                            onChange={(e) => {
                              if (e.target.files.length > 0) {
                                if (
                                  (e.target.files[0].type == "image/png" ||
                                    e.target.files[0].type == "image/jpg" ||
                                    e.target.files[0].type == "image/jpeg") &&
                                  e.target.files[0].size < 512000
                                ) {
                                  setImageError("");
                                  setIsDefaultImage(false);

                                  readProfileImage(e.target.files[0]);
                                } else {
                                  setImageError("Only JPG, PNG files are allowed. Max file size should be 500 Kb.");
                                }
                              } else {
                                setImageError("");
                              }
                            }}
                          />
                          <div
                            className="d-flex align-item-center justify-content-center text-danger"
                            style={{ display: imageError ? "block" : "none" }}
                            role="alert"
                          >
                            <small>{imageError}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion>
                <Accordion
                  heading="Change Password"
                  step={2}
                  stepCompleted={editUser || formik.values.userPassword ? true : false}
                  forcedActiveState={formik.errors.userPassword || formik.errors.userConfirmPassword ? true : false}
                  activeState={!editUser}
                >
                  <div className="row p-3">
                    <div className="col-md-4">
                      <TextField
                        formik={formik}
                        required={true}
                        type="password"
                        id="userPassword"
                        placeholder={`Enter New Password${editUser ? " * " : " *"}`}
                        name="userPassword"
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        formik={formik}
                        required={true}
                        type="password"
                        id="userConfirmPassword"
                        placeholder={`Confirm New Password${editUser ? " * " : " *"}`}
                        name="userConfirmPassword"
                      />
                    </div>
                  </div>
                </Accordion>
                <Accordion
                  heading="User Role, Product Variant & Empanelment"
                  step={3}
                  stepCompleted={formik.values.userRole || formik.values.productVariant || formik.values.userEmpaneledBy ? true : false}
                  forcedActiveState={formik.errors.userRole || formik.errors.productVariant || formik.errors.userEmpaneledBy ? true : false}
                  activeState={!editUser}
                  required={!editUser}
                >
                  <div className="row p-3">
                    <div className={`${isAdmin() || isMultipleProductVariant ? "col-md-4" : "col-md-6"}`}>
                      {rolesDataSource?.length > 0 && (
                        <Dropdown
                          formik={formik}
                          id="userRole"
                          name="userRole"
                          placeholder="User Role *"
                          dataSource={rolesDataSource}
                          // fields={{ text: "user_role_name", value: "user_role_value" }}
                          disabled={false}
                        />
                      )}
                    </div>
                    <div className={`${isAdmin() || isMultipleProductVariant ? "col-md-4" : "d-none"}`}>
                      {teams.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          required="required"
                          placeholder="Product Variant *"
                          id="productVariant"
                          name="productVariant"
                          value={formik.values.productVariant}
                          allowCustomValue={false}
                          fields={{ text: "productName", value: "productName" }}
                          dataSource={teams}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}
                    </div>
                    <div className={`${isAdmin() || isMultipleProductVariant ? "col-md-4" : "col-md-6"}`}>
                      <Dropdown
                        formik={formik}
                        id="userEmpaneledBy"
                        name="userEmpaneledBy"
                        placeholder="Empanelment By"
                        dataSource={EMPANELMENT_LIST}
                        disabled={false}
                      />
                    </div>
                  </div>
                </Accordion>
                <Accordion
                  heading="User Competency"
                  step="4"
                  stepCompleted={
                    (formik.values.userCompentencyTechnology && formik.values.userCompentencyTechnology.length) ||
                    (formik.values.userCompentencyIndustry && formik.values.userCompentencyIndustry.length)
                      ? true
                      : false
                  }
                  activeState={!editUser}
                >
                  <div className="row p-3" ref={userCompetencyPanelRef}>
                    <div className="col-md-4">
                      {technologies.length && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Technology Expertise"
                          id="userCompentencyTechnology"
                          name="userCompentencyTechnology"
                          value={formik.values.userCompentencyTechnology}
                          allowCustomValue={false}
                          fields={{ text: "dtName", value: "dtId" }}
                          dataSource={technologies}
                          showDropDownIcon={true}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      {trends.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Industry Expertise"
                          id="userCompentencyIndustry"
                          name="userCompentencyIndustry"
                          value={formik.values.userCompentencyIndustry}
                          allowCustomValue={false}
                          fields={{ text: "trendName", value: "id" }}
                          dataSource={trends}
                          disabled={false}
                          showDropDownIcon={true}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}

                      {industry.length > 0 && (
                        <MultiSelect
                          formik={formik}
                          mode="CheckBox"
                          sortOrder="Acending"
                          showSelectAll={true}
                          // selectAllText="Select All"
                          showClearButton={false}
                          //enableRtl={true}
                          placeholder="Industry Expertise"
                          id="userCompentencyIndustry"
                          name="userCompentencyIndustry"
                          value={formik.values.userCompentencyIndustry}
                          allowCustomValue={false}
                          fields={{ text: "industryName", value: "id" }}
                          dataSource={industry}
                          disabled={false}
                          showDropDownIcon={true}
                          cssClass="multi-without-parent e-outline customCss"
                        />
                      )}
                    </div>
                  </div>
                </Accordion>
              </div>
              <div className="form-submit-box ml-1">
                <div className="container-fluid">
                  <div className="row">
                    <div className="float-left col-md-6 text-left">
                      {userId !== "" && selectedIndex > -1 && selectedIndex < 1 ? (
                        <span className="pl-4" style={{ "margin-left": "-3em" }}>
                          <span className="span-link">
                            <button
                              type="button"
                              className="btn btn-outline-dark pl-4 pr-4 cy-btn"
                              onClick={() => {
                                selectedIndex < 1 && tabRef.current.select(selectedIndex + 1);
                              }}
                            >
                              Next
                            </button>
                          </span>
                        </span>
                      ) : null}
                    </div>
                    <div className="float-right col-md-6 text-right">
                      <button type="button" className="btn btn-outline-dark cy-btn pl-3 pr-3 " onClick={() => history.push("/internal-user")}>
                        Cancel
                      </button>
                      {accessActionSave ? (
                        (existingUser.userActive && editUser) || !editUser ? (
                          <button type="button" onClick={saveUser} className="btn btn-primary cy-btn pl-3 pr-3  ml-3">
                            {editUser ? "Update" : "Save"}
                          </button>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default UserProfile;
