/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
     File Description :  News Overview component - Add | Edit Overview
 --------------------------------------------------------------------------------- 
    Creation Details 
    Date Created				: 20/Aug/2020 
    Author						: Aditya Tijare
================================================================ 
*/
import React from "react";
import OverviewForm from "./form";
import PropTypes from 'prop-types';

const NewsOverview = (props) => {
    return (
        <div className="pt-3 mx-2 gennx-content-wrapper padding-bottom-150i footer-button-wrapper" style={{ "padding": "1%", "marginTop": "1%", 'background-color': "#ffffff", "width": "98.7%", "border-radius": "5px" }}>
            <OverviewForm
                fetchNews={props.fetchNews}
                newsInformation={props.newsInformation}
                handleSaveOverview={props.handleSaveOverview}
                newsImage={props.newsImage}
                selectedIndex={props.selectedIndex}
                {...props} />
        </div>
    )
}
NewsOverview.propTypes = {
    fetchNews: PropTypes.func, 
    newsInformation: PropTypes.object, 
    handleSaveOverview: PropTypes.func,
    newsImage: PropTypes.string, 
    selectedIndex: PropTypes.number,
}

export default NewsOverview;