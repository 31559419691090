import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "../../../../../components/accordion";
import { actionSuccess, actionError } from "../../../../../../middleware/actions/utilityAction";
import { showAlertBox } from "../../../../../../middleware/actions/alertBoxAction";
import ReactTooltip from "react-tooltip";
import {
  fetchProductDocumentsByProductId,
  deleteProductDocument,
  fetchProductDocumentUrl,
  editProductDocument,
  addProductDocument,
} from "../../../../../../middleware/services/productApi";
import DocumentModal from "./documentModal";
import { accessRightActionCheck } from "../../../../../../utilities";
import ThreeDotMenu from "../../../../../components/threeDotMenu";
import { COMMON_ACCESS_ACTION } from "../../../../../../constants";

const DocumentsBrochure = (props) => {
  const { accessRights: aR } = props;
  const dispatch = useDispatch();
  const [documentListing, setDocumentListing] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(props.productId ? true : false);
  const [documentId, setDocumentId] = useState(null);
  const [editDocumentData, setEditDocumentData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [interfaceActionAccess, setInterfaceActionAccess] = useState([]);

  /* Interface action access from common access */
  useEffect(() => {
    let interfaceName = "Overview";
    let actionAccess = accessRightActionCheck(aR.moduleName, interfaceName);
    setInterfaceActionAccess(actionAccess);
  }, []);

  /* Common access */
  const accessPermissionAdd = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.ADD_DOCUMENTS);
  const accessPermissionEdit = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.EDIT_DOCUMENTS);
  const accessPermissionDelete = interfaceActionAccess?.includes(COMMON_ACCESS_ACTION.DELETE_DOCUMENTS);

  const deleteSingleDocument = (data) => {
    const deleteResponse = deleteProductDocument(data.productId, data.Id);
    deleteResponse
      .then((res) => {
        if (res.status === 200) {
          dispatch(actionSuccess("Document deleted Successfully"));
        }
        getDocuments();
      })
      .catch((err) => {
        dispatch(actionError(err.data.message || "Something went wrong"));
      });
  };
  const onUpdateData = (productId, documentId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = editProductDocument(productId, documentId, formData);
    return response
      .then((result) => {
        try {
          if (result.status === 200) {
            dispatch(actionSuccess("Product document updated successfully"));
            setIsOpen(false);
            getDocuments();
          } else {
            dispatch(actionError("Something went wrong"));
          }
        } catch (err) {
          dispatch(actionError(err ? `${err.status}:${err.statusText}` : "Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err ? `${err.status}:${err.statusText}` : "Something went wrong"));
      });
  };
  const onSaveData = (productId, data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = addProductDocument(productId, formData);
    return response
      .then((result) => {
        try {
          if (result.status === 200) {
            dispatch(actionSuccess("Product document saved successfully"));
            setIsOpen(false);
            getDocuments();
            return { status: "success" };
          } else {
            dispatch(actionError("Something went wrong"));
          }
        } catch (err) {
          dispatch(actionError(err || "Something went wrong"));
        }
      })
      .catch((err) => {
        dispatch(actionError(err || "Something went wrong"));
      });
  };

  const getDocuments = () => {
    fetchProductDocumentsByProductId(props.productId)
      .then((res) => {
        const docData = res.data?.data?.productDocuments || [];
        setDocumentListing(docData);
        setDocumentLoading(false);
      })
      .catch((err) => {
        dispatch(actionError(err.data?.message || "Something went wrong"));
      });
  };

  useEffect(() => {
    getDocuments();
  }, []);
  const handleDeleteDocument = async (id) => {
    dispatch(
      showAlertBox({
        okCallback: async () => {
          await deleteSingleDocument({ productId: props.productId, Id: id });
        },
      })
    );
  };

  return (
    <Accordion heading="Documents / Brochure" stepCompleted={documentListing && documentListing.length > 0 ? true : false} step={2}>
      <div className="row px-3">
        <div className="table-responsive pt-2">
          {documentLoading ? (
            "Loading..."
          ) : (
            <table className="normal-table table table-bordered">
              <thead className="internal-grid">
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Attachment</th>
                  <th className="internal-grid-action text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {documentListing.map((item) => {
                  return (
                    <React.Fragment key={`prod-doc-${item.id}`}>
                      <tr>
                        <td>{item.docTitle}</td>
                        <td>{item.docDescription.replace(/(<([^>]+)>|&nbsp;)/gi, "")}</td>
                        <td>
                          <a href={fetchProductDocumentUrl(item.docFileName, item.docName)} download>
                            <i className="fas fa-download" data-tip="Download" /> {item.docName}
                          </a>
                          <ReactTooltip />
                        </td>
                        <td className="text-center">
                          {accessPermissionEdit || accessPermissionDelete ? (
                            <ThreeDotMenu
                              rowID={item.id}
                              Edit={
                                accessPermissionEdit
                                  ? () => {
                                      setEditDocumentData(true);
                                      setIsOpen(true);
                                      setDocumentId(item.id);
                                    }
                                  : undefined
                              }
                              Delete={
                                accessPermissionDelete
                                  ? () => {
                                      handleDeleteDocument(item.id);
                                    }
                                  : undefined
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="text-right">
            {accessPermissionAdd ? (
              <button
                type="button"
                className="btn btn-dark cy-btn"
                onClick={() => {
                  setEditDocumentData(false);
                  setIsOpen(true);
                }}
              >
                Add New
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {isOpen && (
        <DocumentModal
          editDocumentData={editDocumentData}
          documentId={documentId}
          onSaveData={onSaveData}
          onUpdateData={onUpdateData}
          closeHandler={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          productId={props.productId}
        />
      )}
    </Accordion>
  );
};
export default DocumentsBrochure;
DocumentsBrochure.propTypes = {
  productId: PropTypes.string,
  accessRights: PropTypes.arrayOf(PropTypes.string), 
};
