/*================================================================
‘Copyright © 2020, Cheers Interactive Pvt Ltd.  All rights reserved.
	 File Description :  Template Listing Page
	 - Use common grid component for list
 ---------------------------------------------------------------------------------
	Creation Details
	Date Created				: 20/Aug/2020
	Author						: Aditya Tijare
================================================================
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { getProductVariant } from "../../../../utilities";
import { actionSuccess, actionError, showLoader, hideLoader } from "../../../../middleware/actions/utilityAction";
import CommonDataGrid from "../../../components/dataGrid";
import { StringFilter } from "../../../components/dataGrid/fliters";
import { setBreadCrumb } from "../../../../middleware/actions/breadCrumbAction";
import { fetchTemplates, updateTemplate } from "../../../../middleware/services/templatesApi";
import { showAlertBox } from "../../../../middleware/actions/alertBoxAction";
import { getDateFromMongoDate, getLoggedInUser } from "../../../../utilities/index";
import AddPage from "./addPageModal";
import ThreeDotMenu from "../../../components/threeDotMenu";
import { visitRecord, setMaintainedState } from "../../../../middleware/actions/gridAction";

const InlineEdit = (props) => {
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState(props.data["Template name"]);
  const user = getLoggedInUser();
  const saveTitle = async (id) => {
    let saveData = new FormData();
    saveData.append("templateCreatedBy", user._id);
    saveData.append("templateTitle", title);
    try {
      props.dispatch(showLoader());
      const response = await updateTemplate(id, saveData);
      props.dispatch(hideLoader());
      if (response.status == 200) {
        props.dispatch(actionSuccess("Template Title Updated successfully."));
        setEdit(false);
      }
    } catch (e) {
      props.dispatch(hideLoader());
      props.dispatch(actionError("Something went wrong."));
      setEdit(false);
    }
  };
  return (
    <>
      <InputGroup size="sm" className="mb-3" style={{ display: edit ? "" : "none" }}>
        <FormControl value={title} onChange={(e) => setTitle(e.target.value)} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
        <InputGroup.Append>
          <span
            className="span-link text-muted p-2 fas fa-times"
            title="Cancel"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEdit(false);
            }}
          ></span>
        </InputGroup.Append>
        <InputGroup.Append>
          <span
            className="p-2 span-link text-muted p-2 fas fa-save"
            title="Save"
            style={{ cursor: "pointer" }}
            onClick={() => {
              saveTitle(props.data["id"]);
            }}
          ></span>
        </InputGroup.Append>
      </InputGroup>
      <div style={{ display: edit ? "none" : "" }} onClick={() => setEdit(true)}>
        <span>{title}</span>
        <i className="span-link text-muted fas fa-pencil-alt ml-3"></i>
      </div>
    </>
  );
};
export default (props) => {
  const [deleteId, setDeleteId] = useState(undefined);

  const accessPermission = true;
  const productVariant = getProductVariant();
  const dispatch = useDispatch();
  const history = useHistory();
  const showLoaderGrid = () => dispatch(showLoader());
  const hideLoaderGrid = () => dispatch(hideLoader());
  const [showModal, setShowModal] = useState(false);
  const gridState = useSelector((state) => state.gridState);
  /*
		@Description : List of templates columns
	*/
  const templateCol = {
    id: "id",
    "Template name": "templateTitle",
    "Template status": "templateStatus",
    "Create Date": "templateCreatedDate",
    // "Is Live": "templateIsLive",
  };
  // if (productVariant) templateCol["Is Live"] = `productVariant~productName:${productVariant},isLive`;
  // else templateCol["Is Live"] = "productVariant.isLive";

  const breadCrumbLinks = [{ linkUrl: "/template", linkName: "Templates", linkActive: true }];
  /*
		@Description : Use for setting breadcrumb values
	*/

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    return () => dispatch(setBreadCrumb());
  }, [dispatch, breadCrumbLinks]);

  /* 
		@Description : Template Component for action column
	*/
  const actionTemplate = (value) => {
    return (
      <ThreeDotMenu
        rowID={value.id}
        Edit={() => {
          history.push(`/template/edit/${value.id}`);
          dispatch(visitRecord());
        }}
        Delete={async () => {
          dispatch(
            showAlertBox({
              okCallback: async () => {
                const user = getLoggedInUser();
                const params = {
                  templateId: value.id,
                };
                const payload = new FormData();
                payload.append("templateIsDelete", "YES");
                payload.append("templateCreatedBy", user._id);
                updateTemplate(value.id, payload)
                  .then((response) => {
                    setDeleteId(value.id);
                    dispatch(actionSuccess("Template deleted successfully"));
                  })
                  .catch((err) => {
                    dispatch(actionError(err.data?.message || "Fail to delete template"));
                  });
              },
              okText: "Delete",
              cancelText: "Cancel",
              content: "Are you sure you want to delete ?",
              title: "dialogAlertCssWarning",
            })
          );
        }}
      />
    );
  };
  /*
		@Description : Template Component for template title
	*/
  const templateTitleTemplate = (value) => {
    return <InlineEdit dispatch={dispatch} data={value} history={history} />;
  };
  /*
		@Description : Template Component for Date col
	*/
  const templateDateTemplate = (value) => {
    switch (value.column.field) {
      case "Create Date":
        return !isNaN(Date.parse(value["Create Date"])) ? <span>{getDateFromMongoDate(value["Create Date"])}</span> : "";
      default:
        return "";
    }
  };
   
  /*
		@Description : Sending CcolumnFields to Grid
	*/
  const columnFields = [
    { field: "Template name", type: "String", filterTemplate: StringFilter, showInColumnChooser: false, textAlign: "Left" },
    { field: "Create Date", type: "Date", template: templateDateTemplate, textAlign: "Center", headerTextAlign: "Center", width: 150 },
    { field: "Template Status", type: "String", filterTemplate: StringFilter, textAlign: "Left", headerTextAlign: "Left", width: 170 },
    // { field: "Is Live", type: "String", filterTemplate: ItemFilter, filter: { operator: "equal" }, textAlign: "Center", headerTextAlign: "Center", width: 100 },
  ];
  /*
		@Description : Check permission for access column
	*/
  if (accessPermission) {
    columnFields.unshift({ field: null, type: "checkbox", width: 32, allowFiltering: false, showInColumnChooser: false, textAlign: "Center" });
    columnFields.push({
      field: "Action",
      type: "String",
      width: 65,
      template: actionTemplate,
      allowFiltering: false,
      showInColumnChooser: false,
      textAlign: "Right",
      headerTextAlign: "Center",
      allowSorting: false,
      freeze: "Right",
    });
  }

  // formatRowData function required to format rowData for datGrid
  const formatRowData = (rowData) => {
    let formatedRowData = [];

    rowData.forEach((templateData) => {
      let data = {};
      data.id = templateData.id;
      data["Template name"] = templateData.templateTitle;
      data["Create Date"] = new Date(templateData.templateCreatedDate);
      data["Template Status"] = templateData.templateStatus;
      // data["Is Live"] = (!templateData.newsIsLive) ? 'NO' : templateData.newsIsLive;
      // if (templateData.productVariant?.length) {
      // 	if (productVariant) {
      // 		const rDPV = templateData.productVariant.find(pV => pV.productName === productVariant);
      // 		data["Is Live"] = rDPV?.isLive ? rDPV.isLive : "NO";
      // 	} else data["Is Live"] = templateData.productVariant[0].isLive ? templateData.productVariant[0].isLive : "NO";
      // } else data["Is Live"] = "NO";
      formatedRowData.push(data);
    });
    return formatedRowData;
  };

  const fetchTemplateWithProductVariant = (params = {}) => {
    let defaultFilter = [];
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    defaultFilter.push(["templateIsDelete", "eq", "NO"]);
    let { filters = [] } = params;
    params.filters = [...filters, ...defaultFilter];
    if (!params.sort) {
      params.sort = "templateCreatedDate:desc";
    }
    if (!gridState.recordVisited) {
      // if (!params.skip) {
      // 	params.skip = 0
      // }
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (gridState.recordVisited) {
      params = {
        filters: gridState.filtersParams,
        sort: gridState.sortRecords,
        limit: gridState.recordsPerPage,
        skip: gridState.skipRecords,
      };
      dispatch(setMaintainedState(params.skip, params.filters, params.sort));
    }
    if (params.filters?.length === 0) {
      params.filters = [...filters, ...defaultFilter];
      return fetchTemplates(params);
    }
    return fetchTemplates(params);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="gennx-content-wrapper content-wrapper px-3">
        <div className="gennx-grid-container">
          <CommonDataGrid
            gridTitle="Templates"
            fetch={fetchTemplateWithProductVariant}
            deleteOne={() => null}
            deleteMany={false}
            showLoader={showLoaderGrid}
            hideLoader={hideLoaderGrid}
            columnNames={templateCol}
            columnFields={columnFields}
            formatRowData={formatRowData}
            deleteId={deleteId}
            addNewRight={accessPermission}
            addNew={() => setShowModal(true)}
          />
        </div>
      </div>
      <AddPage showModal={showModal} closeModal={closeModal} />
    </>
  );
};
