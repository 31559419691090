import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap"
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setSideNavForcedActiveLink } from "../../../../../../middleware/actions/sideNavAction";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { imageValidationSchema } from "../../../../../helper/validationSchema/productValidationSchema";
import {
    actionSuccess, actionError, showLoader, hideLoader
} from "../../../../../../middleware/actions/utilityAction";
import {
    fetchProductImagesByProductId,
    addProductImages, editProductImage
} from "../../../../../../middleware/services/productApi";
import { PRODUCT_MAX_IMG_SIZE } from "../../../../../../config";


const ImageModal = (props) => {
    const dispatch = useDispatch();
    const [oldData, setOldData] = useState(null);

    const onSaveData = (productId, data) => {
        const formData = new FormData();
        for (let key in data) {
            formData.append("file", data[key]);
        }
        dispatch(showLoader());
        addProductImages(productId, formData).then(() => {
            dispatch(hideLoader());
            dispatch(actionSuccess("Image saved successfully"));
            props.clicked();
            handleReset();
        }).catch(err => {
            dispatch(actionError(err.data.message || "something went wrong"))
        });
    };

    const onUpdateData = (productId, imageId, data) => {
        // console.log(productId, "vvvvv", data);
        const formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        editProductImage(productId, imageId, formData).then((result) => {
            dispatch(actionSuccess("Image updated successfully"));
            props.clicked();
            handleReset();
        }).catch(err => {
            dispatch(actionError(err.data.message || "Something went wrong"))
        })
    };
    const UploadComponent = props => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            accept: "image/png,image/jpg,image/jpeg",
            maxFiles: 1,//comment this line if upload multiple File 
            // maxSize: PRODUCT_MAX_IMG_SIZE,//5 MB
            multiple: false,
            onDrop: acceptedFiles => {
                if (acceptedFiles && acceptedFiles[0].size > PRODUCT_MAX_IMG_SIZE) {
                    props.formik.setTouched({ ...props.formik.touched, imageFile: true });
                    props.formik.setFieldError("imageFile", "File size is more than 5 MB")
                    // dispatch(actionError("File size is more than 5 MB"))
                }
                props.formik.setValues({ ...props.formik.values, imageFile: acceptedFiles.length ? acceptedFiles : "" });
            }
        });
        return (
            <>
                <div className="form-group bg-light p-3 border-dotted mb-1"
                    {...getRootProps()}
                >
                    <input {...getInputProps()}
                        multiple={false}
                    />
                    <div ><button type="button" className="btn btn-dark">UPLOAD IMAGE</button>
                        &nbsp; Or drop image file here</div>
                    {props.formik.values.imageFile &&
                        props.formik.values.imageFile.map((file, i) => (
                            <div className="col-md-9">( {file.name} )</div>
                        ))}
                </div>
                <small style={{ float: "right" }}><b>Note: </b>Only image files (png, jpg) are allowed, and max file size is 5 MB.</small>
                <div className="text-danger" style={{ display: props.formik.touched.imageFile === true ? "block" : "none" }} role="alert">
                    <small>{props.formik.touched.imageFile === true ? props.formik.errors.imageFile || "" : ""}</small>
                </div>
            </>
        );
    };

    const initialFormValues = {
        imageFile: "",
    };

    const formik = useFormik({
        initialValues: {
            ...initialFormValues,
        },
        validationSchema: Yup.object().shape(imageValidationSchema)
    });
    const setFormikValues = (data) => {
        const oldData = {
            imageFile: data.docPath + data.docFileName || "",
        };
        setOldData(oldData);
        formik.setValues(
            oldData
        );
    };

    useEffect(() => {
        dispatch(setSideNavForcedActiveLink("/product"));
        let isMounted = false;
        if (props.editDocumentData) {
            const response = fetchProductImagesByProductId(props.productId, props.imageId);
            response.then((response2) => {
                if (response2.status === 200) {
                    if (!isMounted) {
                        setFormikValues(response2.data.data[0]);
                    }
                } else {
                    dispatch(actionError("Something Went Wrong"))
                    throw Object.assign(
                        new Error("SOMETHING WENT WRONG"),
                        { code: 500 }
                    );
                }
            }).catch((e) => {
                dispatch(actionError("Something Went Wrong while processing."))
            });
        } else {
            formik.resetForm();
        }
        return () => {
            dispatch(setSideNavForcedActiveLink());
        };
    }, [props.editDocumentData, props.productId, props.imageId]);

    const handleReset = () => {
        formik.resetForm();
        props.clicked();
        if (props.editDocumentData) {
            formik.setValues(
                oldData
            );
        }
    };
    const saveDocumentData = async () => {
        const { values } = formik;
        const productImageData = {
            imgFile: values.imageFile[0],
        };

        onSaveData(props.productId, productImageData);
        props.clicked();
        handleReset();

    };
    const handleSubmitDocument = () => {
        formik.validateForm().then((res) => {
            if (Object.keys(res).length) {
                const touched = {};
                Object.keys(res).map((field) => {
                    touched[field] = true;
                    return touched;
                });
                formik.setFormikState({ ...formik, touched: touched, errors: res });
            } else {
                saveDocumentData();
            }
        });
    };
    return (
        <Modal
            show={props.showModal}
            backdrop="static"
            className="modal fade lg"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="modal-header bg-none text-dark">
                <h5 className="modal-title" id="exampleModalLabel">{/*isEdit ? "Edit Location" : */"Add Image"}</h5>
                <button type="button" className="close" aria-label="Close" onClick={handleReset}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <UploadComponent formik={formik} />

                    </div>
                </div>
            </Modal.Body>
            <div className="modal-footer">
                <button type="button" onClick={handleReset} className="btn btn-outline-dark cy-btn mr-3" data-dismiss="modal">Cancel</button>
                <button type="button" onClick={handleSubmitDocument} className="btn btn-primary cy-btn">Save</button>
            </div>
        </Modal>
   )
};

export default ImageModal;
ImageModal.propTypes = {
    productId: PropTypes.string,
    formik: PropTypes.shape({
        setTouched: PropTypes.func,
        setFieldError: PropTypes.func,
        setValues: PropTypes.func,
        touched: PropTypes.object,
        values: PropTypes.object,
        errors: PropTypes.object,
     }),
     clicked: PropTypes.string,
     imageId: PropTypes.string,
     editDocumentData: PropTypes.object,
     showModal: PropTypes.bool
};