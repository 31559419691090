import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionError } from "../../../../../../middleware/actions/utilityAction";
import PropTypes from "prop-types";
import { setSideNavForcedActiveLink } from "../../../../../../middleware/actions/sideNavAction";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { TextField, TextEditor } from "../../../../../components/CustomFormElements/syncFusionFormFields";
import { addDocumentValidationSchema, editDocumentValidationSchema } from "../../../../../helper/validationSchema/productValidationSchema";
import { fetchDocumentByDocumentId, fetchProductDocumentUrl } from "../../../../../../middleware/services/productApi";
import { ckEditorMinimalConfig } from "../../../../../../config/ckEditorConfig";

const DocumentModal = (props) => {
  const [saveBtnText, setSaveBtnText] = useState("Save");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [oldData, setOldData] = useState(null);

  const UploadComponent = (props) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // accept: "image/*",
      accept: ".pdf,.txt,.doc,.docx,.xls,.xlsx,.pptx,.csv",
      maxFiles: 1, //comment this line if upload multiple File
      onDrop: (acceptedFiles) => {
        props.formik.setValues({ ...props.formik.values, documentFile: acceptedFiles });
      },
    });
    return (
      <>
        <div className="form-group bg-light p-3 border-dotted mb-1" {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" className="btn btn-dark">
            Upload Document
          </button>{" "}
          or drop file here
        </div>
        <small style={{ float: "right" }}>
          <b>Note: </b>Only pdf,txt,doc,docx,xls,xlxs,pptx,csv files are allowed.
        </small>
        {props.editDocumentData ? (
          <>
            <br />
            <a href={fetchProductDocumentUrl(props.formik.values.linkFile, props.formik.values.documentFileName)}>
              <i className="fas fa-download" data-tip="Download" />
              {props.formik.values.documentFileName}
            </a>
            <ReactTooltip />
          </>
        ) : (
          ""
        )}

        <div className="text-danger" style={{ display: props.formik.touched.documentFile === true ? "block" : "none" }} role="alert">
          <small>{props.formik.touched.documentFile === true ? props.formik.errors.documentFile || "" : ""}</small>
        </div>
      </>
    );
  };

  const initialFormValues = {
    documentTitle: "",
    documentDescription: "",
    linkFile: "",
    documentFile: "",
    documentFileName: "",
  };

  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object().shape(props.editDocumentData ? editDocumentValidationSchema : addDocumentValidationSchema),
  });
  const setFormikValues = (data) => {
    // console.log('bbbbb', data)
    const oldData = {
      documentTitle: data.docTitle ? data.docTitle : "",
      documentDescription: data.docDescription || "",
      linkFile: data.docFileName || "",
      documentFile: "",
      documentFileName: data.docName || "",
    };
    setOldData(oldData);
    formik.setValues(oldData);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let isMounted = false;
    if (props.editDocumentData) {
      const response = fetchDocumentByDocumentId(props.productId, props.documentId);
      response
        .then((response) => {
          if (response.status === 200) {
            if (!isMounted) {
              setFormikValues(response.data.data[0]);
            }
          } else {
            dispatch(actionError("Something went wrong"));
          }
        })
        .catch((e) => {
          dispatch(actionError("Something Went Wrong while processing"));
        });
    } else {
      formik.resetForm();
    }
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [props.editDocumentData, props.productId, props.documentId, props.isOpen]);

  const handleReset = () => {
    formik.resetForm();
    if (props.editDocumentData) {
      formik.setValues(oldData);
    }
  };
  const saveDocumentData = async () => {
    const { values } = formik;
    const productDocumentData = {
      docTitle: values.documentTitle,
      docDescription: values.documentDescription,
      file: values.documentFile[0],
    };
    if (props.editDocumentData) {
      if (!values.documentFile[0]) delete productDocumentData.file;
    }
    const response = props.editDocumentData
      ? props.onUpdateData(props.productId, props.documentId, productDocumentData)
      : props.onSaveData(props.productId, productDocumentData);
    setSaveBtnText(props.editDocumentData ? "UPDATING..." : "SAVING...");
    setBtnDisabled(true);
    response.then((result) => {
      setSaveBtnText("Save");
      setBtnDisabled(false);
      handleReset();
    });
  };
  const handleSubmitDocument = () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });
        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        saveDocumentData();
      }
    });
  };
  return (
    <Modal
        size="lg"
        show={props.isOpen}
        onHide={() => {
          props.closeHandler();
        }}
        centered
      >
        <Modal.Header className="bg-none text-dark">
          <Modal.Title>{props.editDocumentData ? "Update Document" : "Add New Document"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <TextField formik={formik} id="documentTitle" name="documentTitle" placeholder="Name *" />
                <label style={{ top: "5px", position: "relative" }}>
                  Description <span style={{ color: "#dc3545" }}>*</span>
                </label>
                <TextEditor
                  formik={formik}
                  properties={{
                    id: "documentDescription",
                    name: "documentDescription",
                    activeClass: "documentDescription",
                    // label: "About",
                    config: { ...ckEditorMinimalConfig, editorplaceholder: "Description *", height: "200px" },
                  }}
                />

                <UploadComponent editDocumentData={props.editDocumentData} formik={formik} />
              </div>
              <div className="col-md-12">
                {formik.values.documentFile &&
                  formik.values.documentFile.map((file, i) => (
                    <li key={i}>
                      {`File:${file.name} 												
												`}
                    </li>
                  ))}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => props.closeHandler()} className="btn btn-outline-dark cy-btn mr-3">
            Cancel
          </button>
          <button type="button" onClick={handleSubmitDocument} disabled={btnDisabled} className="btn btn-primary cy-btn">
            {saveBtnText}
          </button>
        </Modal.Footer>
      </Modal>
  );
};

export default DocumentModal;
DocumentModal.propTypes = {
  productId: PropTypes.string,
  editDocumentData: PropTypes.bool.isRequired,
  documentId: PropTypes.string,
  onSaveData: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    setTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    setValues: PropTypes.func,
    touched: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
  }),
  closeHandler:PropTypes.func
};
