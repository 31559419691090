/**
 * @author: Anand Kumar
 * @date : 06-Aug-2020
 * File Description: Tech specification Modal handle add and edit product tech specification UI and API wiring up
 */

import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { Modal } from "react-bootstrap";
import { fetchSingleTechSPecification, fetchTechSpecification } from "../../../../../middleware/services/cmsApi";
import { techSpecificationSchema } from "../../../../helper/validationSchema/productValidationSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Dropdown, AutoCompleteDropdown } from "../../../../components/CustomFormElements/syncFusionFormFields";
import PropTypes from 'prop-types';


// default state
const initialState = {
  specificationName: "",
  specificationUnit: "",
  selectedUnits: "",
  specificationValueFormat: "",
  specificationValueType: "",
  singleValue: "",
};

const TechSpecificationModal = (props) => {
  const { showModal, setShowModal, isEdit, editData } = props;
  const [state, setState] = useState(null);
  const [options, setOptions] = useState([]);

  const formik = useFormik({
    initialValues: {
      ...initialState,
    },
    validationSchema: () =>
      Yup.lazy((values) => {
        if (values.specificationValueType == "NUMERIC") {
          if (typeof techSpecificationSchema.minValue == "undefined") {
            techSpecificationSchema.minValue = Yup.number().required("");
            techSpecificationSchema.maxValue = Yup.number().required("Please enter parameter max value");
          }
          delete techSpecificationSchema.singleValue;
        } else {
          if (typeof techSpecificationSchema.singleValue == "undefined") {
            techSpecificationSchema.singleValue = Yup.string().required("Please enter parameter value");
          }
          delete techSpecificationSchema.minValue;
          delete techSpecificationSchema.maxValue;
        }
        return Yup.object().shape(techSpecificationSchema);
      }),
  });

  const fetchUnitofMeasurementValue = (specificationId) => {
    fetchSingleTechSPecification(specificationId).then((res) => {
      // console.log(res.data.data.specificationUnit, "specification unit");
      const specificationUnits = res.data.data.specificationUnit.map((item) => {
        return { label: item.unitName, value: item.unitId };
      });
      formik.setFieldValue("specificationUnits", specificationUnits);
    });
  };
  const setFormikValues = () => {
    // console.log(editData, "edit data");
    fetchUnitofMeasurementValue(editData.specificationId);
    setState(editData);
    const specificationValueType = editData.specificationValue?.max ? "NUMERIC" : "ALPHA";
    const value =
      specificationValueType == "NUMERIC"
        ? {
            minValue: editData.specificationValue?.min,
            maxValue: editData.specificationValue?.max,
          }
        : { singleValue: editData.specificationValue?.single };
    const formikData = {
      id: editData.id,
      specificationId: editData.specificationId,
      specificationName: editData.specificationName,
      specificationUnit: editData.specificationUnit,
      specificationValueType: specificationValueType,
      ...value,
    };
    setOptions({ label: editData.specificationName, value: editData.specificationId });
    // formik.setFieldValue("specificationUnits", [{ label: editData.specificationUnit, value: editData.specificationUnit }])
    // searchText({text:editData.specificationName,updateData:[]})

    formik.setValues(formikData);
  };

  useEffect(() => {
    if (editData && !state && isEdit) {
      setFormikValues();
    }
    return () => {
      setState(null);
    };
  }, [editData, isEdit]);

  useEffect(() => {
    if (formik.values.specificationUnits) {
      let val = formik.values.specificationUnits.find((s) => s.label === formik.values.specificationUnit);
      val && formik.setFieldValue("specificationUnit", val.value);
    }
  }, [formik.values.specificationUnits]);

  const handleSave = async () => {
    formik.validateForm().then((res) => {
      if (Object.keys(res).length) {
        const touched = {};
        Object.keys(res).map((field) => {
          touched[field] = true;
          return touched;
        });

        formik.setFormikState({ ...formik, touched: touched, errors: res });
      } else {
        props.callBackFunc(formik.values);
        formik.handleReset();
        setState(null);
      }
    });
  };
  /**
	/**
	 * @description fetch parameter 
	 */

  const searchText = useCallback(
    debounce(async (e) => {
      const params = { filters: [["specificationName", "cn", e.text]] };
      const response = await fetchTechSpecification(params);
      if (response.data && response.data.data) {
        setOptions(response.data.data);
        const resData = response.data.data.map((v) => {
          return {
            value: v.id,
            label: v.specificationName,
          };
        });
        if (resData) {
          e.updateData(resData);
        } else {
          e.updateData([]);
        }
      }
    }, 300),
    []
  );

  return (
      <Modal show={showModal} backdrop="static" className="modal fade md" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header bg-none text-dark">
          <h5 className="modal-title" id="exampleModalLabel">
            {isEdit ? "Edit Product Technical Specification" : "Add Product Technical Specification"}
          </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setShowModal();
              formik.handleReset();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <AutoCompleteDropdown
                formik={formik}
                placeholder="Parameter Name *"
                id="specificationName"
                name="specificationName"
                // value={formik.values.country}
                select={(selectedOption) => {
                  if (selectedOption) {
                    let filteredvalues = options.filter((item) => item.id == selectedOption.itemData.value)[0];
                    const specificationUnits = filteredvalues.specificationUnit.map((item) => {
                      return { label: item.unitName, value: item.unitName };
                    });

                    formik.setFieldValue("specificationId", selectedOption.itemData.value);
                    formik.setFieldValue("specificationName", selectedOption.itemData);
                    formik.setFieldValue("specificationUnits", specificationUnits);
                    formik.setFieldValue("specificationUnit", "");
                    formik.setFieldValue("specificationValueFormat", filteredvalues.specificationValueFormat);
                    formik.setFieldValue("specificationValueType", filteredvalues.specificationValueType);
                  } else {
                    formik.setFieldValue("specificationUnit", "");
                    formik.setFieldValue("specificationName", "");
                    formik.setFieldValue("specificationUnits", []);
                  }
                  // data && formik.setValues({
                  // 	...formik.values, "collaborationWithCompanyName": data.itemData.label,
                  // 	"collaborationWithCompanyId": data.itemData.value
                  // })
                }}
                allowFiltering={true}
                filtering={searchText}
                autofill={true}
                fields={{ value: "label", id: "value" }}
                // allowCustom ={false}
              />
              {/* <div className="form-group required">
								<label htmlFor="specificationName"> Parameter Name</label>
								<ParamNameSelect
									formik={formik}
									id="specificationName"
									name="specificationName"
									placeholder="Type to search parameter name"
									isSearchable
									maxMenuHeight={250}
								/>
							</div> */}
            </div>
            <div className="col-md-6">
              <Dropdown
                formik={formik}
                id="specificationUnit"
                name="specificationUnit"
                placeholder="Unit of Measurement *"
                dataSource={formik.values.specificationUnits || []}
                filtering={true}
                fields={{ text: "label", value: "value" }}
              />
            </div>
          </div>
          <div className="row">
            {(formik.values.specificationValueType && formik.values.specificationValueType == "NUMERIC" && (
              <>
                <div className="col-md-6">
                  <TextField formik={formik} type="number" name="minValue" id="minValue" placeholder="Parameter Min Value" />
                </div>
                <div className="col-md-6">
                  <TextField formik={formik} type="number" name="maxValue" id="maxValue" placeholder="Max Value *" />
                </div>
              </>
            )) || (
              <div className="col-md-6">
                <TextField formik={formik} name="singleValue" id="singleValue" className="form-control" placeholder="Parameter Value *" />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-dark cy-btn mr-3"
            onClick={() => {
              formik.handleReset();
              setShowModal();
              setState(null);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary cy-btn"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
   );
};
TechSpecificationModal.propTypes = {
  showModal: PropTypes.bool,        
  setShowModal: PropTypes.func,      
  isEdit: PropTypes.bool,           
  editData: PropTypes.object,
  callBackFunc: PropTypes.func
};
export default TechSpecificationModal;
