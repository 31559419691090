/*================================================================
‘Copyright © 2023, Cheers Interactive Pvt Ltd.  All rights reserved.
   File Description :  My Briefcase Draggable Card
   Summary : My Briefcase
 --------------------------------------------------------------------------------- 
  Creation Details 
  Date Created				: 06/Dec/2023 
  Author				      : Satya Prakash Mall
================================================================ 
*/
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import classes from "./myBriefcase.module.css";
import ImageFile from "../../components/imageFileCard/ImageFile";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { updateBriefcaseSequence } from "../../../middleware/services/myBriefcase";
import { useDispatch } from "react-redux";
import { areArraysDifferent, areObjectsEqual } from "../../../utils";
import { actionError, actionSuccess, hideLoader, showLoader } from "../../../middleware/actions/utilityAction";

const DraggableCard = ({
  id,
  itemCard,
  index,
  moveCard,
  onCheckHandler,
  handleIdValue,
  selectedDoc,
  userId,
  categoryId,
  cards,
  briefcaseCards,
  handleModalShow,
  setContent,
  getCategoryBriefcase,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const handlePreview = (itemData) => {
    if (itemData?.presentationEntityPageId && itemData?.presentationEntityPageHtmlContent) {
      handleModalShow();
      setContent(itemData);
    }
  };

  const [{ handlerId }, drop] = useDrop({
    accept: "PRESENTATION",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!areArraysDifferent(briefcaseCards, cards)) return;
      const payload = cards.reduce((result, data, i) => {
        // Compare each item in cards with the corresponding item in briefcaseCards
        if (!areObjectsEqual(data, briefcaseCards[i])) {
          result.push({
            presentationId: data.id,
            presentationSeqNo: i + 1,
          });
        }
        return result;
      }, []);

      if (!payload.length) return;
      dispatch(showLoader());
      updateBriefcaseSequence(userId, payload)
        .then(() => {
          dispatch(actionSuccess("Briefcase sequenced successfully."));
          getCategoryBriefcase(categoryId, `presentationSeqNo:asc`);
          dispatch(hideLoader());
        })
        .catch((error) => {
          dispatch(actionError(error.response.data.message));
          dispatch(hideLoader());
        });
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, "DropHover");
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "PRESENTATION",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(item.index, index, "DragEnd");
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div style={{ background: isDragging ? "#FFB4BA" : "" }}>
      <div className={classes.bookMarkFiltersImg} ref={preview} style={{ opacity }} data-handler-id={handlerId}>
        <div
          ref={ref}
          className={classes.presentBorder}
          onClick={() => onCheckHandler(itemCard)}
          style={handleIdValue(itemCard.id) ? { border: "1px solid #F94F5E", cursor: "move" } : { border: "", cursor: "move" }}
        >
          <ImageFile
            className={classes.presentationThumbnail}
            fileName={itemCard.presentationThumbnail}
            entityType="presentation"
            draggable="false"
          />
        </div>
        <div className={classes.presentation_title_container}>
          <div className={classes.presentation_title}>{itemCard?.presentationTitle}</div>
          <div className={classes.presentation_info_content}>
            <p
              style={{ marginBottom: "0px" }}
              dangerouslySetInnerHTML={{
                __html: itemCard?.presentationTitle,
              }}
            />
          </div>
        </div>
        <div className={classes.singleDownDeleteDiv}>
          <div className={classes.card_cal}>
            <span style={{ fontSize: "12px", color: "#939399" }}>Added on</span>
            <span style={{ paddingLeft: "4px", fontSize: "12px", color: "#4B4B55" }}>
              {moment(itemCard.presentationCreatedDate).format("DD MMM YYYY")}
            </span>
            {itemCard?.presentationContentType && <div style={{ paddingLeft: "8px" }}>|</div>}
            <p style={{ paddingLeft: "8px", fontSize: "12px", color: "#4B4B55", marginBottom: "0px" }}>{itemCard?.presentationContentType}</p>
          </div>
          <div
            style={{
              width: "24px",
              height: "24px",
              border: "1px solid #DBDBDD",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src="/assets/eye.svg" alt="eye" width={18} style={{ color: "#707070", cursor: "pointer" }} onClick={() => handlePreview(itemCard)} />
          </div>
        </div>

        <div className={selectedDoc.length ? classes.showCheckBox : classes.forgot_check}>
          <Checkbox
            id="check"
            checked={handleIdValue(itemCard.id)}
            onChange={() => {
              onCheckHandler(itemCard);
            }}
            sx={{
              borderRadius: "2px",
              borderWidth: "2px",
              height: "20px",
              width: "20px",
              marginTop: "-4px",
              marginRight: "0px",
              color: "#B7B7BB",
              "&.Mui-checked": { color: "#f94f5e" },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DraggableCard;
